import React, { ReactNode } from 'react';
import './Body.css';

type Props = {
    children: ReactNode
}

export default function Body(props: Props) {
    return (
        <div className="body p-4">
            {props.children}
        </div>
    )
}
