import React, {useRef, useState, useEffect} from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import {Link} from "react-router-dom";
import './Dashboard.css';
import {
    useFindings,
    usePostCloseAudits,
    usePrefundAudits,
    useServicingAudits
} from "../../state/sampleAuditsExceptionsState";
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';

import { NavLink, useNavigate } from "react-router-dom";


export default function Dashboard() {
    const navigate = useNavigate()

    const { prefundData, prefundError } = usePrefundAudits();
    const { postCloseData, postCloseError } = usePostCloseAudits();
    const { serviceData, serviceError } = useServicingAudits();

    const toast = useRef<Toast>(null);
    const op = useRef<OverlayPanel>(null);
    const _opMsg = useRef("");
    const [_opMsgState] = useState(_opMsg.current);

    const isQCValidator = useHasRole([
        'QC Validator'
    ]).hasRoles

    useEffect(() => {
        if (prefundError || postCloseError || serviceError) {
            // alert('Error saving user');
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
        }
    }, [prefundError, postCloseError, serviceError]);

     // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let {hasRoles, isLoading} = useHasRole(['Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager', 'QC Auditor', 'System Administrator'])

    
    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        if (isQCValidator) {
            navigate('/Validations')
        }
        return NotFound();
    }

    return (
        <div>
            <Nav />

            <Body>
                <OverlayPanel ref={op}>
                    <div>{_opMsg.current}</div>
                </OverlayPanel>
                <Toast ref={toast} position="bottom-center" />
                <div className="dashboard">
                    {
                        true && (
                            <div className="dashboard-item">
                                <h2>Prefund Audits</h2>
                                {prefundData.Total == undefined ?
                                    <div>
                                        <p></p>
                                        <p className="loading"></p>
                                        <p></p>
                                    </div>
                                    :
                                    <p>{prefundData.Total}</p>
                                }
                                <Link to={'/prefund-qc-audits'}>View All <i className="pi pi-arrow-right" /></Link>
                            </div>
                        )
                    }

                    {
                        true && (
                            <div className="dashboard-item">
                                <h2>Post Close Audits</h2>
                                {postCloseData.Total == undefined ?
                                    <div>
                                        <p></p>
                                        <p className="loading"></p>
                                        <p></p>
                                    </div>
                                    :
                                    <p>{postCloseData.Total}</p>
                                }
                                <Link to={'/post-close-qc-audits'}>View All <i className="pi pi-arrow-right" /></Link>
                            </div>
                        )
                    }

                    {
                        true && (
                            <div className="dashboard-item">
                                <h2>Servicing Audits</h2>
                                {serviceData.Total == undefined ?
                                    <div>
                                        <p></p>
                                        <p className="loading"></p>
                                        <p></p>
                                    </div>
                                    :
                                    <p>{serviceData.Total}</p>
                                }
                                <Link to={'/service-audits'}>View All <i className="pi pi-arrow-right" /></Link>
                            </div>
                        )
                    }
                </div>
            </Body>
        </div>
    )
}
