import {useQuery} from '@tanstack/react-query';
import {authenticatedFetch} from "./apiUtils";

export interface Exception {
    id: number;
    exceptionReviewName: string;
    loanNumber: string;
    exceptionStage: string;
    initialQcTestResult: string;
    finalQcTestResult: string;
    fundingMonth: string;
    prefundAuditPlan: string;
    postCloseAuditPlan: string;
    ownerLastName: string;
    createdDateTime: string;
}

export interface PrefundAudit {
    Id: number;
    CreatedDateTime: string;
    AuditStage: string;
    PrefundAuditPlanName: string;
    AuditPlanStatus: string;
    QcTestResults: string;
    FinalQcTestResults: string;
    Channel: string;
    LoanType: string;
    Investor: string;
    Client: string;
    Owner: string;
    PrefundQcAuditId: string;
}

export interface PostCloseAudit {
    Id: number;
    CreatedDateTime: string;
    PostCloseAuditPlanName: string;
    QcTestResults: string;
    AuditStage: string;
    AuditPlanStatus: string;
    Client: string;
    FundingMonth: string;
    OwnerAlias: string;
}

export interface ServiceAudit {
    Id: number;
    CreatedDateTime: string;
    ServiceAuditPlanName: string;
    QcTestResults: string;
    AuditStage: string;
    AuditPlanStatus: string;
    Client: string;
    FundingMonth: string;
    OwnerAlias: string;
}

export const useExceptions = (): Array<Exception> => {
    return [
        {
            id: 1,
            exceptionReviewName: "Exception Review 1",
            loanNumber: "222222",
            exceptionStage: "New",
            initialQcTestResult: "Finding",
            finalQcTestResult: "Remained Finding",
            fundingMonth: "8/31/2021",
            prefundAuditPlan: "Prefund Audit Plan 1",
            postCloseAuditPlan: "Post Close Audit Plan 1",
            ownerLastName: "Doe",
            createdDateTime: "2021-01-01 12:00:00",
        },
        {
            id: 2,
            exceptionReviewName: "Exception Review 2",
            loanNumber: "333333",
            exceptionStage: "New",
            initialQcTestResult: "Finding",
            finalQcTestResult: "Remained Finding",
            fundingMonth: "8/31/2021",
            prefundAuditPlan: "Prefund Audit Plan 2",
            postCloseAuditPlan: "Post Close Audit Plan 2",
            ownerLastName: "Doe",
            createdDateTime: "2021-01-01 12:00:00",
        },
        {
            id: 3,
            exceptionReviewName: "Exception Review 3",
            loanNumber: "444444",
            exceptionStage: "New",
            initialQcTestResult: "Finding",
            finalQcTestResult: "Remained Finding",
            fundingMonth: "8/31/2021",
            prefundAuditPlan: "Prefund Audit Plan 3",
            postCloseAuditPlan: "Post Close Audit Plan 3",
            ownerLastName: "Doe",
            createdDateTime: "2021-01-01 12:00:00",
        },
        {
            id: 4,
            exceptionReviewName: "Exception Review 4",
            loanNumber: "555555",
            exceptionStage: "New",
            initialQcTestResult: "Finding",
            finalQcTestResult: "Remained Finding",
            fundingMonth: "8/31/2021",
            prefundAuditPlan: "Prefund Audit Plan 4",
            postCloseAuditPlan: "Post Close Audit Plan 4",
            ownerLastName: "Doe",
            createdDateTime: "2021-01-01 12:00:00",
        }
    ];
}

async function fetchPrefundAudits() {
    return authenticatedFetch("/dashboard/GetPrefundOverview");
}

async function fetchPostCloseAudits() {
    return authenticatedFetch("/dashboard/GetPostcloseOverview");
}

async function fetchServicingAudits() {
    return authenticatedFetch("/dashboard/GetServicingOverview");
}


async function fetchFindings() {
    return authenticatedFetch("/dashboard/GetFindingOverview");
}

export const usePrefundAudits = () => {
    const { status, data, error } = useQuery({
        queryKey: ['prefundAudits'],
        queryFn: fetchPrefundAudits,
    });

    //const prefundData = data as PrefundAudit[] || [];
    return { prefundStatus: status,prefundData: data || [], prefundError: error };
};

export const usePostCloseAudits = () => {
    const { status, data, error } = useQuery({
        queryKey: ['postCloseAudits'],
        queryFn: fetchPostCloseAudits,
    });

    //const postCloseData = data as PostCloseAudit[] || [];
    return { postCloseStatus: status,postCloseData: data || [], postCloseError: error };
};

export const useServicingAudits = () => {
    const { status, data, error } = useQuery({
        queryKey: ['serviceAudits'],
        queryFn: fetchServicingAudits,
    });

    //const serviceData = data as ServiceAudit[] || [];
    return { serviceStatus: status,serviceData: data || [], serviceError: error };
};


export const useFindings = () => {
    const { status, data, error } = useQuery({
        queryKey: ['findings'],
        queryFn: fetchFindings,
    });

    //const serviceData = data as ServiceAudit[] || [];
    return { findingStatus: status, findingData: data || [], findingError: error };
};
