import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { authenticatedFetch } from "../state/apiUtils";

interface UserPermissionsState {
    validRoles: string[];
}

interface UserPermissionsContextProps {
    validRoles: UserPermissionsState;
    isLoading: boolean;
    requestValidRoles: () => void;
}

const defaultState: UserPermissionsState = {
    validRoles: []
};

const defaultContextValue: UserPermissionsContextProps = {
    validRoles: defaultState,
    isLoading: false,
    requestValidRoles: () => {}
};

export const UserPermissionsContext = createContext<UserPermissionsContextProps>(defaultContextValue);

export const useUserPermissions = () => useContext(UserPermissionsContext);

interface Props {
    children: ReactNode;
}

export const UserPermissionsProvider: React.FC<Props> = ({ children }) => {
    const [validRoles, setValidRoles] = useState<UserPermissionsState>(defaultState);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchValidRoles = async () => {
        setIsLoading(true);
        try {
            const response = await authenticatedFetch(`/Roles/GetRolesForUser`);
            setValidRoles({ validRoles: response });
        } catch (error) {
            console.error('Error fetching permissions:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchValidRoles();
    }, []);

    return (
        <UserPermissionsContext.Provider value={{ validRoles, isLoading, requestValidRoles: fetchValidRoles }}>
            {children}
        </UserPermissionsContext.Provider>
    );
};
