import React, { useEffect, useRef, useState } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';
import './AccountForm.css';
import { Button } from "primereact/button";
import { useAccount, useCreateAccount, useUpdateAccount } from '../../state/accountState';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";

interface State {
    name: string;
    code: string;
}

export default function AccountForm() {
    const navigate = useNavigate();
    const { id } = useParams();
    const isEdit = id != undefined;
    const { accountStatus, accountData, accountError } = useAccount(id);
    const [account, setAccount] = useState(accountData);
    // Set up isvalid for validation checking
    const [isValid, setIsValid] = useState<any>({
        AccountName: true,
        Street: true,
        City: true,
        State: true,
        PostalCode: true,
        Country: true,
        RecordStatus: true,
    });
    const toast = useRef<Toast>(null);
    const op = useRef<OverlayPanel>(null);
    const _opMsg = useRef("");
    const { mutate: createAccount, isSuccess: isCreateSuccess, isError: isCreateError } = useCreateAccount();
    const { mutate: updateAccount, isSuccess: isUpdateSuccess, isError: isUpdateError } = useUpdateAccount();

    const states: State[] = [
        { name: 'Alabama', code: 'AL' },
        { name: 'Alaska', code: 'AK' },
        { name: 'Arizona', code: 'AZ' },
        { name: 'Arkansas', code: 'AR' },
        { name: 'California', code: 'CA' },
        { name: 'Colorado', code: 'CO' },
        { name: 'Connecticut', code: 'CT' },
        { name: 'Delaware', code: 'DE' },
        { name: 'District of Columbia', code: 'DC' },
        { name: 'Florida', code: 'FL' },
        { name: 'Georgia', code: 'GA' },
        { name: 'Guam', code: 'GU' },
        { name: 'Hawaii', code: 'HI' },
        { name: 'Idaho', code: 'ID' },
        { name: 'Illinois', code: 'IL' },
        { name: 'Indiana', code: 'IN' },
        { name: 'Iowa', code: 'IA' },
        { name: 'Kansas', code: 'KS' },
        { name: 'Kentucky', code: 'KY' },
        { name: 'Louisiana', code: 'LA' },
        { name: 'Maine', code: 'ME' },
        { name: 'Maryland', code: 'MD' },
        { name: 'Massachusetts', code: 'MA' },
        { name: 'Michigan', code: 'MI' },
        { name: 'Minnesota', code: 'MN' },
        { name: 'Mississippi', code: 'MS' },
        { name: 'Missouri', code: 'MO' },
        { name: 'Montana', code: 'MT' },
        { name: 'Nebraska', code: 'NE' },
        { name: 'Nevada', code: 'NV' },
        { name: 'New Hampshire', code: 'NH' },
        { name: 'New Jersey', code: 'NJ' },
        { name: 'New Mexico', code: 'NM' },
        { name: 'New York', code: 'NY' },
        { name: 'North Carolina', code: 'NC' },
        { name: 'North Dakota', code: 'ND' },
        { name: 'Northern Mariana Islands', code: 'MP' },
        { name: 'Ohio', code: 'OH' },
        { name: 'Oklahoma', code: 'OK' },
        { name: 'Oregon', code: 'OR' },
        { name: 'Pennsylvania', code: 'PA' },
        { name: 'Puerto Rico', code: 'PR' },
        { name: 'Rhode Island', code: 'RI' },
        { name: 'South Carolina', code: 'SC' },
        { name: 'South Dakota', code: 'SD' },
        { name: 'Tennessee', code: 'TN' },
        { name: 'Texas', code: 'TX' },
        { name: 'Trust Territories', code: 'TT' },
        { name: 'Utah', code: 'UT' },
        { name: 'Vermont', code: 'VT' },
        { name: 'Virginia', code: 'VA' },
        { name: 'Virgin Islands', code: 'VI' },
        { name: 'Washington', code: 'WA' },
        { name: 'West Virginia', code: 'WV' },
        { name: 'Wisconsin', code: 'WI' },
        { name: 'Wyoming', code: 'WY' }
    ];

    useEffect(() => {
        setAccount(accountData);
    }, [accountData]);

    // Function to check if anything is invalid
    function checkValid(e: any): boolean {
        let hasError = false;
        // console.log("EEE?", e)
        if (e === null) {
            hasError = true;
            setIsValid({
                AccountName: false,
                Street: false,
                City: false,
                State: false,
                PostalCode: false,
                Country: false,
                RecordStatus: false,
            })
        }
        else {
            if (e.AccountName === null || e.AccountName === "" || e.AccountName === undefined) {
                // setIsValid({ ...isValid, ["AzureObjectId"]: false })
                handleErrors("AccountName", e.AccountName);
            }
            if (e.Street === null || e.Street === "" || e.Street === undefined) {
                // setIsValid({ ...isValid, ["FirstName"]: false })
                handleErrors("Street", e.Street);
                hasError = true;
            }
            if (e.City === null || e.City === "" || e.City === undefined) {
                // setIsValid({ ...isValid, ["LastName"]: false })
                handleErrors("City", e.City);
                // console.log("Setting last name to false!!!!!")
                hasError = true;
            }
            if (e.State === null || e.State === "" || e.State === undefined) {
                // setIsValid({ ...isValid, ["Email"]: false })
                handleErrors("State", e.Email);
                hasError = true;
            }
            if (e.PostalCode === null || e.PostalCode === "" || e.PostalCode === undefined) {
                // setIsValid({ ...isValid, ["Phone"]: false })
                handleErrors("PostalCode", e.PostalCode);
                hasError = true;
            }
            if (e.Country === null || e.Country === "" || e.Country === undefined) {
                // setIsValid({ ...isValid, ["Account"]: false })
                handleErrors("Country", e.Country);
                hasError = true;
            }
            if (e.RecordStatus === null || e.RecordStatus === "" || e.RecordStatus === undefined) {
                // setIsValid({ ...isValid, ["RecordStatus"]: false })
                handleErrors("RecordStatus", e.RecordStatus);
                hasError = true;
            }
        }
        // console.log(isValid);
        return hasError;
    }

    const checkSave = (e: any) => {
        let hasError = checkValid(e);
        // If check valid returns true, then don't save and show an error
        if (hasError) {
            toast.current?.show({ severity: 'warn', summary: 'Error', detail: 'One or more required items were incomplete.', life: 10000 });
            return
        }
        // Else, go ahead and save
        handleSave();
    }


    const handleSave = () => {
        if (isEdit) {
            updateAccount(account);
        } else {
            createAccount(account);
        }

    };

    useEffect(() => {
        if (isCreateSuccess || isUpdateSuccess) {
            toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Account successfully saved.', life: 3000 });
            navigate('/accounts');
        }
    }, [isCreateSuccess, isUpdateSuccess, navigate]);

    useEffect(() => {
        if (isCreateError || isUpdateError) {
            // alert('Error saving account');
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'There was a server error while saving the account. Please try again later.', life: 10000 });
        }
    }, [isCreateError, isUpdateError]);

    const handleChange = (field: string, value: string) => {
        setAccount({ ...account, [field]: value });
        handleErrors(field, value);
    };

    const handleErrors = (field: string, value: string) => {
        if (value === undefined || value === null || value === '') {
            // setIsValid({ ...isValid, [field]: false })
            setIsValid((errors: any) => ({ ...errors, [field]: false }))
            console.log("Setting something false!")
        }
        else {
            setIsValid((errors: any) => ({ ...errors, [field]: true }))
        }
        console.log("Set this??", isValid)
    }

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let {hasRoles, isLoading} = useHasRole(['System Administrator'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div className="account-form">
            <Nav />
            <Body>
                <div>
                    <OverlayPanel ref={op}>
                        <div>{_opMsg.current}</div>
                    </OverlayPanel>
                    <Toast ref={toast} position="bottom-center" />
                    <div className="flex justify-content-between">
                        <h1>Account</h1>
                        <Button label="Save" icon="pi pi-save" onClick={() => checkSave(account)} />
                    </div>

                    <div className="form">
                        <div className="form-column gap-5">
                            <span className="p-float-label">
                                <InputText
                                    id="AccountName"
                                    value={account?.AccountName}
                                    onChange={(e) => handleChange('AccountName', e.target.value)}
                                    className={isValid.AccountName === false ? 'p-invalid ' : ''}

                                />
                                <label htmlFor="AccountName">Account Name*</label>
                            </span>
                            <span className="p-float-label">
                                <InputText
                                    id="Street"
                                    value={account?.Street}
                                    onChange={(e) => handleChange('Street', e.target.value)}
                                    className={isValid.Street === false ? 'p-invalid ' : ''}

                                />
                                <label htmlFor="Street">Street*</label>
                            </span>
                            <span className="p-float-label">
                                <InputText
                                    id="City"
                                    value={account?.City}
                                    onChange={(e) => handleChange('City', e.target.value)}
                                    className={isValid.City === false ? 'p-invalid ' : ''}

                        />
                        <label htmlFor="City">City*</label>
                    </span>
                    <span className="p-label">
                        {/* <InputText
                            id="State"
                            value={account?.State}
                            onChange={(e) => handleChange('State', e.target.value)}
                            className={isValid.State === false ? 'p-invalid ' : ''}

                        /> */}
                        <label htmlFor="State">State*</label>
                        <Dropdown value={account?.State}
                                onChange={(e) => handleChange('State', e.value.code)}
                                options={states} optionLabel="name"
                                placeholder={(account?.State !== null) && (account?.State !== undefined) && (account?.State !== "") ?
                                    account?.State
                                    :
                                    "Please Select"}
                                filter
                                  className={isValid.State === false ? 'w-full p-invalid' : 'w-full'}
                        />
                    </span>
                    <span className="p-float-label">
                        <InputText
                            id="PostalCode"
                            value={account?.PostalCode}
                            onChange={(e) => handleChange('PostalCode', e.target.value)}
                            className={isValid.PostalCode === false ? 'p-invalid ' : ''}

                                />
                                <label htmlFor="PostalCode">Postal Code*</label>
                            </span>
                            <span className="p-float-label">
                                <InputText
                                    id="Country"
                                    value={account?.Country}
                                    onChange={(e) => handleChange('Country', e.target.value)}
                                    className={isValid.Country === false ? 'p-invalid ' : ''}

                                />
                                <label htmlFor="Country">Country*</label>
                            </span>
                        </div>

                        <div className="form-column gap-5">
                            <span className="p-float-label">
                                <Dropdown
                                    value={account?.RecordStatus}
                                    onChange={(e) => handleChange('RecordStatus', e.target.value)}
                                    options={["Active", "Inactive"]}
                                    className={isValid.RecordStatus === false ? 'w-full p-invalid' : 'w-full'}
                                    id="recordStatus"
                                />
                                <label htmlFor="recordStatus">Record Status*</label>
                            </span>

                            <span className="p-float-label">
                                <InputText
                                    id="ShippingStreet"
                                    value={account?.ShippingStreet}
                                    onChange={(e) => handleChange('ShippingStreet', e.target.value)}
                                    // className={account?.ShippingStreet === "" ? 'p-invalid ' : ''}

                                />
                                <label htmlFor="ShippingStreet">Shipping Street</label>
                            </span>
                            <span className="p-float-label">
                                <InputText
                                    id="ShippingCity"
                                    value={account?.ShippingCity}
                                    onChange={(e) => handleChange('ShippingCity', e.target.value)}
                                    // className={account?.ShippingCity === "" ? 'p-invalid ' : ''}

                                />
                        <label htmlFor="ShippingCity">Shipping City</label>
                    </span>
                            <span className="p-label">
                    <label htmlFor="ShippingState">Shipping State</label>
                    <Dropdown value={account?.ShippingState}
                              onChange={(e) => handleChange('ShippingState', e.value.code)}
                              options={states} optionLabel="name"
                              placeholder={(account?.ShippingState !== null) && (account?.ShippingState !== undefined) && (account?.ShippingState !== "") ?
                                  account?.ShippingState
                                  :
                                  "Please Select"}
                              filter
                              className="w-full"/>
                                {/* <InputText
                            id="ShippingState"
                            value={account?.ShippingState}
                            onChange={(e) => handleChange('ShippingState', e.target.value)}
                        // className={account?.ShippingState === "" ? 'p-invalid ' : ''}

                        />
                        <label htmlFor="ShippingState">Shipping State</label> */}
                    </span>
                            <span className="p-float-label">
                        <InputText
                            id="ShippingPostalCode"
                            value={account?.ShippingPostalCode}
                            onChange={(e) => handleChange('ShippingPostalCode', e.target.value)}
                            // className={account?.ShippingPostalCode === "" ? 'p-invalid ' : ''}

                        />
                                <label htmlFor="ShippingPostalCode">Shipping Postal Code</label>
                            </span>
                            <span className="p-float-label">
                                <InputText
                                    id="ShippingCountry"
                                    value={account?.ShippingCountry}
                                    onChange={(e) => handleChange('ShippingCountry', e.target.value)}
                                    // className={account?.ShippingCountry === "" ? 'p-invalid ' : ''}

                                />
                                <label htmlFor="ShippingCountry">Shipping Country</label>
                            </span>
                        </div>
                    </div>
                </div>
            </Body>
        </div>
    );
}
