import React, { useRef, useState } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useReport } from '../../state/reportsState';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

export default function PrefundAuditPlanSubmissions() {
    const reportTitle = "Prefund Audit Plan Submissions";
    const dt = useRef(null);
    const {reportStatus, reportData} = useReport("prefundauditplansubmissions");
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [globalFilter, setGlobalFilter] = useState(null);

    const cols = [
        { field: 'AccountName', header: 'Account', width: '15rem' },
        { field: 'PlanName', header: 'Plan', width: '20rem' },
        { field: 'CreatedDate', header: 'CreateDate', width: '13rem' },
        { field: 'LoanNumber', header: 'Loan Number', width: '13rem' },
        { field: 'BorrowersFirstName', header: 'Borrowers First Name', width: '13rem' },
        { field: 'BorrowersLastName', header: 'Borrowers Last Name', width: '13rem' },
        { field: 'Channel', header: 'Channel', width: '13rem' },
        { field: 'AuditPlanStatus', header: 'Audit Plan Status', width: '13rem' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        AccountName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PlanName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreatedDate: { value: null, matchMode: FilterMatchMode.EQUALS },
        LoanNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        BorrowersFirstName: { value: null, matchMode: FilterMatchMode.EQUALS },
        BorrowersLastName: { value: null, matchMode: FilterMatchMode.EQUALS },
        Channel: { value: null, matchMode: FilterMatchMode.EQUALS },
        AuditPlanStatus: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const exportCSV = (selectionOnly: boolean) => {
        //dt.exportCSV();
    };

    const onGlobalFilterChange = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <span className="p-input-icon-left">
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="Export CSV" />   
            </span>
        </div>
        </div>
    );

    return (
        <div>
            <Nav />
            <Body>
            <h1 className="m-0">{reportTitle}</h1>
            <DataTable ref={dt} value={reportData} tableStyle={{ minWidth: '25rem' }}
                    filters={filters} filterDisplay="row" showGridlines stripedRows
                    emptyMessage="No records found" alwaysShowPaginator={false} selectionMode="single"
                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    >
                {cols.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} style={{ minWidth: col.width }} filter sortable />
                ))}
            </DataTable>
            </Body>
        </div>
    )
}
