import React, { useContext, useEffect, useRef, useMemo } from 'react';
import { Menubar } from 'primereact/menubar';
import Search from "./Search";
import { NavLink, useNavigate } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import './Nav.css';
import { Image } from "primereact/image";
import useHasRole from "../hooks/validRolesHook";
import { UserPermissionsContext } from "../contexts/userPermissionsContext";

interface Item {
    label: string;
    items?: Item[];
    command?: () => void;
}

export default function Nav() {
    const navigate = useNavigate()
    const notificationOverlayRef = useRef(null);
    const [adminItems, setAdminItems] = React.useState<Item[]>();

    const { validRoles } = useContext(UserPermissionsContext);

    const isSysAdmin = useHasRole([
        'System Administrator'
    ]).hasRoles

    const isAccountAdmin = useHasRole([
        'Account Administrator'
    ]).hasRoles

    const isAccountManager = useHasRole([
        'Account Manager'
    ]).hasRoles

    const isQCAdmin = useHasRole([
        'QC Admin'
    ]).hasRoles

    const isQCManager = useHasRole([
        'QC Manager'
    ]).hasRoles

    const isQCAuditor = useHasRole([
        'QC Auditor'
    ]).hasRoles

    const isQCValidator = useHasRole([
        'QC Validator'
    ]).hasRoles

    useEffect(() => {
        if (isSysAdmin) {
            setAdminItems([
                {
                    label: "Dashboard", command: () => {
                        navigate('/')
                    }
                },
                {
                    label: "My Work Pages",
                    items: [
                        {
                            label: "QC Work", command: () => {
                                navigate('/worklist')
                            }
                        },
                        {
                            label: "Validation Work", command: () => {
                                navigate('/Validations')
                            }
                        },
                    ]

                },
                {
                    label: 'File Upload', command: () => {
                        navigate('/upload')
                    }
                },
                {
                    label: 'Audits',
                    items: [
                        {
                            label: "Prefund Audits", command: () => {
                                navigate('/prefund-qc-audits')
                            }
                        },
                        {
                            label: "Post Close Audits", command: () => {
                                navigate('/post-close-qc-audits')
                            }
                        },
                        {
                            label: "Servicing Audits", command: () => {
                                navigate('/service-audits')
                            }
                        },
                        {
                            label: "Findings", command: () => {
                                navigate('/FindingList')
                            }
                        }
                    ]
                },
                {
                    label: 'QC Reporting', command: () => {
                        navigate('/reporting')
                    }
                },
                // {
                //     label: 'QC Analytics', command: () => {
                //         navigate('/analytics')
                //     }
                // },
                {
                    label: 'QC Checklist', command: () => {
                        navigate('/checklist')
                    }
                },
                {
                    label: 'Admin',
                    items: [
                        {
                            label: "Users", command: () => {
                                navigate('/users')
                            }
                        },
                        {
                            label: "Accounts", command: () => {
                                navigate('/accounts')
                            }
                        },
                        {
                            label: "Client Banks", command: () => {
                                navigate('/clientbanks')
                            }
                        },
                    ]
                }
            ]);
        }
        else if (isAccountAdmin) {
            setAdminItems([
                {
                    label: "Dashboard", command: () => {
                        navigate('/')
                    }
                },
                {
                    label: "QC Work", command: () => {
                        navigate('/worklist')
                    }
                },
                {
                    label: 'File Upload', command: () => {
                        navigate('/upload')
                    }
                },
                {
                    label: 'Audits',
                    items: [
                        {
                            label: "Prefund Audits", command: () => {
                                navigate('/prefund-qc-audits')
                            }
                        },
                        {
                            label: "Post Close Audits", command: () => {
                                navigate('/post-close-qc-audits')
                            }
                        },
                        {
                            label: "Servicing Audits", command: () => {
                                navigate('/service-audits')
                            }
                        },
                        {
                            label: "Findings", command: () => {
                                navigate('/FindingList')
                            }
                        }
                    ]
                },
                {
                    label: 'QC Reporting', command: () => {
                        navigate('/reporting')
                    }
                },
                // {
                //     label: 'QC Analytics', command: () => {
                //         navigate('/analytics')
                //     }
                // },
                {
                    label: 'QC Checklist', command: () => {
                        navigate('/checklist')
                    }
                },
                {
                    label: 'Admin',
                    items: [
                        {
                            label: "Users", command: () => {
                                navigate('/users')
                            }
                        },
                        {
                            label: "Client Banks", command: () => {
                                navigate('/clientbanks')
                            }
                        },
                    ]
                }
            ]);
        }
        else if (isAccountManager) {
            setAdminItems([
                {
                    label: "Dashboard", command: () => {
                        navigate('/')
                    }
                },
                {
                    label: "QC Work", command: () => {
                        navigate('/worklist')
                    }
                },
                {
                    label: 'File Upload', command: () => {
                        navigate('/upload')
                    }
                },
                {
                    label: 'Audits',
                    items: [
                        {
                            label: "Prefund Audits", command: () => {
                                navigate('/prefund-qc-audits')
                            }
                        },
                        {
                            label: "Post Close Audits", command: () => {
                                navigate('/post-close-qc-audits')
                            }
                        },
                        {
                            label: "Servicing Audits", command: () => {
                                navigate('/service-audits')
                            }
                        },
                        {
                            label: "Findings", command: () => {
                                navigate('/FindingList')
                            }
                        }
                    ]
                },
                {
                    label: 'QC Reporting', command: () => {
                        navigate('/reporting')
                    }
                },
                // {
                //     label: 'QC Analytics', command: () => {
                //         navigate('/analytics')
                //     }
                // },
                {
                    label: 'QC Checklist', command: () => {
                        navigate('/checklist')
                    }
                },
                {
                    label: 'Admin',
                    items: [
                        {
                            label: "Users", command: () => {
                                navigate('/users')
                            }
                        },
                        {
                            label: "Client Banks", command: () => {
                                navigate('/clientbanks')
                            }
                        },
                    ]
                }
            ]);
        }
        else if (isQCAdmin) {
            setAdminItems([
                {
                    label: "Dashboard", command: () => {
                        navigate('/')
                    }
                },
                {
                    label: "My Work Pages",
                    items: [
                        {
                            label: "QC Work", command: () => {
                                navigate('/worklist')
                            }
                        },
                        {
                            label: "Validation Work", command: () => {
                                navigate('/Validations')
                            }
                        },
                    ]

                },
                {
                    label: 'File Upload', command: () => {
                        navigate('/upload')
                    }
                },
                {
                    label: 'Audits',
                    items: [
                        {
                            label: "Prefund Audits", command: () => {
                                navigate('/prefund-qc-audits')
                            }
                        },
                        {
                            label: "Post Close Audits", command: () => {
                                navigate('/post-close-qc-audits')
                            }
                        },
                        {
                            label: "Servicing Audits", command: () => {
                                navigate('/service-audits')
                            }
                        },
                        {
                            label: "Findings", command: () => {
                                navigate('/FindingList')
                            }
                        }
                    ]
                },
                {
                    label: 'QC Reporting', command: () => {
                        navigate('/reporting')
                    }
                },
                // {
                //     label: 'QC Analytics', command: () => {
                //         navigate('/analytics')
                //     }
                // },
                {
                    label: 'QC Checklist', command: () => {
                        navigate('/checklist')
                    }
                },
            ]);
        }
        else if (isQCManager) {
            setAdminItems([
                {
                    label: "Dashboard", command: () => {
                        navigate('/')
                    }
                },
                {
                    label: "QC Work", command: () => {
                        navigate('/worklist')
                    }
                },
                {
                    label: 'File Upload', command: () => {
                        navigate('/upload')
                    }
                },
                {
                    label: 'Audits',
                    items: [
                        {
                            label: "Prefund Audits", command: () => {
                                navigate('/prefund-qc-audits')
                            }
                        },
                        {
                            label: "Post Close Audits", command: () => {
                                navigate('/post-close-qc-audits')
                            }
                        },
                        {
                            label: "Servicing Audits", command: () => {
                                navigate('/service-audits')
                            }
                        },
                        {
                            label: "Findings", command: () => {
                                navigate('/FindingList')
                            }
                        }
                    ]
                },
                {
                    label: 'QC Reporting', command: () => {
                        navigate('/reporting')
                    }
                },
                // {
                //     label: 'QC Analytics', command: () => {
                //         navigate('/analytics')
                //     }
                // },
                {
                    label: 'QC Checklist', command: () => {
                        navigate('/checklist')
                    }
                }
            ]);
        }
        else if (isQCAuditor) {
            setAdminItems([
                {
                    label: "Dashboard", command: () => {
                        navigate('/')
                    }
                },
                {
                    label: "QC Work", command: () => {
                        navigate('/worklist')
                    }
                },
                {
                    label: 'Audits',
                    items: [
                        {
                            label: "Prefund Audits", command: () => {
                                navigate('/prefund-qc-audits')
                            }
                        },
                        {
                            label: "Post Close Audits", command: () => {
                                navigate('/post-close-qc-audits')
                            }
                        },
                        {
                            label: "Servicing Audits", command: () => {
                                navigate('/service-audits')
                            }
                        },
                        {
                            label: "Findings", command: () => {
                                navigate('/FindingList')
                            }
                        }
                    ]
                },
                {
                    label: 'QC Reporting', command: () => {
                        navigate('/reporting')
                    }
                }
            ]);
        }
        else if (isQCValidator) {
            setAdminItems([
                {
                    label: "Validation Work", command: () => {
                        navigate('/Validations')
                    }
                }
            ]);
        }
        else {
            setAdminItems([
                {
                    label: "Your Profile", command: () => {
                        navigate('/profile')
                    }
                }
            ]);
        }

    }, [isAccountAdmin, isAccountManager, isQCAdmin, isQCAuditor, isQCManager, isQCValidator, isSysAdmin, navigate, validRoles]);


    const endHeader = useMemo(() => (
        <div className="flex align-items-center gap-4 pt-2 pb-2">
            <div>
                <span className="ml-4 mr-2 font-normal font-italic">Powered By </span>
                <Image alt="logo" src='/assets/images/loanDNA_logo.jpg' height="24px" className="mr-2"></Image>
            </div>

            <Search />

            {/*@ts-ignore*/}
            <button style={{ all: "unset" }} onClick={(e) => notificationOverlayRef.current.toggle(e)}>
                <i className="pi pi-bell " style={{ fontSize: '1.5rem', color: 'var(--ca-color-2)' }}></i>
            </button>
            <NavLink to={'/profile'}>
                <i className="pi pi-user " style={{ fontSize: '1.5rem', color: 'var(--ca-color-2)' }}></i>
            </NavLink>

            <OverlayPanel ref={notificationOverlayRef}>
                <h4>Notifications</h4>
            </OverlayPanel>
        </div>
    ), []);

    const startHeader = useMemo(() => (
        <div className="h-5rem">
            <Image alt="logo" src='/assets/images/ca_logo.png' style={{ height: "100%" }} className="mr-2"></Image>
        </div>
    ), []);

    return (
        <div>
            <div className="header">
                <Menubar className="pt-0 pb-0"
                    start={startHeader} end={endHeader} />
            </div>
            <div className="nav">
                <Menubar model={adminItems} />
            </div>
        </div>
    )
}
