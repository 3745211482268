import React, { useState, useRef, useEffect } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { Link, matchRoutes, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from 'primereact/panel';
import "./AuditForm.css";
import "./AuditView.css";
import axios, { AxiosResponse } from 'axios';
import { RadioButton } from 'primereact/radiobutton';
import { Divider } from 'primereact/divider';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload, FileUploadBeforeUploadEvent, FileUploadSelectEvent } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { useSearchParams } from 'react-router-dom'
import { InputText } from 'primereact/inputtext';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from "react-router-dom";
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import { Avatar } from 'primereact/avatar';
import { authenticatedFetch, getAuthenticatedHeader } from "../../state/apiUtils";
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";
import { Tag } from 'primereact/tag';

export default function AuditView() {
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);

    //const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const auditReviewId = searchParams.get('auditReviewId');
    const auditFindingId = searchParams.get('auditFindingId');
    const [auditPlanType, setAuditPlanType] = useState<string>();

    //Determine if this is for Finding or QC
    const [mode, setMode] = useState<string>();

    //Determine which role is being used
    const userRole = searchParams.get('Role');

    //All the fields about a Loan
    const [loanData, setLoanData] = useState<any>({});

    const [findingData, setFindingData] = useState<any>({});

    //Upload attachment file State
    const [attachmentFileData, setAttachmentFileData] = useState(
        {
            loading: true, data: "", fileID: "", fileName: ""
        }
    );

    //Contains all the checklist items
    const [checklistData, setchecklistData] = useState<any>({
        checklists: []
    });

    // Contains all attachments
    const [attachmentData, setAttachmentData] = useState<any[]>([]);
    const [attachmentDataFetched, setAttachmentDataFetched] = useState<boolean>(false);


    //The selected Attachment for viewing
    const [selectedAttachment, setSelectedAttachment] = useState<any>(null)

    const [noteData, setNoteData] = useState<any[]>([]);
    const [noteFetched, setNoteFetched] = useState<boolean>(false);

    const [activityData, setActivityData] = useState<any[]>([]);
    const [activityDataFetched, setActivityDataFetched] = useState<boolean>(false);

    //List of questions for a selected checklist
    const [selectedChecklist, setSelectedChecklist] = useState<any>(null)


    //Which page to show up on the right-hand section
    const [activePage, setActivePage] = useState<any>("Loan")

    //All the response records from API
    const [allResponses, setAllResponses] = useState<any[]>([])


    //For viewing findings
    const [auditFindings, setAuditFindings] = useState<any[]>([]);
    const [auditFindingsFetched, setAuditFindingsFetched] = useState<boolean>(false);

    //The selected Finding for viewing
    const [selectedAuditFinding, setSelectedAuditFinding] = useState<any>({})

    //For viewing completed items
    const [completedResponses, setCompletedResponses] = useState<any[]>([]);

    //to determine if we are showing only unaswered questions
    const [checklistNoResponseOnly, setChecklistNoResponseOnly] = useState<boolean>(false);

    const [questionLoading, setQuestionLoading] = useState<boolean>(false);
    const [refreshResponse, setResponseResponse] = useState<boolean>(false);

    //Alert for QC Complete
    const [QCCompleteAlert, setQCCompleteAlert] = useState<boolean>(false);

    const [header, setHeader] = useState<any>();

    useEffect(() => {

        prepareAuditOrFinding();
        initHeader();

    }, []);
    
    
    useEffect(() => {

        if (auditPlanType == null) {
            return;
        }
        if (auditFindingId != null) {
            setMode("FINDING");

            getFindingValidationData(auditFindingId)
        }
        else {
            setMode("QC");

            //get back all the findings
            getFindingsData();

        }



        getAttachmentData();

        getNoteData();

        getActivityData(); 

        //Get back all the responses
        getResponses(null);

    }, [auditPlanType])

    
    useEffect(() => {
        console.log('allResponses', allResponses)

        if (allResponses.length > 0)
            updateResponseTally()
    }, [allResponses])

    const initHeader = () => {
        getAuthenticatedHeader().then((response) => {
            console.log('header', response);
            setHeader(response);
        })
    }

    const prepareAuditOrFinding = () => {
        console.log('auditFindingId', auditFindingId);
        console.log('auditReviewId', auditReviewId);

        //Get Audit Review Plan Type
        authenticatedFetch(
            "/Audit/GetAuditPlanType?auditReviewId=" + auditReviewId)
            .then(res => {

                setAuditPlanType(res.AuditPlanType);
                console.log('auditPlanType', res.AuditPlanType);
                //Get Loan Details
                getLoanData(res.MonthlyFundedLoanId);

                //Get checklist information
                getCheckListData(res.MonthlyFundedLoanId, res.AuditPlanType);
            });

    }
    
    //Get back all the questions for this loan
    const getLoanData = (id: any) => {

        authenticatedFetch(
            "/Audit/GetLoanData?LoanId=" + id)
            .then(res => {
                console.log(res);
                console.log('Loan Data', res);
                var data = res;
                setLoanData(data);

            });
    }

    const getFindingsData = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Audit/GetFindings", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('Response Data', data);

                setAuditFindings(data.Responses);
                setAuditFindingsFetched(true);

            });
    }

    const getFindingValidationData = (id: any) => {
        var param = {
            AuditFindingId: id
        }
        authenticatedFetch(
            "/Finding/GetFindingValidations", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('GetFindingValidations', data);

                setSelectedAuditFinding(data);
                setActivePage("Finding");
                setAuditFindingsFetched(true);
            });
    }

    const getAttachmentData = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Attachment/GetReviewAttachments", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('getAttachmentData', data);

                setAttachmentData(data);
                setAttachmentDataFetched(true);

            });
    }

    const getActivityData = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Activity/GetActivities", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('getActivityData', data);

                setActivityData(data);
                setActivityDataFetched(true);
            });
    }

    const getNoteData = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Note/GetNotes", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('GetNotes', data);

                setNoteData(data);
                setNoteFetched(true);
            });
    }

    // const handleUpload = (e: any) => {
    //     console.log('OnUpload Called');
    // };

    //Get back all the questions for this loan
    const getCheckListData = (loanId: any, auditPlanType: any) => {

        //console.log('searchparam', q);
        var param = {
            LoanId: loanId,
            AuditType: auditPlanType,
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Audit/getCheckListData", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                console.log(res);
                console.log('getCheckListData', res);
                var data = res;
                setchecklistData(data);
                getResponses(data);
            });
    }

    //Get back all the responses already made for this loan
    const getResponses = (checklistData: any) => {

        //console.log('searchparam', q);

        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Audit/GetResponses", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('getResponses', data);

                // var completed = data.Responses.filter((item: any) => item.Response != "Finding")
                // setCompletedResponses(completed);



                setAllResponses(data.Responses);

                //If there was a selected one, set it up again
                if (selectedChecklist != null && checklistData != null) {
                    console.log('check active checklist', selectedChecklist, data);
                    var newSelection = checklistData.checklists.filter((f: any) => f.Category == selectedChecklist.Category);
                    if (newSelection) {
                        console.log('newSelection', newSelection);
                        getCheckListDetail(newSelection[0], data.Responses);
                    }
                }

            });
    }

    const getCheckListDetail = (item: any, responses: any) => {
        console.log('getCheckListDetail', item);
        console.log('checkListResponseData', allResponses);

        setSelectedChecklist(item);
        //Build response


        setActivePage("Checklist");

    }
    const editHeaderTemplate = (tabColor: string, options: any) => {

        const className = `${options.className} ${tabColor} justify-content-start  `;
        const titleClassName = `${options.titleClassName} ${tabColor} ml-2 `;
        const style = { fontSize: '1.25rem' };
        //console.log('options', options);
        return (
            <div className={className}>
                <span className={titleClassName} style={style}>{options.props.header}</span>
                {/* {options.props.header} */}
            </div>
        );
    };

    const checklistHeaderTemplate = (tabColor: string, switchOn: boolean, options: any) => {

        const className = `${options.className} ${tabColor}`;
        const titleClassName = `${options.titleClassName} ${tabColor} ml-2 `;
        const style = { fontSize: '1.25rem' };
        //console.log('options', options);
        return (
            <div className={className}>
                <span className={titleClassName} style={style}>{options.props.header}</span>
                {switchOn ?
                    <div className="flex flex-wrap justify-content-end gap-3">
                        <span>Show unaswered questions only</span>
                        <InputSwitch checked={checklistNoResponseOnly} onChange={() => setChecklistNoResponseOnly(!checklistNoResponseOnly)} />





                    </div>
                    : ""}
            </div>

        );
    };

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let { hasRoles, isLoading } = useHasRole(['Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager', 'QC Auditor', 'QC Validator', 'System Administrator'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    function page_Start() {
        return (
            <Panel header="Overview" headerTemplate={(o) => editHeaderTemplate('', o)} style={{ height: "100%" }}>

                <p>Activity goes here</p>
            </Panel>

        )
    }

    const Page_Notes = () => {
        return (
            <Panel header="Notes" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-4', o)} style={{ height: "77.5vh" }}>
                <ScrollPanel style={{ paddingTop: "20px", width: '100%', height: '67.5vh' }}>
                    {
                        noteData.map((data: any, i: number) => {
                            return (
                                <div className="mt-1 w-full">
                                    {i > 0 ? <Divider style={{ margin: "1rem 0" }} /> : ""}

                                    <span className="block note-display">{data.Note}</span>

                                    <span className="block font-italic mt-1">--{data.CreatedBy}, {data.CreatedDate}</span>
                                </div>
                            )
                        })
                    }
                </ScrollPanel>
            </Panel>
        )
    }

    function page_Attachment() {
        const path = `/Attachment/GetReviewAttachmentData?auditReviewAttachmentId=${selectedAttachment.AuditReviewAttachmentId}`;
        console.log('selectedAttachment', path);
        return (
            <Panel header={"Attachment View - " + selectedAttachment.FileName} headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-5', o)} style={{ height: "77vh" }}>
                <div className="col-12">
                    <div className="formgrid grid">
                        <div className="field col">
                            <label>File Name</label><br />
                            <label>{selectedAttachment.FileName}</label>
                        </div>
                        <div className="field col">
                            <label>Date Added</label><br />
                            <label>{selectedAttachment.CreatedDate}</label>
                        </div>
                        <div className="field col">
                            <label>Added By</label><br />
                            <label>{selectedAttachment.CreatedBy}</label>
                        </div>
                        <div className='field col'>
                            <label>Comments</label><br />
                            <label>{selectedAttachment.Comment}</label>

                        </div>
                    </div>
                </div>
                {
                    selectedAttachment.FileType === "img" && <img src={path} height="100%" style={{ display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%" }}></img>
                }
                {
                    selectedAttachment.FileType === "pdf" &&
                    <div>
                        <ScrollPanel style={{ width: '100%', height: '60vh' }}>
                            <iframe src={path} style={{ border: "none", width: "100%", height: "100%" }}>

                            </iframe>
                        </ScrollPanel>
                    </div>
                }
                {
                    selectedAttachment.FileType === "txt" &&
                    <ScrollPanel style={{ width: '100%', height: '60vh' }}>
                        <iframe src={path} style={{ border: "none", width: "100%", height: "100%" }}>

                        </iframe>
                    </ScrollPanel>
                    // <object type="text/pain" data={selectedAttachment.path} width="100%" style={{ overflow: "hidden", minHeight: "66vh" }}></object>
                }

                {/* <object width="910" height="340" type="text/plain" data="message.txt" border="0"
   style="overflow: hidden;">
</object> */}

            </Panel>

        )
    }


    const Page_NewAttachment = () => {

        const [isUploading, setIsUploading] = useState<boolean>(false);
        const [comments, setComments] = useState<string>("")

        const onSelect = (e: FileUploadSelectEvent) => {
            console.log("onSelect called,", e);
            for (let i = 0; i < e.files.length; i++) {
                if ((e.files[i].type === "image/png") || (e.files[i].type === "text/csv")
                    || (e.files[i].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || (e.files[i].type === "image/jpeg")
                    || (e.files[i].type === "text/plain") || (e.files[i].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
                    console.log("Do nothing");
                } else {
                    console.log("File type not allowed", e.files[i].type)
                    toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Attachment type is not supported. The following file types are allowed:  .txt, .pdf, .doc, .docx, .xls, .xlsx, .csv.', life: 3000 });
                    delete e.files[i];
                }
            }
        }

        const onUpload = (e: any) => {

            //this.toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
            var result = JSON.parse(e.xhr.response);
            console.log(result);
            console.log('OnUpload Called ', result.fileID);
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Attachment Successfuly Added', life: 3000 });
            getAttachmentData();

        };

        const onBeforeUpload = (e: FileUploadBeforeUploadEvent) => {
            console.log("set is uploading");
            setIsUploading(true);
            e.formData.append('AuditReviewId', auditReviewId!);
            e.formData.append('Comments', comments!);

        }

        return (
            <Panel header={"Upload New Attachment"} headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-5', o)} style={{ height: "76vh" }}>

                {/* Change the url to correct URL for database */}

                <ProgressBar mode={isUploading ? "indeterminate" : "determinate"} style={{ height: '6px', borderRadius: '5px', margin: '1.2rem 0.6rem 0.2rem' }}></ProgressBar>
                <div className="col-12">
                    <span className='text-sm'>Allowed file types:  .txt, .pdf, .doc, .docx, .xls, .xlsx, .csv.</span>
                    <FileUpload onBeforeSend={onBeforeUpload} onSelect={onSelect} onUpload={onUpload}
                        name="UploadedFiles" url={"/Attachment/AddReviewAttachment"}
                        maxFileSize={10000000}
                        emptyTemplate={<p className="m-0">Drag and drop a file to here to upload.</p>}
                    // disabled={isInval id}
                    />
                </div>
                <div className="col-12 audit-plan">
                    <label className="block mb-2">Attachment Comments</label>
                    <InputTextarea value={comments} onChange={(e: any) => setComments(e.target.value)} rows={3} style={{ width: "100%", padding: "0px;" }} />
                </div>


            </Panel>

        )
    }


    const Page_Loan = (props: any) => {
        //Make copy of loan
        const [editLoanData, setEditLoan] = useState<any>();

        useEffect(() => {
            //Make copy
            console.log('Edit Loan Data?', props.LoanData)
            var copyItem = JSON.parse(JSON.stringify(props.LoanData))

            //Add dummy items
            copyItem.Account = {};
            copyItem.CreatedBy = {};
            copyItem.LastModifiedBy = {};

            setEditLoan(copyItem);

        }, [props.LoanData])

        return (
            editLoanData && <Panel header={"Loan Detail - " + loanData.LoanName} headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-1', o)} style={{ height: "fit-content" }}>
                <ScrollPanel style={{ paddingTop: "20px", width: '100%', height: '67.5vh' }}>
                    <div className="loan-form formgrid grid form" style={{ marginTop: "10px" }}>
                        <div className="form-column col-6">

                            <span className="p-float-label">
                                <InputText disabled id="LoanName" value={editLoanData.LoanName} />
                                <label htmlFor="LoanName">Loan Name</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="LoanNumber" value={editLoanData.LoanNumber} />
                                <label htmlFor="loanNumber">Loan Number</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="borrowersFirstName" value={editLoanData.BorrowersFirstName} onChange={(e) => { setEditLoan((s: any) => { return { ...s, BorrowersFirstName: e.target.value } }); }} />
                                <label htmlFor="borrowersFirstName">Borrowers First Name</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="borrowersLastName" value={editLoanData.BorrowersLastName} />
                                <label htmlFor="borrowersLastName">Borrowers Last Name</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="CurrentAddress" value={editLoanData.CurrentAddress} />
                                <label htmlFor="CurrentAddress">Current Address</label>
                            </span>


                            <span className="p-float-label">
                                <InputText disabled id="CurrentCity" value={editLoanData.CurrentCity} />
                                <label htmlFor="CurrentCity">Current City</label>
                            </span>


                            <span className="p-float-label">
                                <InputText disabled id="CurrentState" value={editLoanData.CurrentState} />
                                <label htmlFor="CurrentState">Current State</label>
                            </span>


                            <span className="p-float-label">
                                <InputText disabled id="CurrentZipCode" value={editLoanData.CurrentZipCode} />
                                <label htmlFor="CurrentZipCode">Current Zipcode</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="FundingDate" value={editLoanData.FundingDate} />
                                <label htmlFor="FundingDate">Funding Date</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="fundingMonth" value={editLoanData.FundingMonth} />
                                <label htmlFor="fundingMonth">Funding Month</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="LoanAmount" value={editLoanData.LoanAmount} />
                                <label htmlFor="LoanAmount">Loan Amount</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="InterestRate" value={editLoanData.InterestRate} />
                                <label htmlFor="InterestRate">Interest Rate</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="CreditScore" value={editLoanData.CreditScore} />
                                <label htmlFor="CreditScore">Credit Score</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="InvestorType" value={editLoanData.InvestorType} />
                                <label htmlFor="InvestorType">Investor Type</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="Investor" value={editLoanData.Investor} />
                                <label htmlFor="Investor">Investor</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="InvestorName" value={editLoanData.InvestorName} />
                                <label htmlFor="InvestorName">Investor Name</label>
                            </span>

                            <span className="p-float-label">
                                <InputText id="LoanSelection" disabled value={editLoanData.LoanSelection}                                    
                                />
                                <label htmlFor="LoanSelection">Loan Selection</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="CreatedDate" value={editLoanData.CreatedDate} />
                                <label htmlFor="CreatedDate">Created Date</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="LastModifiedDate" value={editLoanData.LastModifiedDate} />
                                <label htmlFor="LastModifiedDate">Last Updated</label>
                            </span>
                        </div>

                        <div className="form-column col-6">

                            <span className="p-float-label">
                                <InputText disabled id="client" value={editLoanData.Client} />
                                <label htmlFor="client">Client</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="AmortizationType" value={editLoanData.AmortizationType} />
                                <label htmlFor="AmortizationType">Amortization Type</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="LoanType" value={editLoanData.LoanType} />
                                <label htmlFor="LoanType">Loan Type</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="DocumentationType" value={editLoanData.DocumentationType} />
                                <label htmlFor="DocumentationType">Documentation Type</label>
                            </span>


                            <span className="p-float-label">
                                <InputText disabled id="LoanPurpose" value={editLoanData.LoanPurpose} />
                                <label htmlFor="LoanPurpose">Loan Purpose</label>
                            </span>


                            <span className="p-float-label">
                                <InputText disabled id="RefinanceType" value={editLoanData.RefinanceType} />
                                <label htmlFor="RefinanceType">Refinance Type</label>
                            </span>


                            <span className="p-float-label">
                                <InputText disabled id="LienPosition" value={editLoanData.LienPosition} />
                                <label htmlFor="LienPosition">Lien Position</label>
                            </span>


                            <span className="p-float-label">
                                <InputText disabled id="LoanType" value={editLoanData.LoanType} />
                                <label htmlFor="LoanType">Loan Type</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="PropertyType" value={editLoanData.PropertyType} />
                                <label htmlFor="PropertyType">Property Type</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="OccupancyType" value={editLoanData.OccupancyType} />
                                <label htmlFor="OccupancyType">Occupancy Type</label>
                            </span>

                            {auditPlanType == "Servicing" ? 
                            <>
                            <span className="p-float-label">
                    <InputText id="Dlqstatus" value={editLoanData.Dlqstatus}
                      disabled
                    />
                    <label htmlFor="Dlqstatus">Delinquent Status</label>
                </span>
                <span className="p-label">
                <label htmlFor="AccountStatus">Account Status</label>
                <InputText id="AccountFlags" value={editLoanData.AccountStatus}
                    disabled
                />

                </span>
                <span className="p-float-label">
                <InputText id="AccountFlags" value={editLoanData.AccountFlags}
                    disabled
                />
                <label htmlFor="AccountFlags">Account Flags</label>
                </span>


                <span className="p-float-label">
                <InputText id="EscrowPayment" value={editLoanData.EscrowPayment}
                    disabled
                />
                <label htmlFor="EscrowPayment">Escrow Payment</label>
                </span>


                <span className="p-float-label">
                <InputText id="EscrowAnalysisDate" value={editLoanData.EscrowAnalysisDate}
                    disabled
                />
                <label htmlFor="EscrowAnalysisDate">Escrow Analysis Date</label>
                </span>
                
                <span className="p-float-label">
                <InputText id="LastPaymentDate" value={editLoanData.LastPaymentDate}
                    disabled
                />
                <label htmlFor="LastPaymentDate">Last Payment Date</label>
                </span>
                
                <span className="p-float-label">
                <InputText id="NextDueDate" value={editLoanData.NextDueDate}
                   disabled
                />
                <label htmlFor="NextDueDate">Next Due Date</label>
                </span>
                
                <span className="p-float-label">
                <InputText id="ArmadjustmentDate" value={editLoanData.ArmadjustmentDate}
                    disabled                    
                />
                <label htmlFor="ArmadjustmentDate">ARM Adjustment Date</label>
                </span>
                            </>
                            
                            : 
                            <>
                            <span className="p-float-label">
                                <InputText disabled id="Ltv" value={editLoanData.Ltv} />
                                <label htmlFor="Ltv">LTV</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="Cltv" value={editLoanData.Cltv} />
                                <label htmlFor="Cltv">CLTV</label>
                            </span>


                            <span className="p-float-label">
                                <InputText disabled id="LoanOfficer" value={editLoanData.LoanOfficer} />
                                <label htmlFor="LoanOfficer">Loan Officer</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="Closer" value={editLoanData.Closer} />
                                <label htmlFor="Closer">Closer</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled id="LoanProcessor" value={editLoanData.LoanProcessor} />
                                <label htmlFor="LoanProcessor">Loan Processor</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="Underwriter" value={editLoanData.Underwriter} />
                                <label htmlFor="Underwriter">Underwriter</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled id="MortgageOriginator" value={editLoanData.MortgageOriginator} />
                                <label htmlFor="MortgageOriginator">Mortgage Originator</label>
                            </span>
                            </>
                            }
                        </div>
                    </div>
                </ScrollPanel>
            </Panel>

        )
    }

    const updateResponseTally = () => {

        setQuestionLoading(true);
        var copy = { ...checklistData };
        console.log('updateResponseTally', copy);
        //Recount this value

        copy.totalAnswered = 0;
        //var allAnswers = 0;
        copy.checklists.forEach(
            (item: any) => {
                //Get all the checklist items
                //console.log('tallyItems', item);
                let chkIds = item.Questions.map((i: any) => i.QcChecklistId);
                //console.log('chkIds', chkIds);

                let totalAnswered = allResponses.filter(f => chkIds.indexOf(f.QcChecklistId) > -1 && f.Response);
                //console.log(totalAnswered, item.totalItems)

                item.totalAnswered = totalAnswered.length;

                if (item.totalAnswered == item.totalItems) {
                    copy.totalAnswered += 1;
                }
            }
        )

        setQuestionLoading(false);

        setchecklistData(copy);

    }


    const getLoanDetail = () => {
        setActivePage('Loan');

    }

    const getAttachmentDetail = (data: any) => {
        setSelectedAttachment(data);
        setActivePage('Attachment');
    }

    const getNewAttachment = () => {
        setActivePage('NewAttachment');
    }

    const getFindingDetail = (data: any) => {
        console.log('getFindingDetail', data);

        if (mode == "QC") {
            getFindingValidationData(data.AuditFindingId)
        }
        else {
            setSelectedAuditFinding(data);
            setActivePage('Finding');
        }
    }

    const Page_Finding = () => {

        // const [ReasonsForDecisionLookup, setReasonsForDecisionLookup] = useState<string[]>([]);
        // const [ReasonsForRevisionLookup, setReasonsForRevisionLookup] = useState<string[]>([]);
        // const [FindingStatusLookup, setFindingStatusLookup] = useState<string[]>([]);
        // const [QCStatusLookup, setQCStatusLookup] = useState<string[]>([]);
        const [showResponse, setShowResponse] = useState<boolean>(false);
     
        
        const [validationData, setValidationData] = useState<any>({
            Reason: "",
            Status: "",
            Comment: "",
            AuditFindingId: auditFindingId,
            Attachments: []
        });

        
        return (
            <Panel header={"Finding - " + selectedAuditFinding.RecordName} headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-2', o)} style={{ height: "fit-content" }}  >
                <ScrollPanel style={{ paddingTop: "10px", width: '100%', height: '67.5vh' }}>
                    <div className="grid">
                        <div className="col-fixed" style={{ width: "30px" }}><Avatar label={selectedAuditFinding.CreatedBy2} style={{ color: 'white', backgroundColor: '#333' }} className="mr-2" />
                        </div>
                        <div className="col">

                            <div className="col-12 mb-2">
                                <label className="header-label block">QC Initial Finding Details</label>
                                <label className="block mt-1">{selectedAuditFinding.DefectDetail}</label>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label className="header-label">Checklist Number</label><br />
                                        <label>{selectedAuditFinding.QuestionNumber}</label>
                                    </div>
                                    <div className="field col">
                                        <label className="header-label">Question</label><br />
                                        <label>{selectedAuditFinding.QuestionText}</label>
                                    </div>
                                    <div className="field col">
                                        <label className="header-label">QC by</label><br />
                                        <label>{selectedAuditFinding.CreatedBy}</label>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label className="header-label">{auditPlanType != "Servicing" ? "Defect Category" : "Source of Error"}</label><br />
                                        <label>{selectedAuditFinding.DefectCategory}</label>
                                    </div>
                                    <div className="field col">
                                        <label className="header-label">{auditPlanType != "Servicing" ? "Defect Subcategory" : "Error Type"}</label><br />
                                        <label>{selectedAuditFinding.DefectSubCategory}</label>
                                    </div>
                                    <div className="field col">
                                        <label className="header-label">{auditPlanType != "Servicing" ? "Defect Name" : "Is a CAP Required?"}</label><br />
                                        <label>{selectedAuditFinding.DefectName}</label>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-4">
                                        <label className="header-label">Severity</label><br />
                                        <label>{selectedAuditFinding.SeverityRating}</label>
                                    </div>
                                    <div className="field col-4">
                                        <label className="header-label">Area Responsible</label><br />
                                        <label>{selectedAuditFinding.AreaResponsible}</label>
                                    </div>

                                </div>
                                {
                                auditPlanType == "Servicing" &&  selectedAuditFinding.DefectName == "Yes" ? 
                                <div className="formgrid grid">
                                
                                <div className="field col-4">
                                <label className="header-label">Corrective Action Plan</label><br />
                                <label>{selectedAuditFinding.CAPActionPlan}</label>
                                </div>
                                <div className="field col-4">
                                <label className="header-label">CAP Implementation Date</label><br />
                                <label>{selectedAuditFinding.CAPImplementationDate}</label>
                                </div>
                                </div>                                                                                    
                                : ""  
                                }   
                            </div>
                        </div>
                    </div>

                    {
                        selectedAuditFinding.Validations.map((data: any) => {
                            return (
                                <div className="grid">
                                    <div className="col-fixed" style={{ width: "30px" }}><Avatar label={data.CreatedBy2} style={{ color: 'white', backgroundColor: data.ResponseFrom == "Validator" ? 'orange' : '#333' }} className="mr-2" /></div>
                                    <div className="col">
                                        <div className="col-12 mb-2">
                                            <label className="header-label">{data.ResponseFrom == "Validator" ? "Validator Response" : "QC Auditor Response"}</label><br />
                                            <div className="val-comments mt-1" dangerouslySetInnerHTML={{ __html: data.Comment }}></div>
                                        </div>
                                        {data.Attachments.length > 0 ?
                                            <div className="col-12 mb-2">
                                                <label className="header-label">Attachment(s)</label><br />
                                                {
                                                    data.Attachments.map((att: any) => {

                                                        return (
                                                            <div className="linkButton text-base" onClick={() => getAttachmentDetail(att)}>{att.FileName}</div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </div>
                                            : ""}
                                        <div className="col-12 mb-2 grid">
                                            <div className="field col-3">
                                                <label className="header-label">Reason for Decision</label><br />
                                                <label>{data.Reason}</label>
                                            </div>
                                            <div className="field col-3">
                                                <label className="header-label">Updated Finding Status</label><br />
                                                <label>{data.Status}</label>
                                            </div>
                                            <div className="field col-3">
                                                <label className="header-label">{data.ResponseFrom == "Validator" ? "Validated" : "Responded"} On</label><br />
                                                <label>{data.CreatedDate}</label>
                                            </div>
                                            <div className="field col-3">
                                                <label className="header-label">{data.ResponseFrom == "Validator" ? "Validated" : "Responded"} By</label><br />
                                                <label>{data.CreatedBy}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }



                </ScrollPanel>
            </Panel>
        )
    }

    const Page_Questions = () => {
        //console.log('selectedChecklist', selectedChecklist);
        //For all the defect related dropdown boxes
        const [checklistLoadingDone, setChecklistLoadingDone] = useState<boolean>(false);

        const [defectLookup, setDefectLookup] = useState<any[]>([]);

        //For maintaining the responses within a given checklist
        const [checklistResponse, setChecklistResponse] = useState<Record<string, any>>({});


        useEffect(() => {
            //Lookups
            initPage();
           
        }, [])

        const initPage = () => {
            var item = selectedChecklist;
            var matchQuestionResponse = item.Questions.map((x: any) => {
                var matches = allResponses.filter((f: any) => f.QcChecklistId == x.QcChecklistId);
                console.log('matches', matches);
                if (matches.length > 0) {
                    var resp = matches[0];
                    resp.QcChecklistId = x.QcChecklistId;

                    resp.saved = true;
                    resp.savedResponse = {
                        Response: resp.Response,
                        DefectName: resp.DefectName,
                        DefectCategory: resp.DefectCategory,
                        DefectSubCategory: resp.DefectSubCategory,
                        DefectDetail: resp.DefectDetail,
                        SeverityRating: resp.SeverityRating,
                        CAPActionPlan: resp.CAPActionPlan, 
                        CAPImplementationDate: resp.CAPImplementationDate

                    }
                    return resp
                }
                else {
                    //return an empty place
                    resp = {
                        QcChecklistId: x.QcChecklistId,
                        Response: "",
                        DefectCategory: "",
                        defecttSubCategory: "",
                        DefectName: "",
                        SeverityRating: "",
                        saved: false
                    }
                    return resp
                }


            }
            )
            //let matchResponse = Object.fromEntries();

            let responseRecord: Record<string, any> = {};

            console.log('matchQuestionResponse', matchQuestionResponse);
            for (let item in matchQuestionResponse) {
                let row = matchQuestionResponse[item];
                responseRecord[row.QcChecklistId] = row;
                // Use `key` and `value`
            }

            setChecklistResponse(responseRecord);
            setChecklistLoadingDone(true);
        }
        
        //        console.log("Does this work? ", question.QcChecklistId,  checklistResponse[question.QcChecklistId]);


        if (!checklistLoadingDone)
            return (<div>Loading Page</div>)

        return (
            <Panel header={"Checklist - " + selectedChecklist.Category} headerTemplate={(o) => selectedChecklist.totalAnswered == selectedChecklist.totalItems ? checklistHeaderTemplate('bg-ca-extended-6', false, o) : checklistHeaderTemplate('bg-ca-extended-3', true, o)}  >
                <ScrollPanel style={{ paddingTop: "10px", width: '100%', height: '67.5vh' }}>
                    {selectedChecklist.Questions.map((q: any, i: number) => {
                        if (checklistNoResponseOnly && checklistResponse[q.QcChecklistId].saved && checklistResponse[q.QcChecklistId].Response)
                            return ""
                        else
                            return (
                                <div className="grid nested-grid">

                                    <div className="col-12">
                                        {i > 0 ? <Divider /> : ""}
                                        <div className='grid'>
                                            <div className="col-fixed text-primary font-bold" style={{ width: "30px" }}>
                                                {i + 1}.
                                            </div>
                                            <div className="col">
                                                <span className="text-primary font-bold">{q.QuestionText}</span>
                                                <p>

                                                    {checklistResponse[q.QcChecklistId].saved ?
                                                        (<div className="flex flex-wrap gap-4"><span className="col-12 mb-2">A: {checklistResponse[q.QcChecklistId].Response}</span></div>)
                                                        :
                                                        ""
                                                    }

                                                    {checklistResponse[q.QcChecklistId].Response === "Finding" ?
                                                        (
                                                            <div>
                                                                {checklistResponse[q.QcChecklistId].saved ?
                                                                    <div>
                                                                        <div className="col-12 mb-2">
                                                                            <label>{checklistResponse[q.QcChecklistId].DefectDetail}</label>
                                                                        </div>
                                                                        <div className="col-12 mb-2">
                                                                            <div className="formgrid grid">
                                                                                <div className="field col">
                                                                                    <label>Defect Category</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].DefectCategory}</label>
                                                                                </div>
                                                                                <div className="field col">
                                                                                    <label>Defect Subcategory</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].DefectSubCategory}</label>
                                                                                </div>
                                                                                <div className="field col">
                                                                                    <label>Defect Name</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].DefectName}</label>
                                                                                </div>
                                                                                <div className="field col">
                                                                                    <label>Severity</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].SeverityRating}</label>
                                                                                </div>
                                                                                <div className="field col">
                                                                                    <label>Area Responsible</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].AreaResponsible.AreaResponsibleName}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }

                                                            </div>

                                                        )
                                                        :
                                                        ""

                                                    }
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    })}
                </ScrollPanel>
            </Panel>

        )
    }

    const Page_Activities = () => {
        return (
            <Panel header="Activities" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-7', o)} style={{ height: "77.5vh" }}>
                <ScrollPanel style={{ paddingTop: "20px", width: '100%', height: '67.5vh' }}>
                    <div className="flex flex-column w-full">
                        {
                            activityData.map((data: any, i: number) => {
                                return (
                                    <div className="flex flex-row gap-1 mt-1 w-full align-items-center ">


                                        <span className="block mr-2 ">{data.CreatedDate}</span>
                                        <Tag severity="success" value={data.ActivityName}></Tag>

                                        <span className="block  ml-2">{data.ActivityDetail}</span>
                                        <span className="block  ml-2">--{data.CreatedBy}</span>

                                    </div>
                                )
                            })
                        }
                    </div>
                </ScrollPanel>
            </Panel>
        )
    }

    const ActivePage = () => {
        switch (activePage) {
            case "Start":
                return page_Start();
                break;
            case "Checklist":
                return <Page_Questions />
                break;
            case "Loan":
                return <Page_Loan LoanData={loanData} />
                break;
            case "Attachment":
                return page_Attachment();
                break;
            case "Notes":
                return <Page_Notes />
                break;
            case "Finding":
                return <Page_Finding />
                break;
            case "NewAttachment":
                return <Page_NewAttachment />
                break;
            case "Activities":
                return <Page_Activities />
                break;
            default: return page_Start();
        }

    }

    const showTabLoading = () => {
        return (<div>
            <p></p>
            <p className="loading"></p>
            <p></p>
        </div>)
    }
    return (
        <div>
            <Nav />

            <Body>
                <Toast ref={toast} position="bottom-center" />
                <div>

                    <div className="flex justify-content-between">
                        {mode == "FINDING" ?
                            <h1>{auditPlanType} Audit Finding - {selectedAuditFinding.RecordName}</h1>
                            :
                            <h1>{auditPlanType} Audit View - {loanData.LoanName}</h1>
                        }

                        {/* <span className="p-buttonset">
                        <Button label="New Contact" />
                        <Button label="Edit" />
                        <Button label="New Opportunity" />
                        <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                        <Button icon="pi pi-angle-down" className="mr-2" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
                    </span> */}
                    </div>

                    <div className="mt-2 grid">
                        <div className="col-4">
                            <Accordion multiple activeIndex={mode == "QC" ? [0] : [0]} >
                                <AccordionTab
                                    header={
                                        Object.keys(loanData).length == 0 ?
                                            <div>
                                                <span>Loan Detail </span><div className="loader"></div>
                                            </div>
                                            :
                                            <span>Loan Detail</span>
                                    }
                                    pt={{ headerAction: () => ({ className: 'bg-ca-extended-1' }) }}
                                >
                                    {Object.keys(loanData).length == 0 ?
                                        showTabLoading()
                                        :
                                        <div className="grid m-0">

                                            <div className='col-12'>{loanData.LoanName}</div>
                                            <div className='col-12'>{loanData.LoanNumber}</div>
                                            <div className='col-12'>{loanData.BorrowersLastName}, {loanData.BorrowersFirstName}</div>
                                            <div className="col-12 linkButton text-base" onClick={() => getLoanDetail()}>See all details...</div>
                                        </div>
                                    }
                                </AccordionTab>

                                <AccordionTab header={
                                    auditFindingsFetched == false ?
                                        <div>
                                            <span>Findings </span><div className="loader"></div>
                                        </div>
                                        :
                                        <span>Findings ({auditFindings.length})</span>
                                }

                                    pt={{ headerAction: () => ({ className: 'bg-ca-2' }) }} >
                                    {auditFindingsFetched == false ?
                                        showTabLoading()
                                        :
                                        <p className="m-0">
                                            {mode == "QC" ?
                                                auditFindings.map((data: any) => {
                                                    return (
                                                        <div className="grid">
                                                            <div className="col linkButton text-base" onClick={() => getFindingDetail(data)}>{data.RecordName}</div>
                                                        </div>
                                                    )
                                                }
                                                )
                                                : <div className="grid">
                                                    <div className="col linkButton text-base" onClick={() => getFindingDetail(selectedAuditFinding)}>{selectedAuditFinding.RecordName}</div>
                                                </div>
                                            }
                                        </p>
                                    }
                                </AccordionTab>


                                <AccordionTab header={
                                    noteFetched == false ?
                                        <div>
                                            <span>Notes </span><div className="loader"></div>
                                        </div>
                                        :
                                        <span>Notes ({noteData.length})</span>

                                } pt={{ headerAction: () => ({ className: 'bg-ca-extended-4' }) }}>
                                    {noteFetched == false ?
                                        showTabLoading()
                                        :
                                        <span>
                                            <div className="grid m-0">
                                                {
                                                    noteData.map((data: any, i: number) => {
                                                        if (i > 2)
                                                            return (<></>)
                                                        else
                                                            return (
                                                                <div className="mb-1 w-full">
                                                                    {i > 0 ? <Divider style={{ margin: "1rem 0" }} /> : ""}
                                                                    {data.Note.length < 100 ?
                                                                        <span className="block note-display">{data.Note}</span>
                                                                        :
                                                                        <span className="block note-display">{data.Note.substr(0, 100)}...<span onClick={() => setActivePage('Notes')} className="linkButton">more</span></span>
                                                                    }
                                                                    <span className="block font-italic mt-1 text-sm text-right">--{data.CreatedBy}, {data.CreatedDate}</span>
                                                                </div>
                                                            )
                                                    })
                                                }
                                                {noteData.length > 3 ?
                                                    <div className="linkButton text-base" onClick={() => setActivePage('Notes')}>
                                                        See all notes
                                                    </div>

                                                    : ""
                                                }
                                            </div>
                                        </span>
                                    }
                                </AccordionTab>
                                <AccordionTab header={
                                    attachmentDataFetched == false ?
                                        <div>
                                            <span>Attachments </span><div className="loader"></div>
                                        </div>
                                        :
                                        <span>Attachments ({attachmentData.length})</span>
                                } pt={{ headerAction: () => ({ className: 'bg-ca-extended-5' }) }}>
                                    {attachmentDataFetched == false ?
                                        showTabLoading()
                                        :
                                        <span>
                                            <div className="grid m-0">
                                                <p className="m-0">
                                                    <ol className="checklist-set">
                                                        {
                                                            attachmentData.map((data: any) => {

                                                                return (
                                                                    <li><div className="linkButton text-base" style={{ display: 'inline' }} onClick={() => getAttachmentDetail(data)}>{data.FileName}</div></li>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </ol>
                                                </p>
                                                {/* <div className='col-12'>{attachmentData.AttachmentName}</div> */}
                                            </div>
                                        </span>
                                    }
                                </AccordionTab>
                                <AccordionTab header={
                                    checklistData.totalItems == undefined ?
                                        <div>
                                            <span>Completed QC Checklists </span><div className="loader"></div>
                                        </div>
                                        :
                                        <span>Completed QC Checklists ({checklistData.totalAnswered})</span>
                                    // "Completed QC Checklists (" + checklistData.totalAnswered + ")"
                                }
                                    pt={{ headerAction: () => ({ className: 'bg-ca-extended-6' }) }}>
                                    {checklistData.totalItems == undefined ?
                                        showTabLoading()
                                        :
                                        <p className="m-0">
                                            {
                                                checklistData.checklists.map((data: any) => {
                                                    if (data.totalAnswered == data.totalItems)
                                                        return (
                                                            <div ><div className="linkButton text-base" style={{ display: 'inline' }} onClick={() => getCheckListDetail(data, allResponses)}>{data.Category}</div></div>
                                                        )
                                                }
                                                )
                                            }
                                        </p>
                                    }
                                </AccordionTab>
                                {/* <AccordionTab header="Open Activities (0)" pt={{ headerAction: () => ({ className: 'bg-ca-6' }) }}>
                                <p className="m-0">
                                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                                    quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                                    mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                                </p>
                            </AccordionTab> */}
                                {/* <AccordionTab header="Activity History" pt={{ headerAction: () => ({ className: 'bg-ca-extended-7' }) }}>
                                <p className="m-0">
                                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
                                    quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
                                    mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                                </p>
                            </AccordionTab> */}
                                <AccordionTab header={
                                    // make sure to change this if activity data gets fetched!
                                    activityDataFetched == false ?
                                        <div>
                                            <span>Activity History </span><div className="loader"></div>
                                        </div>
                                        :
                                        <span>Activity History ({activityData.length})</span>
                                    // "Activity History (" + activityData.length + ")"
                                }
                                    pt={{ headerAction: () => ({ className: 'bg-ca-extended-7' }) }}>
                                    {
                                        // make sure to change this if activity data gets fetched!

                                        activityDataFetched == false ?
                                            showTabLoading()
                                            :
                                            <div className="grid m-0">
                                                {
                                                    activityData.map((data: any, i: number) => {
                                                        if (i > 2)
                                                            return (<></>)
                                                        else
                                                            return (
                                                                <div className="mb-1 w-full">
                                                                    {i > 0 ? <Divider style={{ margin: "1rem 0" }} /> : ""}
                                                                    <span className="block mb-1 text-sm ">{data.CreatedDate} -{data.CreatedBy}</span>
                                                                    {data.ActivityDetail.length < 100 ?
                                                                        <span className="block note-display ml-2">{data.ActivityDetail}</span>
                                                                        :
                                                                        <span className="block note-display ml-2">{data.ActivityDetail.substr(0, 100)}...<span onClick={() => setActivePage('Activities')} className="linkButton">more</span></span>
                                                                    }
                                                                </div>
                                                            )
                                                    })
                                                }
                                                {activityData.length > 0 ?
                                                    <div className="linkButton mt-3 text-base" onClick={() => setActivePage('Activities')}>
                                                        See all activities
                                                    </div>
                                                    : ""}
                                            </div>
                                    }
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <div className="col-8">
                            <ActivePage />
                            {/* {getActivePage()} */}
                        </div>
                    </div>
                </div>
            </Body>
        </div>
    )
}
