import { useContext } from 'react';
import {UserPermissionsContext} from '../contexts/userPermissionsContext';
import {Role} from "../state/rolesState"; // Update the import path

const useHasRole = (rolesToCheck: string[]) => {
    const { validRoles, isLoading } = useContext(UserPermissionsContext);

    return {
        hasRoles: validRoles.validRoles.some((roleName: string) => rolesToCheck.includes(roleName)),
        isLoading
    }
};

export default useHasRole;
