import React, { useState, useRef, useEffect } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { usePrefundAudits } from "../../state/sampleAuditsExceptionsState";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from 'axios';
import { TabView, TabPanel, TabPanelHeaderTemplateOptions } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ReactDomServer from 'react-dom/server';
import { Badge } from 'primereact/badge';
import { authenticatedFetch } from '../../state/apiUtils';
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";


export default function FindingList(props: any) {
    const toast = useRef<Toast>(null);

    const navigate = useNavigate();
    const auditPlanType = props.AuditPlanType;


    const [tabSummary, setTabSummary] = useState<any[]>([]);


    //Temporary for now since usePrefundAudits() is not working anymore
    //const {prefundStatus, prefundData, prefundError} = usePrefundAudits();

    // const onRowClick = (e: any) => {
    //     navigate(`/AuditForm/${e.data.MonthlyFundedLoanId}?audit_plan_id=${e.data.AuditPlanId}`);
    // }


    useEffect(() => {

        getTabSummary();
        //getAssignableUsers();
    }, [props.AuditPlanType]);

    
    const getTabSummary = () => {
        console.log('getTabSummary', `/FindingList/GetSummary`)
        authenticatedFetch(
            `/FindingList/GetSummary`)
            .then(res => {
                var data = res;
                setTabSummary(data);
            });

    }

    const priorityTemplate = (item: any) => {
        return (<div>
            {item.Priority} {item.PriorityOrder == 0 ? <i className="pi pi-arrow-up" style={{ color: 'red' }} /> : ""}
        </div>)
    };


    const ValidationItems = (props: any) => {

        
        const assignPriorities = [
            "High",
            "Medium",
            "Low"
        ];
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState<any[]>([]);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);
        const [assign, setAssign] = useState<boolean>(false);
        const [assignableUsers, setAssignableUsers] = useState<any[]>([]);

        const [assignedTo, setAssignedTo] = useState<any>({
            userId: "",
            priority: "Medium"
        });

        const [error, setError] = useState<string>(""); 

        useEffect(() => {

            getData();

        }, [props.AreaResponsibleId]);


        const assignInit = () => { 

            console.log('assignInit'); 
            getAssignableUsers(); 
            
        }
        const getAssignableUsers = () => {

            //Check if CLient Bank is unique
            var clients = selectedItems.map(item => item.ClientBankName).filter((value, index, self) => self.indexOf(value) === index)

            var args = ""; 
            if (clients.length == 1)
                args= "?Client=" + clients[0];

            
            console.log('args', args); 
            authenticatedFetch(
                "/FindingList/GetAssignableUsers" + args)
                .then(res => {
                    console.log('getAssignableUsers', res);
                    var data = res;
                    setAssignableUsers(data);
                    setAssign(true);
                });
            
        }
        const assignItems = () => {

            //Check to see if it can be assigned 
            var selectedClient = assignableUsers.filter (f=>f.UserId == assignedTo.userId)[0];

            if (selectedClient.ClientBankName)
            {
                var unMatch = selectedItems.filter(f=>f.ClientBankName != selectedClient.ClientBankName);
                if (unMatch.length > 0)
                {
                    setError("Assigned Validator is not in the same Client Bank (" + selectedClient.ClientBankName + ") as the Loan. Ensure that all selected item(s) have the same Client Bank in order to assign these items to this selected Validator.")
                    return; 
                }
                else 
                {
                    setError(""); 
                }
            }

            let param = {
                UserId: assignedTo.userId,
                Priority: assignedTo.priority,
                AreaResponsibleId : props.AreaResponsibleId,
                AuditReviewIds: selectedItems.map(m => m.AuditFindingId)
            }
            console.log('param', param);
            authenticatedFetch(
                "/FindingList/AssignItems", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('AssignItems', res);
                    var data = res;
                    //refresh data
                    getData();
                    setAssign(false);
                    setSelectedItems([]);
                    getTabSummary();
                    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Items has been successfully assigned', life: 3000 });

                });
        }

        const isQCAuditor = useHasRole(['QC Auditor']).hasRoles;

        const assignActions = (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setAssign(false)} className="p-button-text" />
                <Button label="Submit" icon="pi pi-check" onClick={() => assignItems()} autoFocus />
            </div>
        );

        //Do not do AuditForm here, that would make two people able 
        //to edit the data at the same time. It should be re-assigned and go through the
        //My Works pages to edit it.        
        const auditFindingView = (item: any) => {
            return (<div className="linkButton"
                onClick={() => navigate(`/AuditView/View?auditReviewId=${item.AuditReviewId}&auditFindingId=${item.AuditFindingId}&Role=QC VALIDATOR`)}
            > {item.RecordName}
            </div>)
        };
        const auditFindingViewForQCAuditor = (item: any) => {
            return (<div className="linkButton"
                onClick={() => navigate(`/AuditView/View?auditReviewId=${item.AuditReviewId}&auditFindingId=${item.AuditFindingId}`)}
            > {item.RecordName}
            </div>)
        };
        const QCAuditorHeader = (
            <div className="table-header">
            </div>
        )
        const header = (
            <div className="table-header">
                {props.AreaResponsibleId != '00000000-0000-0000-0000-000000000000' ?
                                <Button className='smallButton' disabled={selectedItems.length == 0} onClick={() => assignInit()}>Re-assign Item(s)</Button>
                : ""

                }
                <Dialog header="QC Validator Assignment" visible={assign} style={{ width: '400px' }} onHide={() => setAssign(false)} footer={assignActions}>
                    <div className="mt-2 grid">
                        <div className="col-12 mb-2">
                            <label className="block mb-2 text-x">Assign selected item(s) to </label>
                            <Dropdown value={assignedTo.userId} onChange={(e) => setAssignedTo((s: any) => { return { ...s, userId: e.value } })} optionLabel='FullName' optionValue='UserId' options={assignableUsers}
                                className="w-full" />
                        </div>
                        <div className="col-12 mb-2">
                            <label className="block mb-2 text-x">Priority</label>
                            <Dropdown value={assignedTo.priority} onChange={(e) => setAssignedTo((s: any) => { return { ...s, priority: e.value } })} options={assignPriorities}
                                className="w-full" />
                        </div>
                        { error != "" ? 
                         <div className="col-12 mb-2" style={{color: "Red"}}>
                            {error}                            
                         </div>
                     : ""
                    }
                    </div>
                </Dialog>
            </div>
        );
        const getData = () => {

            var param = {
                AreaResponsibleId: props.AreaResponsibleId
            }
            authenticatedFetch(
                `/FindingList/GetAreaItems`, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    var data = res;
                    setData(data);
                    console.log('GetAreaItems', data);
                });

        }



        return (
            <div>
                <DataTable value={data} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditFindingId" loading={loading} scrollHeight='56vh' emptyMessage="No Findings"
                    alwaysShowPaginator={false}
                    selectionMode={isQCAuditor ? null : 'multiple'} 
                    selection={selectedItems!}                 
                    onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={isQCAuditor ? QCAuditorHeader : header}
                >
                    {/* auditFindingViewForQCAuditor */}
                    {isQCAuditor || props.AreaResponsibleId == '00000000-0000-0000-0000-000000000000' ?
                        <Column headerStyle={{ width: '3rem' }}></Column>
                        :
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    }                   
                    <Column field="AuditPlanType" header="Audit Plan" />
                    <Column field="RecordName" header="Name" body={isQCAuditor ? auditFindingViewForQCAuditor : auditFindingView} />
                    <Column field="QcassignedTo" header="QC Auditor" />
                    <Column field="ValidatorAssignedTo" header="Validator" />
                    <Column field="Priority" header="Priority" body={priorityTemplate} />
                    <Column field="CurrentAssignment" header="Waiting Response From" />
                    <Column field="FindingDate" header="Date Assigned" />
                    <Column field="LoanName" header="Loan Name" />
                    <Column field="ClientBankName" header="Client Bank" />
                    <Column field="DefectDetail" header="Detail" />
                    <Column field="SeverityRating" header="Severity" />
                    <Column field="DefectName" header="Defect Name" />
                    <Column field="DefectCategory" header="Defect Category" />
                    <Column field="DefectSubCategory" header="Defect Sub Category" />

                    {/* <Column field="QuestionText" header="Question" />
                    <Column field="QuestionNumber" header="Question Number" /> */}
                </DataTable>
            </div>
        );


    }



    const tabHeader = (options: TabPanelHeaderTemplateOptions, header: string, subHeader: string, item: number) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className} style={{ borderWidth: "0px", marginLeft: "0px" }}>
                <div className="block font-bold">
                    <Badge value={item ? item : 0} className={"mb-2 " + (item == undefined || item == 0 ? ' badge-zero-value ' : '')}></Badge><br />{header}<br />
                    {subHeader}

                    {/* {subHeader ? subHeader : '\u00A0'}
                    <div className="block mt-2">{detail} item(s)</div> */}
                </div>
            </button>
        )
    }

    let { hasRoles, isLoading } = useHasRole(['Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager', 'QC Auditor', 'System Administrator'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div>
            <Nav />

            <Body>
                <div className='auditTable'>
                    <Toast ref={toast} position="bottom-center" />

                    <h1>Findings List</h1>
                    <TabView scrollable>
                        {
                            tabSummary.map((m: any) => {
                                return (
                                    <TabPanel  headerTemplate={(o) => tabHeader(o, m.AreaResponsibleName, "", m.MediumPriorityCount + m.LowPriorityCount + m.HighPriorityCount)}>
                                        <ValidationItems AreaResponsibleId={m.AreaResponsibleId} />
                                    </TabPanel>
                                )
                            })
                        }

                    </TabView>
                </div>

            </Body>
        </div>
    )
}
