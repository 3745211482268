import { authenticatedFetch } from "./apiUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export interface Clientbank {
    ClientBankId: string;
    AdminUserId: string;
    ParentClientBankId: string | undefined;
    ClientBankName: string;
    ClientBankSource: string | undefined;
    Street: string | undefined;
    City: string | undefined;
    State: string | undefined;
    PostalCode: string | undefined;
    Country: string | undefined;
    ShippingStreet: string | undefined;
    ShippingCity: string | undefined;
    ShippingState: string | undefined;
    ShippingPostalCode: string | undefined;
    ShippingCountry: string | undefined;
    RecordStatus: string;
}

async function fetchClientBanks() {
    return authenticatedFetch("/data/clientbanks");
}

async function fetchClientBank(id: string | undefined) {
    return authenticatedFetch(`/data/clientbanks/${id}`);
}

export const createClientBank = async (clientbank: Clientbank): Promise<Clientbank> => {
    return authenticatedFetch("/data/clientbanks", {
        method: "POST",
        body: JSON.stringify(clientbank),
        headers: {
            "Content-Type": "application/json",
        }
    });
};

export const updateClientBank = async (clientbank: Clientbank): Promise<Clientbank> => {
    return authenticatedFetch(`/data/clientbanks/${clientbank.ClientBankId}`, {
        method: "PUT",
        body: JSON.stringify(clientbank),
        headers: {
            "Content-Type": "application/json",
        }
    });
};


export const useClientBanks = () => {
    const {status, data, error} = useQuery({
        queryKey: ['clientbanks'],
        queryFn: fetchClientBanks,
    });

    const clientBanksData = data as Clientbank[] || [];
    return {clientBanksStatus: status, clientBanksData, clientBanksError: error};
};

export const useClientBank = (id: string | undefined) => {
    const {status, data, error} = useQuery({
        queryKey: ['clientBank', id],
        queryFn: () => fetchClientBank(id),
        enabled: !!id,
    });

    const clientBankData = data as Clientbank || null;
    return {clientBankStatus: status, clientBankData: clientBankData, clientBankError: error};
};

export const useCreateClientBank = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createClientBank,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['clientbanks'] });
        },
        // Optionally handle errors or other states here
    });
};

export const useUpdateClientBank = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateClientBank,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['clientbanks'] });
        },
        // Optionally handle errors or other states here
    });
};
