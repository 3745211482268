import {authenticatedFetch} from "./apiUtils";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Role} from "./rolesState";

export interface User {
    AzureObjectId: string;
    CompanyName: string;
    CreatedById: string;
    CreatedDate: string;
    Email: string;
    Fax: string;
    FirstName: string;
    LastLoginDate: string;
    LastModifiedById: string;
    LastModifiedDate: string;
    LastName: string;
    LastPasswordChangeDate: string;
    ManagerId: string;
    MobilePhone: string;
    NumberOfFailedLogins: number;
    Phone: string;
    UserId: string;
    AccountId: string;
    ClientBankId: string;
    UserRoleIds: string[];
    Manager?: User;
    CreatedUser?: User;
    LastModifiedUser?: User;
    RecordStatus: string;
}

interface UserRole {
    UserRoleId: string;
    UserId: string;
    RoleId: string;
    Role?: Role;
    User?: User;
}

async function fetchUsers() {
    return authenticatedFetch("/user/getusers");
}

async function fetchUser(id: string | undefined) {
    return authenticatedFetch(`/user/getuser/${id}`);
}

export const createUser = async (user: User): Promise<User> => {
    return authenticatedFetch("/user/createuser", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
            "Content-Type": "application/json",
        }
    });
};

export const updateUser = async (user: User): Promise<User> => {
    return authenticatedFetch(`/user/updateuser/${user.UserId}`, {
        method: "PUT",
        body: JSON.stringify(user),
        headers: {
            "Content-Type": "application/json",
        }
    });
};


export const useUsers = () => {
    const {status, data, error} = useQuery({
        queryKey: ['users'],
        queryFn: fetchUsers,
    });

    const usersData = data as User[] || [];
    return {usersStatus: status, usersData, usersError: error};
};

export const useUser = (id: string | undefined) => {
    const {status, data, error} = useQuery({
        queryKey: ['user', id],
        queryFn: () => fetchUser(id),
        enabled: !!id,
    });

    const userData = data as User || null;
    return {userStatus: status, userData, userError: error};
};

export const useCreateUser = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createUser,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['users']});
        },
        // Optionally handle errors or other states here
    });
};

export const useUpdateUser = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateUser,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['users']});
        },
        // Optionally handle errors or other states here
    });
};
