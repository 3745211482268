import React, { useEffect, useState } from 'react';
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import Nav from "../components/Nav";
import Body from "../components/Body";
import { useSearchParams } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ScrollPanel } from 'primereact/scrollpanel';
import "./SearchResult.css"
import { authenticatedFetch } from "../state/apiUtils";
import { useLocation } from 'react-router-dom';
import useHasRole from "../hooks/validRolesHook";
import NotFound from "../pages/Global/404NotFound";
import Loading from "../pages/Global/loading";

export default function SearchResult() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const q = searchParams.get('q');
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchResult, setSearchResult] = useState<any[]>([]);
    const [searchedName, setSearchedName] = useState<string>("");

    const onKeyDown= (e:any) => { 
        if (e.code == 'Enter')
        {
            onSearch(e.target.value);
        }
    }

    const onSearch = (q: string) => {
        setSearchedName(q);
        setSearchValue(q);
        authenticatedFetch(
            "/Search/Search?q=" + q)
            .then(res => {

                setSearchResult(res);

            });
    }
    useEffect(() => {
        if (q != null) {
            setSearchValue(q);
            onSearch(q);
            setSearchedName(q);
        }

    }, [])

    const location = useLocation();

    useEffect(() => {
        let searched = location.search.slice(3);
        console.log(`The current route is ${location.search}`, location.search.slice(3));
        onSearch(searched);
    }, [location]);

    const SearchItem = (props: any) => {
        var auditPlanType = 'Prefund';

        var loanData = props.data;
        //Check which links 
        var links = [];

        if (loanData.PrefundAuditReviewId != null) {
            console.log('Prefund Link');
            links.push(
                <div className="linkButton"
                    onClick={() => navigate(`/AuditView/${loanData.MonthlyFundedLoanId}?auditPlanType=Prefund&auditReviewId=${loanData.PrefundAuditReviewId}`)}
                >Prefund Audit View</div>
            )
        }


        if (loanData.PostcloseAuditReviewId != null) {
            links.push(
                <div className="linkButton"
                    onClick={() => navigate(`/AuditView/${loanData.MonthlyFundedLoanId}?auditPlanType=Postclose&auditReviewId=${loanData.PostcloseAuditReviewId}`)}
                >Post Close Audit View</div>
            )
        }

        if (loanData.ServicingAuditReviewId != null) {
            links.push(
                <div className="linkButton"
                    onClick={() => navigate(`/AuditView/${loanData.MonthlyFundedLoanId}?auditPlanType=Servicing&auditReviewId=${loanData.ServicingAuditReviewId}`)}
                >Servicing Audit View</div>
            )
        }

        return (
            <div className="flex flex-column mt-3" style={{ marginBottom: "2.5rem" }}>
                <div className="flex flex-column">
                    <span className="text-xl search-value font-bold">{loanData.LoanName}</span>
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Borrower Name: <span className="search-value font-normal">{loanData.BorrowersLastName}, {loanData.BorrowersFirstName}</span> || Loan Number: <span className='search-value font-normal'>{loanData.LoanNumber}</span></span>
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Current Address: <span className="search-value font-normal">{loanData.CurrentAddress}, {loanData.CurrentCity}, {loanData.CurrentState} {loanData.CurrentZipCode}</span></span>
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Amortization Type: <span className="search-value font-normal">{loanData.AmortizationType}</span> || Loan Type: <span className='search-value font-normal'>{loanData.LoanType}</span></span>
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Property Type: <span className="search-value font-normal">{loanData.PropertyType}</span> || Loan Purpose: <span className='search-value font-normal'>{loanData.LoanPurpose}</span></span>
                    {/* <span style={{marginLeft: "2rem"}} className="text-lg">Occupancy Type: <span className="search-value font-normal">{loanData.OccupancyType}</span> || Lien Position: <span className='search-value font-normal'>{loanData.LienPosition}</span></span> */}
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Loan Officer: <span className="search-value font-normal">{loanData.LoanOfficer}</span> || Closer: <span className='search-value font-normal'>{loanData.Closer}</span></span>
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Loan Processor: <span className="search-value font-normal">{loanData.LoanProcessor}</span> || Underwriter: <span className='search-value font-normal'>{loanData.Underwriter}</span></span>
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Mortgage Originator: <span className="search-value font-normal">{loanData.MortgageOriginator}</span></span>
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Created On: <span className="search-value font-normal">{loanData.CreatedDate}</span> || Created By: <span className='search-value font-normal'>{loanData.CreatedById}</span></span>
                    <span style={{ marginLeft: "2rem" }} className="text-lg font-bold">Last Modified On: <span className="search-value font-normal">{loanData.LastModifiedDate}</span> || Modified By: <span className='search-value font-normal'>{loanData.LastModifiedById}</span></span>
                </div>
                <div className='flex flex-row flex-wrap text-lg'>
                    <div className='flex align-items-center mr-2' style={{ marginLeft: "1rem" }}>
                        Link to Associated View(s):
                    </div>
                    {links.map((l: any) => {
                        return (
                            <div className='flex align-items-center mr-2'>
                                {l}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    let { hasRoles, isLoading } = useHasRole(['Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager', 'QC Auditor', 'QC Validator', 'System Administrator'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div>
            <Nav />

            <Body>
                <div className='grid'>
                    <InputText onKeyDown={(e) => onKeyDown(e)} className="col" value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} />
                    <Button className="col-fixed" style={{ width: "10rem", marginLeft: "1rem" }} label="Search" onClick={() => { onSearch(searchValue) }}></Button></div>
                <div className="text-xl p-3">{searchResult.length} Results for {searchedName}</div>
                <ScrollPanel style={{ width: '100%', height: '75.5vh' }}>
                    {
                        searchResult.map((item: any) => {
                            return (
                                <div>
                                    <SearchItem data={item} />
                                </div>
                            )
                        })
                    }
                </ScrollPanel>
            </Body>
        </div>
    )
}
