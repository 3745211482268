import React, { useState, useRef, useEffect } from 'react';
import Nav from "../components/Nav";
import { Steps } from 'primereact/steps';
import "./Upload.css"
import { MenuItem } from 'primereact/menuitem';
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import { FileUpload, FileUploadBeforeUploadEvent, FileUploadErrorEvent } from 'primereact/fileupload';
import { DataTable, DataTablePageEvent, DataTableSelectAllChangeEvent, DataTableSortEvent, DataTableFilterEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { BlockUI } from 'primereact/blockui';
import { Slider } from "primereact/slider";
import axios, { AxiosResponse } from 'axios';
import { InputSwitch } from 'primereact/inputswitch';
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { useUpdateEffect } from 'primereact/hooks';
import { ScrollPanel } from 'primereact/scrollpanel';
import { authenticatedFetch, getAuthenticatedHeader } from "../state/apiUtils";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import useHasRole from '../hooks/validRolesHook';
import Loading from './Global/loading';
import NotFound from './Global/404NotFound';


export default function Upload() {

    //State for Step Changes
    const [activeIndex, setActiveIndex] = useState<number>(0);

    //State for internal tab changes
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    // State for isUploading
    const [isUploading, setIsUploading] = useState<boolean>(false);

    // State for errorMessage
    const [errorUploadMessage, setErrorUploadMessage] = useState<string>("");

    //Upload File State
    const [fileData, setFileData] = useState(
        {
            fileCols: [], loading: true, totalRows: 0, index: 0, fileID: "", fileName: "", totalErrors: 0, totalExists: 0, totalValid: 0, page: 1, pageSize: 10, showErrorsOnly: false, displayOnly: false
        }
    )

    //Columns that are only pertinent to the File
    const [dataColumns, setDataColumn] = useState<any[]>([]);

    const [sampleSize, setSampleSize] = useState<number>(0);

    const [fileTable, setfileTable] = useState([]);

    const [selectedItems, setSelectedItems] = useState([]);

    //Used to control the selection state of each page
    const [selectAll, setSelectAll] = useState(false);

    //Used to control the selection of the table
    const [selectAllTable, setSelectAllTable] = useState(false);

    //Indicate items that are skipped from importing
    const [ignoredItems, setIgnoredItems] = useState<number[]>([]);

    //Indicate items that are duplicates from importing
    const [duplicateItems, setDuplicateItems] = useState<number>(0);

    //Statistics Step State
    const [loanSelection, setLoanSelection] = useState(
        {
            PopulationSize: 0,
            PercentDefect: 5,
            ConfidenceInterval: 2,
            ConfidenceLevel: 95,
            Frequency: "Monthly",
            SampleSize: 0,
            Selection: "All",
            Percentage: 10,
            Stratification: "",
            DefaultPercentage: 10
        }
    )
    const [discretionaryProp, setDiscretionaryProp] = useState<any[]>([]);
    const [targetedProp, setTargetedProp] = useState<any[]>([]);

    const FrequencyList = [
        { name: 'Monthly', key: 'Monthly' },
        { name: 'Quarterly', key: 'Quarterly' },
        { name: 'Semi-Annual', key: 'Semi-Annual' },
        { name: 'Annual', key: 'Annual' }
    ];

    //Detail Step State
    const [submissionData, setSubmissionData] = useState(
        {
            AuditType: "",
        }
    )

    //Return from Submission
    const [submitResult, setSubmitResult] = useState<any>(null)
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [header, setHeader] = useState<any>();

    const toast = useRef<Toast>(null);
    const items: MenuItem[] = [
        {
            label: 'Getting Started',
            command: (event) => {
            }
        },
        {
            label: 'Choose Audit Type',
            command: (event) => {
                console.log(loanSelection);
            }
        },
        {
            label: 'Upload File',
            command: (event) => {
            }
        },
        {
            label: 'Loan Selection',
            command: (event) => {
            }
        },
        {
            label: 'Review and Submit',
            command: (event) => {
            }
        },
        {
            label: 'Finish',
            command: (event) => {
            },
            disabled: true
        },

    ];

    useEffect(() => {

        initHeader();

    }, []);

    const editHeaderTemplate = (tabColor: string, options: any) => {

        const className = `${options.className} ${tabColor} justify-content-start  `;
        const titleClassName = `${options.titleClassName} ${tabColor} ml-2 `;
        const style = { fontSize: '1.25rem' };
        //console.log('options', options);
        return (
            <div className={className}>
                <span className={titleClassName} style={style}>{options.props.header}</span>
                {/* {options.props.header} */}
            </div>
        );
    };

    const helpHeaderTemplate = (options: any) => {

        const className = `${options.className} justify-content-start helpPanelHeader`;
        const titleClassName = `${options.titleClassName} ml-2 text-primary`;
        const style = { fontSize: '1.25rem' };
        console.log('options', options);
        return (
            <div className={className}>
                <span className={titleClassName} style={style}>Help Documentation</span>
                {/* {options.props.header} */}
            </div>
        );
    };

    function Step_Overview() {
        return (
            <div>
                <div className="banner">
                    <div className='grid panel-width' >
                        <div className='col'>
                            <span className="text-2xl font-bold">Uploading Your Loan Data</span>
                            <p className="text-lg">
                                This section will guide you through the process of getting your loan information
                                into our system to begin the exceptions and QC process.
                            </p>

                            <Button label="Let's Get Started" onClick={() => setActiveIndex(1)} className="mr-2" />
                        </div>
                        <div className='col-fixed' style={{ width: "500px" }}>
                            <img src='assets/images/file_upload_start.jpg' style={{ width: "500px" }}></img>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    const navPrevious = (
        <React.Fragment>
            <Button label="Go Back" icon="pi pi-chevron-left" onClick={() => { setActiveIndex(activeIndex - 1); setIsUploading(false); setErrorUploadMessage(""); }} className="mr-2" />
        </React.Fragment>
    );

    const navNext = (
        <React.Fragment>
            <Button label="Next Step" icon="pi pi-chevron-right" iconPos="right" onClick={() => { setActiveIndex(activeIndex + 1); setIsUploading(false); setErrorUploadMessage(""); }} className="mr-2" />
        </React.Fragment>
    );

    // useEffect(() => {
    //     //calcSampleSize(loanSelection.PopulationSize, loanSelection.ConfidenceInterval, loanSelection.PercentDefect, loanSelection.ConfidenceLevel);
    // }, [loanSelection]);

    const initHeader = () => {
        getAuthenticatedHeader().then((response) => {
            console.log('header', response);
            setHeader(response);

        })
    }

    const UploadDataTable = (columns: any[], displayOnly: boolean) => {
        setIsUploading(false);
        const flagRowsClass = (data: any) => {
            return {
                'dataRowError': data.Flag === 'ERROR',
                'dataRowExist': data.Flag === 'EXISTS',
                'dataRowIgnored': ignoredItems.indexOf(data.RowId) > -1
            };
        };

        const onPage = (event: DataTablePageEvent) => {
            getData(event.first + 1, event.rows, fileData.fileID, fileData.showErrorsOnly, displayOnly)
        };

        const isRowSelectable = (event: any) => (event.data.Flag !== "ERROR" || ignoredItems.indexOf(event.data.RowId) < 0 ? true : false);

        const showErrorData = (e: any) => {
            console.log('showErrorData', e);
            getData(1, fileData.pageSize, fileData.fileID, e.value, displayOnly);
        }

        const header = (
            <div className="flex flex-wrap justify-content-end gap-3">
                <span>Show only rows with errors</span>
                <InputSwitch checked={fileData.showErrorsOnly} onChange={showErrorData} />

            </div>
        );

        const onIgnoreChange = (rowId: number, ignored?: boolean) => {
            console.log('rowId', rowId, ignored);

            var items = ignoredItems;
            var totalValid = 0;
            if (ignored) {
                setIgnoredItems(prevArray => [...prevArray, rowId]);
                totalValid = fileData.totalRows - fileData.totalErrors - fileData.totalExists - ignoredItems.length - 1;
            }
            else {
                var result = ignoredItems.filter(item => item !== rowId);
                setIgnoredItems(result);
                totalValid = fileData.totalRows - fileData.totalErrors - fileData.totalExists - result.length;
            }
            //setIgnoredItems(items);
            setFileData(s => { return { ...s, totalValid: totalValid } })
            setLoanSelection(s => { return { ...s, PopulationSize: totalValid } });

            console.log('ignoredItems', items);

        }
        const ignoreColumn = (row: any) => {
            //console.log('ignore', row);
            if (row.Flag !== "ERROR" && row.Flag !== "EXISTS")
                return <Checkbox className='p-ignore-ui' checked={ignoredItems.indexOf(row.RowId) === -1} onChange={(e) => onIgnoreChange(row.RowId, !e.checked)}></Checkbox>
            else
                return ""
        };

        console.log("File table data here,", fileTable);

        let errorMessage = "";

        if (fileTable.length == 0 && fileData.showErrorsOnly == false) {
            errorMessage = "There was no information proccessed from the submitted file. You may have uploaded an empty file, or the format may have been wrong. Please check your file and try re-uploading it."
        }

        return (
            <div>
                {!displayOnly ?
                    <div>
                        <span className="text-sm text-red-500">{errorMessage !== "" && errorMessage}</span>
                        <span className="text-xl">Your file was successfully processed. </span>
                        <table className='text-sm'>
                            <tr><td>Number of record(s) in CSV:</td><td>{fileData.totalRows}</td></tr>
                            <tr><td>Number of record(s) Ignored:</td><td>{ignoredItems.length}</td></tr>
                            <tr><td>Number of record(s) with Errors:</td><td>{fileData.totalErrors}</td></tr>
                            <tr><td>Number of duplicate record(s):</td><td>{fileData.totalExists}</td></tr>
                            <tr><td>Number of record(s) to Import:</td><td>{fileData.totalValid}</td></tr>
                        </table>

                    </div>
                    : ""}
                <div>
                    <DataTable header={!displayOnly ? header : ""} dataKey="RowId" size="small" lazy stripedRows resizableColumns columnResizeMode="expand"
                        showGridlines selectionAutoFocus={false}
                        value={fileTable} rowClassName={flagRowsClass}
                        scrollable rows={fileData.pageSize} scrollHeight='34.5vh'
                        paginator totalRecords={fileData.totalRows}
                        first={fileData.index - 1} onPage={onPage}
                        rowsPerPageOptions={[10, 50, 100, 200, 500, 1000]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    >
                        {/*  selection={selectedItems}  onSelectionChange={onSelect} selectionMode=""checkbox*/}

                        {!displayOnly ? <Column body={ignoreColumn} headerStyle={{ width: '12px' }} header="" ></Column> : ""}

                        {columns.map((col, i) => (
                            <Column key={col.field} field={col.Field} header={col.Header} />
                        ))}
                    </DataTable>
                </div>
            </div>
        );
    };

    const getData = (index: number, pageSize: number, fileID: string, errorsOnly: boolean, displayOnly: boolean) => {

        var param = {
            index: index,
            pageSize: pageSize,
            fileID: fileID,
            errorsOnly: errorsOnly
        }
        authenticatedFetch(
            "/loanimport/GetData",
            {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                console.log(res);
                console.log(res);
                var data = res;

                //If only for display, remove error items
                if (displayOnly) {
                    data = data.filter((f: any) => f.Flag !== "ERROR");
                }

                setfileTable(data);
                setFileData(s => { return { ...s, loading: false, index: index, pageSize: pageSize, showErrorsOnly: errorsOnly, displayOnly: displayOnly } })
                // if (selectAllTable)
                // {
                //     var validSelection = data.filter((f: any) => f['Flag'] != "ERROR" );
                //     console.log('Adding Selection', validSelection);
                //     setSelectedItems(validSelection);
                // }
            })
            .catch(error => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 10000 })
            });

        // CustomerService.getCustomers({ lazyEvent: JSON.stringify(lazyState) }).then((data) => {
        //     setTotalRecords(data.totalRecords);
        //     setCustomers(data.customers);
        //     setLoading(false);
        // });
    }


    function Step_UploadFile() {


        //         const onSelect = (e: any) => {
        //             setSelectedItems(e.value as any);
        //             var validSelection = fileTable.filter(f=> f['Flag'] != "ERROR" ).length;
        //             console.log(e.value.length, validSelection)

        //             //Find if this is selectAll
        //             if (selectAll && e.value.length === validSelection)
        //             {
        //                 //It's still select All
        //             }
        //             else
        //             {
        //                 //If the previous table was still selected all, then just remove this portion
        //                 if (selectAllTable )
        //                 {
        //                     var totalSelection = fileData.totalRows - fileData.totalErrors - validSelection + e.value.length;
        //                     console.log('totalSelection', totalSelection);
        //                     setLoanSelection(s => { return { ...s, PopulationSize: totalSelection } })
        //                 }
        //                 else
        //                 {
        //                     setLoanSelection(s => { return { ...s, PopulationSize: e.value.length } })
        //                 }
        //                 setSelectAll(false);
        //             }
        // //            setSelectAll(e.value.length === fileData.totalRows - fileData.totalErrors);
        //         }

        //         const onSelectAllChange = (event : DataTableSelectAllChangeEvent) => {
        //             const selectAll = event.checked;

        //             if (selectAll) {
        //                 setSelectAll(true);
        //                 setSelectAllTable(true);
        //                 var validSelection = fileTable.filter(f=> f['Flag'] != "ERROR" );
        //                 console.log('Valid Selection', validSelection);
        //                 setSelectedItems(validSelection);
        //                 setLoanSelection(s => { return { ...s, PopulationSize: fileData.totalRows - fileData.totalErrors } })

        //                 }
        //             else {
        //                 setSelectAll(false);
        //                 setSelectedItems([]);
        //                 setSelectAllTable(false);
        //                 setLoanSelection(s => { return { ...s, PopulationSize: 0 } })

        //             }
        //         };


        const onUpload = (e: any) => {

            //this.toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
            var result = JSON.parse(e.xhr.response);
            console.log("Results?", result);
            console.log('OnUpload Called ', result.fileID);
            if (result.total == 0) {
                toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'You may have uploaded an empty file, or the format may have been wrong.', life: 10000 });
            }
            else {
                //Unnecessary as there are other UI reactions
                //toast.current?.show({ severity: 'success', summary: 'Success', detail: 'File successfully uploaded.', life: 3000 });
            }


            var defaultPageSize = 10;
            setFileData({ fileCols: result.Columns, loading: false, totalRows: result.total, index: result.index, fileID: result.FileID, totalErrors: result.totalErrors, totalExists: result.totalExists, totalValid: result.total - result.totalExists - result.totalErrors, fileName: result.FileName, page: 1, pageSize: defaultPageSize, showErrorsOnly: false, displayOnly: false });
            var dataColumns = result.Columns.filter((f: any) => ["RowId", "FlagRemarks", "Flag"].indexOf(f.Field) < 0)

            //Add Empty Item
            dataColumns.splice(dataColumns, 0, { Field: "", Header: "---", Type: "" });

            setDataColumn(dataColumns);
            //Get the first Set of data
            getData(result.index, defaultPageSize, result.FileID, false, false);

            //Update Population Size
            setLoanSelection(s => { return { ...s, PopulationSize: result.total - result.totalExists - result.totalErrors } });

            //Reset Selection
            //setSelectedItems([]);
            setIgnoredItems([]);

        };

        const onBeforeUpload = (e: FileUploadBeforeUploadEvent) => {
            console.log("set is uploading");
            setIsUploading(true);
            e.formData.append('AuditType', submissionData.AuditType);

            console.log('onBeforeUploadHeader', header.Authorization);
            console.log('onBeforeUploadHeader', header.Token);
            e.xhr.setRequestHeader("Authorization", header.Authorization);
            e.xhr.setRequestHeader("X-IdToken", header.Token);
        }

        const onError = (e: FileUploadErrorEvent) => {
            setIsUploading(false);
            setErrorUploadMessage("There was an error uploading your file. Please ensure the file follows the expected file format and double check you selected the correct audit type in the previous step. For additional help, read the help documentation and compare your file's format to the template files.");
            // errorMessage =
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'There was an error uploading your file.', life: 10000 })
        }

        let errorMessage = "";

        let fileContent = fileData.loading
            ? ""
            : UploadDataTable(fileData.fileCols, false);

        errorMessage = submissionData.AuditType == "" ?
            "In order to upload a file, you must first choose a Audit Process Type. Please indicate what Audit Type this file is in the previous step" : "";

        return (
            <div>
                <div className="grid nested-grid p-3">


                    <div className='col-8'>
                        <Panel header="Upload File" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-2', o)} style={{ height: "fit-content" }}>
                            <p className="m-0">
                                {fileData.loading ?
                                    errorMessage == "" ?
                                        // isUploading ?
                                        <div>
                                            <span className="text-lg text-red-500">{errorUploadMessage !== "" && errorUploadMessage}</span>
                                            <ProgressBar mode={isUploading ? "indeterminate" : "determinate"} style={{ height: '6px', borderRadius: '5px', marginBottom: '1rem', marginTop: '1rem' }}></ProgressBar>

                                            <FileUpload mode="advanced" name="UploadedFiles" url={process.env.REACT_APP_API_BASE_URL + "/loanimport/UploadFile"}
                                                accept=".csv" onBeforeSend={onBeforeUpload} maxFileSize={10000000}
                                                onUpload={onUpload}
                                                onError={onError}
                                                disabled={isUploading ? true : false}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <span className="text-lg text-red-500">{errorMessage}</span>

                                            <ProgressBar mode={"determinate"} style={{ height: '6px', borderRadius: '5px', marginBottom: '1rem', marginTop: '1rem' }}></ProgressBar>

                                            <FileUpload mode="advanced" name="UploadedFiles" url={process.env.REACT_APP_API_BASE_URL + "/loanimport/UploadFile"}
                                                accept=".csv" onBeforeSend={onBeforeUpload} maxFileSize={10000000}
                                                onUpload={onUpload}
                                                onError={onError}
                                                disabled={true}
                                            />
                                        </div>
                                    :
                                    <div>
                                        <p></p>
                                        <Button label="Upload A Different File" onClick={() => setFileData(s => { return { ...s, loading: true } })} className="mr-2" />
                                    </div>
                                }
                            </p>
                            {fileContent}

                            <Toolbar start={navPrevious} end={navNext} />
                        </Panel>
                    </div>
                    <div className='col-4' >
                        <Panel headerTemplate={helpHeaderTemplate}>
                            <p className="text-xl">
                                How do I upload a file?
                            </p>
                            <p>
                                Press the <span className='text-primary font-bold'>Choose</span> button and select a file with a CSV extension. Press the <span className='text-primary font-bold'>Upload</span> button afterwards. If the file was successfully processed, your data should appear for you to review.
                            </p>
                            <p className="text-xl">
                                What kind of file do I upload?
                            </p>
                            <p>
                                Depending on the audit type selected in the previous step, a different set of requirements for what the data file needs to provide.
                                If you do not know what the predefined CSV field structures are, you can download it below.

                            </p>
                            <p>
                                <a style={{ display: 'block' }} download href="assets/upload-csv/Prefund-Loan-CSV-Template.csv"><Button label="Download Prefund Audit Template File" className="mr-2 w-full" /></a>
                            </p>
                            <p>
                                <a style={{ display: 'block' }} download href="assets/upload-csv/Post-Close-Loan-CSV-Template.csv"><Button label="Download Post Close Audit Template File" className="mr-2 w-full" /></a>
                            </p>
                            <p>
                                <a style={{ display: 'block' }} download href="assets/upload-csv/Servicing-Loan-CSV-Template.csv"><Button label="Download Servicing Audit Template File" className="mr-2 w-full" /></a>
                            </p>
                        </Panel>

                    </div>


                </div>
            </div>
        );
    }


    const StatisticsForm = (props: any) => {

        useEffect(() => {
            if (props.loanSelection)
                calcSampleSize(loanSelection.PopulationSize, loanSelection.ConfidenceInterval, loanSelection.PercentDefect, loanSelection.ConfidenceLevel);
        }, [props.loanSelection]);

        const percentile_z = (p: number): number => {
            if (p < 0.5) return -percentile_z(1 - p);

            if (p > 0.92) {
                if (p == 1) return Infinity;
                let r = Math.sqrt(-Math.log(1 - p));
                return (((2.3212128 * r + 4.8501413) * r - 2.2979648) * r - 2.7871893) /
                    ((1.6370678 * r + 3.5438892) * r + 1);
            }
            p -= 0.5;
            let r = p * p;
            return p * (((-25.4410605 * r + 41.3911977) * r - 18.6150006) * r + 2.5066282) /
                ((((3.1308291 * r - 21.0622410) * r + 23.0833674) * r - 8.4735109) * r + 1);
        }

        const calcSampleSize = (pop: number, ci: number, pd: number, cl: number) => {


            console.log('Confidence Level', cl);
            console.log('Confidence Interval', ci);
            console.log('Percent Defect', pd)
            console.log('Population', pop);

            var zScore = (1 + cl / 100) / 2;
            var zScoreP = percentile_z(zScore);
            console.log('zScoreP', zScoreP);

            // let sampleSize = loanSelection.PopulationSize *
            //     (loanSelection.ConfidenceLevel / 100) *
            //     (loanSelection.ConfidenceInterval / 100) *
            //     (loanSelection.ConfidenceLevel / 100)
            //     ;

            //=ROUND((E20*(1-E20)*(F16/E24)^2),0)
            let z = +(Math.round(+(zScoreP + "e+3")) + "e-3");
            console.log('z', z);

            let pd_percent = pd / 100;
            let ci_percent = ci / 100;
            let z_portion = (z / ci_percent) * (z / ci_percent);
            console.log('z_portion', z_portion);

            let sampleSizeU = Math.round((pd_percent) * (1 - pd_percent) * z_portion)
            console.log('sampleSizeU', sampleSizeU);

            let sampleSize = Math.round(sampleSizeU / (1 + (sampleSizeU - 1) / pop));
            console.log('sampleSize', sampleSize);

            if (!isNaN(sampleSize)) {
                //setLoanSelection(s => { return { ...s, SampleSize: sampleSize } });
                setSampleSize(sampleSize);


            }

        };

        return (
            <div>

                <Card className='col-12'>
                    <div className="grid nested-grid">
                        <div className="col-6">

                            <label className="block mb-2">Population Size</label>
                            <InputNumber className="col-12 statPopInput" style={{ padding: "0px;" }} value={loanSelection.PopulationSize} onValueChange={(e) => { setLoanSelection(s => { return { ...s, PopulationSize: Number(e.value) } }); }} min={0} max={fileData.totalValid} />


                            <label className="block mt-5">Confidence Level
                            </label>
                            <div className="grid">
                                {/* <div className='col' style={{ paddingTop: "24px" }}>
                                    <Slider value={loanSelection.ConfidenceLevel} onChange={(e) => { setLoanSelection(s => { return { ...s, ConfidenceLevel: Number(e.value) } }); }} />
                                </div> */}
                                <div className="col-fixed">
                                    <InputNumber className="statInput" value={loanSelection.ConfidenceLevel} onValueChange={(e) => { setLoanSelection(s => { return { ...s, ConfidenceLevel: Number(e.value) } }); }} suffix="%" minFractionDigits={0} maxFractionDigits={1} min={0} max={99.9} />
                                </div>
                            </div>


                            <label className="block mt-5">Expected Defect Rate
                            </label>
                            <div className="grid">

                                {/* <div className='col' style={{ paddingTop: "24px" }}>
                                    <Slider value={loanSelection.PercentDefect} onChange={(e) => { setLoanSelection(s => { return { ...s, PercentDefect: Number(e.value) } }); }} min={0} max={50} />
                                </div> */}
                                <div className="col-fixed">
                                    <InputNumber className="statInput" value={loanSelection.PercentDefect} onValueChange={(e) => { setLoanSelection(s => { return { ...s, PercentDefect: Number(e.value) } }); }} suffix="%" minFractionDigits={0} maxFractionDigits={1} min={0} max={50} />
                                </div>
                            </div>


                            <label className="block mt-5">Confidence Interval

                            </label>
                            <div className="grid">

                                {/* <div className='col' style={{ paddingTop: "24px" }}>
                                    <Slider value={loanSelection.ConfidenceInterval} onChange={(e) => { setLoanSelection(s => { return { ...s, ConfidenceInterval: Number(e.value) } }); }} min={0} max={20} />
                                </div> */}
                                <div className="col-fixed">

                                    <InputNumber className="statInput" value={loanSelection.ConfidenceInterval} onValueChange={(e) => { setLoanSelection(s => { return { ...s, ConfidenceInterval: Number(e.value) } }); }} suffix="%" minFractionDigits={0} maxFractionDigits={1} min={0} max={20} />
                                </div>
                            </div>

                        </div>
                        <div className='col-6 pl-5'>

                            <label className="block mb-2" >Based on the entered information, your calculated sample size will be</label>

                            <label className="block mb-2 "><span className='font-bold '>{Math.round(sampleSize / 12)}</span> Monthly</label>
                            <label className="block mb-2  "><span className='font-bold '>{Math.round(sampleSize / 4)}</span> Quarterly</label>
                            <label className="block mb-2 "><span className='font-bold '>{Math.round(sampleSize / 2)}</span> Semi-Annual</label>
                            <label className="block mb-2 "><span className='font-bold '>{sampleSize}</span> Yearly</label>

                            <div>
                                <label className="block mt-5">Select Which Frequency to Process</label>
                                <div className="flex flex-column gap-3 mt-2">
                                    {FrequencyList.map((item) => {
                                        return (
                                            <div key={item.key} className="flex align-items-center">
                                                <RadioButton inputId={item.key} name="statFrequency" value={item} onChange={(e) => setLoanSelection(s => { return { ...s, Frequency: e.value.key } })} checked={loanSelection.Frequency === item.key} />
                                                <label htmlFor={item.key} className="ml-2">{item.name}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>


                </Card>


            </div>




        )
    }

    const RulesForm = (props: any) => {
        const [ruleInfo, setRuleInfo] = useState<any[]>([]);
        const [ruleProp, setRuleProp] = useState<any[]>([]);
        const [rulesError, setRulesError] = useState<any>(
            {
                "0-0": false,
            }
        );
        const checkRulesErrors = (ruleInfo: any) => {
            var hasErrors = false;
            console.log("Rule Info?", ruleInfo)
            // console.log("AHHH HERE", checklistRules)
            for (let i = 0; i < ruleInfo.length; i++) {
                // console.log("Checklist rules - i", i, checklistRules[i])
                // console.log("i", i)
                for (let j = 0; j < ruleInfo[i].ruleItems.length; j++) {
                    // console.log("i", i, "j", j)
                    // console.log(checklistRules[i].ruleItems[j].field.fieldName)
                    if (ruleInfo[i].ruleItems[j].field !== (undefined || null)) {
                        // console.log("Set it false??")
                        if ((ruleInfo[i].ruleItems[j].operator === (undefined || "=" || "" || null)) || (ruleInfo[i].ruleItems[j].value === (undefined || "" || null))) {
                            // rulesError[(i + "-" + j)] = false;
                            handleRuleErrors(i + "-" + j, true)
                            hasErrors = true;
                            // console.log("Set it false??")
                        }
                    } else if (ruleInfo[i].ruleItems[j].field === (undefined || null)) {
                        if ((ruleInfo[i].ruleItems[j].operator !== (undefined || "=" || "" || null)) || (ruleInfo[i].ruleItems[j].value !== (undefined || "" || null))) {
                            // rulesError[(i + "-" + j)] = false;
                            handleRuleErrors(i + "-" + j, true)
                            hasErrors = true;
                            // console.log("Set it false?? again??")
                        }
                    }
                    if ((ruleInfo[i].ruleItems[j].field !== (undefined || null)) && (ruleInfo[i].ruleItems[j].operator !== (undefined || "=" || "" || null)) && (ruleInfo[i].ruleItems[j].value !== (undefined || "" || null))) {
                        // rulesError[(i + "-" + j)] = true;
                        handleRuleErrors(i + "-" + j, false)
                        // console.log("Set it true!!")
                    }
                    if (!((i + "-" + j) in rulesError)) {
                        handleRuleErrors(i + "-" + j, false)
                    }
                }
            }
            // console.log("RULES ERRORS??", rulesError)

            return hasErrors;
        };

        const handleRuleErrors = (name: any, value: any) => {
            // console.log("RULES ERRORS??", rulesError)
            // rulesError[name] = value;
            setRulesError((errors: any) => ({ ...errors, [name]: value }))
            console.log(rulesError);
        };


        const [autoSuggestValues, setAutoSuggestValues] = useState<string[]>([]);

        const operatorList: Record<string, string[]> = {
            'string': ["is", "is not", "contains", "does not contain", "starts with", "ends with"],
            'numeric': ["=", ">=", "<=", ">", "<", "is not"],
            'date': ["is", "is after", "is before", "is not"],
            'control': ["is", "is not"]
        }

        const addRuleSubClause = (e: any) => {
            console.log('addRuleSubClause', e);
            //var ruleRgroups = this.state.ruleInfo.slice();

            // setRulesError((errors: any) => ({ ...errors, [g]: true }))

            var newGroup = {
                ruleClause: 'And',
                ruleItems: [
                    {
                        field: null,
                        op: '=',
                        value: null
                    }
                ]
            }

            setRuleInfo([
                ...ruleInfo,
                newGroup
            ]);

        }

        useEffect(() => {
            console.log('useEffect', props)
            if (props.ruleData)
                setRuleInfo(props.ruleData.rulesObj);
            else
                buildDefaultRules();

            console.log('fileCols', fileData.fileCols);
        }, [props.ruleData])

        const buildDefaultRules = () => {
            var ruleItem = {
                field: null,
                op: '=',
                value: null,
                type: 'string',
            }
            var ruleGroup = [];
            ruleGroup.push({
                ruleItems: [ruleItem],
                ruleClause: 'And'
            });

            setRuleInfo(ruleGroup);
            console.log('ruleGroup', ruleGroup);
        }


        const addRuleRow = (e: any, g: number) => {
            console.log('ADDING RULE ROW');
            var ruleItem = {
                field: null,
                op: '=',
                value: null
            };

            var ruleProp = ruleInfo.slice();
            ruleProp[g].ruleItems.push(ruleItem);

            setRuleInfo(ruleProp);
            checkRulesErrors(ruleInfo);
        }

        const removeRuleItem = (e: any, g: any, idx: number) => {

            var ruleRgroups = ruleInfo.slice();
            ruleRgroups[g].ruleItems.splice(idx, 1);
            if (g > 0 && ruleRgroups[g].ruleItems.length == 0) {
                ruleRgroups.splice(g, 1);
            }

            setRuleInfo(ruleRgroups);
            // this.setState({ ruleInfo: ruleRgroups });
            checkRulesErrors(ruleInfo);
        }


        const setClause = (e: any, g: number) => {
            console.log('setClause', e);
            var ruleRgroups = ruleInfo.slice();
            ruleRgroups[g].ruleClause = e.value;
            setRuleInfo(ruleRgroups);
            checkRulesErrors(ruleInfo);
        }


        const setField = (e: any, g: number, idx: number) => {
            console.log('setField', e, idx);
            let name = g + "-" + idx;
            console.log(name);
            // setRulesError((errors: any) => ({ ...errors, [name]: false }))
            var ruleProp = ruleInfo.slice();
            ruleProp[g].ruleItems[idx].field = e.value;
            ruleProp[g].ruleItems[idx].type = e.value.Type;

            var param = {
                fileID: fileData.fileID,
                fieldName: e.value.Field
            }
            authenticatedFetch(
                "/loanimport/GetFieldValues", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('GetFieldValues', res);
                    var data = res;

                    var list = data.map((m: string) => m[e.value.Field]);

                    console.log('auto list', list)
                    setAutoSuggestValues(list);
                })
                .catch(error => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 10000 })
                });

            //            var list = e.value.values;

            setRuleInfo(ruleProp);
            checkRulesErrors(ruleInfo);
        }

        const setAutoSuggest = (e: any, g: number, idx: number) => {
            try {
                var param = {
                    fileID: fileData.fileID,
                    fieldName: ruleInfo[g].ruleItems[idx].field.Field
                }
                console.log('setAutoSuggest', param);
                authenticatedFetch(
                    "/loanimport/GetFieldValues", {
                    method: "POST",
                    body: JSON.stringify(param),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then(res => {
                        console.log('GetFieldValues', res);
                        var data = res;

                        var list = data.map((m: string) => m[param.fieldName]);

                        console.log('auto list', list)
                        setAutoSuggestValues(list);
                    })
                    .catch(error => {
                        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 10000 })
                    });
            }
            catch (error) {
                toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Unable to generate auto suggestions. Please select a field first.', life: 3000 })
            }
        }
        const setOperator = (e: any, g: number, idx: number) => {
            console.log('setField', e, idx);
            var ruleProp = ruleInfo.slice();
            ruleProp[g].ruleItems[idx].op = e.value;
            setRuleInfo(ruleProp);
            checkRulesErrors(ruleInfo);
        }

        const setValue = (e: any, g: number, idx: number) => {
            console.log('setField', e, idx);
            var ruleProp = ruleInfo.slice();
            ruleProp[g].ruleItems[idx].value = e.target.value;
            setRuleInfo(ruleProp);
            checkRulesErrors(ruleInfo);
        }

        const transformRulesEnglish = () => {

            var whereClauses: string[] = [];
            ruleInfo.forEach(group => {
                let queryDef = '';
                var queryDefList: any[] = [];
                group.ruleItems.forEach((rule: any) => {
                    if (rule.field == null) {
                        return;
                    }
                    console.log('rule', rule);
                    var fieldName = "<span class='ruleField'>" + rule.field.Field + "</span>";
                    if (rule.type == 'string' || rule.type == 'control') {
                        //    'string': ["contains", "does not contain", "starts with", "ends with", "=", "!="],
                        var fieldQuery = '';

                        //escape m.value
                        var value = rule.value; //.replace("'", "''");
                        switch (rule.op) {
                            case 'contains':
                                {
                                    fieldQuery = fieldName + ' contains <span class="ruleValue">' + value + '</span>';
                                }
                                break;
                            case 'does not contain':
                                {
                                    fieldQuery = fieldName + ' does not contain <span class="ruleValue">' + value + '</span>';
                                }
                                break;
                            case 'starts with':
                                {
                                    fieldQuery = fieldName + ' starts with  <span class="ruleValue">' + value + '</span>';
                                }
                                break;
                            case 'ends with':
                                {
                                    fieldQuery = fieldName + ' ends with  <span class="ruleValue">' + value + '</span>';
                                }
                                break;
                            case 'is':
                                {
                                    fieldQuery = fieldName + ' is  <span class="ruleValue">' + value + '</span>';
                                }
                                break;
                            case 'is not':
                                {
                                    fieldQuery = fieldName + ' is not  <span class="ruleValue">' + value + '</span>';
                                }
                                break;

                        }
                        queryDefList.push(fieldQuery);
                    }
                    else if (rule.type == 'numeric') {
                        queryDefList.push(fieldName + rule.op + ' <span class="ruleValue">' + rule.value + '</span>');
                    }
                    else {
                        queryDefList.push(fieldName + rule.op + ' <span class="ruleValue">' + rule.value + "</span>");
                    }
                });

                console.log('queryDefList', queryDefList);
                if (queryDefList.length > 0) {
                    queryDef = '( ' + queryDefList.join(' ' + (group.ruleClause == "And" ? " and " : " or ") + ' ') + ' )';
                    whereClauses.push(queryDef);
                }
            }
            );

            var whereClause = '';
            if (whereClauses.length > 1) {
                whereClause = whereClauses.join(' ' + (ruleInfo[0].ruleClause == "And" ? " and " : " or ") + ' ');
            }
            else if (whereClauses.length > 0) {
                whereClause = whereClauses[0];
            }
            console.log('whereClause', whereClause);
            return whereClause;
        };

        const transformRules = () => {

            var whereClauses: string[] = [];
            ruleInfo.forEach(group => {
                let queryDef = '';
                var queryDefList: any[] = [];
                group.ruleItems.forEach((rule: any) => {
                    if (rule.field == null) {
                        return;
                    }
                    console.log('rule', rule);
                    var fieldName = "[" + rule.field.fieldName + "]";
                    if (rule.type == 'string' || rule.type == 'control') {
                        //    'string': ["contains", "does not contain", "starts with", "ends with", "=", "!="],
                        var fieldQuery = '';

                        //escape m.value
                        var value = rule.value.replace("'", "''");
                        switch (rule.op) {
                            case 'contains':
                                {
                                    fieldQuery = fieldName + '.Contains("' + value.toUpperCase() + '")';
                                }
                                break;
                            case 'does not contain':
                                {
                                    fieldQuery = "!" + fieldName + '.Contains("' + value.toUpperCase() + '")';
                                }
                                break;
                            case 'starts with':
                                {
                                    fieldQuery = fieldName + '.StartsWith("' + value.toUpperCase() + '")';
                                }
                                break;
                            case 'ends with':
                                {
                                    fieldQuery = fieldName + '.EndsWith("' + value.toUpperCase() + '")';
                                }
                                break;
                            case 'is':
                                {
                                    fieldQuery = fieldName + ' == "' + value.toUpperCase() + '"';
                                }
                                break;
                            case 'is not':
                                {
                                    fieldQuery = fieldName + ' != "' + value.toUpperCase() + '"';
                                }
                                break;

                        }
                        queryDefList.push(fieldQuery);
                    }
                    else if (rule.type == 'numeric') {
                        queryDefList.push(fieldName + rule.op + rule.value);
                    }
                    else {
                        queryDefList.push(fieldName + rule.op + "'" + rule.value + "'");
                    }
                });

                console.log('queryDefList', queryDefList);
                if (queryDefList.length > 0) {
                    queryDef = '(' + queryDefList.join(' ' + (group.ruleClause == "And" ? " && " : " || ") + ' ') + ')';
                    whereClauses.push(queryDef);
                }
            }
            );

            var whereClause = '';
            if (whereClauses.length > 1) {
                whereClause = whereClauses.join(' ' + (ruleInfo[0].ruleClause == "And" ? " && " : " || ") + ' ');
            }
            else if (whereClauses.length > 0) {
                whereClause = whereClauses[0];
            }
            console.log('whereClause', whereClause);
            return whereClause;
        };
        const submit = () => {

            var result = {
                rulesEng: transformRulesEnglish(),
                rulesObj: ruleInfo
            }
            props.onSubmit(result);

        }
        const cancel = () => {

            props.onCancel();
        }
        const footer = (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => cancel()} className="p-button-text" />
                <Button label="Submit" icon="pi pi-check" onClick={() => submit()} autoFocus />
            </div>
        );
        return (

            <div>
                <Dialog header="New Criteria" style={{ width: '50vw', height: '30vw' }} visible={props.showRuleForm} onHide={() => props.onCancel()} footer={footer}>

                    {
                        ruleInfo.map((group: any, j: number) => {
                            console.log('Running', j)
                            return (
                                <div key={j}>
                                    {j > 0 ? <div className="col-12 clauseLabel">-{ruleInfo[0].ruleClause}-</div> : ''}

                                    <div className={j != 0 ? 'secondGroup' : 'firstGroup'}>
                                        <div className="field-radiobutton">
                                            <RadioButton value="And" inputId={"Aclause" + j} name={"clause" + j} onChange={e => setClause(e, j)} checked={group.ruleClause === 'And'} />
                                            <label style={{ marginBottom: '0px', marginLeft: '3px' }} htmlFor={"Aclause" + j}>All of the following must be met</label>
                                        </div>
                                        <div className="field-radiobutton">
                                            <RadioButton value="Or" inputId={"Oclause" + j} name={"clause" + j} onChange={e => setClause(e, j)} checked={group.ruleClause === 'Or'} />
                                            <label style={{ marginBottom: '0px', marginLeft: '3px' }} htmlFor={"Oclause" + j}>At last one of the following must be met</label>
                                        </div>
                                        <div className="separator">
                                            {
                                                group.ruleItems.map((item: any, i: number) => {
                                                    //console.log('ITEM LOOP', item);
                                                    let name = j + "-" + i;
                                                    console.log("rulesError[name] ", rulesError[name], " name ", name);
                                                    return (
                                                        <div key={i}>
                                                            {i > 0 ? <div className="col-12 clauseLabel">-{group.ruleClause}-</div> : ''}
                                                            <div className="col-12 p-dropdown-padding">
                                                                <Dropdown
                                                                    // className="col-4 p-dropdown-padding"
                                                                    className={(rulesError[name] == undefined || rulesError[name] == true) ? "col-4 p-dropdown-padding p-invalid" : "col-4 p-dropdown-padding"}
                                                                    value={item.field}
                                                                    onChange={e => setField(e, j, i)}
                                                                    options={dataColumns}
                                                                    optionLabel="Header"
                                                                    placeholder="Field..." />
                                                                <Dropdown
                                                                    className={(rulesError[name] == undefined || rulesError[name] == true) ? "col-2 p-dropdown-padding p-invalid" : "col-2 p-dropdown-padding"}
                                                                    // className="col-2 p-dropdown-padding"
                                                                    value={item.op}
                                                                    onChange={e => setOperator(e, j, i)}
                                                                    options={operatorList[item.type]} />
                                                                {/* suggestions={this.state.autoSuggestValues} completeMethod={e => { this.onAutoSuggest(e, item.field) }}  */}
                                                                <Dropdown
                                                                    value={item.value}
                                                                    onMouseDown={e => setAutoSuggest(e, j, i)}
                                                                    onChange={e => setValue(e, j, i)}
                                                                    options={autoSuggestValues}
                                                                    editable
                                                                    className={(rulesError[name] == undefined || rulesError[name] == true) ? "col-5 p-dropdown-padding p-invalid" : "col-5 p-dropdown-padding"}
                                                                // className="col-5 p-dropdown-padding"
                                                                />
                                                                {/* <AutoComplete inputStyle={{ width: '100%' }} className="col-5 p-dropdown-padding" value={item.value} onChange={e => setValue(e, j, i)} suggestions={autoSuggestValues} completeMethod={e => { onAutoSuggest(e, item.field) }} /> */}
                                                                <i onClick={e => removeRuleItem(e, j, i)} className="col-fixed pi pi-trash danger" style={{ display: 'inline-block', textAlign: 'right', marginLeft: '5px', cursor: "pointer" }}></i>
                                                                {/* <Button rounded text severity="danger" aria-label="Cancel" onClick={e => removeRuleItem(e, j, i)} className="col-1 pi pi-trash" style={{ display: 'inline-block', minWidth: "0" }} /> */}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        {j > 0 ? <div className="linkButton" style={{ marginLeft: '3px' }} onClick={e => addRuleRow(e, j)}>Add Condition to this Section</div> : ''}
                                    </div>

                                    {ruleInfo?.length ? <div className="col-12 p-dropdown-padding"><div className="linkButton" style={{ display: 'inline', marginLeft: '5px' }} onClick={e => addRuleRow(e, 0)}>Add Criteria</div><div className="linkButton" style={{ marginLeft: '10px', display: 'inline' }} onClick={addRuleSubClause}>Add New Section</div></div> : ''}

                                </div>

                            )
                        }

                        )
                    }
                </Dialog>
            </div>
        )




    }
    const DiscretionaryForm = () => {
        const [showRuleForm, setShowRuleForm] = useState<boolean>(false);
        const [previewData, setPreviewData] = useState<any[]>([]);
        const [selectedIndex, setSelectedIndex] = useState<number>();
        const [preview, setPreview] = useState<boolean>(false);
        const [previewIndex, setPreviewIndex] = useState<any>(
            {
                criteriaIndex: 0,
                rowIndex: 0,
                pageSize: 100,
                total: 0
            }
        );
        const showRuleUI = () => {
            setShowRuleForm(true);
        }

        const [ruleData, setRuleData] = useState<any>(null);


        const onCancelRule = () => {
            setShowRuleForm(false);
        }

        const checkRulesErrors = (ruleInfo: any) => {
            var hasErrors = false;
            console.log("Rule Info?", ruleInfo)
            // console.log("AHHH HERE", checklistRules)
            for (let i = 0; i < ruleInfo.length; i++) {
                // console.log("Checklist rules - i", i, checklistRules[i])
                // console.log("i", i)
                for (let j = 0; j < ruleInfo[i].ruleItems.length; j++) {
                    // console.log("i", i, "j", j)
                    // console.log(checklistRules[i].ruleItems[j].field.fieldName)
                    if (ruleInfo[i].ruleItems[j].field !== (undefined || null)) {
                        // console.log("Set it false??")
                        if ((ruleInfo[i].ruleItems[j].operator === (undefined || "=" || "" || null)) || (ruleInfo[i].ruleItems[j].value === (undefined || "" || null))) {
                            // rulesError[(i + "-" + j)] = false;
                            // handleRuleErrors(i + "-" + j, true)
                            hasErrors = true;
                            // console.log("Set it false??")
                        }
                    } else if (ruleInfo[i].ruleItems[j].field === (undefined || null)) {
                        if ((ruleInfo[i].ruleItems[j].operator !== (undefined || "=" || "" || null)) || (ruleInfo[i].ruleItems[j].value !== (undefined || "" || null))) {
                            // rulesError[(i + "-" + j)] = false;
                            // handleRuleErrors(i + "-" + j, true)
                            hasErrors = true;
                            // console.log("Set it false?? again??")
                        }
                    }
                    if ((ruleInfo[i].ruleItems[j].field !== (undefined || null)) && (ruleInfo[i].ruleItems[j].operator !== (undefined || "=" || "" || null)) && (ruleInfo[i].ruleItems[j].value !== (undefined || "" || null))) {
                        // rulesError[(i + "-" + j)] = true;
                        // handleRuleErrors(i + "-" + j, false)
                        // console.log("Set it true!!")
                    }
                    // if (!((i + "-" + j) in rulesError)) {
                    //     handleRuleErrors(i + "-" + j, false)
                    // }
                }
            }

            return hasErrors
        }

        const onUpdateRule = (data: any) => {
            console.log('onUpdateRule', data);
            console.log('updateIndex', selectedIndex);
            // for (let item in rulesError) {
            //     console.log("here", rulesError[item]);
            // }
            var rulesHasError = checkRulesErrors(data.rulesObj)
            console.log("rulesHasError", rulesHasError);
            console.log(data)
            if (rulesHasError == true) {
                console.log("I BAILED!")
                toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'One or more criteria have error(s). Please fix all errors before proceeding.', life: 10000 });
                return;
            }
            var copy = discretionaryProp.slice(0);
            if (selectedIndex != undefined && selectedIndex > -1) {
                //Update
                console.log('update');
                copy[selectedIndex] = data;
                setDiscretionaryProp(copy);
            }
            else {
                console.log('add');
                copy.push(data);
                setDiscretionaryProp(copy);

            }
            setShowRuleForm(false);
        }

        const onAddData = () => {
            setRuleData(null);
            setShowRuleForm(true);
            setSelectedIndex(-1);
        }
        const onEditData = (i: number) => {
            setSelectedIndex(i);
            var existingRule = discretionaryProp[i];
            console.log('existingRule', existingRule);
            //Make a copy
            var copyItem = JSON.parse(JSON.stringify(existingRule))
            setRuleData(copyItem);
            setShowRuleForm(true);

        }

        const onRemoveData = (i: number) => {
            var rules = discretionaryProp.slice(0);
            rules.splice(i, 1);
            console.log('onRemoveData', rules);
            setDiscretionaryProp(rules);
        }

        const onPreviewData = (i: number) => {
            var param = {
                fileID: fileData.fileID,
                rules: discretionaryProp[i].rulesObj,
                pageSize: previewIndex.pageSize,
                index: 0
            }

            console.log('previewData', param);

            authenticatedFetch(
                "/loanimport/GetPreviewData", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('GetPreviewData', res);
                    var data = res.data;
                    setPreviewData(data);
                    setPreview(true);
                    setPreviewIndex((s: any) => { return { ...s, index: param.index, total: res.total, criteriaIndex: i } });
                })
                .catch(error => {
                    toast.current?.show({
                        severity: 'error', summary: 'Error',
                        detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 10000
                    })
                });
        }

        const onPreviewPage = (event: DataTablePageEvent) => {
            var param = {
                fileID: fileData.fileID,
                rules: discretionaryProp[previewIndex.criteriaIndex].rulesObj,
                pagingOnly: true,
                index: event.first + 1,
                pageSize: event.rows
            }

            console.log('previewData', param);

            authenticatedFetch(
                "/loanimport/GetPreviewData", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('GetPreviewData', res);
                    var data = res;
                    setPreviewData(data);
                    setPreview(true);
                    setPreviewIndex((s: any) => { return { ...s, index: param.index, pageSize: param.pageSize } });
                })
                .catch(error => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 10000 })
                });
        };


        return (
            <div>
                <Button label="Add a Discretionary Criteria" onClick={() => onAddData()}></Button>
                <RulesForm showRuleForm={showRuleForm} ruleData={ruleData} onSubmit={(e: any) => onUpdateRule(e)} onCancel={onCancelRule} />

                <div >
                    {
                        discretionaryProp.map((item: any, i: number) => {
                            return (
                                <div className="flex align-items-center gap-3 mt-3 text-lg" key={i}>

                                    <div className='flex'>{i + 1}.</div><div dangerouslySetInnerHTML={{ __html: item.rulesEng }}></div>
                                    <div onClick={() => onPreviewData(i)} className="flex linkButton mt-2">Preview</div>
                                    <div onClick={() => onEditData(i)} className="flex linkButton mt-2">Edit</div>
                                    <div onClick={() => onRemoveData(i)} className="flex linkButton mt-2">Remove</div>
                                </div>
                            )

                        })
                    }
                </div>
                {preview ?
                    <DataTable className="mt-3" header={"Previewing Discretion Criteria #" + (previewIndex.criteriaIndex + 1)} dataKey="RowId" size="small" lazy stripedRows resizableColumns columnResizeMode="expand"
                        showGridlines selectionAutoFocus={false}
                        value={previewData}
                        scrollable rows={previewIndex.pageSize} scrollHeight='42vh'
                        paginator totalRecords={previewIndex.total}
                        first={previewIndex.index} onPage={onPreviewPage}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[100, 200, 500, 1000]}
                    >
                        {/*  selection={selectedItems}  onSelectionChange={onSelect} selectionMode=""checkbox*/}

                        {fileData.fileCols.map((col: any, i) => (
                            <Column key={col.Field} field={col.Field} header={col.Header} />
                        ))}
                    </DataTable>
                    : ""
                }


            </div>
        )
    }

    const RandomSampleForm = () => {
        return (

            <div className="col-12">

                <div className="flex flex-column gap-2">
                    <label className="text-lg font-bold">Choose the method to select the loans to audit</label>
                    <div className="flex align-items-center">
                        <RadioButton inputId="LS_All" name="loanSelection" value="All" checked={loanSelection.Selection === "All"} onChange={(e) => setLoanSelection(s => { return { ...s, Selection: e.value } })} />
                        <label htmlFor="LS_All" className="ml-2">Use all the loans in your file</label>
                    </div>
                    {loanSelection.Selection === "All" ?
                        <div className="flex flex-column gap-2 mt-2 ml-4">
                            <Card>
                                <label className="block">Total loans to process:
                                    <span className="text-xl ml-2">{fileData.totalValid}</span>
                                </label>
                            </Card>
                        </div>
                        : ""}
                    <div className="flex align-items-center">
                        <RadioButton inputId="LS_Percentage" name="loanSelection" value="Percentage" checked={loanSelection.Selection === "Percentage"} onChange={(e) => setLoanSelection(s => { return { ...s, Selection: e.value } })} />
                        <label htmlFor="LS_Percentage" className="ml-2">As a percentage of the loans in your file</label>
                    </div>
                    {loanSelection.Selection === "Percentage" ?
                        <div className="flex flex-column gap-2 mt-2 ml-4">
                            <Card>


                                <div className="grid">
                                    <div className="grid col-6">
                                        {/* <div className='col' style={{ paddingTop: "24px" }}>
                                            <Slider value={loanSelection.Percentage} onChange={(e) => { setLoanSelection(s => { return { ...s, Percentage: Number(e.value) } }); }} />
                                        </div> */}
                                        <div className="col-fixed">
                                            <InputNumber className="statInput" value={loanSelection.Percentage} onValueChange={(e) => { setLoanSelection(s => { return { ...s, Percentage: Number(e.value) } }); }} suffix="%" minFractionDigits={0} maxFractionDigits={1} min={0} max={100} />
                                        </div>
                                    </div>
                                    <div className="col-6" style={{ paddingTop: "11px" }}>
                                        <label className="block">
                                            <span className="text-xl mr-2" >{Math.ceil((fileData.totalValid) * loanSelection.Percentage / 100)}</span>
                                            loan(s) to process
                                        </label>
                                    </div>
                                </div>

                            </Card>
                        </div>
                        : ""}

                    {(fileData.totalValid) < 350 ? "" :
                        <>
                            <div className="flex align-items-center">
                                <RadioButton inputId="LS_Sampling" name="loanSelection" value="Sampling" checked={loanSelection.Selection === "Sampling"} onChange={(e) => setLoanSelection(s => { return { ...s, Selection: e.value } })} />
                                <label htmlFor="LS_Sampling" className="ml-2">Use a sample size methodology</label>

                            </div>
                            {loanSelection.Selection === "Sampling" ?
                                <div className="flex flex-column gap-2 mt-2 ml-4"><StatisticsForm loanSelection={loanSelection} /> </div>

                                : ""}

                            <label className="text-lg mt-3 font-bold">Choose stratification field</label>
                            <Dropdown className="col-5 m-2 p-dropdown-padding" value={loanSelection.Stratification} options={dataColumns} onChange={(e) => setLoanSelection(s => { return { ...s, Stratification: e.value } })} optionLabel="Header" optionValue="Field"></Dropdown>

                            <label className="text-lg mt-3  font-bold">Specify percentage for sample under 350 loans</label>
                            <div className="col-5">
                                <div className="grid">
                                    {/* <div className='col' style={{ paddingTop: "24px" }}>
                                        <Slider value={loanSelection.DefaultPercentage} onChange={(e) => { setLoanSelection(s => { return { ...s, DefaultPercentage: Number(e.value) } }); }} />
                                    </div> */}
                                    <div className="col-fixed">
                                        <InputNumber className="statInput" value={loanSelection.DefaultPercentage} onValueChange={(e) => { setLoanSelection(s => { return { ...s, DefaultPercentage: Number(e.value) } }); }} suffix="%" minFractionDigits={0} maxFractionDigits={1} min={0} max={100} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

            </div>
        )
    }

    const TargetedForm = () => {
        const [showRuleForm, setShowRuleForm] = useState<boolean>(false);
        const [previewData, setPreviewData] = useState<any[]>([]);
        const [selectedIndex, setSelectedIndex] = useState<number>();
        const [preview, setPreview] = useState<boolean>(false);
        const [previewIndex, setPreviewIndex] = useState<any>(
            {
                criteriaIndex: 0,
                rowIndex: 0,
                pageSize: 100,
                total: 0
            }
        );
        const showRuleUI = () => {
            setShowRuleForm(true);
        }

        const [ruleData, setRuleData] = useState<any>(null);


        const onCancelRule = () => {
            setShowRuleForm(false);
        }

        const checkRulesErrors = (ruleInfo: any) => {
            var hasErrors = false;
            console.log("Rule Info?", ruleInfo)
            // console.log("AHHH HERE", checklistRules)
            for (let i = 0; i < ruleInfo.length; i++) {
                // console.log("Checklist rules - i", i, checklistRules[i])
                // console.log("i", i)
                for (let j = 0; j < ruleInfo[i].ruleItems.length; j++) {
                    // console.log("i", i, "j", j)
                    // console.log(checklistRules[i].ruleItems[j].field.fieldName)
                    if (ruleInfo[i].ruleItems[j].field !== (undefined || null)) {
                        // console.log("Set it false??")
                        if ((ruleInfo[i].ruleItems[j].operator === (undefined || "=" || "" || null)) || (ruleInfo[i].ruleItems[j].value === (undefined || "" || null))) {
                            // rulesError[(i + "-" + j)] = false;
                            // handleRuleErrors(i + "-" + j, true)
                            hasErrors = true;
                            // console.log("Set it false??")
                        }
                    } else if (ruleInfo[i].ruleItems[j].field === (undefined || null)) {
                        if ((ruleInfo[i].ruleItems[j].operator !== (undefined || "=" || "" || null)) || (ruleInfo[i].ruleItems[j].value !== (undefined || "" || null))) {
                            // rulesError[(i + "-" + j)] = false;
                            // handleRuleErrors(i + "-" + j, true)
                            hasErrors = true;
                            // console.log("Set it false?? again??")
                        }
                    }
                    if ((ruleInfo[i].ruleItems[j].field !== (undefined || null)) && (ruleInfo[i].ruleItems[j].operator !== (undefined || "=" || "" || null)) && (ruleInfo[i].ruleItems[j].value !== (undefined || "" || null))) {
                        // rulesError[(i + "-" + j)] = true;
                        // handleRuleErrors(i + "-" + j, false)
                        // console.log("Set it true!!")
                    }
                    // if (!((i + "-" + j) in rulesError)) {
                    //     handleRuleErrors(i + "-" + j, false)
                    // }
                }
            }

            return hasErrors
        }

        const onUpdateRule = (data: any) => {
            console.log("E", data)
            console.log('onUpdateRule', data);
            console.log('updateIndex', selectedIndex);
            // for (item in rulesError) {

            // }
            var rulesHasError = checkRulesErrors(data.rulesObj)
            console.log("rulesHasError", rulesHasError);
            console.log(data)
            if (rulesHasError == true) {
                console.log("I BAILED!")
                toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'One or more criteria have error(s). Please fix all errors before proceeding.', life: 10000 });
                return;
            }
            var copy = targetedProp.slice(0);
            if (selectedIndex != undefined && selectedIndex > -1) {
                //Update
                console.log('update');
                copy[selectedIndex] = data;
                setTargetedProp(copy);
            }
            else {
                console.log('add');
                copy.push(data);
                setTargetedProp(copy);

            }
            setShowRuleForm(false);
        }

        const onAddData = () => {
            setRuleData(null);
            setShowRuleForm(true);
            setSelectedIndex(-1);
        }
        const onEditData = (i: number) => {
            setSelectedIndex(i);
            var existingRule = targetedProp[i];
            console.log('existingRule', existingRule);
            //Make a copy
            var copyItem = JSON.parse(JSON.stringify(existingRule))
            setRuleData(copyItem);
            setShowRuleForm(true);

        }
        const onRemoveData = (i: number) => {
            var rules = targetedProp.slice(0);
            rules.splice(i, 1);
            console.log('onRemoveData', rules);
            setTargetedProp(rules);
        }

        const onPreviewData = (i: number) => {
            var param = {
                fileID: fileData.fileID,
                rules: targetedProp[i].rulesObj,
                pageSize: previewIndex.pageSize,
                index: 0
            }

            console.log('previewData', param);

            authenticatedFetch(
                "/loanimport/GetPreviewData", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('GetPreviewData', res);
                    var data = res.data;
                    setPreviewData(data);
                    setPreview(true);
                    setPreviewIndex((s: any) => { return { ...s, index: param.index, total: res.total, criteriaIndex: i } });
                })
                .catch(error => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 10000 })
                });
        }

        const onPreviewPage = (event: DataTablePageEvent) => {
            var param = {
                fileID: fileData.fileID,
                rules: targetedProp[previewIndex.criteriaIndex].rulesObj,
                pagingOnly: true,
                index: event.first + 1,
                pageSize: event.rows
            }

            console.log('previewData', param);

            authenticatedFetch(
                "/loanimport/GetPreviewData", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('GetPreviewData', res);
                    var data = res;
                    setPreviewData(data);
                    setPreview(true);
                    setPreviewIndex((s: any) => { return { ...s, index: param.index, pageSize: param.pageSize } });
                })
                .catch(error => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 10000 })
                });
        };


        return (
            <div>
                <Button label="Add a Targeted Criteria" onClick={() => onAddData()}></Button>
                <RulesForm showRuleForm={showRuleForm} ruleData={ruleData} onSubmit={(e: any) => onUpdateRule(e)} onCancel={onCancelRule} />

                <div >
                    {
                        targetedProp.map((item: any, i: number) => {
                            return (
                                <div className="flex align-items-center gap-3 mt-3 text-lg" key={i}>

                                    <div className='flex'>{i + 1}.</div><div dangerouslySetInnerHTML={{ __html: item.rulesEng }}></div>
                                    <div onClick={() => onPreviewData(i)} className="flex linkButton mt-2">Preview</div>
                                    <div onClick={() => onEditData(i)} className="flex linkButton mt-2">Edit</div>
                                    <div onClick={() => onRemoveData(i)} className="flex linkButton mt-2">Remove</div>
                                </div>
                            )

                        })
                    }
                </div>
                {preview ?
                    <DataTable className="mt-3" header={"Previewing Targeted Criteria #" + (previewIndex.criteriaIndex + 1)} dataKey="RowId" size="small" lazy stripedRows resizableColumns columnResizeMode="expand"
                        showGridlines selectionAutoFocus={false}
                        value={previewData}
                        scrollable rows={previewIndex.pageSize} scrollHeight='42vh'
                        paginator totalRecords={previewIndex.total}
                        first={previewIndex.index} onPage={onPreviewPage}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[100, 200, 500, 1000]}
                    >
                        {/*  selection={selectedItems}  onSelectionChange={onSelect} selectionMode=""checkbox*/}

                        {fileData.fileCols.map((col: any, i) => (
                            <Column key={col.Field} field={col.Field} header={col.Header} />
                        ))}
                    </DataTable>
                    : ""
                }


            </div>
        )
    }
    const Step_LoanSelection_Postclose = () => {


        let errorMessage = fileData.totalRows < 1 ?
            "In order to create a sample size, you must first upload a file in the previous step. This allows us to gather the proper population size." : "";
        if (fileData.totalRows < 1) {
            //Not necessary here.
            //toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error uploading selected file.', life: 3000 });
        }

        return (
            <div>
                <div className="grid nested-grid p-3">
                    <div className='col-8'>
                        <Panel header="Loan Selection" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-2', o)} style={{ height: "fit-content" }} >
                            {errorMessage != "" ? <div className='col-12'>
                                <span className="text-lg text-red-500">{errorMessage}</span>
                            </div> : ""
                            }
                            <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                                <TabPanel header="Random Sampling">
                                    <RandomSampleForm />
                                </TabPanel>
                                <TabPanel header="Discretionary" >
                                    <DiscretionaryForm />
                                </TabPanel>
                                <TabPanel header="Targeted" >
                                    <TargetedForm />
                                </TabPanel>
                            </TabView>
                            <div className='col-12'>
                                <Toolbar start={navPrevious} end={navNext} />

                            </div>
                        </Panel>

                    </div>
                    <div className='col-4'>
                        <Panel headerTemplate={helpHeaderTemplate} style={{ height: "100%" }}>
                            <div style={{ height: "100%" }}>
                                <p>
                                    <span className='text-primary font-bold'>Confidence Level</span> - This indicates how confident do you want to be that your sample represents the population.
                                </p>
                                <p>
                                    <span className='text-primary font-bold'>Expected Defect Rate</span> - If you do not know the estimated percent defects, then assume the worse case (50%)
                                </p>
                                <p>
                                    <span className='text-primary font-bold'>Confidence Interval</span> - Given the confidence level, what range do you want the population to fall within +/-? (e.g. +/- 2%)
                                </p>
                            </div>
                        </Panel>

                    </div>
                </div>
            </div>


        );
    }

    const Step_LoanSelection = () => {

        let errorMessage = fileData.totalRows < 1 ?
            "In order to create a sample size, you must first upload a file in the previous step. This allows us to gather the proper population size." : "";


        return (
            <div>

                <div className="grid nested-grid p-3">

                    <div className='col-8'>
                        <Panel header="Loan Selection" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-2', o)} style={{ height: "fit-content" }}>
                            {errorMessage != "" ? <div className='col-12'>
                                <span className="text-lg text-red-500">{errorMessage}</span>
                            </div> : ""
                            }

                            <RandomSampleForm />

                            <div className='col-12'>
                                <Toolbar start={navPrevious} end={navNext} />

                            </div>
                        </Panel>
                    </div>
                    <div className='col-4'>
                        <Panel headerTemplate={helpHeaderTemplate} style={{ height: "100%" }}>
                            <div style={{ height: "100%" }}>
                                <p>
                                    <span className='text-primary font-bold'>Confidence Level</span> - This indicates how confident do you want to be that your sample represents the population.
                                </p>
                                <p>
                                    <span className='text-primary font-bold'>Expected Defect Rate</span> - If you do not know the estimated percent defects, then assume the worse case (50%)
                                </p>
                                <p>
                                    <span className='text-primary font-bold'>Confidence Interval</span> - Given the confidence level, what range do you want the population to fall within +/-? (e.g. +/- 2%)
                                </p>
                            </div>
                        </Panel>

                    </div>

                </div>


            </div>


        );
    }

    function Step_AuditType() {
        return (
            <div>

                <div className="grid nested-grid p-3">


                    <div className='col-8'>
                        <Panel header="Enter Submission Details" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-2', o)} style={{ height: "fit-content" }}>
                            <p></p>
                            <label className="block mb-2 text-xl">Which type of audit process are you checking?</label>
                            <div className="flex flex-column gap-1 mb-5">
                                <div className="flex align-items-center">
                                    <RadioButton inputId="AuditType_prefund" name="AuditType" value="Prefund" onChange={(e) => setSubmissionData(s => { return { ...s, AuditType: e.value } })} checked={submissionData.AuditType === 'Prefund'} />
                                    <label htmlFor="AuditType_prefund" className="ml-2">Prefund Audit</label>
                                </div>
                                <div className="flex align-items-center">
                                    <RadioButton inputId="AuditType_Postclose" name="AuditType" value="Postclose" onChange={(e) => setSubmissionData(s => { return { ...s, AuditType: e.value } })} checked={submissionData.AuditType === 'Postclose'} />
                                    <label htmlFor="AuditType_Postclose" className="ml-2">Post Close Audit</label>
                                </div>
                                <div className="flex align-items-center">
                                    <RadioButton inputId="AuditType_Servicing" name="AuditType" value="Servicing" onChange={(e) => setSubmissionData(s => { return { ...s, AuditType: e.value } })} checked={submissionData.AuditType === 'Servicing'} />
                                    <label htmlFor="AuditType_Servicing" className="ml-2">Servicing Audit</label>
                                </div>

                            </div>

                            <Toolbar start={navPrevious} end={navNext} />
                        </Panel>
                    </div>
                    <div className='col-4'>

                        <Panel headerTemplate={helpHeaderTemplate} style={{ height: "100%" }}>

                            <p className="text-xl">
                                What is an Audit Process Type?
                            </p>
                            <p>
                                The following describes the type of audit which this system can QC. <br />
                                <p><span className='text-primary font-bold'>Prefund Audit</span> - An audit type often conducted in real-time, that is performed prior to the funds being distributed to the loan applicant. These are often audited at a 100% audit rate, meaning that every Prefund audit batch should have all the loans being selected for audit.</p>
                                <p><span className='text-primary font-bold'>Post Close Audit</span> - An audit type that is being conducted on a loan that has already been closed. These are often selected as a simple random sample from the overall total import population, meaning that only a subset of the post-close audit batch would result in loan audits being generated.</p>
                                <p><span className='text-primary font-bold'>Servicing Audit</span> - An audit review of servicing activities such as loan administration, default management, foreclosures, or loan modification by a Mortgage Servicer to ensure that the loan servicing meets the servicer’s internal policies and procedures including investor, state, and federal requirements.</p>
                            </p>
                            <p className="text-xl">
                                What kind of loan data do I need?
                            </p>
                            <p>
                                Each Audit Type has a different set of requirements for what the data file needs to provide.
                                If you do not know what the predefined CSV field structures are, you can download it below.

                            </p>
                            <p>
                                <a style={{ display: 'block' }} download href="assets/upload-csv/Prefund-Loan-CSV-Template.csv"><Button label="Download Prefund Audit Template File" className="mr-2 w-full" /></a>
                            </p>
                            <p>
                                <a style={{ display: 'block' }} download href="assets/upload-csv/Post-Close-Loan-CSV-Template.csv"><Button label="Download Post Close Audit Template File" className="mr-2 w-full" /></a>
                            </p>
                            <p>
                                <a style={{ display: 'block' }} download href="assets/upload-csv/Servicing-Loan-CSV-Template.csv"><Button label="Download Servicing Audit Template File" className="mr-2 w-full" /></a>
                            </p>
                        </Panel>


                    </div>


                </div>
            </div>
        )
    }

    //Once submitted, make sure we clear all the state so they can't submit again.
    function clearSession() {
        setfileTable([]);
        setFileData(s => { return { ...s, loading: true, index: 0, totalRows: 0, totalValid: 0, totalErrors: 0, totalExists: 0, showErrorsOnly: false, displayOnly: false } })
        setIgnoredItems([]);
        setSampleSize(0);
        setDiscretionaryProp([]);
        setTargetedProp([]);

    }
    function Step_Review() {

        let errorMessage = fileData.totalRows < 1 ?
            "There is no data to import. Make sure you have uploaded a file that contains valid data to import" : "";


        const accepted = (e: any) => {
            toast.current?.show({ severity: 'info', summary: 'Confirmed', detail: 'Proceeding with upload. Please wait for the upload to finish.', life: 3000 });
            submitForm(e);
        };
        const reject = () => {
            toast.current?.show({ severity: 'warn', summary: 'Rejected', detail: 'Upload canceled.', life: 3000 });
        };

        const confirmSubmit = (e: any) => {
            const event = e;
            confirmDialog({
                message: 'Please confirm all information is correct. Once this file is uploaded, the sampling size, discretionary criteria, and targeted criteria cannot be changed. Only certain details can be edited on the loans selected for audit. Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                defaultFocus: 'accept',
                accept: () => accepted(e),
                reject
            });
        }
        const submitForm = (e: any) => {


            setSubmitting(true);

            if (e.target.disabled) {
                //console.log("Already disabled");
                console.log('submitForm returning')
                return;
            }

            setTimeout(() => {
                //Build Parameters
                console.log('submitting form');
                loanSelection.SampleSize = sampleSize;

                var param = {
                    LoanSelection: loanSelection,
                    AuditType: submissionData.AuditType,
                    IgnoredItems: ignoredItems,
                    FileID: fileData.fileID,
                    FileName: fileData.fileName,
                    DiscretionaryProp: discretionaryProp,
                    TargetedProp: targetedProp
                }

                authenticatedFetch(
                    "/loanimport/Submit", {
                    method: "POST",
                    body: JSON.stringify(param),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then(res => {
                        console.log(res);
                        console.log(res);
                        setSubmitResult(res);

                        clearSession();

                        setActiveIndex(5);
                        e.target.disabled = false;
                        setSubmitting(false);
                    })
                    .catch(error => {
                        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 10000 })
                    });


            }, 10);

        };

        const navSubmit = (
            <React.Fragment>
                {submitting ? <div className="mr-2 loading"></div> : ""} <Button disabled={submitting || errorMessage != "" ? true : false} label="Submit" onClick={(e) => confirmSubmit(e)} className="mr-2" />
            </React.Fragment>
        );


        return (
            <div>
                <p></p>
                <Panel header="Review and Submit" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-2', o)} style={{ height: "fit-content" }}>
                    <p></p>
                    {errorMessage != "" ? <div className='col-12'>
                        <span className="text-lg text-red-500">{errorMessage}</span>
                    </div> : ""
                    }
                    <p className="m-0">
                        Here is what we have so far.
                    </p>
                    <p className='m-0 text-sm'>
                        Warning: Duplicate files cannot be uploaded. Please take the time to confirm all your selections here are correct. These selections cannot be changed or undone at a later time.
                    </p>
                    <div className="grid">
                        <div className="col">
                            <Fieldset legend="Loan Selection">
                                <table>
                                    <tr><td>Total Valid Loans to Import</td><td>{fileData.totalValid}</td></tr>
                                    <tr><td>Loan Selection</td>
                                        {loanSelection.Selection == "All" ?
                                            <td>All loans</td>
                                            : loanSelection.Selection == "Percentage" ?
                                                <td>{loanSelection.Percentage}% of loans</td>
                                                : loanSelection.Selection == "Sampling" ?
                                                    <td>Random Sampling</td>
                                                    : ""
                                        }
                                    </tr>
                                    {loanSelection.Selection == "Sampling" ?
                                        <>
                                            <tr><td>Population Size</td><td>{loanSelection.PopulationSize}</td></tr>
                                            <tr><td>Defect Rate</td><td>{loanSelection.PercentDefect}%</td></tr>
                                            <tr><td>Standard Deviation</td><td>{loanSelection.ConfidenceInterval}%</td></tr>
                                            <tr><td>Confidence Level</td><td>{loanSelection.ConfidenceLevel}%</td></tr>
                                            <tr><td>Frequency</td><td>{loanSelection.Frequency}</td></tr>
                                            <tr><td>Calculated Sample Size</td><td>
                                                {
                                                    loanSelection.Frequency == 'Monthly' ? Math.round(sampleSize / 12) :
                                                        loanSelection.Frequency == 'Quarterly' ? Math.round(sampleSize / 4) :
                                                            loanSelection.Frequency == 'Semi-Annual' ? Math.round(sampleSize / 2) :
                                                                sampleSize
                                                }</td></tr>

                                        </>
                                        : ""}
                                    {loanSelection.PopulationSize > 345 ?
                                        <>
                                            <tr><td>Stratification Field</td><td>{loanSelection.Stratification}</td></tr>
                                            <tr><td>Default &lt; 350 loans selection</td><td>{loanSelection.DefaultPercentage}% of selection</td></tr>
                                        </>
                                        : ""
                                    }
                                </table>
                            </Fieldset>
                        </div>
                        <div className="col">
                            <Fieldset legend="Process Detail" >
                                <table>
                                    <tr><td>Plan Type</td><td>{submissionData.AuditType}</td></tr>
                                    {submissionData.AuditType == "Postclose"
                                        ?
                                        <>
                                            <tr><td style={{ verticalAlign: "top" }}>Discretionary Loans Selection</td>
                                                {discretionaryProp.length > 0 ?
                                                    <td>
                                                        <ol style={{ marginBlockStart: "0", paddingInlineStart: "1rem" }}>
                                                            {discretionaryProp.map((item: any) => {
                                                                return (
                                                                    <li>
                                                                        <div dangerouslySetInnerHTML={{ __html: item.rulesEng }}></div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ol>
                                                    </td>
                                                    : <td>None</td>
                                                }
                                            </tr>
                                            <tr><td style={{ verticalAlign: "top" }}>Targeted Loans Selection</td>
                                                {targetedProp.length > 0 ?
                                                    <td>
                                                        <ol style={{ marginBlockStart: "0", paddingInlineStart: "1rem" }}>
                                                            {targetedProp.map((item: any) => {
                                                                return (
                                                                    <li>
                                                                        <div dangerouslySetInnerHTML={{ __html: item.rulesEng }}></div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ol>
                                                    </td>
                                                    : <td>None</td>
                                                }
                                            </tr>
                                        </>
                                        : ""

                                    }
                                </table>
                            </Fieldset>
                        </div>

                    </div>

                </Panel>
                <Toolbar start={navPrevious} end={navSubmit} />
            </div>
        )
    }

    const navigate = useNavigate();

    function Step_Finish() {

        const uploadAnotherFile = () => {
            //Clear everything
            clearSession();
            setSubmitResult(null);
            setSubmissionData(s => { return { ...s, AuditType: "" } });
            setActiveIndex(1);
        }

        const navigateQCPage = () => {
            let page = "";
            switch (submissionData.AuditType) {
                case "Prefund":
                    page = '/prefund-qc-audits'
                    break;
                case "Postclose":
                    page = '/post-close-qc-audits'
                    break;
                case "Servicing":
                    page = "/service-audits"
                    break;

            }
            console.log('navpage', page);
            navigate(page);
        }

        function groupBy(list: any, keyGetter: any) {
            const map = new Map();
            list.forEach((item: any) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        }
        const LoanSelectionReport = () => {
            var groups = groupBy(submitResult.LoanReport, (g: any) => g.section);
            var sections = Array.from(groups.entries());
            console.log('loanSelectionReport', sections);
            return (
                <Panel className="mt-3 mb-2" header="Loan Selection Report">
                    <ScrollPanel style={{ width: '100%', maxHeight: '56vh' }}>
                        <table className="loan-report-table">
                            <tr><td>Selection Breakdown</td><td style={{ textAlign: "center" }} colSpan={2}>Total # of Loan(s)</td></tr>
                            <tr><td></td><td style={{ textAlign: "center" }}>In Selection Group</td><td style={{ textAlign: "center" }}>Selected for auditing</td></tr>
                            {sections.map((item: any) => {
                                return (
                                    <>
                                        <tr><td colSpan={3}>{item[0]}</td></tr>
                                        {item[1].map((subItem: any) => {
                                            return (
                                                <tr><td className="pl-3"><div dangerouslySetInnerHTML={{ __html: subItem.subSection }}></div></td><td style={{ textAlign: "center" }}>{subItem.totalMatchedLoans}</td><td style={{ textAlign: "center" }}>{subItem.totalSelectedLoans}</td></tr>
                                            )
                                        })
                                        }
                                    </>
                                )
                            })}
                        </table>
                    </ScrollPanel>
                </Panel>
            )
        }
        let success_view = (
            <div className="banner">
                <div className='grid panel-width' >
                    <div className='col'>
                        <span className="text-2xl font-bold">Success! </span>
                        <p className='text-lg'>
                            Your data has been imported, and it's ready for review. An notification email has been sent that confirms your data submission.
                        </p>

                        <Button label={"Go to " + submissionData.AuditType + " QC Reviews Page"}
                            onClick={navigateQCPage} className="mr-2" />
                        <span className="text-lg" style={{ lineHeight: "35px", marginRight: "6px" }}>Or</span>
                        <Button label="Upload another file" onClick={() => uploadAnotherFile()} className="mr-2" />

                        <LoanSelectionReport />

                    </div>
                    <div className='col-fixed' style={{ width: "500px" }}>
                        <img src='assets/images/file_upload_success.jpg' style={{ width: "500px" }}></img>
                    </div>
                </div>
            </div>
        )

        let fail_view = (
            <div>Looks like we ran into an error</div>
        )

        return (<div>
            {submitResult?.Status === "OK" ? success_view : fail_view}
        </div>)
    }
    const ShowStep = () => {
        switch (activeIndex) {
            case 0:
                return Step_Overview();
                break;
            case 1:
                return Step_AuditType();
                break;
            case 2:
                return Step_UploadFile();
                break;
            case 3:
                //Check to see which Audit Type
                if (submissionData.AuditType == "Postclose")
                    return Step_LoanSelection_Postclose();
                else
                    return Step_LoanSelection();
                break;

            case 4:
                return Step_Review();
                break;
            case 5:
                return Step_Finish();
                break;
            default:
                break;
        }
        return (<div></div>)
    }

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let { hasRoles, isLoading } = useHasRole(['Account Administrator', 'System Administrator', 'Account Manager', 'QC Admin', 'QC Manager'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div>
            <Nav />
            <div>
                <Toast ref={toast} position="bottom-center" />
                <ConfirmDialog />
                <div style={{ backgroundColor: "var(--ca-color-5-faded)" }}>
                    <div style={{ maxWidth: "1280px", margin: "auto" }}>
                        <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
                    </div>
                </div>
                <div>
                    <div className="panel-width"><ShowStep /></div>
                </div>
            </div>
        </div>
    )
}
