import React, { useState, useRef, useEffect } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import "./Worklist.css";
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { usePrefundAudits } from "../../state/sampleAuditsExceptionsState";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from 'axios';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import { authenticatedFetch } from '../../state/apiUtils';
import useHasRole from '../../hooks/validRolesHook';
import Loading from '../Global/loading';
import NotFound from '../Global/404NotFound';

const headerDropdownOptions = [
    { label: "All", value: "all" },
    { label: "Current Month Prefund Reviews", value: "currentMonthPrefundReviews" },
    { label: "Open Prefunds", value: "openPrefunds" },
    { label: "Prefunds Cleared-to-Close", value: "prefundsClearedToClose" },
    { label: "Previous Months Prefund Reviews", value: "previousMonthsPrefundReviews" },
    { label: "Recently Viewed", value: "recentlyViewed" },
]

export default function Worklist() {
    const toast = useRef<Toast>(null);
    const op = useRef<OverlayPanel>(null);
    const _opMsg = useRef("");
    const [_opMsgState, set_opMsgState] = useState(_opMsg.current);

    const navigate = useNavigate();
    const [auditPlanType, setAuditPlanType] = useState<string>();

    //Temporary for now since usePrefundAudits() is not working anymore
    //const {prefundStatus, prefundData, prefundError} = usePrefundAudits();
    const [auditData, setAuditData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedHeaderDropdownOption, setSelectedHeaderDropdownOption] = useState(headerDropdownOptions[0].value);

    const [tabSummary, setTabSummary] = useState<any>(
        {
            TotalPrefund: 0, TotalPostclose: 0, TotalServicing: 0, TotalFinding: 0
        }
    );


    const getTabSummary = () => {
        authenticatedFetch(
            "/Worklist/GetWorklistSummary")
            .then(res => {
                console.log('GetWorklistSummary', res);
                var data = res;
                setTabSummary(data);
            })
            .catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin. Error Message: ' + error.message, life: 10000 });
                console.log(error);
            });

    }

    //Temporary for now since usePrefundAudits() is not working anymore
    useEffect(() => {

        getTabSummary();

    }, []);


    const Findings = () => {
        const [findingData, setFindingData] = useState<any[]>([]);
        const [loading, setLoading] = useState(true);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);

        useEffect(() => {
            getFindingData();
        }, []);

        const auditFindingView = (item: any) => {
            return (<div className="linkButton"
                onClick={() => navigate(`/AuditForm/${item.LoanData.MonthlyFundedLoanId}?auditReviewId=${item.AuditReviewId}&auditFindingId=${item.AuditFindingId}`)}
            > {item.RecordName}
            </div>)
        };

        const getFindingData = () => {
            authenticatedFetch(
                "/WorkList/GetFindings")
                .then(res => {
                    console.log('Findings', res);
                    var data = res;
                    setFindingData(data);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin. Error Message: ' + error.message, life: 10000 });
                    console.log(error);
                });

        }


        return (
            <div>
                <DataTable value={findingData} paginator rows={20} dataKey="AuditFindingId" loading={loading} scrollHeight='66vh' emptyMessage="No Items Assigned To You"
                    selectionMode="single" resizableColumns
                    alwaysShowPaginator={false} >
                    <Column field="AuditPlanType" header="Audit Plan" sortable />
                    <Column field="RecordName" header="Name" sortable body={auditFindingView} />
                    <Column field="DateCreated" header="Created Date" sortable />
                    <Column field="DefectDetail" header="Detail" sortable />
                    <Column field="SeverityRating" header="Severity" sortable />
                    <Column field="DefectName" header="Defect Name" sortable />
                    <Column field="DefectCategory" header="Defect Category" sortable />
                    <Column field="DefectSubCategory" header="Defect Sub Category" sortable />
                    <Column field="QuestionText" header="Question" sortable />
                    <Column field="QuestionNumber" header="Question Number" sortable />
                </DataTable>
            </div>
        );

    }

    const AssignedAudits = (props: any) => {

        const [auditData, setAuditData] = useState<any[]>([]);
        const [loading, setLoading] = useState(true);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);
        const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
        const [showAllFindings, setShowAllFindings] = useState<boolean>(false);
        useEffect(() => {
            getAuditData();
        }, []);

        const getAuditData = () => {
            authenticatedFetch(
                `/WorkList/GetAssigned${props.AuditPlanType}Audits`)
                .then(res => {
                    console.log('UnassignedAudits', res);
                    var data = res;
                    setAuditData(data);
                    setLoading(false);

                    let _expandedRows: any = {};

                    // data.forEach((p:any) => {
                    //     if (p.Findings!.length > 0)
                    //         _expandedRows[`${p.AuditReviewId}`] = true
                    //     else
                    //     _expandedRows[`${p.AuditReviewId}`] = false
                    // });

                    setExpandedRows(_expandedRows);
                })
                .catch((error) => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin. Error Message: ' + error.message, life: 10000 });
                    console.log(error);
                });

        }


        const priorityTemplate = (item: any) => {
            return (<div>
                {item.Priority} {item.PriorityOrder == 0 ? <i className="pi pi-arrow-up" style={{ color: 'red' }} /> : ""}
            </div>)
        };

        const loanTemplate = (item: any) => {
            return (<div className="linkButton"
                onClick={() => navigate(`/AuditForm/${item.LoanData.MonthlyFundedLoanId}?auditReviewId=${item.AuditReviewId}`)}
            > {item.LoanData.LoanName}
            </div>)
        };

        const findingTemplate = (data: any) => {
            return (
                <div className="findingGroup" >
                    <DataTable value={data.Findings} size="small" className="findingTable" resizableColumns scrollable selectionMode="single" scrollHeight='66vh'>
                        <Column field="RecordName" header="Finding" sortable />
                        <Column field="DefectDetail" header="Detail" sortable />
                        <Column field="DefectCategory" header="Category" sortable />
                        <Column field="DefectSubCategory" header="Subcategory" sortable />
                        <Column field="DefectName" header="Defect Name" sortable />
                        <Column field="SeverityRating" header="Severity" sortable />
                        <Column field="CreatedBy" header="By" sortable />
                        <Column field="CreatedDate" header="Date" sortable />
                    </DataTable>
                </div>
            );
        };

        const allowExpansion = (data: any) => {
            return data.Findings!.length > 0;
        };

        const toggleFindings = (state: boolean) => {
            if (!state) {
                setExpandedRows({});
                //setShowAllFindings(!showAllFindings);
            }
            else {
                console.log('toggleFindings');
                let _expandedRows: any = {};

                auditData.forEach((p: any) => {
                    if (p.Findings!.length > 0)
                        _expandedRows[`${p.AuditReviewId}`] = true
                    //else
                    //_expandedRows[`${p.AuditReviewId}`] = false
                });


                setExpandedRows(_expandedRows);
                //setExpandedRows({});
                //setShowAllFindings(!showAllFindings);

            }
        }
        const header = (
            <div className="flex flex-wrap justify-content-start gap-3">
                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => toggleFindings(true)} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => toggleFindings(false)} />

            </div>
        );

        return (
            <div>
                <DataTable header={header} value={auditData} sortField="PriorityOrder" sortIcon={null} sortOrder={1} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='66vh' emptyMessage="No Items Assigned To You"
                    selectionMode="single" rowExpansionTemplate={findingTemplate} expandedRows={expandedRows}
                    alwaysShowPaginator={false} onRowToggle={(e) => setExpandedRows(e.data)} >
                    <Column header="See Findings" expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field="LoanData.LoanName" header="Loan Name" sortable body={loanTemplate} />
                    <Column field="OpenDate" header="Date Added" sortable />
                    <Column field="QC_StartDate" header="Date Started" sortable />
                    <Column field="Priority" header="Priority" sortable body={priorityTemplate} />
                    {props.AuditPlanType == "Prefund" ?
                        <Column field="LoanData.FundingDate" header="Funding Date" sortable />
                        :
                        <Column field="LoanData.ClosingDate" header="Closing Date" sortable />
                    }

                    <Column field="LoanData.LoanAmount" header="Loan Amount" sortable />
                    <Column field="LoanData.LoanType" header="Loan Type" sortable />
                    <Column field="LoanData.Investor" header="Investor" sortable />
                </DataTable>
            </div>
        );


    }

    const tabHeader = (options: any, text: string, items: number, itemsHigh: number) => {
        return (
            <div onClick={options.onClick} className="flex flex-wrap justify-content-start gap-1 p-tabview-nav-link tabItems ">
                <span >{text}</span> {items > 0 ? <Badge value={items}></Badge> : ""} {itemsHigh > 0 ? <Badge value={itemsHigh} severity="danger"></Badge> : ""}
            </div>
        )
    }

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let {hasRoles, isLoading} = useHasRole(['System Administrator', 'Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager', 'QC Auditor'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div>
            <Nav />

            <Body>
                <div>
                    <OverlayPanel ref={op}>
                        <div>{_opMsg.current}</div>
                    </OverlayPanel>
                    <Toast ref={toast} position="bottom-center" />

                    <h1>{auditPlanType} Audits</h1>
                    <TabView>
                        <TabPanel headerTemplate={(o) => tabHeader(o, "Prefund Audits", tabSummary.TotalPrefund, tabSummary.TotalPrefund2)}>
                            <AssignedAudits AuditPlanType="Prefund" />
                        </TabPanel>
                        <TabPanel headerTemplate={(o) => tabHeader(o, "Post Close Audits", tabSummary.TotalPostclose, tabSummary.TotalPostclose2)}>
                            <AssignedAudits AuditPlanType="Postclose" />

                        </TabPanel>
                        <TabPanel headerTemplate={(o) => tabHeader(o, "Servicing Audits", tabSummary.TotalServicing, tabSummary.TotalServicing2)} >
                            <AssignedAudits AuditPlanType="Servicing" />

                        </TabPanel>
                        <TabPanel headerTemplate={(o) => tabHeader(o, "Audits with Findings", tabSummary.TotalFinding, tabSummary.TotalFinding2)} >
                            <Findings />
                        </TabPanel>
                    </TabView>
                </div>
            </Body>
        </div>
    )
}
