import React, { useEffect, useState, useRef } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import './UserForm.css';
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useCreateUser, useUpdateUser, useUser } from "../../state/userState";
import { useRoles } from "../../state/rolesState";
import { useAccounts } from "../../state/accountState";
import { Toast } from 'primereact/toast';
import { InputMask } from "primereact/inputmask";
import { OverlayPanel } from 'primereact/overlaypanel';
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";
import { useClientBanks } from "../../state/clientbankState";

export default function UserForm() {
    const navigate = useNavigate();
    const { id } = useParams();
    const isEdit = id != undefined;
    // const [prefundAccess, setPrefundAccess] = useState(false);
    // const [postCloseAccess, setPostCloseAccess] = useState(false);
    // const [servicingAccess, setServicingAccess] = useState(false);
    const { userData } = useUser(id);
    const { rolesData } = useRoles();
    const { accountsData } = useAccounts();
    const { clientBanksData } = useClientBanks();
    const [user, setUser] = useState(userData);

    const filteredRoles = rolesData.filter((role) => role.RoleName !== "Account Administrator" && role.RoleName !== "Account Manager")

    // Set up isvalid for validation checking
    const [isValid, setIsValid] = useState<any>({
        AzureObjectId: true,
        FirstName: true,
        LastName: true,
        Email: true,
        Phone: true,
        Account: true,
        Role: true,
        RecordStatus: true
    });
    const toast = useRef<Toast>(null);
    const op = useRef<OverlayPanel>(null);
    const _opMsg = useRef("");
    const { mutate: createUser, isSuccess: isCreateSuccess, isError: isCreateError } = useCreateUser();
    const { mutate: updateUser, isSuccess: isUpdateSuccess, isError: isUpdateError } = useUpdateUser();

    useEffect(() => {
        setUser(userData);
    }, [userData]);

    // Function to check if anything is invalid
    function checkValid(e: any): boolean {
        let hasError = false;
        // console.log("EEE?", e)
        if (e === null) {
            hasError = true;
            setIsValid({
                AzureObjectId: false,
                FirstName: false,
                LastName: false,
                Email: false,
                Phone: false,
                Account: false,
                Role: false,
                RecordStatus: false
            })
        } else {
            if (e.AzureObjectId === null || e.AzureObjectId === "" || e.AzureObjectId === undefined) {
                // setIsValid({ ...isValid, ["AzureObjectId"]: false })
                handleErrors("AzureObjectId", e.AzureObjectId);
            }
            if (e.FirstName === null || e.FirstName === "" || e.FirstName === undefined) {
                // setIsValid({ ...isValid, ["FirstName"]: false })
                handleErrors("FirstName", e.FirstName);
                hasError = true;
            }
            if (e.LastName === null || e.LastName === "" || e.LastName === undefined) {
                // setIsValid({ ...isValid, ["LastName"]: false })
                handleErrors("LastName", e.LastName);
                // console.log("Setting last name to false!!!!!")
                hasError = true;
            }
            if (e.Email === null || e.Email === "" || e.Email === undefined) {
                // setIsValid({ ...isValid, ["Email"]: false })
                handleErrors("Email", e.Email);
                hasError = true;
            }
            if (e.Phone === null || e.Phone === "" || e.Phone === undefined) {
                // setIsValid({ ...isValid, ["Phone"]: false })
                handleErrors("Phone", e.Phone);
                hasError = true;
            }
            if (e.AccountId === null || e.AccountId === "" || e.AccountId === undefined) {
                // setIsValid({ ...isValid, ["Account"]: false })
                handleErrors("Account", e.Account);
                hasError = true;
            }
            if (e.RecordStatus === null || e.RecordStatus === "" || e.RecordStatus === undefined) {
                // setIsValid({ ...isValid, ["RecordStatus"]: false })
                handleErrors("RecordStatus", e.RecordStatus);
                hasError = true;
            }

            // const regex = new RegExp('/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gm')
            // // /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gm
            // if (regex.test(e.AzureObjectId)) {
            //     handleErrors("AzureObjectId", e.AzureObjectId);
            //     hasError = true;
            //     toast.current?.show({
            //         severity: 'warn',
            //         summary: 'Error',
            //         detail: 'The provided Azure Object Id did not follow the expected GUID format.',
            //         life: 10000
            //     });
            //     console.log(regex.test(e.AzureObjectId))
            // }
            // else {
            //     handleErrors("AzureObjectId", e.AzureObjectId);
            //     hasError = true;
            // }
            // UserRoleIds
            let hasRoleError = false;
            // console.log("eeee userrole thing", e.UserRoleIds);
            if (e.UserRoleIds !== null && e.UserRoleIds !== undefined) {
                for (var role of e.UserRoleIds) {
                    if (role === null || role === "" || role === undefined) {
                        hasRoleError = true;
                    }
                }
                if (hasRoleError) {
                    // setIsValid({ ...isValid, ["Role"]: false })
                    handleErrors("Role", e.Account);
                    hasError = true;
                }
            } else {
                // setIsValid({ ...isValid, ["Role"]: false })
                handleErrors("Role", e.Account);
                hasError = true;
            }
        }
        // console.log(isValid);
        return hasError;
    }

    // Before saving, check if we can save
    const checkSave = (e: any) => {
        let hasError = checkValid(e);
        // console.log(checkValid(e))
        // console.log('e', e);

        // If check valid returns true, then don't save and show an error
        if (hasError) {
            toast.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: 'One or more required items were incomplete, or had an incorrect format.',
                life: 10000
            });
            return
        }
        // Else, go ahead and save
        handleSave();
    }

    const handleSave = async () => {
        console.log(user)
        if (isEdit) {
            await updateUser(user);
        } else {
            await createUser(user);
        }

        // toast.current?.show({ severity: 'error', summary: 'Error', detail: 'There was a server error while saving the user. Please try again later.', life: 10000 });

        // toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'User successfully saved.', life: 3000 });
    };

    useEffect(() => {
        if (isCreateSuccess || isUpdateSuccess) {
            toast.current?.show({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'User successfully saved.',
                life: 3000
            });
            navigate('/users');
        }
    }, [isCreateSuccess, isUpdateSuccess, navigate]);

    useEffect(() => {
        if (isCreateError || isUpdateError) {
            // alert('Error saving user');
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'There was a server error while saving the user. Please ensure the form is filled out appropriately. If issue persists, please reach out to the System Admin.',
                life: 10000
            });
        }
    }, [isCreateError, isUpdateError]);

    const handleErrors = (field: string, value: string | null | undefined) => {
        if (value === undefined || value === null || value === '') {
            // setIsValid({ ...isValid, [field]: false })
            setIsValid((errors: any) => ({ ...errors, [field]: false }))
            // console.log("Setting something false!")
        } else {
            // if (field === "AzureObjectId") {
            //     console.log("Here")
            //     const regex = new RegExp('/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gm')
            //     // /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gm
            //     // ^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}?-[-0-9a-fA-F]{0,4}-?[0-9a-fA-F]{12}
            //     console.log(regex.test(value))
            //     if (regex.test(value)) {
            //         setIsValid((errors: any) => ({ ...errors, [field]: true }))
            //         console.log(regex.test(value))
            //     }
            //     else {
            //         setIsValid((errors: any) => ({ ...errors, [field]: false }))
            //         console.log("Bad regex!", value)
            //     }
            // } else {
            //     setIsValid((errors: any) => ({ ...errors, [field]: true }))
            // }
            setIsValid((errors: any) => ({ ...errors, [field]: true }))
        }
        // console.log("Set this??", isValid)
    }

    const handleChange = (field: string, value: string | null | undefined) => {
        console.log(field, value)
        setUser({ ...user, [field]: value });
        handleErrors(field, value);
    };

    const handleAccountChange = (e: any) => {
        console.log('e', e);

        setUser({
            ...user,
            AccountId: e.target.value.AccountId
        });

        if (e.target.value.AccountId === undefined || null || "") {
            // setIsValid({ ...isValid, ["Account"]: false })
            handleErrors("Account", "");
        } else {
            // setIsValid({ ...isValid, ["Account"]: true })
            handleErrors("Account", "Not false");
        }
    }

    const handleClientBankChange = (e: any) => {
        console.log('e', e);

        setUser({
            ...user,
            ClientBankId: e.target.value.ClientBankId
        });

        if (e.target.value.ClientBankId === undefined || null || "") {
            // setIsValid({ ...isValid, ["Account"]: false })
            handleErrors("ClientBank", "");
        } else {
            // setIsValid({ ...isValid, ["Account"]: true })
            handleErrors("ClientBank", "Not false");
        }
    }

    const handleRoleChange = (e: any) => {
        console.log('e', e);

        setUser({
            ...user,
            UserRoleIds: [
                e.target.value.RoleId
            ]
        });
        if (e.target.value.RoleId === undefined || null || "") {
            // setIsValid({ ...isValid, ["Role"]: false })
            handleErrors("Role", "");
        } else {
            // setIsValid({ ...isValid, ["Role"]: true })
            handleErrors("Role", "Not false");
        }
    }

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let { hasRoles, isLoading } = useHasRole(['Account Administrator', 'System Administrator', 'Account Manager'])

    if (isLoading) {
        return <Loading />
    } else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div className="user-form">
            <Nav />
            <Body>
                <div>
                    <OverlayPanel ref={op}>
                        <div>{_opMsg.current}</div>
                    </OverlayPanel>
                    <Toast ref={toast} position="bottom-center" />
                    <div className="flex justify-content-between">
                        <h1>User</h1>
                        <Button label="Save" icon="pi pi-save" onClick={() => checkSave(user)} />
                    </div>
                    <div className="form">
                        <div className="form-column gap-5">
                            <span className="p-float-label">
                                <InputMask
                                    id="azureObjectId"
                                    value={user?.AzureObjectId}
                                    onChange={(e) => handleChange('AzureObjectId', e.target.value)}
                                    className={isValid.AzureObjectId === false ? 'p-invalid ' : ''}
                                    // 4b0202c9-d024-418c-a78ba01810da581
                                    // 4b0202c9-d024-418c-a78b-a01810da5813
                                    // 37ead440-091b-48bc-841a-cab216ab6437
                                    // 37ead440-091b-48bc-841a-cab216ab6437
                                    mask="********-****-****-****-************"
                                />
                                {/* /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/g */}
                                <label htmlFor="azureObjectId">Azure Object Id*</label>
                                <small id="azure-id-help">The Azure Object Id must follow expected format.</small>
                            </span>

                            <span className="p-float-label">
                                <InputText
                                    id="firstName"
                                    value={user?.FirstName}
                                    onChange={(e) => handleChange('FirstName', e.target.value)}
                                    className={isValid.FirstName === false ? 'p-invalid ' : ''}

                                />
                                <label htmlFor="firstName">First Name*</label>
                            </span>

                            <span className="p-float-label">
                                <InputText
                                    id="lastName"
                                    value={user?.LastName}
                                    onChange={(e) => handleChange('LastName', e.target.value)}
                                    className={isValid.LastName === false ? 'p-invalid ' : ''}
                                />
                                <label htmlFor="lastName">Last Name*</label>
                            </span>

                            <span className="p-float-label">
                                <InputText
                                    id="email"
                                    value={user?.Email}
                                    keyfilter="email"
                                    onChange={(e) => handleChange('Email', e.target.value)}
                                    className={isValid.Email === false ? 'p-invalid ' : ''}
                                    disabled={isEdit}
                                />
                                <label htmlFor="email">Email*</label>
                                <small id="email-help">The email address must be unique. Users cannot share the same email address.</small>
                            </span>

                            <span className="p-float-label">
                                <InputText
                                    keyfilter='pnum'
                                    id="phone"
                                    value={user?.Phone}
                                    // mask="(999) 999-9999"
                                    onChange={(e) => handleChange('Phone', e.target.value)}
                                    className={isValid.Phone === false ? 'p-invalid ' : ''}
                                />
                                <label htmlFor="phone">Phone*</label>
                            </span>
                        </div>

                        <div className="form-column gap-5">
                            <span className="p-float-label">
                                <Dropdown
                                    value={user?.RecordStatus}
                                    onChange={(e) => handleChange('RecordStatus', e.target.value)}
                                    options={["Active", "Inactive"]}
                                    className={isValid.RecordStatus === false ? 'w-full p-invalid' : 'w-full'}
                                    id="recordStatus"
                                />
                                <label htmlFor="recordStatus">Record Status*</label>
                            </span>

                            <span className="p-float-label">
                                <Dropdown
                                    value={accountsData.find((account: any) => account.AccountId === user?.AccountId)}
                                    onChange={(e) => handleAccountChange(e)}
                                    options={accountsData}
                                    optionLabel="AccountName"
                                    className={isValid.Account === false ? 'w-full p-invalid' : 'w-full'}
                                    id="account"
                                />
                                <label htmlFor="account">Account*</label>
                            </span>

                            <span className="p-float-label">
                                <Dropdown
                                    value={clientBanksData.find((clientBank: any) => clientBank.ClientBankId === user?.ClientBankId)}
                                    onChange={(e) => handleClientBankChange(e)}
                                    options={clientBanksData}
                                    optionLabel="ClientBankName"
                                    className={'w-full'}
                                    id="clientBank"
                                />
                                <label htmlFor="clientBank">Client Bank</label>
                            </span>

                            <span className="p-float-label">
                                <Dropdown
                                    value={rolesData.find((role: any) => role.RoleId === user?.UserRoleIds?.[0])}
                                    onChange={(e) => handleRoleChange(e)}
                                    options={filteredRoles}
                                    optionLabel="RoleName"
                                    className={isValid.Role === false ? 'w-full p-invalid' : 'w-full'}
                                    id="role"
                                />
                                <label htmlFor="role">Role*</label>
                            </span>

                            {/*{*/}
                            {/*    selectedRole && selectedRole === 'qcAuditor' &&*/}
                            {/*    <div className="flex flex-column gap-3">*/}
                            {/*        <h3>Audit Access</h3>*/}

                            {/*        <div className="flex flex-column gap-3 ml-2">*/}
                            {/*            <div className="flex align-items-center">*/}
                            {/*                <Checkbox checked={prefundAccess}*/}
                            {/*                          onChange={(e) => setPrefundAccess(!!e.checked)} id="prefundAccess"/>*/}
                            {/*                <label htmlFor="prefundAccess" className="ml-2">Prefund</label>*/}
                            {/*            </div>*/}

                            {/*            <div className="flex align-items-center">*/}
                            {/*                <Checkbox checked={postCloseAccess}*/}
                            {/*                          onChange={(e) => setPostCloseAccess(!!e.checked)}*/}
                            {/*                          id="postCloseAccess"/>*/}
                            {/*                <label htmlFor="postCloseAccess" className="ml-2">Post Close</label>*/}
                            {/*            </div>*/}

                            {/*            <div className="flex align-items-center">*/}
                            {/*                <Checkbox checked={servicingAccess}*/}
                            {/*                          onChange={(e) => setServicingAccess(!!e.checked)}*/}
                            {/*                          id="servicingAccess"/>*/}
                            {/*                <label htmlFor="servicingAccess" className="ml-2">Servicing</label>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*} */}
                        </div>
                    </div>
                </div>
            </Body>
        </div>
    );
}
