import React, { useState, useRef, useEffect, useMemo, memo } from 'react';
import Nav from "../components/Nav";
import Body from "../components/Body";
import "./Checklist.css";
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';
import { DataTable, DataTablePageEvent, DataTableSelectAllChangeEvent, DataTableSortEvent, DataTableFilterEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete'
import { Toolbar } from 'primereact/toolbar';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { Ripple } from 'primereact/ripple';
import { ScrollPanel } from 'primereact/scrollpanel';
import { OverlayPanel } from 'primereact/overlaypanel';
import { OrderList } from 'primereact/orderlist';
import Help from '../components/Help';
import { authenticatedFetch } from "../state/apiUtils";
import useHasRole from "../hooks/validRolesHook";
import NotFound from "./Global/404NotFound";
import Loading from "./Global/loading";

export default function Checklist() {
    const toast = useRef<Toast>(null);
    const op = useRef<OverlayPanel>(null);
    const _opMsg = useRef("");
    const [_opMsgState, set_opMsgState] = useState(_opMsg.current);
    const [loading, setLoading] = useState(true);

    const [auditTypes, setAuditTypes] = useState<string[]>([]);

    //For disabling the submit button to avoid multiple-clicking
    const [submitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        //Get the total records
        //getTotal();
        //Get First Page
        //getData(0, 50);

        getRuleList();
        getCategories();
        setLoading(false);
    }, []);

    const resetValues = (param: any) => {
        return {
            param
        }
    }

    const [checkListInfo, setcheckListInfo] = useState(
        {
            columns: [
                {
                    field: "CategoryNavigation.CategoryName",
                    header: "Category",
                    dataType: "string",
                    width: "100px"
                }, {
                    field: "QuestionNumber",
                    header: "Number",
                    dataType: "string"
                },
                {
                    field: "QuestionText",
                    header: "Question",
                    dataType: "string",
                    width: "400px"
                },
                {
                    field: "EffectiveDate",
                    header: "Effective",
                    dataType: "date"
                },
                {
                    field: "ExpirationDate",
                    header: "Expires",
                    dataType: "date"
                },
                {
                    field: "AuditType",
                    header: "Audit Types",
                    dataType: "string"
                },
                {
                    field: "OriginationRegulationSource",
                    header: "Regulation Source",
                    dataType: "string"
                },
                {
                    field: "DirectRegulatoryLink",
                    header: "Regulatory Link",
                    dataType: "string"
                }



            ], loading: true, totalRows: 0, index: 0, page: 1, pageSize: 25, searchParam: {
                QuestionText: "",
                AuditPlanTypes: [],
                Categories: "",
                QuestionNumber: ""
            }
        }
    )

    const [matchQuestionText, setMatchQuesetionText] = useState<string>("")

    //Dropdown field for Investors
    const [investorList, setInvestorList] = useState<any>(null);

    const [checkListItems, setCheckListItems] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    // To keep track of invalid errors, start off with no errors
    const [selectedItemError, setSelectedItemError] = useState<any>(
        {
            QuestionNumber: true,
            QuestionText: true,
            CategoryNavigation: true,
            EffectiveDate: true,
            ExpirationDate: true,
            QuestionType: true,
            QuestionTypeisControl: false,
            ResponseName: true,
            ResponseNameResult: false,
        }
    );

    const [rulesError, setRulesError] = useState<any>(
        {
            "0-0": undefined,
        }
    );

    const handleChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        handleErrors(name, value);
        if (name == "EffectiveDate" || name == "ExpirationDate") {
            setSelectedItem((values: any) => ({ ...values, [name]: value, [name + '2']: value }))
        }
        else {
            setSelectedItem((values: any) => ({ ...values, [name]: value }))
        }

        // setAuditType(event);
    }

    const checkRulesErrors = (checklistRules: any) => {
        var hasErrors = false;
        // console.log("AHHH HERE", checklistRules)
        for (let i = 0; i < checklistRules.length; i++) {
            // console.log("Checklist rules - i", i, checklistRules[i])
            // console.log("i", i)
            for (let j = 0; j < checklistRules[i].ruleItems.length; j++) {
                // console.log("i", i, "j", j)
                // console.log(checklistRules[i].ruleItems[j].field.fieldName)
                if (checklistRules[i].ruleItems[j].field !== (undefined || null)) {
                    // console.log("Set it false??")
                    if ((checklistRules[i].ruleItems[j].operator === (undefined || "=" || "" || null)) || (checklistRules[i].ruleItems[j].value === (undefined || "" || null))) {
                        // rulesError[(i + "-" + j)] = false;
                        handleRuleErrors(i + "-" + j, true)
                        hasErrors = true;
                        // console.log("Set it false??")
                    }
                } else if (checklistRules[i].ruleItems[j].field === (undefined || null)) {
                    if ((checklistRules[i].ruleItems[j].operator !== (undefined || "=" || "" || null)) || (checklistRules[i].ruleItems[j].value !== (undefined || "" || null))) {
                        // rulesError[(i + "-" + j)] = false;
                        handleRuleErrors(i + "-" + j, true)
                        hasErrors = true;
                        // console.log("Set it false?? again??")
                    }
                }
                if ((checklistRules[i].ruleItems[j].field !== (undefined || null)) && (checklistRules[i].ruleItems[j].operator !== (undefined || "=" || "" || null)) && (checklistRules[i].ruleItems[j].value !== (undefined || "" || null))) {
                    // rulesError[(i + "-" + j)] = true;
                    handleRuleErrors(i + "-" + j, undefined)
                    // console.log("Set it true!!")
                }
                if (!((i + "-" + j) in rulesError)) {
                    handleRuleErrors(i + "-" + j, undefined)
                }
            }
        }
        // console.log("RULES ERRORS??", rulesError)

        return hasErrors;
    };

    const handleRuleErrors = (name: any, value: any) => {
        // console.log("RULES ERRORS??", rulesError)
        // rulesError[name] = value;
        setRulesError((errors: any) => ({ ...errors, [name]: value }))
    };

    // const createDefaultRuleErrors = (name: any, value: any) => {
    //     console.log("rule errors", name, value)
    //     const data = name.split("-");
    //     if (!((data[1] + "-" + data[2]) in rulesError)) {
    //         rulesError[data[1] + "-" + data[2]] = true;
    //     }


    // }

    // To keep track of invalid inputs
    const handleErrors = (name: any, value: any) => {
        // console.log("value")
        if (value === null || value === "" || value === undefined) {
            setSelectedItemError((errors: any) => ({ ...errors, [name]: false }))
        }
        else {
            setSelectedItemError((errors: any) => ({ ...errors, [name]: true }))
        }
        if (name === "QuestionType") {
            // console.log("True?");
            let newName = "QuestionTypeisControl"
            if (value === "Control") {
                setSelectedItemError((errors: any) => ({ ...errors, [newName]: true }))
            }
            if (value === "Finding") {
                setSelectedItemError((errors: any) => ({ ...errors, [newName]: false }))
            }
        }

        if (selectedItem.QuestionType === "Control" || (name === "QuestionType" && value === "Control") || selectedItemError.QuestionTypeisControl === true) {
            // console.log("True?");
            let newName = "QuestionTypeisControl"
            setSelectedItemError((errors: any) => ({ ...errors, [newName]: true }))
            if (selectedItem.ResponseName === null || selectedItem.ResponseName === "" || selectedItem.ResponseName === undefined) {
                let newName = "ResponseNameResult"
                // console.log("Setting response name result to true")
                setSelectedItemError((errors: any) => ({ ...errors, [newName]: true }))
            }
        }
        if (selectedItem.QuestionType === "Finding" || (name === "QuestionType" && value === "Finding") || selectedItemError.QuestionTypeisControl === false) {
            // console.log("True?");
            let newName = "QuestionTypeisControl"
            setSelectedItemError((errors: any) => ({ ...errors, [newName]: false }))
            let newerName = "ResponseNameResult"
            setSelectedItemError((errors: any) => ({ ...errors, [newerName]: false }))

        }
        if (selectedItemError.QuestionTypeisControl && selectedItemError.ResponseName) {
            let newName = "ResponseNameResult"
            setSelectedItemError((errors: any) => ({ ...errors, [newName]: true }))
        }
        else if (selectedItemError.QuestionTypeisControl) {
            if (selectedItem.ResponseName === null || selectedItem.ResponseName === "" || selectedItem.ResponseName === undefined) {
                let newName = "ResponseNameResult"
                console.log("Setting response name result to true")
                setSelectedItemError((errors: any) => ({ ...errors, [newName]: true }))
            }
            else {
                let newName = "ResponseNameResult"
                console.log("Setting response name result to true")
                setSelectedItemError((errors: any) => ({ ...errors, [newName]: false }))
            }
        }
        // console.log("ERRORS HERE", selectedItemError)
    }

    const [checklistRules, setChecklistRules] = useState<any[]>([]);

    //Dropdown field for Categories
    const [categories, setCategories] = useState<any[]>([]);

    //Auto suggesting populate dropdown with field values for any given field
    const [autoSuggestValues, setAutoSuggestValues] = useState<string[]>([]);

    //For showing Category Form
    const [manageCategory, setManageCategory] = useState<boolean>(false);

    const operatorList: Record<string, string[]> = {
        'string': ["is", "is not", "contains", "does not contain", "starts with", "ends with"],
        'numeric': ["=", ">=", "<=", ">", "<", "is not"],
        'date': ["is", "is after", "is before", "is not"],
        'control': ["is", "is not"]
    }

    const auditPlanTypeList: string[] = [
        "Prefund", "Postclose", "Servicing"
    ]

    const [ruleList, setRuleList] = useState<any[]>([]);


    const addRuleSubClause = (e: any) => {
        console.log('addRuleSubClause', e);
        //var ruleRgroups = this.state.checklistRules.slice();

        var newGroup = {
            ruleClause: 'And',
            ruleItems: [
                {
                    field: null,
                    operator: '=',
                    value: null
                }
            ]
        }

        setChecklistRules([
            ...checklistRules,
            newGroup
        ]);

        // checkRulesErrors(checklistRules);
    }


    const buildDefaultRules = () => {
        var ruleItem = {
            field: null,
            operator: '=',
            value: null,
            type: 'string',
        }
        var ruleGroup = [];
        ruleGroup.push({
            ruleItems: [ruleItem],
            ruleClause: 'And'
        });

        setChecklistRules(ruleGroup);
        console.log('ruleGroup', ruleGroup);
        checkRulesErrors(checklistRules);
    }


    const addRuleRow = (e: any, g: number) => {

        console.log('ADDING RULE ROW');
        var ruleItem = {
            field: null,
            operator: '=',
            value: null
        };

        var ruleList = checklistRules.slice();
        ruleList[g].ruleItems.push(ruleItem);

        setChecklistRules(ruleList);
        checkRulesErrors(checklistRules);
    }

    const removeRuleItem = (e: any, g: any, idx: number) => {

        var ruleRgroups = checklistRules.slice();
        ruleRgroups[g].ruleItems.splice(idx, 1);
        if (g > 0 && ruleRgroups[g].ruleItems.length == 0) {
            ruleRgroups.splice(g, 1);
        }

        console.log("remove rule: ", g, idx)

        setChecklistRules(ruleRgroups);
        // this.setState({ checklistRules: ruleRgroups });
        handleRuleErrors(g + "-" + idx, undefined);
        checkRulesErrors(checklistRules);
    }


    const setClause = (e: any, g: number) => {
        console.log('setClause', e);
        var ruleRgroups = checklistRules.slice();
        ruleRgroups[g].ruleClause = e.value;
        setChecklistRules(ruleRgroups);
        checkRulesErrors(checklistRules);
    }


    const setField = (e: any, g: number, idx: number) => {
        console.log('setField', e, idx);
        var ruleList = checklistRules.slice();
        ruleList[g].ruleItems[idx].field = e.value;
        ruleList[g].ruleItems[idx].type = e.value.type;

        var list = e.value.values;
        console.log('auto list', list)
        setAutoSuggestValues(list);

        setChecklistRules(ruleList);
        checkRulesErrors(checklistRules);
        console.log(e);

    }


    const setOperator = (e: any, g: number, idx: number) => {
        console.log('setField', e, idx);
        var ruleList = checklistRules.slice();
        ruleList[g].ruleItems[idx].operator = e.value;
        setChecklistRules(ruleList);
        checkRulesErrors(checklistRules);
    }

    const setValue = (e: any, g: number, idx: number) => {
        console.log('setField', e, idx);
        var ruleList = checklistRules.slice();
        ruleList[g].ruleItems[idx].value = e.target.value;
        setChecklistRules(ruleList);
        checkRulesErrors(checklistRules);
    }

    const checklistRulesSection = useMemo(() => {
        console.log('checklistRules', checklistRules);
        return (

            checklistRules.map((group: any, j: number) => {
                console.log('Running', j)
                return (
                    <div key={j}>
                        {j > 0 ? <div className="col-12 clauseLabel">-{checklistRules[0].ruleClause}-</div> : ''}

                        <div className={j != 0 ? 'secondGroup' : 'firstGroup'}>
                            <div className="field-radiobutton">
                                <RadioButton value="And" inputId={"Aclause" + j} name={"clause" + j} onChange={e => setClause(e, j)} checked={group.ruleClause === 'And'} />
                                <label style={{ marginBottom: '0px', marginLeft: '3px' }} htmlFor={"Aclause" + j}>All of the following must be met</label>
                            </div>
                            <div className="field-radiobutton">
                                <RadioButton value="Or" inputId={"Oclause" + j} name={"clause" + j} onChange={e => setClause(e, j)} checked={group.ruleClause === 'Or'} />
                                <label style={{ marginBottom: '0px', marginLeft: '3px' }} htmlFor={"Oclause" + j}>At least one of the following must be met</label>
                            </div>
                            <div className="separator">
                                {
                                    group.ruleItems.map((item: any, i: number) => {
                                        //console.log('ITEM LOOP', item);
                                        // console.log(j, i)
                                        const data = j + "-" + i;
                                        // console.log(data, rulesError[data]);
                                        return (
                                            <div key={i}>
                                                {i > 0 ? <div className="col-12 clauseLabel">-{group.ruleClause}-</div> : ''}
                                                <div className="col-12 p-dropdown-padding">
                                                    <Dropdown className={rulesError[data] !== undefined ? "col-4 p-dropdown-padding p-invalid" : "col-4 p-dropdown-padding"}
                                                        name={"field" + j + "-" + i} value={item.field} onChange={e => setField(e, j, i)} options={ruleList} optionLabel="field" placeholder="Field..." />
                                                    <Dropdown className={rulesError[data] !== undefined ? "col-2 p-dropdown-padding p-invalid" : "col-2 p-dropdown-padding"}
                                                        name={"operator" + j + "-" + i}
                                                        value={item.operator} onChange={e => setOperator(e, j, i)} options={operatorList[item.type]} />
                                                    {/* <Dropdown className="col-2 p-dropdown-padding" value={item.operator} onChange={e => setOperator(e, j, i)} options={operatorList[item.type]} /> */}
                                                    {/* suggestions={this.state.autoSuggestValues} completeMethod={e => { this.onAutoSuggest(e, item.field) }}  */}
                                                    <Dropdown value={item.value}
                                                        name={"value" + j + "-" + i} onChange={e => setValue(e, j, i)} options={autoSuggestValues}
                                                        editable className={rulesError[data] !== undefined ? "col-5 p-dropdown-padding p-invalid" : "col-5 p-dropdown-padding"} />
                                                    {/* <Dropdown value={item.value} onChange={e => setValue(e, j, i)} options={autoSuggestValues}
                                                        editable className="col-5 p-dropdown-padding" /> */}
                                                    {/* <AutoComplete inputStyle={{ width: '100%' }} className="col-5 p-dropdown-padding" value={item.value} onChange={e => setValue(e, j, i)} suggestions={autoSuggestValues} completeMethod={e => { onAutoSuggest(e, item.field) }} /> */}
                                                    <Button rounded text severity="danger" aria-label="Cancel" onClick={e => removeRuleItem(e, j, i)} className="col-1 pi pi-trash" style={{ display: 'inline-block', minWidth: "0" }} />
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {j > 0 ? <div className="linkButton" style={{ marginLeft: '3px' }} onClick={e => addRuleRow(e, j)}>Add Condition to this Section</div> : ''}
                        </div>

                        {checklistRules?.length ? <div className="col-12 p-dropdown-padding"><div className="linkButton" style={{ display: 'inline', marginLeft: '5px' }} onClick={e => addRuleRow(e, 0)}>Add Criteria</div><div className="linkButton" style={{ marginLeft: '10px', display: 'inline' }} onClick={addRuleSubClause}>Add New Section</div></div> : ''}

                    </div>

                )
            }

            )

        )

    }, [checklistRules])

    const getRuleList = () => {


        authenticatedFetch(
            "/QCCheckList/GetRuleList")
            .then(res => {
                console.log(res);
                console.log('Rule List', res);
                var data = res;

                setRuleList(data);
            })
            .catch((error) => {
                toast.current?.show({
                    severity: 'error', summary: 'Error',
                    detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000
                });
                console.log(error);
            });

    }
    const getData = (param: any) => {

        // var param = {
        //     index: param.index,
        //     pageSize: param.pageSize,
        //     QuestionText: checkListInfo.searchParam.QuestionText,
        //     AuditPlanTypes: checkListInfo.searchParam.AuditPlanTypes,
        //     Categories: checkListInfo.searchParam.Categories,
        //     QuestionNumber: checkListInfo.searchParam.QuestionNumber
        // }

        authenticatedFetch(
            "/QCCheckList/GetData",
            {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                console.log(res);
                console.log(res);
                var data = res;

                if (param.QuestionText) {
                    setMatchQuesetionText(param.QuestionText);
                }
                else {
                    setMatchQuesetionText("");
                }
                setCheckListItems(data);
                console.log("Checklist items??", data);
                setAuditTypes(auditTypes);
                setcheckListInfo(s => {
                    return {
                        ...s, loading: false, index: param.index, pageSize: param.pageSize,
                        searchParam: {
                            QuestionText: param.QuestionText,
                            AuditPlanTypes: param.AuditPlanTypes,
                            Categories: param.Categories,
                            QuestionNumber: param.QuestionNumber
                        }
                    }
                })



            })
            .catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                console.log(error);
            });


    }

    const getCategories = () => {

        //setCategories(_Categories);
        authenticatedFetch(`/QCCheckList/GetCategories`)
            .then((e) => {
                setCategories(e);
            }).catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                console.log(error);
            });

    }

    const getTotal = (param: any) => {


        authenticatedFetch(
            "/QCCheckList/GetCount",
            {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                console.log(res);
                console.log('total count', res);
                var data = res;

                setcheckListInfo(s => { return { ...s, totalRows: data } })

            })
            .catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                console.log(error);
            });

    }

    const onPage = (event: DataTablePageEvent) => {
        var param: any = {
            index: event.first,
            pageSize: event.rows,
            QuestionText: checkListInfo.searchParam.QuestionText,
            AuditPlanTypes: checkListInfo.searchParam.AuditPlanTypes,
            Categories: checkListInfo.searchParam.Categories,
            QuestionNumber: checkListInfo.searchParam.QuestionNumber
        }
        getData(param);
        setSelectedItem(null);
    };

    const onSelect = (e: any) => {
        console.log('selected item', e.value);

        console.log('categories', categories);

        setAuditTypes([]);
        setSelectedItemError({
            QuestionNumber: true,
            QuestionText: true,
            CategoryNavigation: true,
            EffectiveDate: true,
            ExpirationDate: true,
            QuestionType: true,
            QuestionTypeisControl: false,
            ResponseName: true,
            ResponseNameResult: false,
        });

        let auditTypes = [];
        e.value.AuditType = "";
        if (e.value.PostcloseAudit) {
            e.value.AuditType += "Postclose, "
            auditTypes.push("Postclose");
        }
        if (e.value.PrefundAudit) {
            e.value.AuditType += "Prefund, "
            auditTypes.push("Prefund");
        }
        if (e.value.ServicingAudit) {
            e.value.AuditType += "Servicing, "
            auditTypes.push("Servicing");
        }
        setAuditTypes(auditTypes);

        //Update Investor List, somehow it always have a mismatch
        //e.value.Investors = e.value.Investors.map((i:any)=> { i.InvestorChecklists = []; return i;})
        //console.log('Transformed Investors', e.value)

        //Need to create a DateType for the calendar component
        if (e.value.EffectiveDate)
            e.value.EffectiveDate2 = new Date(e.value.EffectiveDate);
        else
            e.value.EffectiveDate2 = null;

        if (e.value.ExpirationDate)
            e.value.ExpirationDate2 = new Date(e.value.ExpirationDate);
        else
            e.value.ExpirationDate2 = null;

        if (e.value.ResponseName == null) {
            e.value.ResponseName = "";
        }
        //A hack because the items won't match the dropdown, because it has the QcChecklist populated for some reason.
        e.value.CategoryNavigation.QcChecklists = [];


        setSelectedItem(e.value);

        if (e.value.RulesObj) {
            console.log('Existing Rules', e.value.RulesObj);
            var ruleGroup = JSON.parse(e.value.RulesObj);
            setChecklistRules(ruleGroup);
            checkRulesErrors(checklistRules);
        }
        else {
            buildDefaultRules();
            checkRulesErrors(checklistRules);
        }
    }

    const editHeaderTemplate = (options: any) => {

        const className = `${options.className} justify-content-start helpPanelHeader`;
        const titleClassName = `${options.titleClassName} ml-2 text-primary`;
        const style = { fontSize: '1.25rem' };
        //console.log('options', options);
        return (
            <div className={className} style={{ padding: "11px" }}>
                <span className={titleClassName} style={style}>{options.props.header}</span>
                {/* {options.props.header} */}
            </div>
        );
    };
    const removeCheckListItem = (e: any) => {
        console.log('removeCheckListItem', e);
        const accept = () => {
            authenticatedFetch(
                "/QCCheckList/DeleteItem",
                {
                    method: "POST",
                    body: JSON.stringify(e),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                .then(res => {
                    console.log(res);
                    console.log(res);
                    var data = res;
                    setCheckListItems(
                        checkListItems.filter(a => a.QcChecklistId !== e.QcChecklistId)
                    );
                    setSelectedItem(null);
                    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Question has been removed', life: 3000 });

                }
                )
                .catch((error) => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                    console.log(error);
                });
        }

        const reject = () => {

        }

        confirmDialog({
            message: 'Are you sure you want to remove this question?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });

    }

    //Convert the rules from UI friend to straight up query for SQL
    const transformRules = () => {

        var whereClauses: string[] = [];
        checklistRules.forEach(group => {
            let queryDef = '';
            var queryDefList: any[] = [];
            group.ruleItems.forEach((rule: any) => {
                if (rule.field == null) {
                    return;
                }
                console.log('rule', rule);
                var fieldName = "[" + rule.field.fieldName + "]";
                if (rule.type == 'string' || rule.type == 'control') {
                    //    'string': ["contains", "does not contain", "starts with", "ends with", "=", "!="],
                    var fieldQuery = '';

                    //escape m.value
                    var value = rule.value.replace("'", "''");
                    switch (rule.operator) {
                        case 'contains':
                            {
                                fieldQuery = fieldName + '.Contains("' + value.toUpperCase() + '")';
                            }
                            break;
                        case 'does not contain':
                            {
                                fieldQuery = "!" + fieldName + '.Contains("' + value.toUpperCase() + '")';
                            }
                            break;
                        case 'starts with':
                            {
                                fieldQuery = fieldName + '.StartsWith("' + value.toUpperCase() + '")';
                            }
                            break;
                        case 'ends with':
                            {
                                fieldQuery = fieldName + '.EndsWith("' + value.toUpperCase() + '")';
                            }
                            break;
                        case 'is':
                            {
                                fieldQuery = fieldName + ' == "' + value.toUpperCase() + '"';
                            }
                            break;
                        case 'is not':
                            {
                                fieldQuery = fieldName + ' != "' + value.toUpperCase() + '"';
                            }
                            break;

                    }
                    queryDefList.push(fieldQuery);
                }
                else if (rule.type == 'numeric') {
                    queryDefList.push(fieldName + rule.operator + rule.value);
                }
                else {
                    queryDefList.push(fieldName + rule.operator + "'" + rule.value + "'");
                }
            });

            console.log('queryDefList', queryDefList);
            if (queryDefList.length > 0) {
                queryDef = '(' + queryDefList.join(' ' + (group.ruleClause == "And" ? " && " : " || ") + ' ') + ')';
                whereClauses.push(queryDef);
            }
        }
        );

        var whereClause = '';
        if (whereClauses.length > 1) {
            whereClause = whereClauses.join(' ' + (checklistRules[0].ruleClause == "And" ? " && " : " || ") + ' ');
        }
        else if (whereClauses.length > 0) {
            whereClause = whereClauses[0];
        }
        console.log('whereClause', whereClause);
        return whereClause;
    };

    function checkErrors(e: any): boolean {
        handleErrors("QuestionType", e.QuestionType);
        var hasError = false;
        if (e.QuestionNumber === null || e.QuestionNumber === "" || e.QuestionNumber === undefined) {
            handleErrors("QuestionNumber", e.QuestionNumber);
            hasError = true;
        }
        // console.log("hasError2", hasError);
        if (e.QuestionText === null || e.QuestionText === "" || e.QuestionText === undefined) {
            handleErrors("QuestionText", e.QuestionText);
            hasError = true;
        }
        // console.log("hasError3", hasError);
        if (e.CategoryNavigation === null || e.CategoryNavigation === "" || e.CategoryNavigation === undefined) {
            handleErrors("CategoryNavigation", e.CategoryNavigation);
            hasError = true;
        }
        // console.log("hasError4", hasError);
        if (e.EffectiveDate === null || e.EffectiveDate === "" || e.EffectiveDate === undefined) {
            handleErrors("EffectiveDate", e.EffectiveDate);
            hasError = true;
        }
        // console.log("hasError5", hasError);
        if (e.ExpirationDate === null || e.ExpirationDate === "" || e.ExpirationDate === undefined) {
            handleErrors("ExpirationDate", e.ExpirationDate);
            hasError = true;
        }
        // console.log("hasError6", hasError);
        if (e.QuestionType === null || e.QuestionType === "" || e.QuestionType === undefined) {
            handleErrors("QuestionType", e.QuestionType);
            if (e.ResponseName === null || e.ResponseName === "" || e.ResponseName === undefined) {
                hasError = true;
            }
        }
        // console.log("hasError7", hasError);
        if (e.QuestionType === "Control") {
            let newName = "QuestionTypeisControl"
            setSelectedItemError((errors: any) => ({ ...errors, [newName]: true }))
            if (e.ResponseName === null || e.ResponseName === "" || e.ResponseName === undefined) {
                let newName = "ResponseNameResult"
                setSelectedItemError((errors: any) => ({ ...errors, [newName]: true }))
                hasError = true;
            }
        } else if (e.QuestionType === "Finding") {
            let newName = "QuestionTypeisControl"
            setSelectedItemError((errors: any) => ({ ...errors, [newName]: false }))
            let newName2 = "ResponseNameResult"
            setSelectedItemError((errors: any) => ({ ...errors, [newName2]: false }))
        }

        // if (selectedItemError.QuestionTypeisControl) {
        //     if (e.ResponseName === null || e.ResponseName === "" || e.ResponseName === undefined) {
        //         handleErrors("ResponseName", e.ResponseName);
        //         // selectedItemError.ResponseNameResult = true;
        //         let newName = "ResponseNameResult"
        //         console.log("Setting response name result to true")
        //         setSelectedItemError((errors: any) => ({ ...errors, [newName]: true }))
        //         hasError = true;
        //     }
        // }
        // console.log("hasError8", hasError);

        console.log(selectedItemError, e.EffectiveDate2);

        for (const name in selectedItemError) {
            if (selectedItemError[name] === false) {
                if (name !== "QuestionType" && name !== "ResponseNameResult" && name !== "ResponseName" && name !== "QuestionTypeisControl") {
                    hasError = true;
                }
            }
        }
        // console.log("hasError9", hasError);

        // for (const name in rulesError) {
        //     if (rulesError[name] === false) {
        //         hasError = true;
        //         console.log("Found a rules error")
        //     }
        // }

        if (hasError === true) {
            console.log("Am toast");
            toast.current?.show({ severity: 'warn', summary: 'Error', detail: 'One or more required items were incomplete.', life: 5000 });
        }
        // console.log("hasError10", hasError);
        return hasError;
    }

    const saveCheckListItem = (b: any, e: any) => {
        console.log('saveCheckListItem', e);


        setSubmitting(true);
        if (b.target.disabled) {
            //console.log("Already disabled");
            console.log('submitForm returning')
            return;
        }

        

        setTimeout(() => {
            // setAuditType(e);
            var hasError = checkErrors(e)
            var rulesHasError = checkRulesErrors(checklistRules)

            if (((hasError == true) || (rulesHasError == true))) {
                console.log("I BAILED!")
                setSubmitting(false);
                return;
            }

            // if (e.QuestionNumber === null || e.QuestionNumber === "" || e.QuestionNumber === undefined) {
            //     handleErrors("QuestionNumber", e.QuestionNumber);
            // }
            // if (e.QuestionText === null || e.QuestionText === "" || e.QuestionText === undefined) {
            //     handleErrors("QuestionText", e.QuestionText);
            // }
            // if (e.CategoryNavigation === null || e.CategoryNavigation === "" || e.CategoryNavigation === undefined) {
            //     handleErrors("CategoryNavigation", e.CategoryNavigation);
            // }
            // if (e.EffectiveDate === null || e.EffectiveDate === "" || e.EffectiveDate === undefined) {
            //     handleErrors("EffectiveDate", e.EffectiveDate);
            // }
            // if (e.ExpirationDate === null || e.ExpirationDate === "" || e.ExpirationDate === undefined) {
            //     handleErrors("ExpirationDate", e.ExpirationDate);
            // }
            // if (e.QuestionType === "Control") {
            //     handleErrors("QuestionType", e.QuestionType);
            // }

            // console.log(selectedItemError, e.EffectiveDate2);

            // for (const name in selectedItemError) {
            //     if (selectedItemError[name] === false) {
            //         if (name !== "QuestionType") {
            //             toast.current?.show({ severity: 'warn', summary: 'Error', detail: 'One or more required items were incomplete.', life: 5000 });
            //             return;
            //         }
            //     }
            // }

            // useEffect(() => {
            //     var orderedList = categories.sort(s => s.CategoryOrder);
            //     setEditCategories(orderedList);
            // }, [])

            //transform rule to SQL friendly expressions
            //let sql = transformRules();

            // AuditPlanTypes
            //add control items
            let control: string[] = [];
            checklistRules.forEach(group => {
                group.ruleItems.forEach((rule: any) => {
                    if (rule.type == "control") {
                        control.push(rule.field.fieldName);
                    }
                });
            }

            );

            //Add rule to item
            // var ruleParam = {
            //     Rules: checklistRules,
            //     SQL: transformRules(),
            //     Control: control.join("|")
            // };


            let auditTypes = [];
            if (e.PostcloseAudit) {
                auditTypes.push("Postclose");
            }
            if (e.PrefundAudit) {
                auditTypes.push("Prefund");
            }
            if (e.ServicingAudit) {
                auditTypes.push("Servicing");
            }
            setAuditTypes(auditTypes);

            e.AuditTypeData = auditTypes;
            e.AuditType = auditString;
            var ruleString = JSON.stringify(checklistRules);
            e.RulesObj = ruleString;
            e.RulesExp = transformRules();
            e.RulesControl = control.join("|");

            //Check if New Category is added
            var catValue = e.CategoryNavigation;

            if (!catValue.hasOwnProperty('QcChecklistCategoryId')) {
                console.log('no id found?');
                var match = categories.filter(f => f.CategoryName == catValue);

                if (match.length > 0) {
                    console.log('match found?', match)
                    e.CategoryNavigation = match[0];
                }
                else {

                    e.CategoryNavigation = {
                        QcChecklistCategoryId: crypto.randomUUID(),
                        CategoryName: catValue,
                        CaetgoryOrder: 0,
                        QcChecklists: []
                    }


                    console.log('New Value', e.CategoryNavigation);
                    //Add in value
                    var cats = categories.slice(0);
                    cats.push(e.CategoryNavigation);
                    setCategories(cats);
                    //setSelectedItem((s: any) => { return { ...s, CategoryNavigation: e.value } });
                }
            }

            //Determine if it's new or not
            var apiRoute = "";
            if (e.QcChecklistId) {
                apiRoute = "UpdateItem"
            }
            else {
                apiRoute = "AddItem"
            }

            authenticatedFetch(
                "/QCCheckList/" + apiRoute,
                {
                    method: "POST",
                    body: JSON.stringify(e),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                .then(res => {
                    console.log(res);
                    console.log(res);
                    var data = res;
                    var updatedRow = data.Added;
                    console.log("Data?", data);
                    console.log("updated row?", updatedRow);
                    if (apiRoute == "UpdateItem") {
                        const newData = checkListItems.map((item: any) => {
                            if (item.QcChecklistId === e.QcChecklistId) {
                                // No change
                                return {
                                    ...item,
                                    CategoryNavigation: updatedRow.CategoryNavigation,
                                    QuestionText: updatedRow.QuestionText,
                                    QuestionNumber: updatedRow.QuestionNumber,
                                    OriginationRegulationSource: updatedRow.OriginationRegulationSource,
                                    AuditType: updatedRow.AuditType,
                                    AuditTypeData: updatedRow.AuditTypeData,
                                    PostcloseAudit: updatedRow.PostcloseAudit,
                                    PrefundAudit: updatedRow.PrefundAudit,
                                    ServicingAudit: updatedRow.ServicingAudit,
                                    EffectiveDate: updatedRow.EffectiveDate,
                                    ExpirationDate: updatedRow.ExpirationDate,
                                    DirectRegulatoryLink: updatedRow.DirectRegulatoryLink,
                                    Rules: ruleString,
                                };
                            } else {
                                return item;

                            }
                        });
                        console.log("New data?", newData)
                        setCheckListItems(newData);
                        // Re-render with the new array
                        toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Question has been updated successfully', life: 3000 });

                        //Refresh Categories
                        //getCategories();

                        //Refresh Rules
                        getRuleList();
                    }
                    else {
                        const newData = [
                            // Put item on top
                            ...checkListItems.slice(0, 0),
                            // New item:
                            updatedRow,
                            // Items after the insertion point:
                            ...checkListItems.slice(0)
                        ];
                        setCheckListItems(newData);
                        console.log("New data?", newData)
                        setSelectedItem(updatedRow);

                        toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Question has been added successfully', life: 3000 });

                        //Refresh Categories
                        getCategories();

                        //Refresh Rules
                        getRuleList();

                       
                    }

                    setSubmitting(false); 
                })
                .catch((error) => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                    console.log(error);
                    setSubmitting(false); 
                });

        }, 10);

    }

    const addCheckListItem = () => {

        //Clear Form
        var newItem = {
            OriginationRegulationSource: "",
            QuestionText: "",
            QuestionNumber: "",
            DirectRegulatoryLink: "",
            ResponseName: "",
            PrefundAudit: false,
            PostcloseAudit: false,
            ServicingAudit: false,
            AuditType: ""
        }
        buildDefaultRules();
        setSelectedItem(newItem);

    }

    const updateInvestor = (e: any) => {

    }
    const [auditString, setAuditString] = useState<string>("");

    const onAuditTypeChange = (e: any) => {
        console.log("Audit type event", e)
        // let auditTypes = selectedItem.AuditType;

        let _auditTypes = [...auditTypes];

        if (e.checked) {
            _auditTypes.push(e.value);
            if (e.value == "Prefund") {
                e.PrefundAudit = true;
                setSelectedItem((values: any) => ({ ...values, "PrefundAudit": true }))
            }
            if (e.value == "Postclose") {
                e.PostcloseAudit = true;
                setSelectedItem((values: any) => ({ ...values, "PostcloseAudit": true }))
            }
            if (e.value == "Servicing") {
                e.ServicingAudit = true;
                setSelectedItem((values: any) => ({ ...values, "ServicingAudit": true }))
            }
        }
        else {
            _auditTypes.splice(_auditTypes.indexOf(e.value), 1);
            if (e.value == "Prefund") {
                e.PrefundAudit = false;
                setSelectedItem((values: any) => ({ ...values, "PrefundAudit": false }))
            }
            if (e.value == "Postclose") {
                e.PostcloseAudit = false;
                setSelectedItem((values: any) => ({ ...values, "PostcloseAudit": false }))
            }
            if (e.value == "Servicing") {
                e.ServicingAudit = false;
                setSelectedItem((values: any) => ({ ...values, "ServicingAudit": false }))
            }
        }

        setAuditTypes(_auditTypes);

        var auditStr = "";

        for (let i = 0; i < _auditTypes.length; i++) {
            auditStr += _auditTypes[i] + "; "
        }

        console.log(auditStr);

        setAuditString(auditString);

        setSelectedItem((s: any) => { return { ...s, AuditType: auditString } })
    };
    // const setAuditType = (e: CheckboxChangeEvent) => {
    //     console.log("Audit type event", e)
    //     let auditTypes = selectedItem.AuditType;

    //     if (auditTypes == null)
    //         auditTypes = "";

    //     if (e.checked) {
    //         if (!auditTypes.includes(e.value)) {
    //             auditTypes += e.value + ";"
    //         }
    //     }
    //     else {
    //         auditTypes = auditTypes.replace(e.value + ";", "");
    //     }
    //     console.log("Audit types?,", auditTypes);
    //     setSelectedItem((s: any) => { return { ...s, AuditType: auditTypes } })
    // }
    const leftButtons = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-chevron-left" onClick={() => {
                setSelectedItem(null);
                setAuditTypes([]);
                setSelectedItemError({
                    QuestionNumber: true,
                    QuestionText: true,
                    CategoryNavigation: true,
                    EffectiveDate: true,
                    ExpirationDate: true,
                    QuestionType: true,
                    QuestionTypeisControl: false,
                    ResponseName: true,
                    ResponseNameResult: false,
                });
                setRulesError({
                    "0-0": undefined,
                });
            }} className="mr-2" />

        </React.Fragment>
    );

    const rightButtons = (
        <React.Fragment>
            {!submitting && selectedItem && selectedItem.QcChecklistId ?
                <Button label="Remove Question" severity="danger" className="bg-red-400 mr-2" onClick={() => removeCheckListItem(selectedItem)} />
                : ""
            }
            {submitting ? <div style={{margin: "0px"}} className="mr-2 loading"></div> : "" } <Button label="Save Changes" disabled={submitting} onClick={(e) => saveCheckListItem(e, selectedItem)} className="mr-1" />
        </React.Fragment>
    );

    const CategoryForm = () => {

        const [editCategories, setEditCategories] = useState<any[]>([]);
        const [selectedCat, setSelectedCat] = useState<any>(null);
        const catEditOP = useRef<OverlayPanel>(null);

        useEffect(() => {
            var orderedList = categories.sort(s => s.CategoryOrder);
            setEditCategories(orderedList);
        }, [])

        const editItem = (e: any, item: any) => {
            setSelectedCat(item);
            catEditOP.current?.toggle(e)
        }

        const cancelNameEdit = () => {
            setSelectedCat(null);
            catEditOP.current?.hide()
        }

        const saveNameEdit = () => {
            var copy = editCategories.slice(0);
            var origCat = copy.filter(f => f.QcChecklistCategoryId == selectedCat.QcChecklistCategoryId)[0];
            console.log('hm? ', selectedCat)
            origCat.CategoryName = selectedCat.CategoryName;
            setEditCategories(copy);
            setSelectedCat(null);
            catEditOP.current?.hide()
        }

        const saveEdit = () => {
            console.log('saveEdit', editCategories);
            var copy = editCategories.slice(0);
            //Save Order
            copy.forEach((cat, i) => {
                cat.CategoryOrder = i + 1;
            })

            //copy = copy.sort(s=>s.CategoryOrder);

            authenticatedFetch(
                "/QCCheckList/SaveCategories",
                {
                    method: "POST",
                    body: JSON.stringify(copy),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                .then(res => {
                    console.log('SaveCategories', res);
                    var data = res;

                    setCategories(copy);
                    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Categories updates completed successfully', life: 3000 });

                })
                .catch((error) => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                    console.log(error);
                });


            //setEditCategories(copy);
            setSelectedCat(null);

        }
        const cancelEdit = () => {
            setManageCategory(false);
        }

        const itemTemplate = (item: any) => {
            return (
                <div className="p-0 flex flex-wrap align-items-center">
                    {
                        <span>{item.CategoryName} <div className="linkButton" onClick={(e) => editItem(e, item)} style={{ display: 'inline', marginLeft: '5px' }}>Edit</div></span>

                        // selectedCat != null && selectedCat.QcChecklistCategoryId == item.QcChecklistCategoryId ?
                        //     <div className="w-full">
                        //         <InputText className="w-full" style={{ width: "100%" }} value={selectedCat.CategoryName} onChange={(e) => { setSelectedCat((s: any) => { return { ...s, CategoryName: e.target.value } }); }} />
                        //         <div className="mt-1 mr-3"><Button label="OK" onClick={saveNameEdit} style={{ height: "18px", marginRight: "6px" }} />
                        //             <Button label="Cancel" onClick={cancelNameEdit} style={{ height: "18px" }} />
                        //         </div>
                        //     </div>
                        //     :
                        //     <span>{item.CategoryName} <div className="linkButton" onClick={() => editItem(item)} style={{ display: 'inline', marginLeft: '5px' }}>Edit</div></span>
                    }
                </div>
            );
        };
        const leftCatButtons = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-chevron-left" onClick={cancelEdit} className="mr-2" />

            </React.Fragment>
        );

        const rightCatButtons = (
            <React.Fragment>
                <Button label="Save Changes" onClick={saveEdit} className="mr-1" />
            </React.Fragment>
        );
        return (
            <div className='col-4'>
                <OverlayPanel ref={catEditOP}>
                    {selectedCat &&
                        <div className="w-full">
                            <InputText className="w-full" style={{ width: "100%" }} value={selectedCat.CategoryName} onChange={(e) => { setSelectedCat((s: any) => { return { ...s, CategoryName: e.target.value } }); }} />
                            <div className="mt-1 mr-3"><Button label="OK" onClick={saveNameEdit} style={{ height: "18px", marginRight: "6px" }} />
                                <Button label="Cancel" onClick={cancelNameEdit} style={{ height: "18px" }} />
                            </div>
                        </div>
                    }
                </OverlayPanel>
                <Panel header="Categories" headerTemplate={editHeaderTemplate} >
                    <div className="grid">
                        <div className="col-12 mb-2">
                            <OrderList style={{ height: "62.5vh" }}
                                className='mt-2' dataKey="QcChecklistCategoryId" value={editCategories} itemTemplate={itemTemplate} onChange={(e) => setEditCategories(e.value)} dragdrop>
                            </OrderList>
                        </div>
                        <div className="col-12 mb-2">
                            <Toolbar style={{ padding: 0 }} start={leftCatButtons} end={rightCatButtons} />
                        </div>
                    </div>

                </Panel>
            </div>
        )
    }

    const EditForm = () => {

    }
    const SearchForm = () => {

        //For searching
        const [searchParam, setSearchParam] = useState<any>(checkListInfo.searchParam)


        const searchHeaderTemplate = (options: any) => {

            const className = `${options.className} justify-content-start helpPanelHeader`;
            const titleClassName = `${options.titleClassName} ml-2 text-primary`;
            const style = { fontSize: '1.25rem' };
            const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
            //console.log('options', options);
            return (
                <div className={className}>
                    <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                        <span className={toggleIcon}></span>
                        <Ripple />
                    </button>
                    <span className={titleClassName} style={style}>{options.props.header}</span>
                    {/* {options.props.header} */}
                </div>
            );
        };



        const searchSubmit = () => {
            var param: any = {
                index: 0,
                pageSize: 25,
                QuestionText: searchParam.QuestionText,
                AuditPlanTypes: searchParam.AuditPlanTypes,
                Categories: searchParam.Categories,
                QuestionNumber: searchParam.QuestionNumber
            }


            console.log('WTF????');

            //checkListInfo.searchParam = searchParam;
            getData(param);
            getTotal(param);
        };


        const onResetSearch = () => {
            setSearchParam((s: any) => {
                return {
                    ...s, QuestionText: "",
                    AuditPlanTypes: [],
                    Categories: "",
                    QuestionNumber: ""
                }
            })

            var param: any = {
                index: 0,
                pageSize: 25,
                QuestionText: "",
                AuditPlanTypes: [],
                Categories: "",
                QuestionNumber: ""
            }
            getData(param);
            getTotal(param);
        }
        return (
            <Panel headerTemplate={searchHeaderTemplate} header="Search Questions" className="mb-2" toggleable>

                <div className="mt-2 formgrid grid">
                    {/* <div className="field col">
                    <label className="block mb-2 text-x">Investor</label>
                    <MultiSelect value={searchParam.Investors} onChange={(e) => setSearchParam((s: any) => { return { ...s, Investors: e.value } })} options={investorList} optionValue="InvestorId" optionLabel="InvestorName"
                        maxSelectedLabels={3} className="w-full" />

                </div> */}
                    <div className="field col">
                        <label className="block mb-2 text-x">Audit Plan Type</label>
                        <MultiSelect options={auditPlanTypeList}
                            value={searchParam.AuditPlanTypes} onChange={(e) => setSearchParam((s: any) => { return { ...s, AuditPlanTypes: e.value } })}
                            maxSelectedLabels={3} className="w-full" />

                    </div>
                    <div className="field col">
                        <label className="block mb-2 text-x">Categories</label>
                        <Dropdown value={searchParam.Categories} onChange={(e) => { setSearchParam((s: any) => { return { ...s, Categories: e.value } }); }} optionLabel='CategoryName' optionValue='QcChecklistCategoryId' options={categories}
                            className="w-full" />
                    </div>
                    <div className="field col">
                        <label className="block mb-2 text-x">Question Number <Help helpMessage="The following special characters are blocked: < > * ! ? { } ( ) ; : + ="></Help></label>
                        <InputText keyfilter={/^[^<>*!?{}();:+=]+$/} style={{ width: "100%" }} value={searchParam.QuestionNumber} onChange={(e) => { setSearchParam((s: any) => { return { ...s, QuestionNumber: e.target.value } }); }} />
                        {/* <small id="question-text-help">
                            Try only alphanumeric characters.
                        </small> */}
                    </div>
                    <div className="field col">
                        <label className="block mb-2 text-x">Question Text <Help helpMessage="The following special characters are blocked: < > * ! ? { } ( ) ; : + =" /></label>
                        <InputText id="question-text" keyfilter={/^[^<>*!?{}();:+=]+$/} style={{ width: "100%" }} value={searchParam.QuestionText} onChange={(e) => { setSearchParam((s: any) => { return { ...s, QuestionText: e.target.value } }); }} />
                        {/* <small id="question-text-help">
                            Only alphanumeric characters are allowed.
                        </small> */}
                    </div>

                    <div className="flex align-items-center justify-content-center">
                        <Button label="Search" style={{ height: "30px", marginTop: "1rem" }} onClick={searchSubmit} />
                        <Button label="Reset" className="ml-2" style={{ height: "30px", marginTop: "1rem" }} onClick={() => onResetSearch()} />
                    </div>

                </div>

            </Panel>
        )
    }

    const questionBodyTemplate = (data: any) => {
        // <div dangerouslySetInnerHTML={{ __html: data.QuestionText.replace(/matchQuestionText/gi, "<span class='highlightMatch'>$&</span>") }}></div>
        let matchQuestionTextisValid = false;

        try {
            data.QuestionText.replace(new RegExp(matchQuestionText, 'gi'));
            matchQuestionTextisValid = true;
        }
        catch (error) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! It seems your search was invalid. Please try searching with place text only. Avoid punctionation and special characters.', life: 5000 });
        }

        return (
            <div>
                {matchQuestionText && matchQuestionTextisValid ?
                    <div dangerouslySetInnerHTML=
                        {{ __html: data.QuestionText.replace(new RegExp(matchQuestionText, 'gi'), "<span class='highlightMatch'>$&</span>") }}>

                    </div>

                    : data.QuestionText
                }
            </div>
        )
    }

    const auditTypeTemplate = (data: any) => {
        let auditTypes = [];
        data.AuditType = "";
        if (data.PostcloseAudit) {
            data.AuditType += "Postclose, "
            auditTypes.push("Postclose");
        }
        if (data.PrefundAudit) {
            data.AuditType += "Prefund, "
            auditTypes.push("Prefund");
        }
        if (data.ServicingAudit) {
            data.AuditType += "Servicing, "
            auditTypes.push("Servicing");
        }
        // setAuditTypes(auditTypes);
        return (
            <div>
                <p>{data.AuditType}</p>
            </div>
        );
    }

    const regulatoryLinkTemplate = (data: any) => {
        const linkRegex = new RegExp('(https?://|www\.)');
        const isValidLink = linkRegex.test(data.DirectRegulatoryLink);
        // console.log(isValidLink, "isValidLink")
        return (

            <div>
                {isValidLink ?
                    <a href={data.DirectRegulatoryLink} target="_blank" rel="noopener noreferrer" style={{ display: "inline-block", textAlign: "left" }}>
                        {data.DirectRegulatoryLink}
                    </a>
                    :
                    data.DirectRegulatoryLink
                }
            </div>
        )
    }

    // const showHelp = (msg: any, e: any) => {
    //     console.log('showHelp', msg, e);
    //     _opMsg.current = msg;
    //     set_opMsgState(_opMsg.current);
    //     op.current?.toggle(e);
    // }

    const manageCategories = () => {
        setManageCategory(true);
    }

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let { hasRoles, isLoading } = useHasRole(['System Administrator', 'Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div>
            <Nav />

            <Body>
                <div>
                    <h1>Checklist <Button label="Add a new question" icon="pi pi-plus" iconPos="left" onClick={() => {
                        addCheckListItem();
                        setAuditTypes([]);
                        setSelectedItemError({
                            QuestionNumber: true,
                            QuestionText: true,
                            CategoryNavigation: true,
                            EffectiveDate: true,
                            ExpirationDate: true,
                            QuestionType: true,
                            QuestionTypeisControl: false,
                            ResponseName: true,
                            ResponseNameResult: false,
                        });
                        setRulesError({
                            "0-0": undefined,
                        });
                    }} className="mr-2" /><Button label="Manage Categories" icon="pi pi-server" iconPos="left" onClick={() => manageCategories()} className="mr-2" /></h1>
                    <ConfirmDialog />
                    <OverlayPanel ref={op}>
                        <div>{_opMsg.current}</div>
                    </OverlayPanel>
                    <Toast ref={toast} position="bottom-center" />
                    <div className="grid nested-grid pt-3">
                        <div className={"h-full overflow-hidden " + (selectedItem != null || manageCategory ? 'col-8' : 'col-12')}>
                            <div>
                                <SearchForm />
                            </div>
                            <div>
                                <DataTable dataKey="QcChecklistId" size="small" lazy resizableColumns columnResizeMode="expand"
                                    showGridlines selectionAutoFocus={false}

                                    // I added this for ease of reading. What do you think?
                                    stripedRows
                                    loading={loading}
                                    value={checkListItems}
                                    scrollable rows={checkListInfo.pageSize}
                                    paginator totalRecords={checkListInfo.totalRows}
                                    first={checkListInfo.index}
                                    onPage={onPage} scrollHeight='55.8vh'
                                    rowsPerPageOptions={[25, 50, 100, 200, 500, 1000]}
                                    selection={selectedItem} onSelectionChange={onSelect} selectionMode="single"
                                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                >
                                    {/*  selection={selectedItems}  onSelectionChange={onSelect} selectionMode=""checkbox*/}
                                    {/* {col.field === "" ? <Button label="Link" link><a href={col.field}>col.field</a></Button> : col.field} */}
                                    {/*
                            Category
                            Number
                            Question
                            Effective
                            Expires
                            Audit Types
                            Regulation Source
                            Regulatory Link
                             */}
                                    {checkListInfo.columns.map((col, i) => {
                                        if (col.field == "QuestionText") {
                                            return <Column body={questionBodyTemplate} key={col.field} field={col.field} style={{ minWidth: col.width }} header={col.header} dataType={col.dataType} />
                                        }
                                        else if (col.field == "DirectRegulatoryLink") {
                                            return <Column body={regulatoryLinkTemplate} key={col.field} field={col.field} style={{ minWidth: col.width }} header={col.header} dataType={col.dataType} />
                                        }
                                        else if (col.field == "AuditType") {
                                            return <Column body={auditTypeTemplate} key={col.field} field={col.field} style={{ minWidth: col.width }} header={col.header} dataType={col.dataType} />
                                        }
                                        else {
                                            return <Column key={col.field} field={col.field} style={{ minWidth: col.width }} header={col.header} dataType={col.dataType} />
                                        }
                                    })}

                                </DataTable>
                            </div>
                        </div>
                        {manageCategory ?
                            <CategoryForm />
                            :
                            selectedItem == null ? "" :
                                <div className='col-4'>
                                    <Panel header={selectedItem.QcChecklistId ? "Update Question" : "New Question"} headerTemplate={editHeaderTemplate} >
                                        <ScrollPanel className="pt-1" style={{ height: "71vh" }}>
                                            <div className="grid">
                                                <div className="col-12 mb-2">
                                                    <label className="block mb-2 text-x">Category*</label>
                                                    <Dropdown value={selectedItem.CategoryNavigation} name="CategoryNavigation" onChange={handleChange} optionLabel="CategoryName" options={categories}
                                                        editable
                                                        className={selectedItemError.CategoryNavigation ? "w-full" : "w-full p-invalid"}
                                                    />
                                                    {/* <Dropdown value={selectedItem.CategoryNavigation} onChange={(e) => setSelectedItem((s: any) => { return { ...s, CategoryNavigation: e.value } })} optionLabel="CategoryName" options={categories}
                                        editable className="w-full" /> */}
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label className="block mb-2 text-x">Question Number*</label>
                                                    <InputText style={{ width: "100%" }} value={selectedItem.QuestionNumber} name="QuestionNumber" onChange={handleChange}
                                                        className={selectedItemError.QuestionNumber ? "" : "p-invalid"}
                                                    />
                                                    {/* <InputText className="" style={{ width: "100%" }} value={selectedItem.QuestionNumber} onChange={(e) => { setSelectedItem((s: any) => { return { ...s, QuestionNumber: e.target.value } }); }} /> */}
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label className="block mb-2 text-x">Question Text*</label>

                                                    <InputTextarea rows={5} style={{ width: "100%", padding: "0px" }} value={selectedItem.QuestionText} name="QuestionText" onChange={handleChange}
                                                        className={selectedItemError.QuestionText ? "" : "p-invalid"}
                                                    />
                                                </div>
                                                {/* <div className="col-12 mb-2">
                        <label className="block mb-2 text-x">Investor</label>
                        <MultiSelect value={selectedItem.Investors} onChange={(e) => setSelectedItem((s: any) => { return { ...s, Investors: e.value } })} options={investorList} optionLabel="InvestorName"
                            placeholder="Select Invesors" maxSelectedLabels={3} className="w-full" />
                        </div> */}
                                                <div className="col-12">
                                                    <div className="formgrid grid">
                                                        <div className="field col">
                                                            <label className="block mb-2 text-x">Effective Date*</label>

                                                            <Calendar value={selectedItem.EffectiveDate2} name="EffectiveDate" onChange={handleChange}
                                                                className={selectedItemError.EffectiveDate ? "w-full" : "w-full p-invalid"}
                                                            />


                                                            {/* <Calendar value={selectedItem.EffectiveDate2} className="w-full" onChange={(e) => setSelectedItem((s: any) => { return { ...s, EffectiveDate: e.value, EffectiveDate2: e.value } })} /> */}
                                                        </div>
                                                        <div className="field col">
                                                            <label className="block mb-2 text-x">Expiration Date*</label>

                                                            <Calendar value={selectedItem.ExpirationDate2} name="ExpirationDate" onChange={handleChange}
                                                                className={selectedItemError.ExpirationDate ? "w-full" : "w-full p-invalid"}
                                                            />

                                                            {/* <Calendar value={selectedItem.ExpirationDate2} className="w-full" onChange={(e) => setSelectedItem((s: any) => { return { ...s, ExpirationDate: e.value, ExpirationDate2: e.value } })} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label className="block mb-2 text-x">Origination Regulation Source <Help helpMessage="Specify which regulatory source did this question originated from. Optional" /></label>
                                                    <InputText style={{ width: "100%" }} value={selectedItem.OriginationRegulationSource} onChange={(e) => { setSelectedItem((s: any) => { return { ...s, OriginationRegulationSource: e.target.value } }); }} />
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label className="block mb-2 text-x">Regulatory Link <Help helpMessage="If applicable, provide the corresponding link to the regulatory body that governs this question. Optional" /></label>
                                                    <InputText style={{ width: "100%" }} value={selectedItem.DirectRegulatoryLink} onChange={(e) => { setSelectedItem((s: any) => { return { ...s, DirectRegulatoryLink: e.target.value } }); }} />
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label className="block mb-2 text-x">Type of Question*</label>
                                                    <div className="flex flex-wrap gap-3">
                                                        <div className="flex">
                                                            <RadioButton value="Finding" className={selectedItemError.QuestionType ? "" : "p-invalid"} inputId="Finding" name="QuestionType" onChange={(e) => { setSelectedItem((s: any) => { return { ...s, QuestionType: e.target.value } }); }} checked={selectedItem.QuestionType === 'Finding'} />
                                                            <label className="text-x" style={{ marginBottom: '0px', marginLeft: '3px' }} htmlFor="Finding" >Finding Question <Help helpMessage="A finding question is the default. It is used to report audit findings." /></label>
                                                        </div>
                                                        <div className="flex">
                                                            <RadioButton value="Control" className={selectedItemError.QuestionType ? "" : "p-invalid"} inputId="Control" name="QuestionType" onChange={(e) => { setSelectedItem((s: any) => { return { ...s, QuestionType: e.target.value } }); }} checked={selectedItem.QuestionType === 'Control'} />
                                                            <label className="text-x" style={{ marginBottom: '0px', marginLeft: '3px' }} htmlFor="Control">Control Question  <Help helpMessage="A control question is used to control the behavior of a questionnaire. It is a type of yes or no question that controls whether a set of other questions will appear or not." />
                                                            </label>
                                                        </div>
                                                        {/* <Button type="button" icon="pi pi-image" onClick={(e)=>showHelp('Testing XYZ', e)} /> */}

                                                    </div>
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label className="block mb-2 text-x">Control Name{selectedItem.QuestionType == "Control" ? "*" : ""} <Help helpMessage="This is the control name used to operate a control-related question(s). Once this control question has been saved, this name will appear under the 'field' criteria section." /></label>
                                                    <InputText style={{ width: "100%" }} value={selectedItem.ResponseName} name="ResponseName" onChange={handleChange}
                                                        className={selectedItemError.ResponseNameResult ? "p-invalid" : ""}
                                                    />


                                                    {/* <InputText style={{ width: "100%" }} value={selectedItem.ResponseName} onChange={(e) => { setSelectedItem((s: any) => { return { ...s, ResponseName: e.target.value } }); }} /> */}
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <label className="block mb-2 text-x">Audit Type(s)</label>
                                                    <div className="flex flex-wrap gap-3">
                                                        <div className="flex">
                                                            <Checkbox inputId="Prefund" value="Prefund" name="Prefund" onChange={onAuditTypeChange} checked={auditTypes.includes('Prefund')} />
                                                            <label htmlFor="Prefund" className="ml-2">Prefund</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Postclose" value="Postclose" name="Postclose" onChange={onAuditTypeChange} checked={auditTypes.includes('Postclose')} />
                                                            <label htmlFor="Postclose" className="ml-2">Post Close</label>
                                                        </div>
                                                        <div className="flex align-items-center">
                                                            <Checkbox inputId="Servicing" value="Servicing" name="Servicing" onChange={onAuditTypeChange} checked={auditTypes.includes('Servicing')} />
                                                            <label htmlFor="Servicing" className="ml-2">Servicing</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-2'>
                                                    <label className="block mb-3 text-x">Rules / Criteria to use this Question</label>

                                                    {checklistRulesSection}
                                                </div>
                                                <div className="col-12">
                                                    <Toolbar style={{ padding: 0 }} start={leftButtons} end={rightButtons} />
                                                </div>
                                            </div>
                                        </ScrollPanel>
                                    </Panel>
                                </div>
                        }
                    </div>
                </div>
            </Body >
        </div >
    )
}
