import { msalInstance } from "../index";

export async function authenticatedFetch(endpoint: string, options: RequestInit = {}, format: string = "json"): Promise<any> {
    try {
        // Acquire token
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) throw new Error("No accounts found");

        let scope = (process.env.REACT_APP_SCOPE === undefined) ? '' : process.env.REACT_APP_SCOPE;
        console.log(scope);

        const response = await msalInstance.acquireTokenSilent({
            scopes: [scope],
            account: accounts[0]
        });

        // Set Authorization header
        const headers = new Headers(options.headers);
        headers.append("Authorization", `Bearer ${response.accessToken}`);
//        headers.append("X-IdToken", `${response.idToken}`);

        // Make the API request with custom options
        //        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
        var REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

        const res = await fetch(`${REACT_APP_API_BASE_URL}${endpoint}`, {
            ...options,
            headers: headers,
        });
        if (!res.ok) {
            throw new Error('Network response was not ok');
        }

        //Need to get other format back. 
        if (format == "json") {         
            return res.json();
        }
        else if (format == "string"){
            return res.text(); 
        }
        else {
            return res;
        }
    } catch (error) {
        console.error('Error in authenticatedFetch:', error);
        throw error;
    }
}

export async function getAuthenticatedHeader(): Promise<any> {
    try {
        // Acquire token
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) throw new Error("No accounts found");

        let scope = (process.env.REACT_APP_SCOPE === undefined) ? '' : process.env.REACT_APP_SCOPE;
        console.log(scope);

        const response = await msalInstance.acquireTokenSilent({
            scopes: [scope],
            account: accounts[0]
        });

        // Set Authorization header

        var header = {
            Authorization: `Bearer ${response.accessToken}`,
            Token: `${response.idToken}`
        };

        return header;

        // Make the API request with custom options
        //        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
    } catch (error) {
        console.error('Error in authenticatedFetch:', error);
        throw error;
    }
}
