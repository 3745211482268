import React, { useEffect, useRef, useState } from 'react';
import Nav from "../components/Nav";
import Body from "../components/Body";
import { Card } from 'primereact/card';
import { authenticatedFetch } from '../state/apiUtils';
import { Toast } from 'primereact/toast';

export default function Reporting() {
    const [reportData, setReportData] = useState<any[]>([]);
    const toast = useRef<Toast>(null);

    useEffect(() => {
        console.log("Effect");
        loadReports();
    }, []);

    const loadReports = () => {
        authenticatedFetch(
            "/QcReports",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
               }
            })
            .then(res => {
                setReportData(res);
            })
            .catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                console.log(error);
            });
    };



    return (
        <div>
            <Nav />

            <Body>
                <h1>Reporting</h1>
                <div className='grid grid-rows-3 grid-flow-col gap-4'>
                    <div className="card" style={{ padding: "25px" }}>
                    <a href="/report/prefundauditplansubmissions" style={{textDecoration: "none"}}>
                        <Card title="Prefund Audit Plan Submissions" className="md:w-25rem">
                            <p className="m-0">
                            Prefund Audit Plan Submissions
                            </p>
                        </Card>
                        </a>
                    </div>
                    <div className="card" style={{ padding: "25px" }}>
                    <a href="/report/postcloseauditplanbysampletype" style={{textDecoration: "none"}}>
                        <Card title="Post Close Audit Plan By Sample Type" className="md:w-25rem">
                            <p className="m-0">
                            Post Close Audit Plan By Sample Type
                            </p>
                        </Card>
                        </a>
                    </div>
                    <div className="card" style={{ padding: "25px" }}>
                    <a href="/report/servicingauditplanbysampletype" style={{textDecoration: "none"}}>
                        <Card title="Servicing Audit Plan by Sample Type" className="md:w-25rem">
                            <p className="m-0">
                            Servicing Audit Plan by Sample Type
                            </p>
                        </Card>
                        </a>
                    </div>
                    <div className="card" style={{ padding: "25px" }}>
                    <a href="/report/postcloseauditplansmonthend" style={{textDecoration: "none"}}>
                        <Card title="Post Close Audit Plans Month End" className="md:w-25rem">
                            <p className="m-0">
                            Post Close Audit Plans Month End
                            </p>
                        </Card>
                        </a>
                    </div>
                    <div className="card" style={{ padding: "25px" }}>
                    <a href="/report/prefundauditplansmonthend" style={{textDecoration: "none"}}>
                        <Card title="Prefund Audit Plans Month End" className="md:w-25rem">
                            <p className="m-0">
                            Prefund Audit Plans Month End
                            </p>
                        </Card>
                        </a>
                    </div>
                </div>
            </Body>
        </div>
    )
}
