import React, { useState, useRef, useEffect } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { usePrefundAudits } from "../../state/sampleAuditsExceptionsState";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from 'axios';
import { TabView, TabPanel, TabPanelHeaderTemplateOptions } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ReactDomServer from 'react-dom/server';
import { Badge } from 'primereact/badge';
import { authenticatedFetch } from '../../state/apiUtils';
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";


export default function AuditList(props: any) {
    const toast = useRef<Toast>(null);

    const navigate = useNavigate();
    const auditPlanType = props.AuditPlanType;

    const [assignableUsers, setAssignableUsers] = useState<any[]>([]);

    const [tabSummary, setTabSummary] = useState<any>(
        {
            Unassigned: 0, Reviewing: 0, Total: 0, QCComplete: 0, QCCompleteFinding: 0, Complete: 0
        }
    );

    const [selectedFindings, setSelectedFindings] = useState<any[]>([])

    const assignPriorities = [
        "High",
        "Medium",
        "Low"
    ];
    //Temporary for now since usePrefundAudits() is not working anymore
    //const {prefundStatus, prefundData, prefundError} = usePrefundAudits();

    // const onRowClick = (e: any) => {
    //     navigate(`/AuditForm/${e.data.MonthlyFundedLoanId}?audit_plan_id=${e.data.AuditPlanId}`);
    // }


    useEffect(() => {

        getAssignableUsers();
        getTabSummary();

    }, [props.AuditPlanType]);

    const issue166Flag = (item: any) => {
        console.log(item);
        toast.current?.show({ severity: 'error', summary: 'Error: Issue 166', detail: 'Issue 166 has been detected. Please report this to the developers.', life: 6000 });
    }


    const getAssignableUsers = () => {
        authenticatedFetch(
            "/Audit/GetAssignableUsers")
            .then(res => {
                console.log('getAssignableUsers', res);
                var data = res;
                setAssignableUsers(data);
            });

    }

    const getTabSummary = () => {
        console.log('getTabSummary', `/Audit/Get${auditPlanType}Summary`)
        authenticatedFetch(
            `/Audit/Get${auditPlanType}Summary`)
            .then(res => {
                var data = res;
                setTabSummary(data);
            });

    }

    const priorityTemplate = (item: any) => {
        return (<div>
            {item.Priority} {item.PriorityOrder == 0 ? <i className="pi pi-arrow-up" style={{ color: 'red' }} /> : ""}
        </div>)
    };


    const loanTemplate = (item: any) => {
        if (item.LoanData.LoanName === null) {
            issue166Flag(item);
            return (<div>Error: Issue 166</div>)
        }
        return (<div className="linkButton"
            onClick={() => navigate(`/AuditView/${item.LoanData.MonthlyFundedLoanId}?auditReviewId=${item.AuditReviewId}`)}
        > {item.LoanData.LoanName}
        </div>)
    };

    const isQCAuditor = useHasRole(['QC Auditor']).hasRoles;

    const findingTemplate = (data: any) => {


        return (
            <div className="findingGroup" >
                <DataTable value={data.Findings} size="small" className="findingTable" dataKey="AuditFindingId" columnResizeMode="fit" scrollable


                    scrollHeight='56vh'>

                    <Column field="RecordName" header="Finding" />
                    <Column field="DefectDetail" header="Detail" />
                    <Column field="DefectCategory" header="Category" />
                    <Column field="DefectSubCategory" header="Subcategory" />
                    <Column field="DefectName" header="Defect Name" />
                    <Column field="SeverityRating" header="Severity" />
                    <Column field="CreatedBy" header="By" />
                    <Column field="CreatedDate" header="Date" />
                </DataTable>
            </div>
        );
    };

    const allowExpansion = (data: any) => {
        return data.Findings != null && data.Findings!.length > 0;
    };

    const toggleFindings: any = (state: boolean, auditData: any) => {
        if (!state) {
            return {};
            //setShowAllFindings(!showAllFindings);
        }
        else {
            console.log('toggleFindings');
            let _expandedRows: any = {};

            auditData.forEach((p: any) => {
                if (p.Findings!.length > 0)
                    _expandedRows[`${p.AuditReviewId}`] = true
                //else
                //_expandedRows[`${p.AuditReviewId}`] = false
            });


            return _expandedRows;
            //setExpandedRows(_expandedRows);
            //setExpandedRows({});
            //setShowAllFindings(!showAllFindings);

        }
    }

    const AssignAudits = (props: any) => { 

        const [assign, setAssign] = useState<boolean>(false);
        const [assignedTo, setAssignedTo] = useState<any>({
            userId: "",
            priority: "Medium"
        });

        

        const assignActions = (
            <div>
                <Button label="Cancel" icon="pi pi-times" className='smallButton p-button-text' onClick={() => setAssign(false)} />
                <Button label="Submit" icon="pi pi-check" className='smallButton' onClick={() => assignItems()} autoFocus />
            </div>
        );

        const assignItems = () => {

            let param = {
                UserId: assignedTo.userId,
                Priority: assignedTo.priority,
                AuditReviewIds: props.selectedItems.map((m:any) => m.AuditReviewId)
            }
            console.log('param', param);
            authenticatedFetch(
                "/Audit/AssignItems", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('AssignItems', res);
                    var data = res;
                    //refresh data
                    props.onSubmit();
                    
                    setAssign(false);
                    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Items has been successfully assigned', life: 3000 });

                });
        }

        function assignLabel() { 
            if (props.new) {
                return "Assign";
            }
            else 
            {
                return "Re-assign"; 
            }
        }
        return (
            <>
            <Button className='smallButton' disabled={props.selectedItems.length == 0} onClick={() => setAssign(true)}>{assignLabel()} Item(s)</Button>

                <Dialog header="QC Auditor Assignment" visible={assign} style={{ width: '400px' }} onHide={() => setAssign(false)} footer={assignActions}>
                    <div className="mt-2 grid">
                        <div className="col-12 mb-2">
                            <label className="block mb-2 text-x">{assignLabel()} Item(s) to </label>
                            <Dropdown value={assignedTo.userId} onChange={(e) => setAssignedTo((s: any) => { return { ...s, userId: e.value } })} optionLabel='FullName' optionValue='UserId' options={assignableUsers}
                                className="w-full" />
                        </div>
                        <div className="col-12 mb-2">
                            <label className="block mb-2 text-x">Priority</label>
                            <Dropdown value={assignedTo.priority} onChange={(e) => setAssignedTo((s: any) => { return { ...s, priority: e.value } })} options={assignPriorities}
                                className="w-full" />
                        </div>
                    </div>
                </Dialog>
            </>
        )

    }
    const ReviewingAudits = () => {
        const [loading, setLoading] = useState(false);
        const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

        useEffect(() => {

            getAuditData();

        }, []);

        const getAuditData = () => {

            authenticatedFetch(
                `/Audit/GetReviewing${auditPlanType}Audits`)
                .then(res => {
                    var data = res;
                    setAuditData(data);
                    let _expandedRows: any = {};
                    setExpandedRows(_expandedRows);
                    console.log('ReviewingAudits', data);
                });

        }

        const [auditData, setAuditData] = useState<any[]>([]);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);

        const onAssign = () => { 
            getAuditData();
            setSelectedItems([]);
        }
        
        const QCAuditorHeader = (
            <div className="table-header flex flex-wrap justify-content-start gap-3">
                 
                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => setExpandedRows(toggleFindings(true, auditData))} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => setExpandedRows(toggleFindings(false, auditData))} />
            </div>
        )
        const header = (
            <div className="table-header flex flex-wrap justify-content-start gap-3">
                <AssignAudits selectedItems={selectedItems} onSubmit={onAssign} />

                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => setExpandedRows(toggleFindings(true, auditData))} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => setExpandedRows(toggleFindings(false, auditData))} />
            </div>
        );

        return (
            <div>
                <DataTable value={auditData} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='56vh' emptyMessage="No Items Being Reviewed"
                    selectionMode={isQCAuditor ? null : 'multiple'} selection={selectedItems!} onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={isQCAuditor ? QCAuditorHeader : header} alwaysShowPaginator={false}

                    rowExpansionTemplate={findingTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                >
                    {isQCAuditor ?
                        <Column headerStyle={{ width: '3rem' }}></Column>
                        :
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    }
                    <Column header="See Findings" expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field={"LoanData.LoanName" === null ? "Error: Issue 166" : "LoanData.LoanName"} header="Loan Name" body={loanTemplate} />
                    {/* <Column field="LoanData.LoanName" header="Loan Name" body={loanTemplate} /> */}
                    <Column field="CurrentStatus" header="Current Status" />
                    <Column field="Priority" header="Priority" body={priorityTemplate} />
                    {/* <Column field="PriorityOrder" header="Priority Order"  /> */}
                    <Column field="QC_AssignedTo" header="QC Assigned To" />
                    <Column field="QC_AssignedDate" header="Date Assigned" />
                    <Column field="QC_StartDate" header="Date Started" />
                    <Column field="LoanData.FundingDate" header="Funding Date" />
                    <Column field="LoanData.LoanAmount" header="Loan Amount" />
                    <Column field="LoanData.LoanType" header="Loan Type" />
                    <Column field="LoanData.Investor" header="Investor" />
                </DataTable>
            </div>
        );


    }


    const QCCompletedAudits = () => {
        const [loading, setLoading] = useState(false);
        const [showConfirm, setShowConfirm] = useState<boolean>(false);

        useEffect(() => {

            getAuditData();

        }, []);

        const getAuditData = () => {

            authenticatedFetch(
                `/Audit/GetQCCompleted${auditPlanType}Audits`)
                .then(res => {
                    var data = res;
                    setAuditData(data);

                });

        }
        const completeItems = () => {

            let param = {

                AuditReviewIds: selectedItems.map(m => m.AuditReviewId)
            }
            console.log('param', param);
            authenticatedFetch(
                "/Audit/SetComplete", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('CompleteItems', res);
                    var data = res;
                    //refresh data
                    getAuditData();
                    setShowConfirm(false);
                    setSelectedItems([]);
                    getTabSummary();
                    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Items has been successfully set to Completed', life: 3000 });

                });
        }

        const completeActions = (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setShowConfirm(false)} className="p-button-text" />
                <Button label="OK" icon="pi pi-check" onClick={() => completeItems()} autoFocus />
            </div>
        );

        const [auditData, setAuditData] = useState<any[]>([]);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);
        const QCAuditorHeader = (
            <div className="table-header">
            </div>
        )
        const header = (
            <div className="table-header">
                <Button className='smallButton' disabled={selectedItems.length == 0} onClick={() => setShowConfirm(true)}>Set Selected Records to Completed</Button>
                <Dialog header="Complete Items" visible={showConfirm} style={{ width: '400px' }} onHide={() => setShowConfirm(false)} footer={completeActions}>

                    <div className="col-12 mb-2">
                        <label className="block mb-2 text-x">Do you want to set selected audit plans to complete? </label>
                    </div>
                </Dialog>
            </div>
        );

        return (
            <div>
                <DataTable value={auditData} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='56vh' emptyMessage="No Items Being Reviewed"
                    selectionMode={isQCAuditor ? null : 'multiple'} selection={selectedItems!} onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={isQCAuditor ? QCAuditorHeader : header} alwaysShowPaginator={false}

                >
                    {isQCAuditor ?
                        <Column headerStyle={{ width: '3rem' }}></Column>
                        :
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    }
                    <Column field={"LoanData.LoanName" === null ? "Error: Issue 166" : "LoanData.LoanName"} header="Loan Name" body={loanTemplate} />
                    {/* <Column field="LoanData.LoanName" header="Loan Name" body={loanTemplate} /> */}
                    <Column field="CurrentStatus" header="Current Status" />
                    <Column field="Priority" header="Priority" body={priorityTemplate} />
                    {/* <Column field="PriorityOrder" header="Priority Order"  /> */}
                    <Column field="QC_AssignedTo" header="QC Assigned To" />
                    <Column field="QC_AssignedDate" header="Assigned" />
                    <Column field="QC_StartDate" header="QC Started" />
                    <Column field="QC_CompleteDate" header="QC Completed" />
                    <Column field="LoanData.FundingDate" header="Funding Date" />
                    <Column field="LoanData.LoanAmount" header="Loan Amount" />
                    <Column field="LoanData.LoanType" header="Loan Type" />
                    <Column field="LoanData.Investor" header="Investor" />
                </DataTable>
            </div>
        );


    }


    const QCCompletedFindingAudits = () => {
        const [loading, setLoading] = useState(false);
        const [showConfirm, setShowConfirm] = useState<boolean>(false);
        const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

        useEffect(() => {

            getAuditData();

        }, []);

        const getAuditData = () => {

            authenticatedFetch(
                `/Audit/GetQCCompletedFinding${auditPlanType}Audits`)
                .then(res => {
                    var data = res;
                    setAuditData(data);
                });

        }

        const completeItems = () => {

            let param = {

                AuditReviewIds: selectedItems.map(m => m.AuditReviewId)
            }
            console.log('param', param);
            authenticatedFetch(
                "/Audit/SetComplete", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('CompleteItems', res);
                    var data = res;
                    //refresh data
                    getAuditData();
                    setShowConfirm(false);
                    setSelectedItems([]);
                    getTabSummary();
                    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Items has been successfully set to Completed', life: 3000 });

                });
        }
        const assignActions = (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setShowConfirm(false)} className="p-button-text" />
                <Button label="OK" icon="pi pi-check" onClick={() => completeItems()} autoFocus />
            </div>
        );
        const [auditData, setAuditData] = useState<any[]>([]);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);
        const QCAuditorHeader = (
            <div className="table-header">

            </div>
        )
        const header = (
            <div className="table-header">
                <Button className='smallButton' disabled={selectedItems.length == 0} onClick={() => setShowConfirm(true)}>Set Selected Records to Completed</Button>
                <Dialog header="Complete Items" visible={showConfirm} style={{ width: '400px' }} onHide={() => setShowConfirm(false)} footer={assignActions}>

                    <div className="col-12 mb-2">
                        <label className="block mb-2 text-x">Do you want to set selected records to complete? </label>
                    </div>
                </Dialog>
            </div>
        );


        return (
            <div>
                <DataTable value={auditData} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='56vh' emptyMessage="No Items Being Reviewed"
                    selectionMode={isQCAuditor ? null : 'multiple'} selection={selectedItems!} onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={isQCAuditor ? QCAuditorHeader : header} alwaysShowPaginator={false}
                    rowExpansionTemplate={findingTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                >
                    {isQCAuditor ?
                        <Column headerStyle={{ width: '3rem' }}></Column>
                        :
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    }
                    <Column header="See Findings" expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field={"LoanData.LoanName" === null ? "Error: Issue 166" : "LoanData.LoanName"} header="Loan Name" body={loanTemplate} />
                    {/* <Column field="LoanData.LoanName" header="Loan Name" body={loanTemplate} /> */}
                    <Column field="CurrentStatus" header="Current Status" />
                    <Column field="Priority" header="Priority" body={priorityTemplate} />
                    {/* <Column field="PriorityOrder" header="Priority Order"  /> */}
                    <Column field="QC_AssignedTo" header="QC Assigned To" />
                    <Column field="QC_AssignedDate" header="Assigned" />
                    <Column field="QC_StartDate" header="QC Started" />
                    <Column field="QC_CompleteDate" header="QC Completed" />
                    <Column field="LoanData.FundingDate" header="Funding Date" />
                    <Column field="LoanData.LoanAmount" header="Loan Amount" />
                    <Column field="LoanData.LoanType" header="Loan Type" />
                    <Column field="LoanData.Investor" header="Investor" />
                </DataTable>
            </div>
        );


    }


    const UnassignedAudits = () => {

        const [auditData, setAuditData] = useState<any[]>([]);
        const [loading, setLoading] = useState(true);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);

        const [pageData, setPageData] = useState<any>(
            {
                pageSize: 20, index: 0, auditPlanType: auditPlanType
            }
        );


        useEffect(() => {
            console.log('useEffect UnassignedAudits');
            getAuditData();
        }, [pageData]);

        const getAuditData = () => {

            var param = pageData;
            authenticatedFetch(
                `/Audit/GetOpen${auditPlanType}Audits`, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('UnassignedAudits', res);
                    var data = res;
                    setAuditData(data);
                    setLoading(false);
                });

        }


        const onPage = (event: any) => {
            var param: any = {
                index: event.first,
                pageSize: event.rows,
                auditPlanType: auditPlanType
            }
            setPageData(param);
        };


        const onAssign = () => { 
            getAuditData();
            setSelectedItems([]);
            getTabSummary();
        }
        
        

        const QCAuditorHeader = (
            <div className="table-header">
            </div>
        )

        const header = (
            <div className="table-header">
                <AssignAudits selectedItems={selectedItems} onSubmit={onAssign} new={true} />
            </div>
        );

        return (
            <div>
                <DataTable value={auditData} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='56vh' emptyMessage="No Unassigned Items"
                    lazy selectionMode={isQCAuditor ? null : 'multiple'}
                    selection={selectedItems!} onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={isQCAuditor ? QCAuditorHeader : header}
                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    onPage={onPage} first={pageData.index} alwaysShowPaginator={true}
                    totalRecords={tabSummary.Unassigned}
                    scrollable
                >
                    {isQCAuditor ?
                        <Column headerStyle={{ width: '3rem' }}></Column>
                        :
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    }
                    <Column field={"LoanData.LoanName" === null ? "Error: Issue 166" : "LoanData.LoanName"} header="Loan Name" body={loanTemplate} />
                    {/* <Column field="LoanData.LoanName" header="Loan Name" body={loanTemplate} /> */}
                    <Column field="OpenDate" header="Date Added" />
                    {auditPlanType == "Postclose" ?
                        <Column field="LoanData.ClosingDate" header="Closing Date" />
                        :
                        <Column field="LoanData.FundingDate" header="Funding Date" />

                    }
                    <Column field="LoanData.LoanAmount" header="Loan Amount" />
                    <Column field="LoanData.LoanType" header="Loan Type" />
                    <Column field="LoanData.Investor" header="Investor" />
                    <Column field="LoanData.LoanSelection" header="Loan Selection" />
                </DataTable>
            </div>
        );


    }

    const CompletedAudits = () => {
        const [loading, setLoading] = useState(false);
        const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

        useEffect(() => {

            getAuditData();

        }, []);

        const getAuditData = () => {

            authenticatedFetch(
                `/Audit/GetCompleted${auditPlanType}Audits`)
                .then(res => {
                    var data = res;
                    setAuditData(data);
                    let _expandedRows: any = {};
                    setExpandedRows(_expandedRows);
                    console.log('ReviewingAudits', data);
                });

        }

        const [auditData, setAuditData] = useState<any[]>([]);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);
        const header = (
            <div className="table-header flex flex-wrap justify-content-start gap-3">

                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => setExpandedRows(toggleFindings(true, auditData))} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => setExpandedRows(toggleFindings(false, auditData))} />

            </div>
        );


        return (
            <div>
                <DataTable value={auditData} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='56vh' emptyMessage="No Items Being Reviewed"
                    selectionMode={isQCAuditor ? null : 'multiple'} selection={selectedItems!} onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={header} alwaysShowPaginator={false}

                    rowExpansionTemplate={findingTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                >
                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                    <Column header="See Findings" expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field={"LoanData.LoanName" === null ? "Error: Issue 166" : "LoanData.LoanName"} header="Loan Name" body={loanTemplate} />
                    {/* <Column field="LoanData.LoanName" header="Loan Name" body={loanTemplate} /> */}
                    <Column field="CurrentStatus" header="Current Status" />
                    <Column field="Priority" header="Priority" body={priorityTemplate} />
                    {/* <Column field="PriorityOrder" header="Priority Order"  /> */}
                    <Column field="QC_AssignedTo" header="QC Assigned To" />
                    <Column field="QC_AssignedDate" header="Date Assigned" />
                    <Column field="QC_StartDate" header="Date Started" />
                    <Column field="LoanData.FundingDate" header="Funding Date" />
                    <Column field="LoanData.LoanAmount" header="Loan Amount" />
                    <Column field="LoanData.LoanType" header="Loan Type" />
                    <Column field="LoanData.Investor" header="Investor" />
                </DataTable>
            </div>
        );


    }

    const QCValidatingAudits = () => {
        const [loading, setLoading] = useState(false);
        const [showConfirm, setShowConfirm] = useState<boolean>(false);
        const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
        const [auditData, setAuditData] = useState<any[]>([]);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);
       

        useEffect(() => {

            getAuditData();

        }, []);

        
        const onAssign = () => { 
            getAuditData();
            setSelectedItems([]);
            getTabSummary();
        }

        const getAuditData = () => {

            authenticatedFetch(
                `/Audit/GetValidating${auditPlanType}Audits`)
                .then(res => {
                    var data = res;
                    setAuditData(data);
                });

        }

 
        const QCAuditorHeader = (
            <div className="table-header flex flex-wrap justify-content-start gap-3">
                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => setExpandedRows(toggleFindings(true, auditData))} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => setExpandedRows(toggleFindings(false, auditData))} />

            </div>
        )
        const header = (
            <div className="table-header flex flex-wrap justify-content-start gap-3">
                <AssignAudits selectedItems={selectedItems} onSubmit={onAssign} />

                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => setExpandedRows(toggleFindings(true, auditData))} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => setExpandedRows(toggleFindings(false, auditData))} />

            </div>
        );
        return (
            <div>
                <DataTable value={auditData} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='56vh' emptyMessage="No Items Being Reviewed"
                    selectionMode={isQCAuditor ? null : 'multiple'} selection={selectedItems!} onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={isQCAuditor ? QCAuditorHeader : header} alwaysShowPaginator={false}
                    rowExpansionTemplate={findingTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                >
                    {isQCAuditor ?
                        <Column headerStyle={{ width: '3rem' }}></Column>
                        :
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    }
                    <Column header="See Findings" expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field={"LoanData.LoanName" === null ? "Error: Issue 166" : "LoanData.LoanName"} header="Loan Name" body={loanTemplate} />
                    {/* <Column field="LoanData.LoanName" header="Loan Name" body={loanTemplate} /> */}
                    <Column field="CurrentStatus" header="Current Status" />
                    <Column field="Priority" header="Priority" body={priorityTemplate} />
                    {/* <Column field="PriorityOrder" header="Priority Order"  /> */}
                    <Column field="QC_AssignedTo" header="QC Assigned To" />
                    <Column field="QC_AssignedDate" header="Assigned" />
                    <Column field="QC_StartDate" header="QC Started" />
                    <Column field="QC_CompleteDate" header="QC Completed" />
                    <Column field="LoanData.FundingDate" header="Funding Date" />
                    <Column field="LoanData.LoanAmount" header="Loan Amount" />
                    <Column field="LoanData.LoanType" header="Loan Type" />
                    <Column field="LoanData.Investor" header="Investor" />
                </DataTable>
            </div>
        );

    }



    const ValidationCompleteAudits = () => {
        const [loading, setLoading] = useState(false);
        const [showConfirm, setShowConfirm] = useState<boolean>(false);
        const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
        const [auditData, setAuditData] = useState<any[]>([]);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);

        const completeItems = () => {

            let param = {

                AuditReviewIds: selectedItems.map(m => m.AuditReviewId)
            }
            console.log('param', param);
            authenticatedFetch(
                "/Audit/SetComplete", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    console.log('CompleteItems', res);
                    var data = res;
                    //refresh data
                    getAuditData();
                    setShowConfirm(false);
                    setSelectedItems([]);
                    getTabSummary();
                    toast.current?.show({ severity: 'success', summary: 'Confirmed', detail: 'Items has been successfully set to Completed', life: 3000 });

                });
        }
        const QCAuditorHeader = (
            <div className="table-header flex flex-wrap justify-content-start gap-3">
                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => setExpandedRows(toggleFindings(true, auditData))} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => setExpandedRows(toggleFindings(false, auditData))} />

            </div>
        )

        const completeActions = (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setShowConfirm(false)} className="p-button-text" />
                <Button label="OK" icon="pi pi-check" onClick={() => completeItems()} autoFocus />
            </div>
        );

        const header = (
            <div className="table-header flex flex-wrap justify-content-start gap-3">

                <Button className='smallButton' disabled={selectedItems.length == 0} onClick={() => setShowConfirm(true)}>Set Selected Records to Completed</Button>
                <Dialog header="Complete Items" visible={showConfirm} style={{ width: '400px' }} onHide={() => setShowConfirm(false)} footer={completeActions}>

                    <div className="col-12 mb-2">
                        <label className="block mb-2 text-x">Do you want to set selected audit plans to complete? </label>
                    </div>
                </Dialog>

                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => setExpandedRows(toggleFindings(true, auditData))} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => setExpandedRows(toggleFindings(false, auditData))} />

            </div>
        );
        useEffect(() => {

            getAuditData();

        }, []);

        const getAuditData = () => {

            authenticatedFetch(
                `/Audit/GetValidationComplete${auditPlanType}Audits`)
                .then(res => {
                    var data = res;
                    setAuditData(data);
                });

        }


        return (
            <div>
                <DataTable value={auditData} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='56vh' emptyMessage="No Items Being Reviewed"
                    selectionMode={isQCAuditor ? null : 'multiple'} selection={selectedItems!} onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={isQCAuditor ? QCAuditorHeader : header} alwaysShowPaginator={false}
                    rowExpansionTemplate={findingTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                >
                    {isQCAuditor ?
                        <Column headerStyle={{ width: '3rem' }}></Column>
                        :
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    }
                    <Column header="See Findings" expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field={"LoanData.LoanName" === null ? "Error: Issue 166" : "LoanData.LoanName"} header="Loan Name" body={loanTemplate} />
                    {/* <Column field="LoanData.LoanName" header="Loan Name"  body={loanTemplate} /> */}
                    <Column field="CurrentStatus" header="Current Status" />
                    <Column field="Priority" header="Priority" body={priorityTemplate} />
                    {/* <Column field="PriorityOrder" header="Priority Order"  /> */}
                    <Column field="QC_AssignedTo" header="QC Assigned To" />
                    <Column field="QC_AssignedDate" header="Assigned" />
                    <Column field="QC_StartDate" header="QC Started" />
                    <Column field="QC_CompleteDate" header="QC Completed" />
                    <Column field="LoanData.FundingDate" header="Funding Date" />
                    <Column field="LoanData.LoanAmount" header="Loan Amount" />
                    <Column field="LoanData.LoanType" header="Loan Type" />
                    <Column field="LoanData.Investor" header="Investor" />
                </DataTable>
            </div>
        );
    }

    const AllAudits = () => {
        const [loading, setLoading] = useState(false);
        const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
        const [auditData, setAuditData] = useState<any[]>([]);
        const [selectedItems, setSelectedItems] = useState<any[]>([]);

        const [pageData, setPageData] = useState<any>(
            {
                pageSize: 20, index: 0, auditPlanType: auditPlanType
            }
        );

        useEffect(() => {

            getAuditData();

        }, [pageData]);

        const onPage = (event: any) => {
            var param: any = {
                index: event.first,
                pageSize: event.rows,
                auditPlanType: auditPlanType
            }
            setPageData(param);
        };

        const getAuditData = () => {

            var param = pageData;
            authenticatedFetch(
                `/Audit/GetAll${auditPlanType}Audits`, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    var data = res;
                    setAuditData(data);
                    let _expandedRows: any = {};
                    setExpandedRows(_expandedRows);
                    console.log('GetAllAudits', data);
                });

        }

        const header = (
            <div className="table-header flex flex-wrap justify-content-start gap-3">

                <Button icon="pi pi-plus" className='smallButton' label="Expand All Finding" onClick={() => setExpandedRows(toggleFindings(true, auditData))} />
                <Button icon="pi pi-minus" className='smallButton' label="Collapse All Finding" onClick={() => setExpandedRows(toggleFindings(false, auditData))} />

            </div>
        );


        return (
            <div>
                <DataTable value={auditData} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditReviewId" loading={loading} scrollHeight='56vh' emptyMessage="No Items"
                    lazy selectionMode={isQCAuditor ? null : 'multiple'} selection={selectedItems!} onSelectionChange={(e: any) => setSelectedItems(e.value)}
                    header={header} totalRecords={tabSummary.Total}

                    onPage={onPage} first={pageData.index} alwaysShowPaginator={true} scrollable

                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"

                    rowExpansionTemplate={findingTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                >
                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                    <Column header="See Findings" expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field={"LoanData.LoanName" === null ? "Error: Issue 166" : "LoanData.LoanName"} header="Loan Name" body={loanTemplate} />
                    {/* <Column field="LoanData.LoanName" header="Loan Name" body={loanTemplate} /> */}
                    <Column field="CurrentStatus" header="Current Status" />
                    {/* <Column field="PriorityOrder" header="Priority Order"  /> */}
                    <Column field="QC_AssignedTo" header="QC Assigned To" />
                    <Column field="QC_AssignedDate" header="Date Assigned" />
                    <Column field="QC_StartDate" header="Date Started" />
                    <Column field="LoanData.FundingDate" header="Funding Date" />
                    <Column field="LoanData.LoanAmount" header="Loan Amount" />
                    <Column field="LoanData.LoanType" header="Loan Type" />
                    <Column field="LoanData.Investor" header="Investor" />
                </DataTable>
            </div>
        );


    }

    const tabHeader = (options: TabPanelHeaderTemplateOptions, header: string, subHeader: string, detail: string) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className} style={{ borderWidth: "0px", marginLeft: "1rem" }}>
                <div className="block font-bold">
                    <Badge value={detail ? detail : 0} className={"mb-2 " + (detail == undefined || detail == '0' ? ' badge-zero-value ' : '')}></Badge><br />{header}<br />
                    {subHeader}

                    {/* {subHeader ? subHeader : '\u00A0'}
                    <div className="block mt-2">{detail} item(s)</div> */}
                </div>
            </button>
        )
    }

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let { hasRoles, isLoading } = useHasRole(['Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager', 'QC Auditor', 'System Administrator'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div>
            <Nav />

            <Body>
                <div className='auditTable'>
                    <Toast ref={toast} position="bottom-center" />

                    <h1>{auditPlanType} Audits</h1>
                    <TabView>
                        <TabPanel className="col" headerTemplate={(o) => tabHeader(o, "Unassigned", "", tabSummary.Unassigned)}>
                            <UnassignedAudits />
                        </TabPanel>
                        <TabPanel className="col" headerTemplate={(o) => tabHeader(o, "Currently", "Reviewing", tabSummary.Reviewing)} >
                            <ReviewingAudits />
                        </TabPanel>
                        <TabPanel className="col" headerTemplate={(o) => tabHeader(o, "QC Complete", "No Finding", tabSummary.QCComplete)}>
                            <QCCompletedAudits />
                        </TabPanel>
                        <TabPanel className="col" headerTemplate={(o) => tabHeader(o, "QC Complete", "With Findings", tabSummary.QCCompleteFinding)}>
                            <QCCompletedFindingAudits />
                        </TabPanel>
                        <TabPanel className="col" headerTemplate={(o) => tabHeader(o, "Currently", "Validating", tabSummary.Valdating)}>
                            <QCValidatingAudits />
                        </TabPanel>
                        <TabPanel className="col" headerTemplate={(o) => tabHeader(o, "Validation", "Complete", tabSummary.ValidationComplete)}>
                            <ValidationCompleteAudits />
                        </TabPanel>
                        <TabPanel className="col" headerTemplate={(o) => tabHeader(o, "Completed", "", tabSummary.Complete)}>
                            <CompletedAudits />
                        </TabPanel>
                        <TabPanel className="col" headerTemplate={(o) => tabHeader(o, "All", "Plans", tabSummary.Total)}>
                            <AllAudits />
                        </TabPanel>
                    </TabView>
                </div>
            </Body>
        </div>
    )
}
