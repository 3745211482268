import React from 'react';
import Nav from "../components/Nav";
import Body from "../components/Body";
import useHasRole from "../hooks/validRolesHook";
import NotFound from "./Global/404NotFound";
import Loading from "./Global/loading";

export default function Analytics() {

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    // let {hasRoles, isLoading} = useHasRole(['Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager', 'System Administrator'])
    let {hasRoles, isLoading} = useHasRole(['']) // Blocking access to this page for now. Not available in MVP.

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div>
            <Nav />

            <Body>
                <h1>Analytics</h1>
            </Body>
        </div>
    )
}
