import React, { useState, useRef, useEffect } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { DataTable, DataTableExpandedRows, DataTableRowEvent, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { usePrefundAudits } from "../../state/sampleAuditsExceptionsState";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from 'axios';
import { TabView, TabPanel, TabPanelHeaderTemplateOptions } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ReactDomServer from 'react-dom/server';
import { Badge } from 'primereact/badge';
import { authenticatedFetch } from '../../state/apiUtils';
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";


export default function ValidationList(props: any) {
    const toast = useRef<Toast>(null);

    const navigate = useNavigate();
    const auditPlanType = props.AuditPlanType;

    const [assignableUsers, setAssignableUsers] = useState<any[]>([]);

    const [tabSummary, setTabSummary] = useState<any[]>([]);


    const assignPriorities = [
        "High",
        "Medium",
        "Low"
    ];
    //Temporary for now since usePrefundAudits() is not working anymore
    //const {prefundStatus, prefundData, prefundError} = usePrefundAudits();

    // const onRowClick = (e: any) => {
    //     navigate(`/AuditForm/${e.data.MonthlyFundedLoanId}?audit_plan_id=${e.data.AuditPlanId}`);
    // }


    useEffect(() => {

        getTabSummary();

    }, [props.AuditPlanType]);


    const getTabSummary = () => {
        console.log('getTabSummary', `/Finding/GetValidatorSummary`)
        authenticatedFetch(
            `/Finding/GetValidatorSummary`)
            .then(res => {
                var data = res;
                setTabSummary(data);
            });

    }

    const priorityTemplate = (item: any) => {
        return (<div>
            {item.Priority} {item.PriorityOrder == 0 ? <i className="pi pi-arrow-up" style={{ color: 'red' }} /> : ""}
        </div>)
    };


    const loanTemplate = (item: any) => {
        return (<div className="linkButton"
            onClick={() => navigate(`/AuditView/${item.LoanData.MonthlyFundedLoanId}?auditPlanType=${auditPlanType}&auditReviewId=${item.AuditReviewId}`)}
        > {item.LoanData.LoanName}
        </div>)
    };

    const findingTemplate = (data: any) => {
        return (
            <div className="findingGroup" >
                <DataTable value={data.Findings} size="small" className="findingTable" columnResizeMode="fit" scrollable selectionMode="single">
                    <Column field="RecordName" header="Finding" />
                    <Column field="DefectDetail" header="Detail" />
                    <Column field="DefectCategory" header="Category" />
                    <Column field="DefectSubCategory" header="Subcategory" />
                    <Column field="DefectName" header="Defect Name" />
                    <Column field="SeverityRating" header="Severity" />
                    <Column field="CreatedBy" header="By" />
                    <Column field="CreatedDate" header="Date" />
                </DataTable>
            </div>
        );
    };

    const allowExpansion = (data: any) => {
        return data.Findings != null && data.Findings!.length > 0;
    };

    const toggleFindings: any = (state: boolean, auditData: any) => {
        if (!state) {
            return {};
            //setShowAllFindings(!showAllFindings);
        }
        else {
            console.log('toggleFindings');
            let _expandedRows: any = {};

            auditData.forEach((p: any) => {
                if (p.Findings!.length > 0)
                    _expandedRows[`${p.AuditReviewId}`] = true
                //else
                //_expandedRows[`${p.AuditReviewId}`] = false
            });


            return _expandedRows;
            //setExpandedRows(_expandedRows);
            //setExpandedRows({});
            //setShowAllFindings(!showAllFindings);

        }
    }


    const ValidationItems = (props: any) => {
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState<any[]>([]);

        useEffect(() => {

            getData();

        }, []);

        const auditFindingView = (item: any) => {
            return (<div className="linkButton"
                onClick={() => navigate(`/AuditForm/${item.LoanData.MonthlyFundedLoanId}?auditReviewId=${item.AuditReviewId}&auditFindingId=${item.AuditFindingId}&Role=QC VALIDATOR`)}
            > {item.RecordName}
            </div>)
        };

        const getData = () => {

            var param = {
                AreaResponsibleId: props.AreaResponsibleId
            }
            authenticatedFetch(
                `/Finding/GetValidatorItems`, {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    var data = res;
                    setData(data);
                    console.log('GetValidatorItems', data);
                });

        }



        return (
            <div>
                <DataTable value={data} sortField="PriorityOrder" sortOrder={1} paginator rows={20} dataKey="AuditFindingId" loading={loading} scrollHeight='56vh' emptyMessage="No Items Being Reviewed"

                    alwaysShowPaginator={false}


                >
                    <Column field="AuditPlanType" header="Audit Plan" />
                    <Column field="RecordName" header="Name" body={auditFindingView} />
                    <Column field="DateCreated" header="Created Date" />
                    <Column field="DefectDetail" header="Detail" />
                    <Column field="SeverityRating" header="Severity" />
                    <Column field="DefectName" header="Defect Name" />
                    <Column field="DefectCategory" header="Defect Category" />
                    <Column field="DefectSubCategory" header="Defect Sub Category" />
                    <Column field="QuestionText" header="Question" />
                    <Column field="QuestionNumber" header="Question Number" />
                </DataTable>
            </div>
        );


    }



    const tabHeader = (options: TabPanelHeaderTemplateOptions, header: string, subHeader: string, item: number, itemsHigh: number) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className} style={{ borderWidth: "0px", marginLeft: "1rem" }}>
                <div className="block font-bold">
                    {item > 0 || (item == 0 && itemsHigh == 0) ? <Badge value={item ? item : 0} className={"mb-2 " + (item == undefined || item == 0 ? ' badge-blank ' : '')}></Badge> : ""}
                    {itemsHigh > 0 ? <Badge value={itemsHigh} className={"mb-2 ml-1"} severity="danger"></Badge> : ""}
                    <br />{header}<br />
                    {subHeader}

                    {/* {subHeader ? subHeader : '\u00A0'}
                    <div className="block mt-2">{detail} item(s)</div> */}
                </div>
            </button>
        )
    }

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let { hasRoles, isLoading } = useHasRole(['System Administrator', 'QC Validator', 'QC Admin'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    return (
        <div>
            <Nav />

            <Body>
                <div className='auditTable'>
                    <Toast ref={toast} position="bottom-center" />

                    <h1>Validation List</h1>
                    {
                        tabSummary.length == 0 ? <div className="text-lg">
                            <p>
                            You have no Responsible Areas to validate. If you believe this is an issue, please contact your QC Manager regarding this issue. 
                            </p></div>
                    :
                    
                    <TabView>
                        {
                            tabSummary.map((m: any) => {
                                return (
                                    <TabPanel className="col" headerTemplate={(o) => tabHeader(o, m.AreaResponsibleName, "", m.MediumPriorityCount + m.LowPriorityCount, m.HighPriorityCount)}>
                                        <ValidationItems AreaResponsibleId={m.AreaResponsibleId} />
                                    </TabPanel>
                                )
                            })
                        }

                    </TabView>
                    }
                </div>

            </Body>
        </div>
    )
}
