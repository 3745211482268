import React, { useState, useEffect, useRef } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { User, useUsers } from "../../state/userState";
import { useRoles } from "../../state/rolesState";
import { useAccounts } from "../../state/accountState";
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';

export default function Users() {
    const navigate = useNavigate();
    const { usersStatus, usersData, usersError } = useUsers();
    const { rolesData, rolesError } = useRoles();
    const { accountsData, accountsError } = useAccounts();

    const toast = useRef<Toast>(null);
    const op = useRef<OverlayPanel>(null);
    const _opMsg = useRef("");
    const [_opMsgState, set_opMsgState] = useState(_opMsg.current);

    useEffect(() => {
        if (usersError || rolesError || accountsError) {
            // alert('Error saving user');
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'There was a server error while saving the user. Please try again later.', life: 5000 });
        }
    }, [usersError, rolesError, accountsError]);

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let {hasRoles, isLoading} = useHasRole(['Account Administrator', 'System Administrator', 'Account Manager'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    const onRowClick = (e: any) => {
        navigate(`/users/${e.data.UserId}`);
    }

    const header = (
        <div className="flex justify-content-between">
            <h1>Users</h1>
            <Button label="Add User" icon="pi pi-plus" onClick={() => navigate('/users/new')} />
        </div>
    );

    return (
        <div>
            <Nav />

            <Body>
                <div>
                    <OverlayPanel ref={op}>
                        <div>{_opMsg.current}</div>
                    </OverlayPanel>
                    <Toast ref={toast} position="bottom-center" />

                    <DataTable value={usersData} paginator rows={20} dataKey="id" loading={usersStatus === 'pending'}
                        emptyMessage="No users found" alwaysShowPaginator={false} selectionMode="single"
                        header={header}
                        onRowSelect={onRowClick}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        scrollHeight='55.6vh'
                    >
                        <Column field="FirstName" header="First Name" sortable />
                        <Column field="LastName" header="Last Name" sortable />
                        <Column field="Email" header="Email" sortable />
                        <Column field="Phone" header="Phone" sortable />
                        <Column
                            field="Role"
                            header="Role"
                            body={(rowData: User) => rolesData.find(role => role.RoleId === rowData.UserRoleIds[0])?.RoleName}
                        />
                        <Column
                            field="Account"
                            header="Account"
                            body={(rowData: User) => accountsData.find(account => account.AccountId === rowData.AccountId)?.AccountName}
                        />
                    </DataTable>
                </div>
            </Body>
        </div>
    )
}
