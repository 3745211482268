import React, { useRef, useState } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useReport } from '../../state/reportsState';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { authenticatedFetch } from "../../state/apiUtils";
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';

export default function PostCloseAuditPlanBySampleType() {
    const reportTitle = "Post Close Audit Plan By Sample Type";
    const dt = useRef(null);
    //const {reportStatus, reportData} = useReport("postcloseauditplanbysampletype");
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [globalFilter, setGlobalFilter] = useState(null);
    const [dataInCSV, setDataInCSV] = useState("");
    const [reportData, setReportData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const pageSizeOptions: number[] = [100, 250,500,1000];
    const [pageSize, setPageSize] = useState(100);
    const [page, setPage] = useState(1);
    const toast = useRef<Toast>(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 100,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            AccountName: { value: '', matchMode: 'contains' },
            PlanName: { value: '', matchMode: 'contains' },
            ClientBankName: { value: '', matchMode: 'contains' },
            LoanNumber: { value: '', matchMode: 'contains' },
            BorrowersFirstName: { value: '', matchMode: 'contains' },
            BorrowersLastName: { value: '', matchMode: 'contains' },
            SampleSize: { value: '', matchMode: 'contains' },
            AuditPlanStatus: { value: '', matchMode: 'contains' }
        }
    });

    const reportFilter = {
        startDate: '',
        endDate: '',
        page: page,
        pageSize: pageSize
    };

    const cols = [
        { field: 'AccountName', header: 'Account', width: '15rem' },
        { field: 'PlanName', header: 'Plan', width: '20rem' },
        { field: 'ClientBankName', header: 'Client Bank', width: '20rem' },
        { field: 'CreatedDate', header: 'CreateDate', width: '13rem' },
        { field: 'LoanNumber', header: 'Loan Number', width: '13rem' },
        { field: 'BorrowersFirstName', header: 'Borrowers First Name', width: '13rem' },
        { field: 'BorrowersLastName', header: 'Borrowers Last Name', width: '13rem' },
        { field: 'SampleSize', header: 'Sample Size', width: '13rem' },
        { field: 'AuditPlanStatus', header: 'Audit Plan Status', width: '13rem' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        AccountName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PlanName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ClientBankName: { value: null, matchMode: FilterMatchMode.CONTAINS  },
        LoanNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        BorrowersFirstName: { value: null, matchMode: FilterMatchMode.EQUALS },
        BorrowersLastName: { value: null, matchMode: FilterMatchMode.EQUALS },
        SampleSize: { value: null, matchMode: FilterMatchMode.EQUALS },
        AuditPlanStatus: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const loadReport = () => {
        reportCount();
        setLoading(true);

        if (endDate)
            reportFilter.endDate = endDate;
        
        if (startDate)
            reportFilter.startDate = startDate;

        if (lazyState.page <= 0) {
            setPage(1);
        } else {
            setPage(lazyState.page+1);
        }

        reportFilter.page = page;
        reportFilter.pageSize = pageSize;

        authenticatedFetch(
            "/QcReports/postcloseauditplanbysampletype",
            {
                method: "POST",
                body: JSON.stringify(reportFilter),
                headers: {
                    "Content-Type": "application/json",
               }
            })
            .then(res => {
                setReportData(res);
                setLoading(false);
            })
            .catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                console.log(error);
            });
    };

    const reportCount = () => {
        if (endDate)
            reportFilter.endDate = endDate;
        
        if (startDate)
            reportFilter.startDate = startDate;

        authenticatedFetch(
            "/QcReports/postcloseauditplanbysampletype/count",
            {
                method: "POST",
                body: JSON.stringify(reportFilter),
                headers: {
                    "Content-Type": "application/json",
               }
            })
            .then(res => {
                setTotalRecords(res);
            })
            .catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                console.log(error);
            });
    };

    const exportCSV = (selectionOnly: boolean) => {
        setLoading(true);
        authenticatedFetch(
            "/QcReports/postcloseauditplanbysampletype/csv",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
               }
            })
            .then(res => {
                setDataInCSV(res.data);
                setLoading(false);
                const element = document.createElement('a');
                const file = new Blob([dataInCSV], { type: 'text/csv' });
                element.href = URL.createObjectURL(file);
                element.download = 'data.csv';
                document.body.appendChild(element);
                element.click();
            })
            .catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                console.log(error);
            });
    };

    const handleChangeStart = (event: any) => {
        setStartDate(event.target.value);
    }

    const handleChangeEnd = (event: any) => {
        setEndDate(event.target.value);
    }

    const onPage = (event: any) => {
        setlazyState(event);
        loadReport();
    };

    const onGlobalFilterChange = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            Start Date:
            <Calendar value={startDate} onChange={handleChangeStart} />
            End Date:
            <Calendar value={endDate} onChange={handleChangeEnd} />
            <Button type="button" onClick={() => loadReport()}>Submit</Button>
            <div>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
            </div>
        </div>
    );

    return (
        <div>
            <Nav />
            <Body>
            <h1 className="m-0">{reportTitle}</h1>
            <DataTable ref={dt} value={reportData} tableStyle={{ minWidth: '25rem' }}
                    header={header} lazy onPage={onPage} loading={loading}
                    first={lazyState.first} totalRecords={totalRecords}
                    filters={filters} filterDisplay="row" showGridlines stripedRows
                    paginator rowsPerPageOptions={pageSizeOptions} rows={pageSize} 
                    emptyMessage="No records found" alwaysShowPaginator={true} selectionMode="single"
                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    >
                {cols.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} style={{ minWidth: col.width }} filter sortable />
                ))}
            </DataTable>
            </Body>
        </div>
    )
}
