import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {MsalAuthenticationTemplate, MsalProvider} from "@azure/msal-react";
import {InteractionType, PublicClientApplication} from "@azure/msal-browser";

// Style imports
import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import './style.css';

// Component imports
import {PrimeReactProvider} from 'primereact/api';
import Dashboard from "./pages/Home/Dashboard";
import AuditList from "./pages/AuditPlan/AuditList";
import AuditForm from "./pages/AuditPlan/AuditForm";
import AuditView from "./pages/AuditPlan/AuditView";
import Upload from "./pages/Upload";
import Reporting from "./pages/Reporting";
import Analytics from "./pages/Analytics";
import Checklist from "./pages/Checklist";
import Profile from "./pages/Profile";
import Users from "./pages/Users/Users";
import UserForm from "./pages/Users/UserForm";
import Accounts from './pages/Accounts/Accounts';
import AccountForm from './pages/Accounts/AccountForm';
import Clientbanks from './pages/Clientbanks/Clientbanks';
import ClientbankForm from './pages/Clientbanks/ClientbankForm';
import Workklist from "./pages/WorkPlan/Worklist";
import NotFound from "./pages/Global/404NotFound";
import Validations from "./pages/AuditPlan/ValidationList";
import SearchResult  from './components/SearchResult';
import FindingList from "./pages/AuditPlan/FindingList";

import {msalConfig} from "./authConfig";
import {UserPermissionsProvider} from './contexts/userPermissionsContext';
import PrefundAuditPlanSubmissions from './pages/Reporting/PrefundAuditPlanSubmissions';
import Report from './pages/Reporting/Report';
import PostCloseAuditPlanBySampleType from './pages/Reporting/PostCloseAuditPlanBySampleType';
import ServicingAuditPlanBySampleType from './pages/Reporting/ServicingAuditPlanBySampleType';
import PrefundAuditPlansMonthEnd from './pages/Reporting/PrefundAuditPlansMonthEnd';
import PostCloseAuditPlansMonthEnd from './pages/Reporting/PostCloseAuditPlansMonthEnd';

console.log(process.env.REACT_APP_CLIENT_ID);
console.log(process.env.REACT_APP_AUTHORITY);
console.log(process.env.REACT_APP_AUTH_REDIRECT_URI);
console.log(process.env.REACT_APP_SCOPE);

const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root')!);
export const msalInstance = new PublicClientApplication(msalConfig);


root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <QueryClientProvider client={new QueryClient()}>
                    <PrimeReactProvider>
                        <UserPermissionsProvider>
                            <Router>
                                {
                                    // TODO: Replace this with a real check for admin
                                    true ? (
                                        <Routes>
                                            <Route path='*' element={<NotFound />} />
                                            <Route path="/" element={<Dashboard/>}/>

                                        <Route path="/Worklist" element={<Workklist />} />
                                        <Route path="/Validations" element={<Validations />} />
                                        <Route path="/upload" element={<Upload />} />

                                        <Route path="/SearchResult" element={<SearchResult />} />
                                        <Route path="/FindingList" element={<FindingList />} />

                                        <Route path="/prefund-qc-audits"
                                               element={<AuditList AuditPlanType="Prefund"/>}/>
                                        <Route path="/post-close-qc-audits"
                                               element={<AuditList AuditPlanType="Postclose"/>}/>
                                        <Route path="/service-audits" element={<AuditList AuditPlanType="Servicing"/>}/>

                                            <Route path="/AuditForm/:id" element={<AuditForm/>}/>
                                            <Route path="/AuditView/:id" element={<AuditView/>}/>
                                            <Route path="/post-close-qc-audits/:id" element={<AuditForm/>}/>
                                            <Route path="/service-audits/:id" element={<AuditForm/>}/>

                                            <Route path="/reporting" element={<Reporting/>}/>
                                            <Route path="/report/:id" element={<Report />} />
                                            <Route path="/reporting/postcloseauditplanbysampletype" element={<PostCloseAuditPlanBySampleType/>}/>
                                            <Route path="/reporting/prefundauditplansubmissions" element={<PrefundAuditPlanSubmissions/>}/>
                                            <Route path="/reporting/servicingauditplanbysampletype" element={<ServicingAuditPlanBySampleType/>}/>
                                            <Route path="/reporting/prefundauditplansmonthend" element={<PrefundAuditPlansMonthEnd/>}/>
                                            <Route path="/reporting/postcloseauditplansmonthend" element={<PostCloseAuditPlansMonthEnd/>}/>

                                            <Route path="/analytics" element={<Analytics/>}/>

                                            <Route path="/checklist" element={<Checklist/>}/>

                                            <Route path="/profile" element={<Profile/>}/>

                                            <Route path="/users" element={<Users/>}/>
                                            <Route path="/users/:id" element={<UserForm/>}/>
                                            <Route path="/users/new" element={<UserForm/>}/>

                                        <Route path="/accounts" element={<Accounts/>}/>
                                        <Route path="/accounts/:id" element={<AccountForm/>}/>
                                        <Route path="/accounts/new" element={<AccountForm/>}/>
                                        <Route path="/clientbanks" element={<Clientbanks/>}/>
                                        <Route path="/clientbanks/:id" element={<ClientbankForm/>}/>
                                        <Route path="/clientbanks/new" element={<ClientbankForm/>}/>
                                    </Routes>
                                ) : (
                                    <Routes>
                                        <Route path="/" element={<Dashboard/>}/>

                                            <Route path="/upload" element={<Upload/>}/>

                                            <Route path="/prefund-qc-audits" element={<AuditList/>}/>
                                            <Route path="/post-close-qc-audits" element={<AuditList/>}/>
                                            <Route path="/service-audits" element={<AuditList/>}/>

                                            <Route path="/AuditForm/:id" element={<AuditForm/>}/>
                                            <Route path="/AuditView/:id" element={<AuditView/>}/>

                                            <Route path="/post-close-qc-audits/:id" element={<AuditForm/>}/>
                                            <Route path="/service-audits/:id" element={<AuditForm/>}/>

                                            <Route path="/reporting" element={<Reporting/>}/>

                                            <Route path="/analytics" element={<Analytics/>}/>

                                            <Route path="/checklist" element={<Checklist/>}/>

                                            <Route path="/profile" element={<Profile/>}/>
                                        </Routes>
                                    )
                                }
                            </Router>
                        </UserPermissionsProvider>
                    </PrimeReactProvider>
                </QueryClientProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </React.StrictMode>
);
