import React from "react";
import Body from "../../components/Body";
import Nav from "../../components/Nav";
import './404NotFound.css'

export default function NotFound() {
    return (
        <div>
            <Nav />

            <Body>
                <div className="notfound">
                    <h1 className="text-6xl">404: Page Not Found</h1>
                    <p></p>
                    <p className="text-lg">
                        The page you were looking for was not found. Either the page has been moved, deleted, or you do not have permission to view this page. If you continue to encounter this error and need asistance, please reach out to your System Administrator.
                    </p>
                </div>
            </Body>
        </div>
    )
}
