import React, { useState, useRef, useEffect } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { Link, matchRoutes, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from 'primereact/panel';
import "./AuditForm.css";
import axios, { AxiosResponse } from 'axios';
import { RadioButton } from 'primereact/radiobutton';
import { Divider } from 'primereact/divider';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload, FileUploadBeforeUploadEvent, FileUploadSelectEvent } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { useSearchParams } from 'react-router-dom'
import { InputText } from 'primereact/inputtext';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from "react-router-dom";
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { Editor } from 'primereact/editor';
import { Avatar } from 'primereact/avatar';
import { Tag } from 'primereact/tag';
import { authenticatedFetch, getAuthenticatedHeader } from "../../state/apiUtils";
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";

interface State {
    name: string;
    code: string;
}

export default function AuditForm() {
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);

    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const auditReviewId = searchParams.get('auditReviewId');
    const auditFindingId = searchParams.get('auditFindingId');
    const [auditPlanType, setAuditPlanType] = useState<string>();

    //Determine if this is for Finding or QC
    const [mode, setMode] = useState<string>();

    //Determine which role is being used
    const userRole = searchParams.get('Role');

    //All the fields about a Loan
    const [loanData, setLoanData] = useState<any>({});

    const [findingData, setFindingData] = useState<any>({});

    //Upload attachment file State
    const [attachmentFileData, setAttachmentFileData] = useState(
        {
            loading: true, data: "", fileID: "", fileName: ""
        }
    );

    //Contains all the checklist items
    const [checklistData, setchecklistData] = useState<any>({
        checklists: []
    });

    // Contains all attachments
    const [attachmentData, setAttachmentData] = useState<any[]>([]);
    const [attachmentDataFetched, setAttachmentDataFetched] = useState<boolean>(false);

    //The selected Attachment for viewing
    const [selectedAttachment, setSelectedAttachment] = useState<any>(null)

    const [noteData, setNoteData] = useState<any[]>([]);
    const [noteFetched, setNoteFetched] = useState<boolean>(false);

    const [newNote, setNewNote] = useState<any>(
        {
            Note: ""
        }
    )

    const [newNoteError, setNewNoteError] = useState<boolean>(false)

    const handleChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log("value", value)
        if ((value === null) || (value === undefined) || (value === "")) {
            setNewNoteError(true);
            setNewNote((s: any) => { return { ...s, Note: value } })
        }
        else {
            setNewNoteError(false);
            setNewNote((s: any) => { return { ...s, Note: value } })
        }
    }


    const [activityData, setActivityData] = useState<any[]>([]);
    const [activityDataFetched, setActivityDataFetched] = useState<boolean>(false);

    //List of questions for a selected checklist
    const [selectedChecklist, setSelectedChecklist] = useState<any>(null)


    //Which page to show up on the right-hand section
    const [activePage, setActivePage] = useState<any>("Page_Start")

    //All the response records from API
    const [allResponses, setAllResponses] = useState<any[]>([])


    //For viewing findings
    const [auditFindings, setAuditFindings] = useState<any[]>([]);
    const [auditFindingsFetched, setAuditFindingsFetched] = useState<boolean>(false);

    //The selected Finding for viewing
    const [selectedAuditFinding, setSelectedAuditFinding] = useState<any>({})

    //For viewing completed items
    const [completedResponses, setCompletedResponses] = useState<any[]>([]);

    //to determine if we are showing only unaswered questions
    const [checklistNoResponseOnly, setChecklistNoResponseOnly] = useState<boolean>(false);

    const [questionLoading, setQuestionLoading] = useState<boolean>(false);
    const [refreshResponse, setResponseResponse] = useState<boolean>(false);

    //Alert for QC Complete
    const [QCCompleteAlert, setQCCompleteAlert] = useState<boolean>(false);

    const [header, setHeader] = useState<any>();

    const [findingLoaded, setFindingLoaded] = useState<boolean>(false);


    useEffect(() => {

        prepareAuditOrFinding();
        initHeader();

    }, []);

    useEffect(() => {

        if (auditPlanType == null) {
            return;
        }
        if (auditFindingId != null) {
            setMode("FINDING");
            getFindingValidationData(auditFindingId)
        }
        else {
            setMode("QC");

            //get back all the findings
            getFindingsData();

        }

        //Get Loan Details
        getLoanData();

        //Get checklist information
        getCheckListData();

        getAttachmentData();

        getNoteData();

        getActivityData();

        //Get back all the responses
        getResponses(null);

    }, [auditPlanType])

    useEffect(() => {
        console.log('allResponses', allResponses)

        updateResponseTally()
    }, [allResponses])

    const states: State[] = [
        { name: 'Alabama', code: 'AL' },
        { name: 'Alaska', code: 'AK' },
        { name: 'Arizona', code: 'AZ' },
        { name: 'Arkansas', code: 'AR' },
        { name: 'California', code: 'CA' },
        { name: 'Colorado', code: 'CO' },
        { name: 'Connecticut', code: 'CT' },
        { name: 'Delaware', code: 'DE' },
        { name: 'District of Columbia', code: 'DC' },
        { name: 'Florida', code: 'FL' },
        { name: 'Georgia', code: 'GA' },
        { name: 'Guam', code: 'GU' },
        { name: 'Hawaii', code: 'HI' },
        { name: 'Idaho', code: 'ID' },
        { name: 'Illinois', code: 'IL' },
        { name: 'Indiana', code: 'IN' },
        { name: 'Iowa', code: 'IA' },
        { name: 'Kansas', code: 'KS' },
        { name: 'Kentucky', code: 'KY' },
        { name: 'Louisiana', code: 'LA' },
        { name: 'Maine', code: 'ME' },
        { name: 'Maryland', code: 'MD' },
        { name: 'Massachusetts', code: 'MA' },
        { name: 'Michigan', code: 'MI' },
        { name: 'Minnesota', code: 'MN' },
        { name: 'Mississippi', code: 'MS' },
        { name: 'Missouri', code: 'MO' },
        { name: 'Montana', code: 'MT' },
        { name: 'Nebraska', code: 'NE' },
        { name: 'Nevada', code: 'NV' },
        { name: 'New Hampshire', code: 'NH' },
        { name: 'New Jersey', code: 'NJ' },
        { name: 'New Mexico', code: 'NM' },
        { name: 'New York', code: 'NY' },
        { name: 'North Carolina', code: 'NC' },
        { name: 'North Dakota', code: 'ND' },
        { name: 'Northern Mariana Islands', code: 'MP' },
        { name: 'Ohio', code: 'OH' },
        { name: 'Oklahoma', code: 'OK' },
        { name: 'Oregon', code: 'OR' },
        { name: 'Pennsylvania', code: 'PA' },
        { name: 'Puerto Rico', code: 'PR' },
        { name: 'Rhode Island', code: 'RI' },
        { name: 'South Carolina', code: 'SC' },
        { name: 'South Dakota', code: 'SD' },
        { name: 'Tennessee', code: 'TN' },
        { name: 'Texas', code: 'TX' },
        { name: 'Trust Territories', code: 'TT' },
        { name: 'Utah', code: 'UT' },
        { name: 'Vermont', code: 'VT' },
        { name: 'Virginia', code: 'VA' },
        { name: 'Virgin Islands', code: 'VI' },
        { name: 'Washington', code: 'WA' },
        { name: 'West Virginia', code: 'WV' },
        { name: 'Wisconsin', code: 'WI' },
        { name: 'Wyoming', code: 'WY' }
    ];

    const initHeader = () => {
        getAuthenticatedHeader().then((response) => {
            console.log('header', response);
            setHeader(response);

        })
    }
    const prepareAuditOrFinding = () => {
        console.log('auditFindingId', auditFindingId);
        console.log('auditReviewId', auditReviewId);

        //Get Audit Review Plan Type
        authenticatedFetch(
            "/Audit/GetAuditPlanType?auditReviewId=" + auditReviewId)
            .then(res => {
                console.log('auditPlanType', res);
                setAuditPlanType(res.AuditPlanType);


            });

    }

    //Get back all the questions for this loan
    const getLoanData = () => {

        authenticatedFetch(
            "/Audit/GetLoanData?LoanId=" + id)
            .then(res => {
                //console.log(res);
                console.log('Loan Data', res);
                var data = res;
                setLoanData(data);

            });
    }

    const getFindingsData = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Audit/GetFindings", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('Response Data', data);

                setAuditFindings(data.Responses);
                setAuditFindingsFetched(true);

            });
    }

    const getFindingValidationData = (id: any) => {
        var param = {
            AuditFindingId: id
        }
        authenticatedFetch(
            "/Finding/GetFindingValidations", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('GetFindingValidations', data);

                setSelectedAuditFinding(data);
                setActivePage("Finding");
                setAuditFindingsFetched(true);

            });
    }

    const getAttachmentData = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Attachment/GetReviewAttachments", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('getAttachmentData', data);

                setAttachmentData(data);
                setAttachmentDataFetched(true);
            });
    }

    const getActivityData = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Activity/GetActivities", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('getActivityData', data);

                setActivityData(data);
                setActivityDataFetched(true);
            });
    }

    const getNoteData = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Note/GetNotes", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('GetNotes', data);

                setNoteData(data);
                setNoteFetched(true);

            });
    }

    const addNote = () => {
        if (newNoteError || newNote.Note.length < 1) {
            toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Please add content to your note first.', life: 3000 });
            return;
        }
        else {
            var param = {
                AuditReviewId: auditReviewId,
                Note: newNote.Note
            }
            authenticatedFetch(
                "/Note/AddNote", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    var data = res;
                    console.log('AddNote', data);
                    toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Note successfully added.', life: 3000 });
                    setNewNote((s: any) => { return { ...s, Note: "" } })
                    getNoteData();
                });
        }
    }

    const setQCComplete = () => {
        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Audit/SetQCComplete", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('Response Data', data);

                setQCCompleteAlert(true);

            });
    }

    // const handleUpload = (e: any) => {
    //     console.log('OnUpload Called');
    // };

    //Get back all the questions for this loan
    const getCheckListData = () => {

        //console.log('searchparam', q);
        var param = {
            LoanId: id,
            AuditType: auditPlanType,
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Audit/getCheckListData", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                //console.log(res);
                console.log('getCheckListData', res);
                var data = res;
                setchecklistData(data);
                getResponses(data);
            });
    }

    //Get back all the responses already made for this loan
    const getResponses = (checklistData: any) => {

        //console.log('searchparam', q);

        var param = {
            AuditReviewId: auditReviewId
        }
        authenticatedFetch(
            "/Audit/GetResponses", {
            method: "POST",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(res => {
                var data = res;
                console.log('getResponses', data);

                // var completed = data.Responses.filter((item: any) => item.Response != "Finding")
                // setCompletedResponses(completed);

                setAllResponses(data.Responses);

                //If there was a selected one, set it up again
                if (selectedChecklist != null && checklistData != null) {
                    console.log('check active checklist', selectedChecklist, data);
                    var newSelection = checklistData.checklists.filter((f: any) => f.Category == selectedChecklist.Category);
                    if (newSelection) {
                        console.log('newSelection', newSelection);
                        getCheckListDetail(newSelection[0], data.Responses);
                    }
                }

            });
    }

    const getCheckListDetail = (item: any, responses: any) => {
        console.log('getCheckListDetail', item);
        //console.log('checkListResponseData', allResponses);

        console.log('existingCheckList', selectedChecklist);
        setSelectedChecklist(item);
        //Build response

        if (activePage != "Checklist") {

            setActivePage("Checklist");
        }


    }
    const editHeaderTemplate = (tabColor: string, options: any) => {

        const className = `${options.className} ${tabColor} justify-content-start  `;
        const titleClassName = `${options.titleClassName} ${tabColor} ml-2 `;
        const style = { fontSize: '1.25rem' };
        //console.log('options', options);
        return (
            <div className={className}>
                <span className={titleClassName} style={style}>{options.props.header}</span>
                {/* {options.props.header} */}
            </div>
        );
    };

    const checklistHeaderTemplate = (tabColor: string, switchOn: boolean, options: any) => {

        const className = `${options.className} ${tabColor}`;
        const titleClassName = `${options.titleClassName} ${tabColor} ml-2 `;
        const style = { fontSize: '1.25rem' };
        //console.log('options', options);
        return (
            <div className={className}>
                <span className={titleClassName} style={style}>{options.props.header}</span>
                {switchOn ?
                    <div className="flex flex-wrap justify-content-end gap-3">
                        <span>Show unaswered questions only</span>
                        <InputSwitch checked={checklistNoResponseOnly} onChange={() => setChecklistNoResponseOnly(!checklistNoResponseOnly)} />





                    </div>
                    : ""}
            </div>

        );
    };


    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let { hasRoles, isLoading } = useHasRole(['Account Administrator', 'Account Manager', 'QC Admin', 'QC Manager', 'QC Auditor', 'QC Validator', 'System Administrator'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    function page_Start() {
        return (
            <Panel header="Checklist - Loading Questions..." headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-3', o)} style={{ height: "67.5vh" }}>
                <p>Loading checklist items. Once the checklists have loaded, click on the item you'd like to work on.</p>
            </Panel>

        )
    }

    const Page_Notes = () => {
        return (
            <Panel header="Notes" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-4', o)} style={{ height: "77.5vh" }}>
                <ScrollPanel style={{ paddingTop: "20px", width: '100%', height: '67.5vh' }}>
                    {
                        noteData.map((data: any, i: number) => {
                            return (
                                <div className="mt-1 w-full">
                                    {i > 0 ? <Divider style={{ margin: "1rem 0" }} /> : ""}

                                    <span className="block note-display">{data.Note}</span>

                                    <span className="block font-italic mt-1">--{data.CreatedBy}, {data.CreatedDate}</span>
                                </div>
                            )
                        })
                    }
                </ScrollPanel>
            </Panel>
        )
    }


    const Page_Activities = () => {
        return (
            <Panel header="Activities" headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-7', o)} style={{ height: "77.5vh" }}>
                <ScrollPanel style={{ paddingTop: "20px", width: '100%', height: '67.5vh' }}>
                    <div className="flex flex-column w-full">
                        {
                            activityData.map((data: any, i: number) => {
                                return (
                                    <div className="flex flex-row gap-1 mt-1 w-full align-items-center ">


                                        <span className="block mr-2 ">{data.CreatedDate}</span>
                                        <Tag severity="success" value={data.ActivityName}></Tag>

                                        <span className="block  ml-2">{data.ActivityDetail}</span>
                                        <span className="block  ml-2">--{data.CreatedBy}</span>

                                    </div>
                                )
                            })
                        }
                    </div>
                </ScrollPanel>
            </Panel>
        )
    }

    const Page_Attachment = () => {
        const path = process.env.REACT_APP_API_BASE_URL + `/Attachment/GetReviewAttachmentData?auditReviewAttachmentId=${selectedAttachment.AuditReviewAttachmentId}`;
        console.log('selectedAttachment', path);

        const downloadAttachment = () => {
            authenticatedFetch(
                `/Attachment/GetReviewAttachmentData?auditReviewAttachmentId=${selectedAttachment.AuditReviewAttachmentId}`, {}, "blob"
            ).then((response) => {
                response.blob().then((blob: any) => {

                    // Creating new object of PDF file
                    const fileURL =
                        window.URL.createObjectURL(blob);

                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = selectedAttachment.FileName;
                    alink.click();
                    console.log('downloading file');
                });
            });
        }

        return (
            <Panel header={"Attachment View - " + selectedAttachment.FileName} headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-5', o)} style={{ height: "77vh" }}>
                <div className="col-12">
                    <div className="formgrid grid">
                        <div className="field col">
                            <label>File Name</label><br />
                            <label>{selectedAttachment.FileName}
                            </label>

                        </div>
                        <div className="field col">
                            <label>Date Added</label><br />
                            <label>{selectedAttachment.CreatedDate}</label>
                        </div>
                        <div className="field col">
                            <label>Added By</label><br />
                            <label>{selectedAttachment.CreatedBy}</label>
                        </div>
                        <div className='field col'>
                            <label>Comments</label><br />
                            <label>{selectedAttachment.Comment}</label>

                        </div>
                    </div>
                    <Button className="button-small" label="Download File" icon="pi pi-download" onClick={() => downloadAttachment()} title='Download File' />
                </div>
                {
                    selectedAttachment.FileType === "img" && <img src={path} height="100%" style={{ display: "block", marginLeft: "auto", marginRight: "auto", maxWidth: "100%" }}></img>
                }
                {
                    selectedAttachment.FileType === "pdf" &&
                    <div>
                        <ScrollPanel style={{ width: '100%', height: '60vh' }}>
                            <iframe src={path} style={{ border: "none", width: "100%", height: "100%" }}>

                            </iframe>
                        </ScrollPanel>
                    </div>
                }
                {
                    selectedAttachment.FileType === "txt" &&
                    <ScrollPanel style={{ width: '100%', height: '60vh' }}>
                        <iframe src={path} style={{ border: "none", width: "100%", height: "100%" }}>

                        </iframe>
                    </ScrollPanel>
                    // <object type="text/pain" data={selectedAttachment.path} width="100%" style={{ overflow: "hidden", minHeight: "66vh" }}></object>
                }
                {
                    selectedAttachment.FileType != "txt" &&
                        selectedAttachment.FileType != "pdf" &&
                        selectedAttachment.FileType != "img" ?
                        <Card className="col-12 text-lg font-italic">
                            This attachment can not be previewed here. The attachment must be downloaded and
                            opened from your computer.
                        </Card>
                        :
                        ""
                }


                {/* <object width="910" height="340" type="text/plain" data="message.txt" border="0"
   style="overflow: hidden;">
</object> */}

            </Panel>

        )
    }


    const Page_NewAttachment = () => {

        const [isUploading, setIsUploading] = useState<boolean>(false);
        const [comments, setComments] = useState<string>("")
        // const [isInvalid, setIsInvalid] = useState<boolean>(false);

        const onSelect = (e: FileUploadSelectEvent) => {
            console.log("onSelect called,", e);
            for (let i = 0; i < e.files.length; i++) {
                if ((e.files[i].type === "image/png")
                    || (e.files[i].type === "text/csv")
                    || (e.files[i].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                    || (e.files[i].name.toUpperCase().endsWith('XLS'))
                    || (e.files[i].name.toUpperCase().endsWith('DOC'))
                    || (e.files[i].type === "image/jpeg")
                    || (e.files[i].type === "text/plain")
                    || (e.files[i].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                    || (e.files[i].type === "application/pdf")) {
                    console.log("Do nothing");
                    console.log(e.files[i].type);
                } else {
                    console.log("File type not allowed", e.files[i].type)
                    toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Attachment type is not supported. The following file types are allowed:  .txt, .pdf, .doc, .docx, .xls, .xlsx, .csv.', life: 3000 });
                    console.log(e.files[i].type);
                    delete e.files[i];
                }
            }
        }

        const onUpload = (e: any) => {

            //this.toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
            var result = JSON.parse(e.xhr.response);
            console.log(result);
            console.log('OnUpload Called ', result.fileID);
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Attachment Successfuly Added', life: 3000 });
            getAttachmentData();

        };

        const onBeforeUpload = (e: FileUploadBeforeUploadEvent) => {

            console.log("set is uploading", e);
            setIsUploading(true);
            e.formData.append('AuditReviewId', auditReviewId!);
            e.formData.append('Comments', comments!);

            console.log('onBeforeUploadHeader', header.Authorization);
            console.log('onBeforeUploadHeader', header.Token);
            e.xhr.setRequestHeader("Authorization", header.Authorization);
            e.xhr.setRequestHeader("X-IdToken", header.Token);
        }

        return (
            <Panel header={"Upload New Attachment"} headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-5', o)} style={{ height: "76vh" }}>

                {/* Change the url to correct URL for database */}

                <ProgressBar mode={isUploading ? "indeterminate" : "determinate"} style={{ height: '6px', borderRadius: '5px', margin: '1.2rem 0.6rem 0.2rem' }}></ProgressBar>
                <div className="col-12">
                    <span className='text-sm'>Allowed file types:  .txt, .pdf, .doc, .docx, .xls, .xlsx, .csv.</span>
                    <FileUpload onBeforeSend={onBeforeUpload} onSelect={onSelect} onUpload={onUpload}
                        name="UploadedFiles" url={process.env.REACT_APP_API_BASE_URL + "/Attachment/AddReviewAttachment"}
                        maxFileSize={10000000}
                        emptyTemplate={<p className="m-0">Drag and drop a file to here to upload.</p>}
                    // disabled={isInval id}
                    />
                </div>
                <div className="col-12 audit-plan">
                    <label className="block mb-2">Attachment Comments</label>
                    <InputTextarea value={comments} onChange={(e: any) => setComments(e.target.value)} rows={3} style={{ width: "100%", padding: "0px;" }} />
                </div>


            </Panel>

        )
    }

    const Page_Loan = (props: any) => {

        //States for drop-down list options
        const [InvestorTypes, setInvestorTypes] = useState<string[]>([]);
        const [Investors, setInvestors] = useState<string[]>([]);
        const [LoanTypes, setLoanTypes] = useState<string[]>([]);
        const [DocumentationTypes, setDocumentationTypes] = useState<string[]>([]);
        const [RefinanceTypes, setRefinanceTypes] = useState<string[]>([]);
        const [LienPositions, setLienPositions] = useState<string[]>([]);
        const [PropertyTypes, setPropertyTypes] = useState<string[]>([]);
        const [OccupancyTypes, setOccupancyTypes] = useState<string[]>([]);
        const [LoanPurposes, setLoanPurposes] = useState<string[]>([]);
        const [LoanOfficers, setLoanOfficers] = useState<string[]>([]);
        const [Closers, setClosers] = useState<string[]>([]);
        const [LoanProcessors, setLoanProcessors] = useState<string[]>([]);
        const [Underwriters, setUnderwriters] = useState<string[]>([]);
        const [AccountStatus, setAccountStatus] = useState<string[]>([]);

        const [AccountFlags, setAccountFlags] = useState<string[]>([]);

        //Make copy of loan
        const [editLoanData, setEditLoan] = useState<any>();
        const disableEdit = (mode == "FINDING" && userRole == "QC VALIDATOR");

        useEffect(() => {
            //Make copy
            console.log('Edit Loan Data?', props.LoanData)
            var copyItem = JSON.parse(JSON.stringify(props.LoanData))

            //Add dummy items
            copyItem.Account = {};
            copyItem.CreatedBy = {};
            copyItem.LastModifiedBy = {};
            copyItem.AuditReviewId = auditReviewId;

            setEditLoan(copyItem);


            //Get drop-down list options
            if (!disableEdit) {
                getInvestorTypeData();
                getInvestorData();
                getLoanTypeData();
                getRefinanceTypeData();
                getLienPositionData();
                getPropertyTypeData();
                getOccupancyTypeData();
                getDocumentationTypeData();
                getLoanPurposeData();
                if (auditPlanType != "Servicing") {
                    getAssignableUsers(copyItem.Client);
                }
                else {
                    getAccountStatus();
                    getAccountFlags();
                }
            }
        }, [props.LoanData])


        // useEffect(() => {

        // }, [])

        const getInvestorTypeData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Investor%20Type")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log(res);
                    setInvestorTypes(formatedData);
                });
        };

        const getAccountStatus = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Servicing%20Account%20Status")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log(res);
                    setAccountStatus(formatedData);
                });
        };
        
        const getAccountFlags = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Account%20Flag")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    console.log(formatedData);
                    setAccountFlags(formatedData);
                });
        };


        const getInvestorData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Investor")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log(res);
                    setInvestors(formatedData);
                });
        };

        const getLoanTypeData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Loan+Type")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log(res);
                    setLoanTypes(formatedData);
                });
        };

        const getDocumentationTypeData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Documentation%20Type")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log(res);
                    setDocumentationTypes(formatedData);
                });
        };

        const getRefinanceTypeData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Refinance%20Type")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log("refinance types?", res);
                    setRefinanceTypes(formatedData);
                });
        };

        const getLienPositionData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Lien%20Position")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log("refinance types?", res);
                    setLienPositions(formatedData);
                });
        };

        const getPropertyTypeData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Property%20Type")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log("refinance types?", res);
                    setPropertyTypes(formatedData);
                });
        };

        const getOccupancyTypeData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Occupancy%20Type")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log("refinance types?", res);
                    setOccupancyTypes(formatedData);
                });
        };

        const getLoanPurposeData = () => {
            authenticatedFetch(
                "/Audit/GetLookup?groupName=Loan%20Purpose")
                .then(res => {
                    let formatedData = res.map((x: any) => ({ 'name': x, 'code': x }));
                    //console.log("refinance types?", res);
                    setLoanPurposes(formatedData);
                });
        };

        const getAssignableUsers = (client: any) => {
            authenticatedFetch(
                "/FindingList/GetAssignableUsers?client=" + client)
                .then(res => {
                    console.log('getAssignableUsers', res);
                    var data = res;
                    setLoanOfficers(data);
                    setClosers(data);
                    setLoanProcessors(data);
                    setUnderwriters(data);
                });

        }

        const saveLoanData = () => {
            console.log('save loan data', editLoanData)

            authenticatedFetch(
                "/Audit/SaveLoanData",
                {
                    method: "POST",
                    body: JSON.stringify(editLoanData),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                .then(res => {
                    var data = res;
                    console.log('SaveLoanData', data);

                    var copyItem = JSON.parse(JSON.stringify(data))

                    //Add dummy items
                    copyItem.Account = {};
                    copyItem.CreatedBy = {};
                    copyItem.LastModifiedBy = {};
                    copyItem.AuditReviewId = auditReviewId;

                    setEditLoan(copyItem);

                    setLoanData(data);
                    //setAuditFindings(data.Responses);
                    toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Loan data successfully updated.', life: 3000 });
                })
                .catch(err => {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'There was a server error while saving the edited loan detail(s). Please try again later.', life: 3000 });
                });
        }

        const ServiceLoanData = (props: any) => {
            return (
                <>
                    <span className="p-float-label">
                        <InputText disabled={disableEdit} id="Dlqstatus" value={editLoanData.Dlqstatus}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, Dlqstatus: e.target.value } }); }}
                        />
                        <label htmlFor="Dlqstatus">Delinquent Status</label>
                    </span>
                    <span className="p-label">
                        <label htmlFor="AccountStatus">Account Status</label>
                        <Dropdown disabled={disableEdit} value={editLoanData.AccountStatus}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, AccountStatus: e.value.code } }); }}
                            options={AccountStatus} optionLabel="name"
                            placeholder={(editLoanData.AccountStatus !== null) && (editLoanData.AccountStatus !== undefined) && (editLoanData.AccountStatus !== "") && (editLoanData.AccountStatus !== " ") ?
                                editLoanData.AccountStatus
                                :
                                "Account Status"}
                            className="w-full" />
                    </span>
                    <span className="p-float-label">
                        
                        <label htmlFor="AccountFlags">Account Flags</label>
                        <Dropdown disabled={disableEdit} value={editLoanData.AccountFlags}
                            onChange={(e) => { setEditLoan((s: any) => {console.log('wtf', e.value); return { ...s, AccountFlags: e.value } }); }}
                            options={AccountFlags} optionLabel="name" optionValue='name'
                            placeholder={(editLoanData.AccountFlags !== null) && (editLoanData.AccountFlags !== undefined) && (editLoanData.AccountFlags !== "") && (editLoanData.AccountFlags !== " ") ?
                                editLoanData.AccountFlags
                                :
                                "Account Flag"}
                            className="w-full" />
                    
                    </span>


                    <span className="p-float-label">
                        <InputText disabled={disableEdit} id="EscrowPayment" value={editLoanData.EscrowPayment}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, EscrowPayment: e.target.value } }); }}
                        />
                        <label htmlFor="EscrowPayment">Escrow Payment</label>
                    </span>


                    <span className="p-float-label">
                        <InputText disabled={disableEdit} id="EscrowAnalysisDate" value={editLoanData.EscrowAnalysisDate}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, EscrowAnalysisDate: e.target.value } }); }}
                        />
                        <label htmlFor="EscrowAnalysisDate">Escrow Analysis Date</label>
                    </span>

                    <span className="p-float-label">
                        <InputText disabled={disableEdit} id="LastPaymentDate" value={editLoanData.LastPaymentDate}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, LastPaymentDate: e.target.value } }); }}
                        />
                        <label htmlFor="LastPaymentDate">Last Payment Date</label>
                    </span>

                    <span className="p-float-label">
                        <InputText disabled={disableEdit}  id="NextDueDate" value={editLoanData.NextDueDate}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, NextDueDate: e.target.value } }); }}
                        />
                        <label htmlFor="NextDueDate">Next Due Date</label>
                    </span>

                    <span className="p-float-label">
                        <InputText  disabled={disableEdit} id="ArmadjustmentDate" value={editLoanData.ArmadjustmentDate}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, ArmadjustmentDate: e.target.value } }); }}
                        />
                        <label htmlFor="ArmadjustmentDate">ARM Adjustment Date</label>
                    </span>
                </>
            )
        }
        const NonServiceLoanData = (props: any) => {
            return (
                <>
                    <span className="p-float-label">
                        <InputText disabled={disableEdit}  id="Ltv" value={editLoanData.Ltv}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, Ltv: e.target.value } }); }}
                        />
                        <label htmlFor="Ltv">LTV</label>
                    </span>
                    <span className="p-float-label">
                        <InputText disabled={disableEdit}  id="Cltv" value={editLoanData.Cltv}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, Cltv: e.target.value } }); }}
                        />
                        <label htmlFor="Cltv">CLTV</label>
                    </span>

                    <span className="p-label">
                        <label htmlFor="LoanOfficer">Loan Officer</label>
                        <Dropdown  disabled={disableEdit} value={editLoanData.LoanOfficer}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, LoanOfficer: e.target.value } }); }}
                            options={LoanOfficers}
                            optionLabel='Email' optionValue='Email'
                            filter
                            editable
                            placeholder={(editLoanData.LoanOfficer !== null) && (editLoanData.LoanOfficer !== undefined) && (editLoanData.LoanOfficer !== "") && (editLoanData.LoanOfficer !== " ") ?
                                editLoanData.LoanOfficer
                                :
                                "Loan Officer"}
                            className="w-full" />
                    </span>

                    <span className="p-label">
                        <label htmlFor="Closer">Closer</label>
                        <Dropdown  disabled={disableEdit} value={editLoanData.Closer}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, Closer: e.target.value } }); }}
                            options={Closers}
                            optionLabel='Email' optionValue='Email'
                            filter
                            editable
                            placeholder={(editLoanData.Closer !== null) && (editLoanData.Closer !== undefined) && (editLoanData.Closer !== "") && (editLoanData.Closer !== " ") ?
                                editLoanData.Closer
                                :
                                "Closer"}
                            className="w-full" />
                    </span>

                    <span className="p-label">
                        <label htmlFor="LoanProcessor">Loan Processor</label>
                        <Dropdown  disabled={disableEdit} value={editLoanData.LoanProcessor}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, LoanProcessor: e.target.value } }); }}
                            options={LoanProcessors}
                            optionLabel='Email' optionValue='Email'
                            filter
                            editable
                            placeholder={(editLoanData.LoanProcessor !== null) && (editLoanData.LoanProcessor !== undefined) && (editLoanData.LoanProcessor !== "") && (editLoanData.LoanProcessor !== " ") ?
                                editLoanData.LoanProcessor
                                :
                                "Loan Processor"}
                            className="w-full" />
                    </span>


                    <span className="p-label">
                        <label htmlFor="Underwriter">Underwriter</label>
                        <Dropdown  disabled={disableEdit}  value={editLoanData.Underwriter}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, Underwriter: e.target.value } }); }}
                            options={Underwriters}
                            optionLabel='Email' optionValue='Email'
                            filter
                            editable
                            placeholder={(editLoanData.Underwriter !== null) && (editLoanData.Underwriter !== undefined) && (editLoanData.Underwriter !== "") && (editLoanData.Underwriter !== " ") ?
                                editLoanData.Underwriter
                                :
                                "Underwriter"}
                            className="w-full" />
                    </span>

                    <span className="p-float-label">
                        <InputText disabled={disableEdit}  id="MortgageOriginator" value={editLoanData.MortgageOriginator}
                            onChange={(e) => { setEditLoan((s: any) => { return { ...s, MortgageOriginator: e.target.value } }); }}
                        />
                        <label htmlFor="MortgageOriginator">Mortgage Originator</label>
                    </span>
                </>
            )
        }

        return (
            editLoanData && <Panel header={"Loan Detail - " + loanData.LoanName} headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-1', o)} style={{ height: "fit-content" }}>
                <ScrollPanel style={{ paddingTop: "20px", width: '100%', height: '67.5vh' }}>
                    <div className="loan-form formgrid grid form" style={{ marginTop: "10px" }}>
                        <div className="form-column col-6">

                            <span className="p-float-label">
                                <InputText disabled id="LoanName" value={editLoanData.LoanName}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, LoanName: e.target.value } }); }}
                                />
                                <label htmlFor="LoanName">Loan Name</label>
                            </span>
                            <span className="p-float-label">
                                <InputText disabled={disableEdit}  id="LoanNumber" value={editLoanData.LoanNumber}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, LoanNumber: e.target.value } }); }}
                                />
                                <label htmlFor="loanNumber">Loan Number</label>
                            </span>

                            <span className="p-float-label">
                                <InputText  disabled={disableEdit} id="borrowersFirstName" value={editLoanData.BorrowersFirstName}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, BorrowersFirstName: e.target.value } }); }} />
                                <label htmlFor="borrowersFirstName">Borrowers First Name</label>
                            </span>

                            <span className="p-float-label">
                                <InputText  disabled={disableEdit} id="borrowersLastName" value={editLoanData.BorrowersLastName}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, BorrowersLastName: e.target.value } }); }}
                                />
                                <label htmlFor="borrowersLastName">Borrowers Last Name</label>
                            </span>

                            <span className="p-float-label">
                                <InputText  disabled={disableEdit} id="CurrentAddress" value={editLoanData.CurrentAddress}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, CurrentAddress: e.target.value } }); }}
                                />
                                <label htmlFor="CurrentAddress">Current Address</label>
                            </span>


                            <span className="p-float-label">
                                <InputText  disabled={disableEdit} id="CurrentCity" value={editLoanData.CurrentCity}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, CurrentCity: e.target.value } }); }}
                                />
                                <label htmlFor="CurrentCity">Current City</label>
                            </span>
                            <span className="p-label">
                                <label htmlFor="CurrentState">Current State</label>
                                <Dropdown  disabled={disableEdit} value={editLoanData.CurrentState}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, CurrentState: e.value.code } }); }}
                                    options={states} optionLabel="name"
                                    placeholder={(editLoanData.CurrentState !== null) && (editLoanData.CurrentState !== undefined) && (editLoanData.CurrentState !== "") && (editLoanData.CurrentState !== " ") ?
                                        editLoanData.CurrentState
                                        :
                                        "Investor Type"}
                                    filter
                                    className="w-full" />
                            </span>


                            <span className="p-float-label">
                                <InputText  disabled={disableEdit} id="CurrentZipCode" value={editLoanData.CurrentZipCode}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, CurrentZipCode: e.target.value } }); }}
                                />
                                <label htmlFor="CurrentZipCode">Current Zipcode</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled={disableEdit}  id="FundingDate" value={editLoanData.FundingDate}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, FundingDate: e.target.value } }); }}
                                />
                                <label htmlFor="FundingDate">Funding Date</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled={disableEdit}  id="fundingMonth" value={editLoanData.FundingMonth}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, FundingMonth: e.target.value } }); }}
                                />
                                <label htmlFor="fundingMonth">Funding Month</label>
                            </span>

                            <span className="p-float-label">
                                <InputText disabled={disableEdit}  id="LoanAmount" value={editLoanData.LoanAmount}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, LoanAmount: e.target.value } }); }}
                                />
                                <label htmlFor="LoanAmount">Loan Amount</label>
                            </span>

                            <span className="p-float-label">
                                <InputText  disabled={disableEdit} id="InterestRate" value={editLoanData.InterestRate}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, InterestRate: e.target.value } }); }}
                                />
                                <label htmlFor="InterestRate">Interest Rate</label>
                            </span>
                            <span className="p-float-label">
                                <InputText  disabled={disableEdit} id="CreditScore" value={editLoanData.CreditScore}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, CreditScore: e.target.value } }); }}
                                />
                                <label htmlFor="CreditScore">Credit Score</label>
                            </span>
                            <span className="p-label">
                                <label htmlFor="InvestorType">Investor Type</label>
                                <Dropdown  disabled={disableEdit} value={editLoanData.InvestorType}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, InvestorType: e.value.code } }); }}
                                    options={InvestorTypes} optionLabel="name"
                                    placeholder={(editLoanData.InvestorType !== null) && (editLoanData.InvestorType !== undefined) && (editLoanData.InvestorType !== "") && (editLoanData.InvestorType !== " ") ?
                                        editLoanData.InvestorType
                                        :
                                        "Investor Type"}
                                    className="w-full" />
                            </span>
                            <span className="p-label">
                                <label htmlFor="Investor">Investor</label>
                                <Dropdown  disabled={disableEdit} value={editLoanData.Investor}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, Investor: e.value.code } }); }}
                                    options={Investors} optionLabel="name"
                                    placeholder={(editLoanData.Investor !== null) && (editLoanData.Investor !== undefined) && (editLoanData.Investor !== "") && (editLoanData.Investor !== " ") ?
                                        editLoanData.Investor
                                        :
                                        "Investor"}
                                    className="w-full" />
                            </span>
                            {/* <span className="p-float-label">
                                <InputText id="Investor" value={editLoanData.Investor} />
                                <label htmlFor="Investor">Investor</label>
                            </span> */}
                            <span className="p-float-label">
                                <InputText disabled={disableEdit}  id="InvestorName" value={editLoanData.InvestorName}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, InvestorName: e.target.value } }); }}
                                />
                                <label htmlFor="InvestorName">Investor Name</label>
                            </span>

                            <span className="p-float-label">
                                <InputText id="LoanSelection" disabled value={editLoanData.LoanSelection}
                                />
                                <label htmlFor="LoanSelection">Loan Selection</label>
                            </span>

                            <span className="p-float-label">
                                <InputText id="CreatedDate" disabled value={editLoanData.CreatedDate}
                                />
                                <label htmlFor="CreatedDate">Created Date</label>
                            </span>

                            <span className="p-float-label">
                                <InputText id="LastModifiedDate" disabled value={editLoanData.LastModifiedDate}

                                />
                                <label htmlFor="LastModifiedDate">Last Updated</label>
                            </span>
                        </div>

                        <div className="form-column col-6">

                            <span className="p-float-label">
                                <InputText id="client" disabled value={editLoanData.Client}
                                />
                                <label htmlFor="client">Client Bank</label>
                            </span>

                            <span className="p-float-label">
                                <InputText  disabled={disableEdit} id="AmortizationType" value={editLoanData.AmortizationType}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, AmortizationType: e.target.value } }); }}
                                />
                                <label htmlFor="AmortizationType">Amortization Type</label>
                            </span>
                            <span className="p-label">
                                <label htmlFor="LoanType">Loan Type</label>
                                <Dropdown  disabled={disableEdit} value={editLoanData.LoanType}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, LoanType: e.value.code } }); }}
                                    options={LoanTypes} optionLabel="name"
                                    placeholder={(editLoanData.LoanType !== null) && (editLoanData.LoanType !== undefined) && (editLoanData.LoanType !== "") && (editLoanData.LoanType !== " ") ?
                                        editLoanData.LoanType
                                        :
                                        "Loan Type"}
                                    className="w-full" />
                            </span>


                            <span className="p-label">
                                <label htmlFor="DocumentationType">Documentation Type</label>
                                <Dropdown  disabled={disableEdit} value={editLoanData.DocumentationType}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, DocumentationType: e.value.code } }); }}
                                    options={DocumentationTypes} optionLabel="name"
                                    placeholder={(editLoanData.DocumentationType !== null) && (editLoanData.DocumentationType !== undefined) && (editLoanData.DocumentationType !== "") && (editLoanData.DocumentationType !== " ") ?
                                        editLoanData.DocumentationType
                                        :
                                        "Documentation Type"}
                                    className="w-full" />
                            </span>


                            <span className="p-label">
                                <label htmlFor="LoanPurpose">Loan Purpose</label>
                                <Dropdown disabled={disableEdit}  value={editLoanData.LoanPurpose}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, LoanPurpose: e.value.code } }); }}
                                    options={LoanPurposes} optionLabel="name"
                                    placeholder={(editLoanData.LoanPurpose !== null) && (editLoanData.LoanPurpose !== undefined) && (editLoanData.LoanPurpose !== "") && (editLoanData.LoanPurpose !== " ") ?
                                        editLoanData.LoanPurpose
                                        :
                                        "Loan Purpose"}
                                    className="w-full" />
                            </span>


                            <span className="p-label">
                                <label htmlFor="RefinanceType">Refinance Type</label>
                                <Dropdown  disabled={disableEdit} value={editLoanData.RefinanceType}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, RefinanceType: e.value.code } }); }}
                                    options={RefinanceTypes} optionLabel="name"
                                    placeholder={(editLoanData.RefinanceType !== null) && (editLoanData.RefinanceType !== undefined) && (editLoanData.RefinanceType !== "") && (editLoanData.RefinanceType !== " ") ?
                                        editLoanData.RefinanceType
                                        :
                                        "Refinance Type"}
                                    className="w-full" />
                            </span>

                            <span className="p-label">
                                <label htmlFor="LienPosition">Lien Position</label>
                                <Dropdown  disabled={disableEdit} value={editLoanData.LienPosition}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, LienPosition: e.value.code } }); }}
                                    options={LienPositions} optionLabel="name"
                                    placeholder={(editLoanData.LienPosition !== null) && (editLoanData.LienPosition !== undefined) && (editLoanData.LienPosition !== "") && (editLoanData.LienPosition !== " ") ?
                                        editLoanData.LienPosition
                                        :
                                        "Lien Position"}
                                    className="w-full" />
                            </span>

                            <span className="p-label">
                                <label htmlFor="PropertyType">Property Type</label>
                                <Dropdown disabled={disableEdit}  value={editLoanData.PropertyType}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, PropertyType: e.value.code } }); }}
                                    options={PropertyTypes} optionLabel="name"
                                    placeholder={(editLoanData.PropertyType !== null) && (editLoanData.PropertyType !== undefined) && (editLoanData.PropertyType !== "") && (editLoanData.PropertyType !== " ") ?
                                        editLoanData.PropertyType
                                        :
                                        "Property Type"}
                                    className="w-full" />
                            </span>

                            <span className="p-label">
                                <label htmlFor="OccupancyType">Occupancy Type</label>
                                <Dropdown  disabled={disableEdit} value={editLoanData.OccupancyType}
                                    onChange={(e) => { setEditLoan((s: any) => { return { ...s, OccupancyType: e.value.code } }); }}
                                    options={OccupancyTypes} optionLabel="name"
                                    placeholder={(editLoanData.OccupancyType !== null) && (editLoanData.OccupancyType !== undefined) && (editLoanData.OccupancyType !== "") && (editLoanData.OccupancyType !== " ") ?
                                        editLoanData.OccupancyType
                                        :
                                        "Occupancy Type"}
                                    className="w-full" />
                            </span>

                            {auditPlanType == "Servicing" ?
                                <ServiceLoanData />
                                :
                                <NonServiceLoanData />
                            }


                        </div>

                    </div>
                    { disableEdit ? "" : 
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='w-full'>
                        <Button className="w-8" label="Save Changes" onClick={saveLoanData} />
                    </div>
                    }
                </ScrollPanel>
            </Panel>

        )
    }

    const updateResponseTally = () => {

        setQuestionLoading(true);
        var copy = { ...checklistData };
        console.log('updateResponseTally', copy);
        //Recount this value

        copy.totalAnswered = 0;
        //var allAnswers = 0;
        copy.checklists.forEach(
            (item: any) => {
                //Get all the checklist items
                //console.log('tallyItems', item);
                let chkIds = item.Questions.map((i: any) => i.QcChecklistId);
                //console.log('chkIds', chkIds);

                let totalAnswered = allResponses.filter(f => chkIds.indexOf(f.QcChecklistId) > -1 && f.Response);
                //console.log(totalAnswered, item.totalItems)

                item.totalAnswered = totalAnswered.length;

                if (item.totalAnswered == item.totalItems) {
                    copy.totalAnswered += 1;
                }
            }
        )

        setQuestionLoading(false);

        setchecklistData(copy);

    }

    const getLoanDetail = () => {
        setActivePage('Loan');

    }

    const getAttachmentDetail = (data: any) => {
        setSelectedAttachment(data);
        setActivePage('Attachment');
    }

    const getNewAttachment = () => {
        setActivePage('NewAttachment');
    }

    const getFindingDetail = (data: any) => {
        console.log('getFindingDetail', data);

        if (mode == "QC") {
            getFindingValidationData(data.AuditFindingId)
        }
        else {
            setSelectedAuditFinding(data);
            setActivePage('Finding');
        }
    }

    const Page_Finding = () => {

        const [ReasonsLookup, setReasonsLookup] = useState<string[]>([]);
        const [StatusLookup, setStatusLookup] = useState<string[]>([]);
        // const [ReasonsForDecisionLookup, setReasonsForDecisionLookup] = useState<string[]>([]);
        // const [ReasonsForRevisionLookup, setReasonsForRevisionLookup] = useState<string[]>([]);
        // const [FindingStatusLookup, setFindingStatusLookup] = useState<string[]>([]);
        // const [QCStatusLookup, setQCStatusLookup] = useState<string[]>([]);
        const [showResponse, setShowResponse] = useState<boolean>(false);
        const [avatarData, setAvatarData] = useState<string>();

        useEffect(() => {
            if (mode == "FINDING") {
                getAvatar();
                //getFindingStatusLookup();
                if (userRole == "QC VALIDATOR") {
                    getGetReasonsForDecisionLookup();
                    getFindingStatusLookup();
                }
                else {
                    getGetReasonsForRevisionLookup();
                    getQCStatusLookup();
                }
            }


        }, [])

        const getAvatar = () => {

            authenticatedFetch(
                "/Finding/GetInitials", {}, "string")
                .then(res => {
                    console.log('GetInitials', res);
                    setAvatarData(res);
                });

        }

        const getGetReasonsForDecisionLookup = () => {

            authenticatedFetch(
                "/Finding/GetReasonsForDecisionLookup")
                .then(res => {
                    setReasonsLookup(res);
                });

        }


        const getGetReasonsForRevisionLookup = () => {

            authenticatedFetch(
                "/Finding/GetReasonsForRevisionLookup")
                .then(res => {
                    setReasonsLookup(res);
                });

        }

        const getFindingStatusLookup = () => {

            authenticatedFetch(
                "/Finding/GetFindingStatusLookup")
                .then(res => {
                    setStatusLookup(res);
                });

        }


        const getQCStatusLookup = () => {

            authenticatedFetch(
                "/Finding/GetQCStatusLookup")
                .then(res => {
                    setStatusLookup(res);
                });

        }

        const Refresh = () => {
            var param = {
                AuditFindingId: auditFindingId
            }
            authenticatedFetch(
                "/Finding/GetFindingValidations", {
                method: "POST",
                body: JSON.stringify(param),
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    var data = res;
                    console.log('GetFindingValidations', data);

                    setSelectedAuditFinding(data);

                });
        }
        const [validationDataIsValid, setValidationDataIsValid] = useState<any>({
            Reason: true,
            Status: true,
            Comment: true,
        });

        const checkForErrors = (validationData: any) => {
            let hasError = false;
            if (validationData.Comment === null || validationData.Comment === undefined || validationData.Comment === "") {
                hasError = true;
                setValidationDataIsValid((errors: any) => ({ ...errors, Comment: false }))
            }
            if (validationData.Reason === null || validationData.Reason === undefined || validationData.Reason === "") {
                hasError = true;
                setValidationDataIsValid((errors: any) => ({ ...errors, Reason: false }))
            }
            if (validationData.Status === null || validationData.Status === undefined || validationData.Status === "") {
                hasError = true;
                setValidationDataIsValid((errors: any) => ({ ...errors, Status: false }))
            }
            return hasError;
        }

        const AddResponse = () => {
            let hasError = false;
            hasError = checkForErrors(validationData);
            for (let item in validationDataIsValid) {
                console.log(validationDataIsValid[item]);
                if (!validationDataIsValid[item]) {
                    hasError = true;
                }
            }
            if (hasError) {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'One or more required items were incomplete.', life: 5000 });
                return;
            }
            else {
                //console.log('searchparam', q);
                let api = "";
                if (userRole != "QC VALIDATOR")
                    api = "AddQCAuditorResponse";
                else
                    api = "AddValidatorResponse";

                authenticatedFetch(
                    "/Finding/" + api, {
                    method: "POST",
                    body: JSON.stringify(validationData),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then(res => {
                        //Reset
                        Refresh();
                        setValidationData((s: any) => { return { ...s, Reason: "", Status: "", Comment: "", Attachments: [] } })
                        toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Response Successfully Added', life: 3000 });
                    });
            }
        }
        const [validationData, setValidationData] = useState<any>({
            Reason: "",
            Status: "",
            Comment: "",
            AuditFindingId: auditFindingId,
            Attachments: []
        });

        const uiEndRef = useRef<any>(null)

        const scrollToBottom = () => {
            uiEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }

        useEffect(() => {
            scrollToBottom()
        }, [showResponse]);

        const onUpload = (e: any) => {

            //this.toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
            var result = JSON.parse(e.xhr.response);
            console.log(result);
            console.log('OnUpload Called ', result.FileId);

            //Add to result
            var copy = { ...validationData };
            copy.Attachments.push({
                FileId: result.FileId,
                FileName: result.FileName
            })
            setValidationData(copy);
            //toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Attachment Successfuly Added', life: 3000 });


        };

        const onBeforeUpload = (e: FileUploadBeforeUploadEvent) => {
            console.log("set is uploading", e.formData);
            e.formData.append('auditFindingId', auditFindingId!);

            console.log('onBeforeUploadHeader', header.Authorization);
            console.log('onBeforeUploadHeader', header.Token);
            e.xhr.setRequestHeader("Authorization", header.Authorization);
            e.xhr.setRequestHeader("X-IdToken", header.Token);
        }

        const downloadAttachment = (f: any) => {
            authenticatedFetch(
                "/Finding/GetAttachment?auditFindingAttachmentId=" + f.AuditFindingAttachmentId, {}, "blob"
            ).then((response) => {
                response.blob().then((blob: any) => {

                    // Creating new object of PDF file
                    const fileURL =
                        window.URL.createObjectURL(blob);

                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = f.FileName;
                    alink.click();
                    console.log('downloading file');
                });
            });
        }
        const handleValidationDataChange = (event: any) => {
            const name = event.target.name;
            const value = event.target.value;
            if (value === "" || value === undefined || value === null) {
                setValidationDataIsValid((errors: any) => ({ ...errors, [name]: false }))
            } else {
                setValidationDataIsValid((errors: any) => ({ ...errors, [name]: true }))
            }
            setValidationData((values: any) => ({ ...values, [name]: value }));
        }
        const handleCommentChange = (event: any) => {
            const value = event.htmlValue;
            if (value === "" || value === undefined || value === null) {
                setValidationDataIsValid((errors: any) => ({ ...errors, Comment: false }))
            } else {
                setValidationDataIsValid((errors: any) => ({ ...errors, Comment: true }))

            }
            setValidationData((values: any) => ({ ...values, Comment: value }));
        }
        return (
            <Panel header={"Finding - " + selectedAuditFinding.RecordName} headerTemplate={(o) => editHeaderTemplate('bg-ca-extended-2', o)} style={{ height: "fit-content" }}  >
                <ScrollPanel style={{ paddingTop: "10px", width: '100%', height: '67.5vh' }}>
                    <div style={{ marginTop: "14px" }} className="grid">
                        <div className="col-fixed" style={{ width: "30px" }}><Avatar label={selectedAuditFinding.CreatedBy2} style={{ color: 'white', backgroundColor: '#333' }} className="mr-2" />
                        </div>
                        <div className="col">

                            <div className="col-12 mb-2">
                                <label className="header-label block">QC Initial Finding Details</label>
                                <label className="block mt-1">{selectedAuditFinding.DefectDetail}</label>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label className="header-label">Checklist Number</label><br />
                                        <label>{selectedAuditFinding.QuestionNumber}</label>
                                    </div>
                                    <div className="field col">
                                        <label className="header-label">Question</label><br />
                                        <label>{selectedAuditFinding.QuestionText}</label>
                                    </div>
                                    <div className="field col">
                                        <label className="header-label">QC by</label><br />
                                        <label>{selectedAuditFinding.CreatedBy}</label>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label className="header-label">{auditPlanType != "Servicing" ? "Defect Category" : "Source of Error"}</label><br />
                                        <label>{selectedAuditFinding.DefectCategory}</label>
                                    </div>
                                    <div className="field col">
                                        <label className="header-label">{auditPlanType != "Servicing" ? "Defect Subcategory" : "Error Type"}</label><br />
                                        <label>{selectedAuditFinding.DefectSubCategory}</label>
                                    </div>
                                    <div className="field col">
                                        <label className="header-label">{auditPlanType != "Servicing" ? "Defect Name" : "Is a CAP Required?"}</label><br />
                                        <label>{selectedAuditFinding.DefectName}</label>
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-4">
                                        <label className="header-label">Initial Severity</label><br />
                                        <label>{selectedAuditFinding.SeverityRating}</label>
                                    </div>
                                    <div className="field col-4">
                                        <label className="header-label">Area Responsible</label><br />
                                        <label>{selectedAuditFinding.AreaResponsible}</label>
                                    </div>

                                </div>
                                {
                                    auditPlanType == "Servicing" && selectedAuditFinding.DefectName == "Yes" ?
                                        <div className="formgrid grid">

                                            <div className="field col-4">
                                                <label className="header-label">Corrective Action Plan</label><br />
                                                <label>{selectedAuditFinding.CAPActionPlan}</label>
                                            </div>
                                            <div className="field col-4">
                                                <label className="header-label">CAP Implementation Date</label><br />
                                                <label>{selectedAuditFinding.CAPImplementationDate}</label>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>

                    {mode == "FINDING" &&
                        selectedAuditFinding.Validations.map((data: any) => {
                            return (
                                <div className="grid">
                                    <div className="col-fixed" style={{ width: "30px" }}><Avatar label={data.CreatedBy2} style={{ color: 'white', backgroundColor: data.ResponseFrom == "Validator" ? 'orange' : '#333' }} className="mr-2" /></div>
                                    <div className="col">
                                        <div className="col-12 mb-2">
                                            <label className="header-label">{data.ResponseFrom == "Validator" ? "Validator Response" : "QC Auditor Response"}</label><br />
                                            <div className="val-comments mt-1" dangerouslySetInnerHTML={{ __html: data.Comment }}></div>
                                        </div>
                                        {data.Attachments.length > 0 ?
                                            <div className="col-12 mb-2">
                                                <label className="header-label">Attachment(s)</label><br />
                                                {
                                                    data.Attachments.map((att: any) => {

                                                        return (
                                                            <div className="linkButton text-base" onClick={() => downloadAttachment(att)}>{att.FileName}</div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </div>
                                            : ""}
                                        <div className="col-12 mb-2 grid">
                                            <div className="field col-3">
                                                <label className="header-label">Reason for Decision</label><br />
                                                <label>{data.Reason}</label>
                                            </div>
                                            <div className="field col-3">
                                                <label className="header-label">Updated Finding Status</label><br />
                                                <label>{data.Status}</label>
                                            </div>
                                            <div className="field col-3">
                                                <label className="header-label">{data.ResponseFrom == "Validator" ? "Validated" : "Responded"} On</label><br />
                                                <label>{data.CreatedDate}</label>
                                            </div>
                                            <div className="field col-3">
                                                <label className="header-label">{data.ResponseFrom == "Validator" ? "Validated" : "Responded"} By</label><br />
                                                <label>{data.CreatedBy}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }




                    {mode == "FINDING" ?
                        showResponse ?

                            <div className="grid">
                                <div className="col-fixed" style={{ width: "40px" }}><Avatar label={avatarData} className="mr-2" />
                                </div>
                                <div className="col">
                                    <label className="block">Rebuttal Comments</label>
                                    <Editor value={validationData.Comment}
                                        className={validationDataIsValid.Comment ? '' : 'p-invalid'}
                                        onTextChange={handleCommentChange}
                                        style={{ height: '120px' }} />
                                    <div className="grid mt-2">
                                        <div className="col-fixed" style={{ width: "260px" }}>
                                            <FileUpload auto className="mt-1" chooseLabel='Attach Supporting Doucments' mode="basic" onBeforeSend={onBeforeUpload} onUpload={onUpload} name="UploadedFiles" url={process.env.REACT_APP_API_BASE_URL + "/Finding/AddAttachment"} accept="pdf/*" maxFileSize={10000000} emptyTemplate={<p className="m-0">Drag and drop a file to here to upload.</p>} />
                                        </div>
                                        <div className="col">
                                            <ol>
                                                {
                                                    validationData.Attachments.map((m: any) => {
                                                        return (<li className='linkButton' onClick={() => downloadAttachment(m)}>{m.FileName}</li>)
                                                    })
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="formgrid grid mt-2">

                                        <div className="field col">
                                            <label>{userRole == "QC VALIDATOR" ? "Reason for Decision" : "Reason for Revision"}</label><br />
                                            <Dropdown value={validationData.Reason}
                                                className={validationDataIsValid.Reason ? 'w-full' : 'w-full p-invalid'}
                                                name="Reason"
                                                onChange={handleValidationDataChange}
                                                options={ReasonsLookup} />
                                        </div>

                                        <div className="field col">
                                            <label>{userRole == "QC VALIDATOR" ? "Finding Status" : "Finding QC Result"}</label><br />
                                            <Dropdown value={validationData.Status}
                                                className={validationDataIsValid.Status ? 'w-full' : 'w-full p-invalid'}
                                                name="Status"
                                                onChange={handleValidationDataChange}
                                                options={StatusLookup} />
                                        </div>


                                    </div>
                                    <Button label="Add Response" ref={uiEndRef} className="mt-1 mr-1" onClick={() => AddResponse()} /><Button label="Cancel" className="mt-1" onClick={() => setShowResponse(false)} />
                                </div>
                            </div>
                            :
                            <Button label="Add Response" className="mt-1" onClick={() => setShowResponse(true)} />

                        : ""
                    }


                </ScrollPanel>
            </Panel>
        )
    }


    const Page_Questions = (props: any) => {
        //console.log('selectedChecklist', selectedChecklist);
        //For all the defect related dropdown boxes

        const [saving, setSaving] = useState<boolean>(false);
        const [checklistLoadingDone, setChecklistLoadingDone] = useState<boolean>(false);

        const _opMsg = useRef<any>({
            QuestionNumber: "",
            OriginationRegulationSource: "",
            DirectRegulatoryLink: ""
        });

        const [_opMsgState, set_opMsgState] = useState(_opMsg.current);
        const op = useRef<OverlayPanel>(null);

        const [defectLookup, setDefectLookup] = useState<any[]>([]);
        const [responsibleAreaLookup, setResponsibleAreaLookup] = useState<any[]>([]);

        //For maintaining the responses within a given checklist
        const [checklistResponse, setChecklistResponse] = useState<Record<string, any>>({});

        //Break the defectlookup dropdown boxes to individual dropdowns
        const [defectCategories, setDefectCategories] = useState<string[]>([]);
        const [defectSubCategories, setDefectSubCategories] = useState<string[]>([]);
        const [defectNames, setDefectNames] = useState<string[]>([]);

        const [SeverityRating, setSeverityRating] = useState<string[]>([]);

        const [defectDetailResponseIsValid, setDefectDetailResponseIsValid] = useState<boolean>(true);
        const [defectCategoryResponseIsValid, setDefectCategoryResponseIsValid] = useState<boolean>(true);
        const [defectSubCategoryResponseIsValid, setDefectSubCategoryResponseIsValid] = useState<boolean>(true);
        const [defectNameResponseIsValid, setDefectNameResponseIsValid] = useState<boolean>(true);
        const [severityRatingResponseIsValid, setSeverityRatingResponseIsValid] = useState<boolean>(true);
        const [areaResponseIsValid, setAreaResponseIsValid] = useState<boolean>(true);




        useEffect(() => {
            //Lookups
            //initPage();

            getDefectLookups();
            getSeverityRatingLookups();
            getAreaResponsible();

        }, [])

        useEffect(() => {
            //Lookups
            if (props.checklist)
                initPage();
        }, [props.checklist])

        const initPage = () => {
            console.log('initPage');

            var item = selectedChecklist;
            var matchQuestionResponse = item.Questions.map((x: any) => {
                var matches = allResponses.filter((f: any) => f.QcChecklistId == x.QcChecklistId);
                //console.log('matches', matches);
                if (matches.length > 0) {
                    var resp = matches[0];
                    resp.QcChecklistId = x.QcChecklistId;

                    resp.saved = true;
                    resp.savedResponse = {
                        Response: resp.Response,
                        DefectName: resp.DefectName,
                        DefectCategory: resp.DefectCategory,
                        DefectSubCategory: resp.DefectSubCategory,
                        DefectDetail: resp.DefectDetail,
                        SeverityRating: resp.SeverityRating,
                        CAPActionPlan: resp.CAPActionPlan,
                        CAPImplementationDate: resp.CAPImplementationDate,
                    }

                    if (resp.savedResponse.CAPImplementationDate)
                        resp.savedResponse.CAPImplementationDate2 = new Date(resp.savedResponse.CAPImplementationDate)
                    else
                        resp.savedResponse.CAPImplementationDate2 = null;

                    resp.ControlName = x.ResponseName;
                    return resp
                }
                else {
                    //return an empty place
                    resp = {
                        QcChecklistId: x.QcChecklistId,
                        Response: "",
                        DefectCategory: "",
                        defecttSubCategory: "",
                        DefectName: "",
                        SeverityRating: "",
                        CAPImplementationDate2: null,
                        CAPImplementationDate: null,
                        saved: false,
                        ControlName: x.ResponseName
                    }
                    return resp
                }


            }
            )
            //let matchResponse = Object.fromEntries();

            let responseRecord: Record<string, any> = {};

            for (let item in matchQuestionResponse) {
                let row = matchQuestionResponse[item];
                responseRecord[row.QcChecklistId] = row;
                // Use `key` and `value`
            }

            setChecklistResponse(responseRecord);
            setChecklistLoadingDone(true);
        }
        const getDefectLookups = () => {

            authenticatedFetch(
                "/Audit/getDefectLookup?auditPlanType=" + auditPlanType)
                .then(res => {
                    ////console.log(res);
                    //console.log('Defect Data', res);
                    var data = res as any[];
                    setDefectLookup(data);
                    var defectCategories = data.map(item => item.DefectCategory).filter((value, index, self) => self.indexOf(value) === index)
                    setDefectCategories(defectCategories);
                });

        }

        const getAreaResponsible = () => {

            authenticatedFetch(
                "/Audit/GetAreaResponsible?auditPlanType=" + auditPlanType)
                .then(res => {
                    ////console.log(res);
                    var data = res;
                    //strip out all the junk
                    data.forEach((element: any) => {
                        delete element.AuditReviewChecklists;
                        delete element.QcvalidatorAreaResponsibles;

                    });

                    //console.log('AreaResponsible Data', data);

                    setResponsibleAreaLookup(data);

                });

        }

        const getSeverityRatingLookups = () => {

            authenticatedFetch(
                "/Audit/getSeverityRatingLookup")
                .then(res => {
                    ////console.log(res);
                    var data = res as any[];
                    var SeverityRatings = data.map(item => item.ItemValue);
                    setSeverityRating(SeverityRatings);
                });

        }
        const editResponse = (question: any) => {
            var copy = { ...checklistResponse };
            copy[question.QcChecklistId].saved = false;
            console.log('editResponse', copy[question.QcChecklistId]);
            //Need to update the cateegories dropdown
            if (copy[question.QcChecklistId].DefectCategory) {
                let cat = copy[question.QcChecklistId].DefectCategory;
                let subcat = copy[question.QcChecklistId].DefectSubCategory;

                var defectSubCategories = defectLookup.filter(f => f.DefectCategory === cat).map(item => item.DefectSubCategory).filter((value, index, self) => self.indexOf(value) === index)
                copy[question.QcChecklistId].LK_DefectSubCategories = defectSubCategories;

                if (auditPlanType == "Servicing") {
                    copy[question.QcChecklistId].LK_defectNames = ["Yes", "No"];
                }
                else {
                    var defectNames = defectLookup.filter(f => f.DefectCategory === cat && f.DefectSubCategory === subcat).map(item => item.DefectName).filter((value, index, self) => self.indexOf(value) === index)
                    copy[question.QcChecklistId].LK_defectNames = defectNames;
                }

            }

            if (copy[question.QcChecklistId].CAPImplementationDate)
                copy[question.QcChecklistId].CAPImplementationDate2 = new Date(copy[question.QcChecklistId].CAPImplementationDate);
            else
                copy[question.QcChecklistId].CAPImplementationDate2 = null;


            setChecklistResponse(copy);
            return;
        }

        const cancelResponse = (question: any) => {
            var copy = { ...checklistResponse };

            //If this was saved before, revert back to the saved response.
            if (copy[question.QcChecklistId].savedResponse) {
                var lastSaved = copy[question.QcChecklistId].savedResponse;

                copy[question.QcChecklistId].Response = lastSaved.Response
                copy[question.QcChecklistId].DefectName = lastSaved.DefectName
                copy[question.QcChecklistId].DefectCategory = lastSaved.DefectCategory
                copy[question.QcChecklistId].DefectSubCategory = lastSaved.DefectSubCategory
                copy[question.QcChecklistId].DefectDetail = lastSaved.DefectDetail
                copy[question.QcChecklistId].SeverityRating = lastSaved.SeverityRating

                copy[question.QcChecklistId].CAPActionPlan = lastSaved.CAPActionPlan
                copy[question.QcChecklistId].CAPImplementationDate = lastSaved.CAPImplementationDate

                if (copy[question.QcChecklistId].CAPImplementationDate)
                    copy[question.QcChecklistId].CAPImplementationDate2 = new Date(copy[question.QcChecklistId].CAPImplementationDate);
                else
                    copy[question.QcChecklistId].CAPImplementationDate2 = null;

                copy[question.QcChecklistId].saved = true
            }
            else {
                //Nothing was ever chosen initially
                copy[question.QcChecklistId].Response = null;
            }
            setChecklistResponse(copy);
            return;
        }

        const updateResponse = (question: any, newValue: string) => {
            var copy = { ...checklistResponse };
            copy[question.QcChecklistId].Response = newValue;
            setChecklistResponse(copy);

            //If this is a control question, requery checklist
            if (question.QuestionType == "Control") {

            }
            //        console.log("Does this work? ", question.QcChecklistId,  checklistResponse[question.QcChecklistId]);
        }


        const updateDefectCategoryResponse = (question: any, newValue: string) => {
            if (newValue === null || newValue === "" || newValue === undefined) {
                console.log("Error");
                setDefectCategoryResponseIsValid(false);
            }
            else {
                setDefectCategoryResponseIsValid(true);
            }
            var copy = { ...checklistResponse };

            //if it's the same, don't worry about it
            if (copy[question.QcChecklistId].DefectCategory == newValue)
                return

            copy[question.QcChecklistId].DefectCategory = newValue;

            //Get Subcategory list
            var defectSubCategories = defectLookup.filter(f => f.DefectCategory === newValue).map(item => item.DefectSubCategory).filter((value, index, self) => self.indexOf(value) === index)
            copy[question.QcChecklistId].LK_DefectSubCategories = defectSubCategories;

            //Clear DefectName
            copy[question.QcChecklistId].LK_defectNames = []
            copy[question.QcChecklistId].DefectName = "";

            setChecklistResponse(copy);
        }


        const updateDefectSubCategoryResponse = (question: any, newValue: string) => {
            if (newValue === null || newValue === "" || newValue === undefined) {
                console.log("Error");
                setDefectSubCategoryResponseIsValid(false);
            }
            else {
                setDefectSubCategoryResponseIsValid(true);
            }
            var copy = { ...checklistResponse };

            //if it's the same, don't worry about it
            if (copy[question.QcChecklistId].DefectSubCategory == newValue)
                return

            copy[question.QcChecklistId].DefectSubCategory = newValue;

            if (auditPlanType == "Servicing") {
                //Just use Y/N, for CAP Required 
                let defectNames = ["Yes", "No"]
                copy[question.QcChecklistId].LK_defectNames = defectNames;
            }
            else {
                let value = checklistResponse[question.QcChecklistId].DefectCategory;
                let defectNames = defectLookup.filter(f => f.DefectCategory === value && f.DefectSubCategory === newValue).map(item => item.DefectName).filter((value, index, self) => self.indexOf(value) === index)
                copy[question.QcChecklistId].LK_defectNames = defectNames;
            }

            setChecklistResponse(copy);

        }


        const updateDefectNameResponse = (question: any, newValue: string) => {
            if (newValue === null || newValue === "" || newValue === undefined) {
                console.log("Error");
                setDefectNameResponseIsValid(false);
            }
            else {
                setDefectNameResponseIsValid(true);
            }
            var copy = { ...checklistResponse };
            copy[question.QcChecklistId].DefectName = newValue;
            setChecklistResponse(copy);
        }

        const updateSeverityRatingResponse = (question: any, newValue: string) => {
            if (newValue === null || newValue === "" || newValue === undefined) {
                console.log("Error");
                setSeverityRatingResponseIsValid(false);
            }
            else {
                setSeverityRatingResponseIsValid(true);
            }
            var copy = { ...checklistResponse };
            copy[question.QcChecklistId].SeverityRating = newValue;
            setChecklistResponse(copy);
        }


        const updateAreaResponse = (question: any, newValue: string) => {
            if (newValue === null || newValue === "" || newValue === undefined) {
                console.log("Error");
                setAreaResponseIsValid(false);
            }
            else {
                setAreaResponseIsValid(true);
            }
            var copy = { ...checklistResponse };
            copy[question.QcChecklistId].AreaResponsible = newValue;
            setChecklistResponse(copy);

        }

        const updateDefectDetailResponse = (question: any, newValue: string) => {
            if (newValue === null || newValue === "" || newValue === undefined) {
                console.log("Error");
                setDefectDetailResponseIsValid(false);
            }
            else {
                setDefectDetailResponseIsValid(true);
            }
            var copy = { ...checklistResponse };
            copy[question.QcChecklistId].DefectDetail = newValue;
            setChecklistResponse(copy);
        }
        //        console.log("Does this work? ", question.QcChecklistId,  checklistResponse[question.QcChecklistId]);

        const updateCAPResponse = (question: any, newValue: string) => {
            var copy = { ...checklistResponse };
            copy[question.QcChecklistId].CAPActionPlan = newValue;
            setChecklistResponse(copy);
        }
        const updateCAPImplementationDateResponse = (question: any, newValue: any) => {
            var copy = { ...checklistResponse };
            copy[question.QcChecklistId].CAPImplementationDate = newValue;
            copy[question.QcChecklistId].CAPImplementationDate2 = newValue;
            setChecklistResponse(copy);
        }


        const saveResponse = (question: any, e: any) => {

            setSaving(true);

            //console.log('saveresponse checklist', checklistResponse);
            //Prevent user from clicking again
            if (e.target.disabled) {
                //console.log("Already disabled");
                return;
            }

            setTimeout(() => {

                console.log('saveresponse checklist', checklistResponse, question);
                var copy = { ...checklistResponse };

                //See if this ia  new response
                let newRecord = false;
                if (copy[question.QcChecklistId].AuditReviewChecklistId) {
                    newRecord = true;
                }

                let recordName = copy[question.QcChecklistId].RecordName;

                let hasError = false;

                if (copy[question.QcChecklistId].Response === "Finding") {
                    //Give it a record name if it's a new one
                    if (!recordName) {
                        let nextIdx = auditFindings.length + 1;
                        //For findings give it a name
                        recordName = question.CategoryNavigation.CategoryName + '-' + loanData.LoanName + '-' + nextIdx;
                        console.log('recordName', recordName)
                    }

                    if (copy[question.QcChecklistId].DefectName === null || copy[question.QcChecklistId].DefectName === undefined || copy[question.QcChecklistId].DefectName === "") {
                        setDefectNameResponseIsValid(false);
                        hasError = true;
                    }
                    if (copy[question.QcChecklistId].DefectCategory === null || copy[question.QcChecklistId].DefectCategory === undefined || copy[question.QcChecklistId].DefectCategory === "") {
                        setDefectCategoryResponseIsValid(false);
                        hasError = true;
                    }
                    if (copy[question.QcChecklistId].DefectSubCategory === null || copy[question.QcChecklistId].DefectSubCategory === undefined || copy[question.QcChecklistId].DefectSubCategory === "") {
                        setDefectSubCategoryResponseIsValid(false);
                        hasError = true;
                    }
                    if (copy[question.QcChecklistId].DefectDetail === null || copy[question.QcChecklistId].DefectDetail === undefined || copy[question.QcChecklistId].DefectDetail === "") {
                        setDefectDetailResponseIsValid(false);
                        hasError = true;
                    }
                    if (copy[question.QcChecklistId].SeverityRating === null || copy[question.QcChecklistId].SeverityRating === undefined || copy[question.QcChecklistId].SeverityRating === "") {
                        setSeverityRatingResponseIsValid(false);
                        hasError = true;
                    }
                    if (copy[question.QcChecklistId].AreaResponsible === null || copy[question.QcChecklistId].AreaResponsible === undefined || copy[question.QcChecklistId].AreaResponsible === "") {
                        setAreaResponseIsValid(false);
                        hasError = true;
                    }
                }


                if (hasError) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'One or more required items were incomplete.', life: 5000 });
                    setSaving(false);
                    return
                }
                else {

                    var saveParam = {
                        RecordName: recordName,
                        MonthlyFundedLoanId: id,
                        AuditReviewId: auditReviewId,
                        ClientBank: loanData.Client,
                        AuditReviewChecklistId: copy[question.QcChecklistId].AuditReviewChecklistId,
                        DefectName: copy[question.QcChecklistId].DefectName,
                        DefectCategory: copy[question.QcChecklistId].DefectCategory,
                        DefectSubCategory: copy[question.QcChecklistId].DefectSubCategory,
                        DefectDetail: copy[question.QcChecklistId].DefectDetail,
                        SeverityRating: copy[question.QcChecklistId].SeverityRating,
                        AreaResponsible: copy[question.QcChecklistId].AreaResponsible,
                        CAPActionPlan: copy[question.QcChecklistId].CAPActionPlan,
                        CAPImplementationDate: copy[question.QcChecklistId].CAPImplementationDate,


                        Response: copy[question.QcChecklistId].Response,
                        QcChecklistId: question.QcChecklistId,
                        AuditPlanType: auditPlanType

                    }
                    console.log('saveparam', saveParam);

                    authenticatedFetch(
                        "/Audit/SaveResponse", {
                        method: "POST",
                        body: JSON.stringify(saveParam),
                        headers: {
                            "Content-Type": "application/json",
                        }
                    })
                        .then(res => {
                            //console.log(res);
                            console.log('saveResponse Result', res);
                            var data = res;
                            if (data.ErrorMessage == "Already Processed") {
                                //Ignore
                                console.log(data.ErrorMessage);
                                return;
                            }
                            //Get ChecklistData again
                            console.log('copy[question.QcChecklistId].ResponseName', copy[question.QcChecklistId])
                            if (copy[question.QcChecklistId].ControlName && copy[question.QcChecklistId].ControlName != "")
                                {
                                    getCheckListData();
                                }
                            else
                                {
                                getResponses(checklistData);

                                }

                            // //setchecklistData(data);
                            // //Make a copy of the savedResponse, in case we want to cancel an edit
                            // copy[question.QcChecklistId].saved = true;
                            // copy[question.QcChecklistId].AuditReviewChecklistId = data.AuditReviewChecklistId;

                            // copy[question.QcChecklistId].savedResponse = {
                            //     Response: copy[question.QcChecklistId].Response,
                            //     DefectName: copy[question.QcChecklistId].DefectName,
                            //     DefectCategory: copy[question.QcChecklistId].DefectCategory,
                            //     DefectSubCategory: copy[question.QcChecklistId].DefectSubCategory,
                            //     DefectDetail: copy[question.QcChecklistId].DefectDetail,
                            //     SeverityRating: copy[question.QcChecklistId].SeverityRating
                            // }

                            // setChecklistResponse(copy);
                            //If this was an finding, add to finding list
                            if (copy[question.QcChecklistId].Response === "Finding") {
                                getFindingsData();
                            }

                            setTimeout(() => {
                                setSaving(false);
                                // document.getElementById(question?.QuestionNumber)?.scrollIntoView({
                                //     behavior: 'smooth'
                                // });                       
                            }, 2000);

                        });

                }
            }, 1);

        }

        const linkRegex = new RegExp('(https?://|www\.)');
        const showQuestionInfo = (q: any, e: any) => {
            console.log('showHelp', q, e);

            _opMsg.current = q;
            set_opMsgState(_opMsg.current);
            op.current?.toggle(e);
        }

        // if (!checklistLoadingDone)
        //     return (<div>Loading Page</div>)




        return (checklistLoadingDone &&
            <Panel header={"Checklist - " + selectedChecklist.Category} headerTemplate={(o) => selectedChecklist.totalAnswered == selectedChecklist.totalItems ? checklistHeaderTemplate('bg-ca-extended-6', false, o) : checklistHeaderTemplate('bg-ca-extended-3', true, o)}  >
                <ScrollPanel style={{ paddingTop: "10px", width: '100%', height: '67.5vh' }}>
                    <OverlayPanel ref={op}>
                        <div><b>Reference Question Number: </b><br />{_opMsg.current.QuestionNumber}<br />
                            <p><b>Regulation Source / Description</b><br />
                                {_opMsg.current.OriginationRegulationSource}
                            </p>
                            <p><b>Regulatory Link</b><br />
                                {
                                    linkRegex.test(_opMsg.current.DirectRegulatoryLink) ?
                                        <a href={_opMsg.current.DirectRegulatoryLink} target="_blank" rel="noopener noreferrer" style={{ display: "inline-block" }}>
                                            {_opMsg.current.DirectRegulatoryLink}
                                        </a>
                                        :
                                        _opMsg.current.DirectRegulatoryLink
                                }
                            </p></div>
                    </OverlayPanel>
                    {selectedChecklist.Questions.map((q: any, i: number) => {
                        if (checklistNoResponseOnly && checklistResponse[q.QcChecklistId].saved && checklistResponse[q.QcChecklistId].Response)
                            return ""
                        else
                            return (
                                <div className="grid nested-grid" id={q.QuestionNumber}>

                                    <div className="col-12">
                                        {i > 0 ? <Divider /> : ""}
                                        <div className='grid'>
                                            <div className="col-fixed text-primary font-bold" style={{ width: "30px" }}>
                                                {i + 1}.
                                            </div>
                                            <div className="col">
                                                <span className="text-primary font-bold">{q.QuestionText} <Button rounded text severity="help" aria-label="Favorite" icon="pi pi-question-circle" onClick={(e) => showQuestionInfo(q, e)} style={{ fontSize: '0.5rem', display: "inline", padding: "0", margin: "0", height: "1.1rem", width: "1.1rem", color: 'var(--ca-color-2)' }} /></span>
                                                <p>

                                                    {checklistResponse[q.QcChecklistId].saved ?
                                                        (<div className="flex flex-wrap gap-4"><span className="col-12 mb-2">A: {checklistResponse[q.QcChecklistId].Response}<div className="linkButton" style={{ display: 'inline', marginLeft: '10px' }} onClick={e => editResponse(q)}>Edit Response</div></span></div>)
                                                        :
                                                        q.QuestionType == "Finding" ?
                                                            <div className="flex flex-wrap gap-4">
                                                                <div className="flex align-items-center">
                                                                    <RadioButton inputId={"finding" + i} name={"response" + i} value="Finding" onChange={(e) => updateResponse(q, e.target.value)} checked={checklistResponse[q.QcChecklistId].Response === "Finding"} />
                                                                    <label htmlFor={"finding" + i} className="ml-1 mt-1">Finding</label>
                                                                </div>
                                                                <div className="flex align-items-center">
                                                                    <RadioButton inputId={"nofinding" + i} name={"response" + i} value="No Finding" onChange={(e) => updateResponse(q, e.target.value)} checked={checklistResponse[q.QcChecklistId].Response === "No Finding"} />
                                                                    <label htmlFor={"nofinding" + i} className="ml-1  mt-1">No Finding</label>
                                                                </div>
                                                                <div className="flex align-items-center">
                                                                    <RadioButton inputId={"notapplicable" + i} name={"response" + i} value="Not Applicable" onChange={(e) => updateResponse(q, e.target.value)} checked={checklistResponse[q.QcChecklistId].Response === "Not Applicable"} />
                                                                    <label htmlFor={"notapplicable" + i} className="ml-1  mt-1">Not Applicable</label>
                                                                </div>
                                                                <div className="flex align-items-center">
                                                                    <RadioButton inputId={"informational" + i} name={"response" + i} value="Informational" onChange={(e) => updateResponse(q, e.target.value)} checked={checklistResponse[q.QcChecklistId].Response === "Informational"} />
                                                                    <label htmlFor={"informational" + i} className="ml-1  mt-1">Informational</label>
                                                                </div>

                                                            </div>
                                                            :
                                                            <div className="flex flex-wrap gap-4">
                                                                <div className="flex align-items-center">
                                                                    <RadioButton inputId={"finding" + i} name={"response" + i} value="Yes" onChange={(e) => updateResponse(q, e.target.value)} checked={checklistResponse[q.QcChecklistId].Response === "Yes"} />
                                                                    <label htmlFor={"finding" + i} className="ml-1 mt-1">Yes</label>
                                                                </div>
                                                                <div className="flex align-items-center">
                                                                    <RadioButton inputId={"nofinding" + i} name={"response" + i} value="No" onChange={(e) => updateResponse(q, e.target.value)} checked={checklistResponse[q.QcChecklistId].Response === "No"} />
                                                                    <label htmlFor={"nofinding" + i} className="ml-1  mt-1">No</label>
                                                                </div>
                                                            </div>
                                                    }

                                                    {checklistResponse[q.QcChecklistId].Response === "Finding" ?
                                                        (
                                                            <div>
                                                                {checklistResponse[q.QcChecklistId].saved ?
                                                                    <div>
                                                                        <div className="col-12 mb-2">
                                                                            <label>{checklistResponse[q.QcChecklistId].DefectDetail}</label>
                                                                        </div>
                                                                        <div className="col-12 mb-2">
                                                                            <div className="formgrid grid">
                                                                                <div className="field col">
                                                                                    <label className="header-label">{auditPlanType != "Servicing" ? "Defect Category" : "Source of Error"}</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].DefectCategory}</label>
                                                                                </div>
                                                                                <div className="field col">
                                                                                    <label className="header-label">{auditPlanType != "Servicing" ? "Defect Subcategory" : "Error Type"}</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].DefectSubCategory}</label>
                                                                                </div>
                                                                                <div className="field col">
                                                                                    <label className="header-label">{auditPlanType != "Servicing" ? "Defect Name" : "Is a CAP Required?"}</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].DefectName}</label>
                                                                                </div>
                                                                            </div>
                                                                            {auditPlanType == "Servicing" && checklistResponse[q.QcChecklistId].DefectName == "Yes" ?
                                                                                <div className="formgrid grid">

                                                                                    <div className="field col-4">
                                                                                        <label className="header-label">Corrective Action Plan</label><br />
                                                                                        <label>{checklistResponse[q.QcChecklistId].CAPActionPlan}</label>
                                                                                    </div>
                                                                                    <div className="field col-4">
                                                                                        <label className="header-label">CAP Implementation Date</label><br />
                                                                                        <label>{checklistResponse[q.QcChecklistId].CAPImplementationDate}</label>
                                                                                    </div>
                                                                                </div>
                                                                                : ""
                                                                            }
                                                                            <div className="formgrid grid">
                                                                                <div className="field col-4">
                                                                                    <label className="header-label">Initial Severity</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].SeverityRating}</label>
                                                                                </div>
                                                                                <div className="field col-4">
                                                                                    <label className="header-label">Area Responsible</label><br />
                                                                                    <label>{checklistResponse[q.QcChecklistId].AreaResponsible.AreaResponsibleName}</label>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <p>
                                                                        <div className="col-12">
                                                                            <div className="mb-2">
                                                                                <label className="block mb-2">Defect Details</label>
                                                                                <InputTextarea
                                                                                    className={defectDetailResponseIsValid ? '' : 'p-invalid'}
                                                                                    value={checklistResponse[q.QcChecklistId].DefectDetail} rows={3} style={{ width: "100%", padding: "0px;" }}
                                                                                    onChange={e => updateDefectDetailResponse(q, e.target.value)} />
                                                                            </div>
                                                                            <div className="formgrid grid">

                                                                                <div className="field col">
                                                                                    <label>{auditPlanType != "Servicing" ? "Defect Category" : "Source of Error"}</label>
                                                                                    <Dropdown
                                                                                        className={defectCategoryResponseIsValid ? 'w-full' : 'w-full p-invalid'}
                                                                                        value={checklistResponse[q.QcChecklistId].DefectCategory}
                                                                                        onChange={e => updateDefectCategoryResponse(q, e.value)} options={defectCategories} />
                                                                                </div>
                                                                                <div className="field col">
                                                                                    <label>{auditPlanType != "Servicing" ? "Defect Subcategory" : "Error Type"}</label>
                                                                                    <Dropdown
                                                                                        className={defectSubCategoryResponseIsValid ? 'w-full' : 'w-full p-invalid'}
                                                                                        value={checklistResponse[q.QcChecklistId].DefectSubCategory}
                                                                                        onChange={e => updateDefectSubCategoryResponse(q, e.value)} options={checklistResponse[q.QcChecklistId].LK_DefectSubCategories} />
                                                                                </div>
                                                                                <div className="field col">
                                                                                    <label>{auditPlanType != "Servicing" ? "Defect Name" : "Is a CAP Required?"}</label>
                                                                                    <Dropdown
                                                                                        className={defectNameResponseIsValid ? 'w-full' : 'w-full p-invalid'}
                                                                                        value={checklistResponse[q.QcChecklistId].DefectName}
                                                                                        onChange={e => updateDefectNameResponse(q, e.value)} options={checklistResponse[q.QcChecklistId].LK_defectNames} />
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                auditPlanType == "Servicing" && checklistResponse[q.QcChecklistId].DefectName == "Yes" ?
                                                                                    <div className="formgrid grid">

                                                                                        <div className="field col-9">
                                                                                            <label>Corrective Action Plan</label>
                                                                                            <InputTextarea

                                                                                                value={checklistResponse[q.QcChecklistId].CAPActionPlan} rows={3} style={{ width: "100%", padding: "0px;" }}
                                                                                                onChange={e => updateCAPResponse(q, e.target.value)} />
                                                                                        </div>
                                                                                        <div className="field col-3">
                                                                                            <label>CAP Implementation Date</label>
                                                                                            <Calendar value={checklistResponse[q.QcChecklistId].CAPImplementationDate2} name="CAPImplementationDate2" onChange={e => updateCAPImplementationDateResponse(q, e.target.value)}
                                                                                                className="w-full"
                                                                                            />

                                                                                        </div>
                                                                                    </div>
                                                                                    : ""
                                                                            }

                                                                            <div className="formgrid grid">
                                                                                <div className="field col-4">
                                                                                    <label>Severity Level</label>
                                                                                    <Dropdown
                                                                                        className={severityRatingResponseIsValid ? 'w-full' : 'w-full p-invalid'}
                                                                                        value={checklistResponse[q.QcChecklistId].SeverityRating}
                                                                                        onChange={e => updateSeverityRatingResponse(q, e.value)} options={SeverityRating} />
                                                                                </div>
                                                                                <div className="field col-4">
                                                                                    <label>Area Responsible</label>
                                                                                    <Dropdown
                                                                                        className={areaResponseIsValid ? 'w-full' : 'w-full p-invalid'}
                                                                                        value={checklistResponse[q.QcChecklistId].AreaResponsible}
                                                                                        onChange={e => updateAreaResponse(q, e.value)} optionLabel='AreaResponsibleName' options={responsibleAreaLookup} />
                                                                                </div>
                                                                            </div>



                                                                            {/* <label className="block mt-2 mb-2">Upload Supporting Document (if any)</label>
                                            <FileUpload name="demo[]" url={'/api/upload'} mode="basic" multiple accept="image/*" maxFileSize={1000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} /> */}

                                                                        </div>
                                                                        <div className="col-12 mt-4 flex flex-col">
                                                                            {saving ? <div style={{ margin: "-3px" }} className="mr-2 loading"></div> : <Button disabled={saving} label="Save Response" onClick={e => saveResponse(q, e)}></Button>}
                                                                            {!saving ? <div className="linkButton" style={{ display: 'inline', marginLeft: '10px', lineHeight: "30px" }} onClick={e => cancelResponse(q)}>Cancel</div> : ""}</div>
                                                                    </p>
                                                                }

                                                            </div>

                                                        )
                                                        :
                                                        (
                                                            <div>
                                                                {checklistResponse[q.QcChecklistId].Response && !checklistResponse[q.QcChecklistId].saved ?
                                                                    <div className="mt-4 flex flex-col">
                                                                        {saving ? <div style={{ margin: "-3px" }} className="mr-2 loading"></div> : <Button disabled={saving} label="Save Response" onClick={e => saveResponse(q, e)}></Button>}
                                                                        {!saving ? <div className="linkButton" style={{ display: 'inline', marginLeft: '10px', lineHeight: "30px" }} onClick={e => cancelResponse(q)}>Cancel</div> : ""}</div> : ""
                                                                }
                                                            </div>
                                                        )

                                                    }
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    })}
                </ScrollPanel>
            </Panel>

        )
    }

    const ActivePage = () => {
        switch (activePage) {
            case "Start":
                return page_Start();
                break;
            case "Checklist":
                return <Page_Questions checklist={selectedChecklist} />
                break;
            case "Loan":
                return <Page_Loan LoanData={loanData} />
                break;
            case "Attachment":
                return <Page_Attachment />
                break;
            case "Notes":
                return <Page_Notes />
                break;
            case "Finding":
                return <Page_Finding />
                break;
            case "NewAttachment":
                return <Page_NewAttachment />
                break;
            case "Activities":
                return <Page_Activities />
                break;
            default: return page_Start();
        }

    }

    const showTabLoading = () => {
        return (
            <div>
                <p></p>
                <p className="loading"></p>
                <p></p>
            </div>
        )
    }
    return (
        <div>
            <Nav />

            <Body>
                <Toast ref={toast} position="bottom-center" />

                <div className="flex justify-content-between">
                    {mode == "FINDING" ?
                        <h1>{auditPlanType} Audit Finding - {selectedAuditFinding.RecordName}</h1>
                        :
                        <h1>{auditPlanType} Audit Plan QC - {loanData.LoanName}</h1>
                    }

                    {/* <span className="p-buttonset">
                        <Button label="New Contact" />
                        <Button label="Edit" />
                        <Button label="New Opportunity" />
                        <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
                        <Button icon="pi pi-angle-down" className="mr-2" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
                    </span> */}
                </div>

                <div className="mt-2 grid">
                    <div className="col-4">
                        <Accordion multiple activeIndex={mode == "FINDING" ? [1] : [2]} >
                            <AccordionTab header={
                                Object.keys(loanData).length == 0 ?
                                    <div>
                                        <span>Loan Detail </span><div className="loader"></div>
                                    </div>
                                    :
                                    <span>Loan Detail</span>
                            }
                                pt={{ headerAction: () => ({ className: 'bg-ca-extended-1' }) }}
                            >
                                {Object.keys(loanData).length == 0 ?
                                    showTabLoading()
                                    :
                                    <div className="grid m-0">

                                        <div className='col-12'>{loanData.LoanName}</div>
                                        <div className='col-12'>{loanData.LoanNumber}</div>
                                        <div className='col-12'>{loanData.BorrowersLastName}, {loanData.BorrowersFirstName}</div>
                                        <div className="col-12 linkButton text-base" onClick={() => getLoanDetail()}>See all details...</div>
                                    </div>
                                }
                            </AccordionTab>

                            <AccordionTab header={
                                auditFindingsFetched == false ?
                                    <div>
                                        <span>Findings </span><div className="loader"></div>
                                    </div>
                                    :
                                    <span>Findings ({mode == "FINDING" ? 1 : auditFindings.length})</span>
                            }
                                pt={{ headerAction: () => ({ className: 'bg-ca-2' }) }} >
                                {auditFindingsFetched == false ?
                                    showTabLoading()
                                    :
                                    <p className="m-0">
                                        {mode == "QC" ?
                                            auditFindings.map((data: any) => {
                                                return (
                                                    <div className="grid">
                                                        <div className="col linkButton text-base" onClick={() => getFindingDetail(data)}>{data.RecordName}</div>
                                                    </div>
                                                )
                                            }
                                            )
                                            : <div className="grid">
                                                <div className="col linkButton text-base" onClick={() => getFindingDetail(selectedAuditFinding)}>{selectedAuditFinding.RecordName}</div>
                                            </div>
                                        }
                                    </p>
                                }
                            </AccordionTab>
                            {mode == "FINDING" && userRole == "QC VALIDATOR" ? "" :
                                <AccordionTab header={checklistData.totalItems == undefined ?
                                    <div>
                                        <span>QC Checklist </span><div className="loader"></div>
                                    </div>
                                    :
                                    <span>QC Checklist ({checklistData.totalItems - checklistData.totalAnswered})</span>
                                } pt={{ headerAction: () => ({ className: 'bg-ca-extended-3' }) }}>

                                    {checklistData.totalItems == undefined ?
                                        showTabLoading()
                                        :
                                        ((checklistData.totalItems - checklistData.totalAnswered)) == 0 ?
                                            <div>
                                                <p>
                                                    <span className="text-lg">All questions have been answered. Do you want to set the status of this Audit Plan to <b>QC Completed</b>?</span>
                                                </p>
                                                <Dialog header="Audit Plan QC Complete" visible={QCCompleteAlert} style={{ width: '500px' }} onHide={() => setQCCompleteAlert(false)} >
                                                    <p className="m-0">
                                                        <label className="text-lg">This audit has been set to QC Complete. You will now be directed back to your worklist page. </label>
                                                        <p><Button className="mt-2" label="OK" onClick={() => navigate(`/worklist`)}></Button></p>
                                                    </p>
                                                </Dialog>
                                                <p><Button label="Yes, set to QC Completed" onClick={setQCComplete}></Button></p>
                                                <p>
                                                    <span>Setting the status to QC Completed will notify the QC Manager that this audit is complete and will remove the item from your work list.</span>
                                                </p>
                                            </div>

                                            :
                                            <p className="m-0">
                                                <ol className="checklist-set">
                                                    {
                                                        checklistData.checklists.map((data: any) => {
                                                            if (data.totalAnswered != data.totalItems)
                                                                return (
                                                                    <li><div className="linkButton text-base" style={{ display: 'inline' }} onClick={() => getCheckListDetail(data, allResponses)}>{data.Category}</div><span >- {data.totalAnswered} of {data.totalItems} completed</span></li>
                                                                )
                                                        }
                                                        )
                                                    }
                                                </ol>
                                            </p>
                                    }

                                    {/* <Link to="/qc-questionnaires/new" className="mt-4">
                                    <Button label="New QC Questionnaire" className="mt-4" />
                                </Link> */}
                                </AccordionTab>
                            }
                            <AccordionTab header={
                                noteFetched == false ?
                                    <div>
                                        <span>Notes </span><div className="loader"></div>
                                    </div>
                                    :
                                    <span>Notes ({noteData.length})</span>

                            } pt={{ headerAction: () => ({ className: 'bg-ca-extended-4' }) }}>
                                {noteFetched == false ?
                                    showTabLoading()
                                    :
                                    <span>
                                        <div className="grid m-0">
                                            {
                                                noteData.map((data: any, i: number) => {
                                                    if (i > 2)
                                                        return (<></>)
                                                    else
                                                        return (
                                                            <div className="mb-1 w-full">
                                                                {i > 0 ? <Divider style={{ margin: "1rem 0" }} /> : ""}
                                                                {data.Note.length < 100 ?
                                                                    <span className="block note-display">{data.Note}</span>
                                                                    :
                                                                    <span className="block note-display">{data.Note.substr(0, 100)}...<span onClick={() => setActivePage('Notes')} className="linkButton">more</span></span>
                                                                }
                                                                <span className="block font-italic mt-1 text-sm text-right">--{data.CreatedBy}, {data.CreatedDate}</span>
                                                            </div>
                                                        )
                                                })
                                            }
                                            {noteData.length > 3 ?
                                                <div className="linkButton text-base" onClick={() => setActivePage('Notes')}>
                                                    See all notes
                                                </div>

                                                : ""
                                            }
                                            <div className="col-12"></div>
                                            <label className="block mb-2">New Note</label>
                                            <InputTextarea value={newNote.Note} rows={2} style={{ width: "100%", padding: "0px;" }} onChange={handleChange} className={newNoteError === false ? '' : 'p-invalid'} />
                                        </div>
                                        <Button label="Add Note" className="button-small mt-1" onClick={() => addNote()} />
                                    </span>
                                }
                            </AccordionTab>
                            <AccordionTab header={
                                attachmentDataFetched == false ?
                                    <div>
                                        <span>Attachments </span><div className="loader"></div>
                                    </div>
                                    :
                                    <span>Attachments ({attachmentData.length})</span>
                            } pt={{ headerAction: () => ({ className: 'bg-ca-extended-5' }) }}>
                                {attachmentDataFetched == false ?
                                    showTabLoading()
                                    :
                                    <span>
                                        <div className="grid m-0">
                                            <p className="m-0">
                                                <ol className="checklist-set">
                                                    {
                                                        attachmentData.map((data: any) => {

                                                            return (
                                                                <li><div className="linkButton text-base" style={{ display: 'inline' }} onClick={() => getAttachmentDetail(data)}>{data.FileName}</div></li>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </ol>
                                            </p>
                                            {/* <div className='col-12'>{attachmentData.AttachmentName}</div> */}
                                        </div>
                                        {mode == "FINDING" && userRole == "QC VALIDATOR" ? "" :
                                            <Button label="Upload New Attachment" className="button-small mt-3" onClick={() => getNewAttachment()} />
                                        }
                                    </span>
                                }
                            </AccordionTab>

                            {mode == "FINDING" && userRole == "QC VALIDATOR" ? "" :

                                <AccordionTab header={
                                    checklistData.totalItems == undefined ?
                                        <div>
                                            <span>Completed QC Checklists </span><div className="loader"></div>
                                        </div>
                                        :
                                        <span>Completed QC Checklists ({checklistData.totalAnswered})</span>
                                    // "Completed QC Checklists (" + checklistData.totalAnswered + ")"
                                }
                                    pt={{ headerAction: () => ({ className: 'bg-ca-extended-6' }) }}>
                                    {checklistData.totalItems == undefined ?
                                        showTabLoading()
                                        :
                                        <p className="m-0">
                                            {
                                                checklistData.checklists.map((data: any) => {
                                                    if (data.totalAnswered == data.totalItems)
                                                        return (
                                                            <div ><div className="linkButton text-base" style={{ display: 'inline' }} onClick={() => getCheckListDetail(data, allResponses)}>{data.Category}</div></div>
                                                        )
                                                }
                                                )
                                            }
                                        </p>
                                    }
                                </AccordionTab>
                            }
                            {mode == "FINDING" && userRole == "QC VALIDATOR" ? "" :
                                <AccordionTab header={
                                    activityDataFetched == false ?
                                        <div>
                                            <span>Activity History </span><div className="loader"></div>
                                        </div>
                                        :
                                        <span>Activity History ({activityData.length})</span>
                                    // "Activity History (" + activityData.length + ")"
                                }
                                    pt={{ headerAction: () => ({ className: 'bg-ca-extended-7' }) }}>
                                    {activityDataFetched == false ?
                                        showTabLoading()
                                        :
                                        <div className="grid m-0">
                                            {
                                                activityData.map((data: any, i: number) => {
                                                    if (i > 2)
                                                        return (<></>)
                                                    else
                                                        return (
                                                            <div className="mb-1 w-full">
                                                                {i > 0 ? <Divider style={{ margin: "1rem 0" }} /> : ""}
                                                                <span className="block mb-1 text-sm ">{data.CreatedDate} -{data.CreatedBy}</span>
                                                                {data.ActivityDetail.length < 100 ?
                                                                    <span className="block note-display ml-2">{data.ActivityDetail}</span>
                                                                    :
                                                                    <span className="block note-display ml-2">{data.ActivityDetail.substr(0, 100)}...<span onClick={() => setActivePage('Activities')} className="linkButton">more</span></span>
                                                                }
                                                            </div>
                                                        )
                                                })
                                            }
                                            {activityData.length > 0 ?
                                                <div className="linkButton mt-3 text-base" onClick={() => setActivePage('Activities')}>
                                                    See all activities
                                                </div>
                                                : ""}
                                        </div>
                                    }
                                </AccordionTab>

                            }
                        </Accordion>
                    </div>

                    <div className="col-8">
                        <ActivePage />
                        {/* {getActivePage()} */}
                    </div>
                </div>
            </Body>
        </div>
    )
}
