import {authenticatedFetch} from "./apiUtils";
import {useQuery} from "@tanstack/react-query";

export interface AuditPlanReport {
}

export async function fetchReport(name: string | undefined) {
    return authenticatedFetch("/QcReports/" + name);
}

export const useReport = (name: string | undefined) => {
    const {status, data, error} = useQuery({
        queryKey: ['report', name],
        queryFn: () => fetchReport(name),
        enabled: !!name,
    });

    const reportData = data as AuditPlanReport[] || [];
    return {reportStatus: status, reportData, reportError: error};
};