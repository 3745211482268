import {authenticatedFetch} from "./apiUtils";
import {useQuery} from "@tanstack/react-query";

export interface Role {
    RoleId: string;
    RoleName: string;
}

async function fetchRoles() {
    return authenticatedFetch("/roles/getroles");
}


export const useRoles = () => {
    const {status, data, error} = useQuery({
        queryKey: ['roles'],
        queryFn: fetchRoles,
    });
    const rolesData = data as Role[] || [];
    return {rolesStatus: status, rolesData, rolesError: error};
};
