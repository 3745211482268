import React, { useState, useRef, useEffect, useMemo } from 'react';
import "./Help.css";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

export default function Help(props: any) {
    const toast = useRef<Toast>(null);
    const op = useRef<OverlayPanel>(null);
    const _opMsg = useRef("");
    const [_opMsgState, set_opMsgState] = useState(_opMsg.current);

    const showHelp = (msg: any, e: any) => {
        console.log('showHelp', msg, e);
        _opMsg.current = msg;
        set_opMsgState(_opMsg.current);
        op.current?.toggle(e);
    }

    return (
        <div style={{ display: "inline" }}>
            <Button rounded text severity="help" aria-label="Favorite" icon="pi pi-question-circle" onClick={(e) => showHelp(props.helpMessage, e)} style={{ fontSize: '0.5rem', display: "inline", padding: "0", margin: "0", height: "1.1rem", width: "1.1rem", color: 'var(--ca-color-2)'}} />
            <OverlayPanel ref={op}>
                <div>{_opMsg.current}</div>
            </OverlayPanel>
        </div>
    );
}
