import React, {useState} from 'react';
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';


export default function Search() {
    const navigate = useNavigate();

    const [searchValue, setSearchVaule] = useState<string>("");
    const onSearch = (e:any) => { 
        if (e.code == 'Enter')
        {
            navigate(`/SearchResult?q=${e.target.value}`);
        }
    }
    const handleClick = (e:any) => { 
            navigate(`/SearchResult?q=${searchValue}`);
    }
    return (
        <div className="card flex flex-wrap justify-content-center gap-3 w-24rem">
            <span className="p-input-icon-right w-full">
                <i className="pi pi-search" onClick={handleClick} style={{cursor: "pointer"}} />
                {/* <Button icon="pi pi-search"></Button> */}
                <InputText className="w-full" placeholder="Search" 
                value={searchValue} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchVaule(e.target.value)}
                onKeyDown={(e) => onSearch(e)} />
            </span>
        </div>
    )
}
