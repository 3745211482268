import React, { useState } from "react";
import Body from "../../components/Body";
import Nav from "../../components/Nav";
import { Card } from 'primereact/card';
import './loading.css'
import { ProgressBar } from 'primereact/progressbar';



export default function Loading() {
    return (
        <div>
            <div className="mask">
                <Card title="Loading Page..." className="mask-content">
                    <p></p>
                    <p></p>
                    <ProgressBar mode="indeterminate" style={{ height: '6px', borderRadius: "6px" }}></ProgressBar>
                    <p></p>
                    <p></p>
                </Card>
            </div>
        </div>
    )
}
