import React, {useEffect, useState} from 'react';
import Nav from "../components/Nav";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { SignOutButton } from '../components/SignOutButton';
import { SignInButton } from '../components/SignInButton';
import Body from "../components/Body";

export default function Profile() {
    const { instance, accounts } = useMsal();
    const [idTokenClaims, setIdTokenClaims] = useState(null);

    useEffect(() => {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            console.log("Silent token response:", response)

            // @ts-ignore
            setIdTokenClaims(response.account.idTokenClaims);
        });
    }, [instance, accounts])

    return (
        <div>
            <AuthenticatedTemplate>
                <Nav />

                <Body>
                    <h1>Home</h1>
                    <h5 className="card-title">Welcome {accounts[0].name}</h5>
                    {/*// @ts-ignore*/}
                    <p>Name: {idTokenClaims?.name}</p>
                    {/*// @ts-ignore*/}
                    <p>Email: {idTokenClaims?.preferred_username}</p>
                    {/*// @ts-ignore*/}
                    <p>Id: {idTokenClaims?.oid}</p>
                    <SignOutButton />
                </Body>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Nav />
                <SignInButton />
            </UnauthenticatedTemplate>
        </div>
    )
}
