import React, { useEffect, useRef, useState } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useAccounts } from "../../state/accountState";
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import useHasRole from "../../hooks/validRolesHook";
import NotFound from "../Global/404NotFound";
import Loading from "../Global/loading";

export default function Accounts() {
    const navigate = useNavigate();
    const { accountsStatus, accountsData, accountsError } = useAccounts();

    const toast = useRef<Toast>(null);
    const op = useRef<OverlayPanel>(null);
    const _opMsg = useRef("");
    const [_opMsgState, set_opMsgState] = useState(_opMsg.current);

    const onRowClick = (e: any) => {
        navigate(`/accounts/${e.data.AccountId}`);
    }

    useEffect(() => {
        if (accountsError) {
            // alert('Error saving user');
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'There was a server error while saving the user. Please try again later.', life: 5000 });
        }
    }, [accountsError]);

    // Security to check if the user has any of the allowed roles.
    // NOTE: Keep below any react.js "use" items (i.e., useHook, useEffect, useState, useMemo, etc.)
    let {hasRoles, isLoading} = useHasRole(['System Administrator'])

    if (isLoading) {
        return <Loading />
    }
    else if (!hasRoles) {
        return NotFound();
    }

    const header = (
        <div className="flex justify-content-between">
            <h1>Accounts</h1>
            {/* <Button label="Add Account" icon="pi pi-plus" onClick={() => navigate('/accounts/new')} /> */}
        </div>
    );

    return (
        <div>
            <Nav />

            <Body>

                <OverlayPanel ref={op}>
                    <div>{_opMsg.current}</div>
                </OverlayPanel>
                <Toast ref={toast} position="bottom-center" />

                <DataTable value={accountsData} paginator rows={20} dataKey="id" loading={accountsStatus === 'pending'}
                    emptyMessage="No accounts found" alwaysShowPaginator={false} selectionMode="single"
                    header={header}
                    onRowSelect={onRowClick}
                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    scrollHeight='55.6vh'
                >
                    <Column field="AccountName" header="Account Name" sortable />
                    <Column field="AccountSource" header="Account Source" sortable />

                    <Column field="Street" header="Street" sortable />
                    <Column field="City" header="City" sortable />
                    <Column field="State" header="State" sortable />
                    <Column field="PostalCode" header="Postal Code" sortable />
                    <Column field="Country" header="Country" sortable />

                    <Column field="ShippingStreet" header="Shipping Street" sortable />
                    <Column field="ShippingCity" header="Shipping City" sortable />
                    <Column field="ShippingState" header="Shipping State" sortable />
                    <Column field="ShippingPostalCode" header="Shipping Postal Code" sortable />
                    <Column field="ShippingCountry" header="Shipping Country" sortable />

                    <Column field="RecordStatus" header="Record Status" sortable />


                </DataTable>
            </Body>
        </div>
    )
}
