import React, { useEffect, useRef, useState } from 'react';
import Nav from "../../components/Nav";
import Body from "../../components/Body";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useReport } from '../../state/reportsState';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { authenticatedFetch } from "../../state/apiUtils";
import { Toast } from 'primereact/toast';

export default function PostCloseAuditPlansMonthEnd() {
    const reportTitle = "Post Close Audit Plans Month End";
    const dt = useRef(null);
    const toast = useRef<Toast>(null);
    var reportData: any[] | undefined = [];
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [globalFilter, setGlobalFilter] = useState(null);

    useEffect(() => {
        authenticatedFetch(
            "/QcReports/postcloseauditplansmonthend",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                var reportData = res;
            })
            .catch((error) => {
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Oops! The server encountered an error. Please try again later. If the error persists please reach out to the site admin.', life: 5000 });
                console.log(error);
            });
    }, []);

    const cols = [
        { field: 'PlanName', header: 'Plan', width: '20rem' },
        { field: 'BorrowersFirstName', header: 'Borrowers First Name', width: '13rem' },
        { field: 'BorrowersLastName', header: 'Borrowers Last Name', width: '13rem' },
        { field: 'Channel', header: 'Channel', width: '13rem' },
        { field: 'LoanAmount', header: 'Loan Amount', width: '13rem' },  
        { field: 'LoanType', header: 'Loan Type', width: '13rem' },  
        { field: 'InvestorType', header: 'Loan Type', width: '13rem' },  
        { field: 'Investor', header: 'Loan Type', width: '13rem' },  
        { field: 'InvestorName', header: 'Loan Type', width: '13rem' },  
        { field: 'FundingMonth', header: 'Loan Type', width: '13rem' },  
        { field: 'QCReviewMonth', header: 'Loan Type', width: '13rem' },  
        { field: 'LienPosition', header: 'Loan Type', width: '13rem' },  
        { field: 'LoanPurpose', header: 'Loan Type', width: '13rem' },  
        { field: 'RefinanceType', header: 'Loan Type', width: '13rem' },  
        { field: 'Terms', header: 'Loan Type', width: '13rem' },  
        { field: 'OccupancyType', header: 'Loan Type', width: '13rem' },  
        { field: 'CreditScore', header: 'Loan Type', width: '13rem' },  
        { field: 'LTV', header: 'Loan Type', width: '13rem' },  
        { field: 'CLTV', header: 'Loan Type', width: '13rem' },  
        { field: 'CurrentState', header: 'Loan Type', width: '13rem' },  
        { field: 'ExceptionReviewName', header: 'Loan Type', width: '13rem' },  
        { field: 'InitialQCTestResults', header: 'Loan Type', width: '13rem' },  
        { field: 'FinalQCTestResults', header: 'Loan Type', width: '13rem' },  
        { field: 'DefectDetails', header: 'Loan Type', width: '13rem' },  
        { field: 'InitialSeverityRating', header: 'Loan Type', width: '13rem' },  
        { field: 'FinalSeverityRating', header: 'Loan Type', width: '13rem' },  
        { field: 'ExceptionStatus', header: 'Loan Type', width: '13rem' },  
        { field: 'ExceptionStage', header: 'Loan Type', width: '13rem' },  
        { field: 'DefectCategory', header: 'Loan Type', width: '13rem' },  
        { field: 'DefectSubcategory', header: 'Loan Type', width: '13rem' },  
        { field: 'DefectName', header: 'Loan Type', width: '13rem' },  
        { field: 'AreaResponsible', header: 'Loan Type', width: '13rem' },  
        { field: 'ResponsibleParty', header: 'Loan Type', width: '13rem' },  
        { field: 'AuditPlanStatus', header: 'Loan Type', width: '13rem' },          
        { field: 'AccountName', header: 'Account', width: '15rem' },
        { field: 'CreatedDate', header: 'CreateDate', width: '13rem' },
        { field: 'LoanNumber', header: 'Loan Number', width: '13rem' },
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        AccountName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PlanName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreatedDate: { value: null, matchMode: FilterMatchMode.EQUALS },
        LoanNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        BorrowersFirstName: { value: null, matchMode: FilterMatchMode.EQUALS },
        BorrowersLastName: { value: null, matchMode: FilterMatchMode.EQUALS },
        SampleSize: { value: null, matchMode: FilterMatchMode.EQUALS },
        AuditPlanStatus: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const exportCSV = (selectionOnly: boolean) => {
        //dt.exportCSV();
    };

    const onGlobalFilterChange = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <span className="p-input-icon-left">
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="Export CSV" />   
            </span>
        </div>
        </div>
    );

    return (
        <div>
            <Nav />
            <Body>
            <h1 className="m-0">{reportTitle}</h1>
            <DataTable ref={dt} value={reportData} tableStyle={{ minWidth: '25rem' }}
                    filters={filters} filterDisplay="row" showGridlines stripedRows
                    emptyMessage="No records found" alwaysShowPaginator={false} selectionMode="single"
                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    >
                {cols.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} style={{ minWidth: col.width }} filter sortable />
                ))}
            </DataTable>
            </Body>
        </div>
    )
}
