import {authenticatedFetch} from "./apiUtils";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

export interface Account {
    AccountId: string;
    AdminUserId: string;
    ParentAccountId: string | undefined;
    AccountName: string;
    AccountSource: string | undefined;
    Street: string | undefined;
    City: string | undefined;
    State: string | undefined;
    PostalCode: string | undefined;
    Country: string | undefined;
    ShippingStreet: string | undefined;
    ShippingCity: string | undefined;
    ShippingState: string | undefined;
    ShippingPostalCode: string | undefined;
    ShippingCountry: string | undefined;
    RecordStatus: string;
}

async function fetchAccounts() {
    return authenticatedFetch("/accounts/getaccounts");
}

async function fetchAccount(id: string | undefined) {
    return authenticatedFetch(`/accounts/getaccount/${id}`);
}

export const createAccount = async (account: Account): Promise<Account> => {
    return authenticatedFetch("/accounts/createaccount", {
        method: "POST",
        body: JSON.stringify(account),
        headers: {
            "Content-Type": "application/json",
        }
    });
};

export const updateAccount = async (account: Account): Promise<Account> => {
    return authenticatedFetch(`/accounts/updateaccount/${account.AccountId}`, {
        method: "PUT",
        body: JSON.stringify(account),
        headers: {
            "Content-Type": "application/json",
        }
    });
};


export const useAccounts = () => {
    const {status, data, error} = useQuery({
        queryKey: ['accounts'],
        queryFn: fetchAccounts,
    });

    const accountsData = data as Account[] || [];
    return {accountsStatus: status, accountsData, accountsError: error};
};

export const useAccount = (id: string | undefined) => {
    const {status, data, error} = useQuery({
        queryKey: ['account', id],
        queryFn: () => fetchAccount(id),
        enabled: !!id,
    });

    const accountData = data as Account || null;
    return {accountStatus: status, accountData, accountError: error};
};

export const useCreateAccount = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createAccount,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['accounts']});
        },
        // Optionally handle errors or other states here
    });
};

export const useUpdateAccount = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateAccount,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['accounts']});
        },
        // Optionally handle errors or other states here
    });
};
